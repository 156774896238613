import { getFilterData } from "helpers/backend_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { getProductFail, getProductSuccessful } from "./action"
const { GET_PRODUCT_DATA } = require("./actionType")

function* onLoadProduct({ payload: { token, query } }) {
  try {
    const url = `/order/totalsalesorder?${query}`
    const response = yield call(getFilterData, token, url)
    response?.sales?.forEach(data => {
      data.view = false
    })
    yield put(getProductSuccessful(response))
  } catch (error) {
    // console.log(error.response)
    const message = error?.response?.message || "Invalid Operation"
    yield put(getProductFail(message))
  }
}

export function* productDataSaga() {
  yield takeEvery(GET_PRODUCT_DATA, onLoadProduct)
}
