import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import DashboardFilter from "components/Dashboard/DashboardFilter"
import { tableQuery } from "helpers/Custom/makeQuery"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Container, Row, Spinner, Card } from "reactstrap"
import { getProductData, storeProductData } from "store/ProductTable/action"
import routeListDown from "../../assets/images/routeListDown.svg"
import { AvField, AvForm } from "availity-reactstrap-validation"
import userImage from "../../assets/images/userImage2.svg"
import "../../assets/css/style.css"
import {
  getAttendance,
  getAttendanceExcel,
} from "../../store/Attendance/actions"
import XLSX from "xlsx"
import moment from "moment"
import Select from "react-select"
import { Link, withRouter, useHistory } from "react-router-dom"
import { getAllPJP } from "../../store/PJP/actions"
const tableHead = ["No.", "Route/Store Name", "Days", "Action"]

const TablePjpTwo = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1)
  console.log("currentPage", currentPage)
  const { pjps, authtoken, loading } = useSelector(state => ({
    pjps: state.PJPReducer.pjps,
    authtoken: state.Login.token,
    loading: state.PJPReducer.loading,
  }))
  useEffect(() => {
    dispatch(getAllPJP(authtoken))
  }, [])
  console.log("pjps", pjps)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <CardComponent>
          <div className="user-info-details mb-3">
          <div className="select-role">
              <select
                className="form-select m-auto"
                // value={role}
                // onChange={handleChangeRole}
              >
                <option value="SR">Select SO</option>
                
              </select>
            </div>
            <div className="select-role">
              <select
                className="form-select m-auto"
                // value={role}
                // onChange={handleChangeRole}
              >
                <option value="SR">SO</option>
                <option value="SS">SS</option>
                <option value="TO">TO</option>
                <option value="RBH">RBH</option>
              </select>
            </div>
            <div className="attendance-date">
            <select
                className="form-select m-auto"
                // value={role}
                // onChange={handleChangeRole}
              >
                <option value="Jan">January</option>
                <option value="Feb">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="Jan">June</option>
                <option value="Feb">July</option>
                <option value="March">August</option>
                <option value="April">September</option>
                <option value="May">October</option>
                <option value="Nov">November</option>
                <option value="dec">December</option>
              </select>
            </div>
          </div>
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={[0, 1, 2, 3]}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                page={10}
                currentPage={currentPage}
                isPagination
                loading={loading}
              >
                {loading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner />
                      </div>
                    </div>
                  </tr>
                ) : [0, 1, 2, 3].length ? (
                  [0, 1, 2, 3]?.map((data, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td>kkk</td>

                        <td>jjjj</td>
                        <td>uuu</td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>Currently you have no Data. </span>
                  </NoTableData>
                )}
              </CustomTable>

              {/* table end */}
            </Row>
          </CardComponent>
        </Container>
        <Card>
          <div className="route-excel">
            <div>
              <p className="mb-0">Download Excelsheet</p>
            </div>
            <div>
              <button className="route-excel-download">
                {" "}
                <img src={routeListDown}></img>Download
              </button>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default TablePjpTwo
