import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Row, Spinner } from "reactstrap"
import { getSurveyData, getSurveyQuestion } from "store/survey/actions"
import { toCapitalize } from "../Helpers/Custom/Capitalize"
import CustomTable from "../Layout/CustomTable"
import InnerLayer from "../Layout/InnerLayer"
import NoTableData from "../Layout/NoTableData"
import StepOne from "./StepOne"
import StepTwo from "./StepTwo"

const tableHead = ["Sl. No.", "Question", "Type", "Options"]
const renderTooltipView = props => (
  <Tooltip id="button-tooltip" {...props}>
    View Details
  </Tooltip>
)

const AddQuestion = ({ history }) => {
  const { id } = useParams()
  let dispatch = useDispatch()
  const { adding, loading, singleSurvey, surveyQuestion } = useSelector(
    store => ({
      loading: store.survey.loading,
      singleSurvey: store.survey.singleSurvey,
      surveyQuestion: store.survey?.surveyQuestion,
      adding: store.survey.adding,
    })
  )

  console.log(
    `activationDetailsLoading,`,
    loading,
    adding,
    singleSurvey,
    surveyQuestion
  )
  const { authtoken, users } = useSelector(state => ({
    authtoken: state.Login.token,
    users: state?.UserReducer?.users,
  }))
  useEffect(() => {
    // dispatch(getUsers(authtoken, "ORG", 0, 0))
    dispatch(getSurveyData(id, authtoken))
    dispatch(getSurveyQuestion(id, authtoken))
  }, [])

  console.log("users", users)

  return (
    <React.Fragment>
      <InnerLayer
        title="Activation"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Activation"}
        subTitle={"Create"}
      >
        <Row>
          <div className="col-md-5">
            <div className="card">
              <div className="card-body">
                <h5 className="mt-4 mb-3">Question Create</h5>
                {surveyQuestion?.length ? <StepTwo /> : <StepOne />}
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="card">
              <div className="card-body">
                <CustomTable
                  paginationClass="paginationContainer text-right"
                  data={[]}
                  // pageNo={totalPageNumber}
                  tableHead={tableHead}
                  // setCurrentPage={setCurrentPage}
                  // isPagination
                >
                  {loading ? (
                    <tr style={{ width: "100%" }} colSpan="4">
                      <div
                        className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                        style={{ width: "100%" }}
                      >
                        <div>
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </div>
                    </tr>
                  ) : surveyQuestion?.length > 0 ? (
                    surveyQuestion?.map((data, idx) => (
                      <tr key={idx}>
                        <th scope="row" style={{ paddingLeft: "25px" }}>
                          {idx + 1}
                        </th>
                        <td>{data?.name}</td>
                        <td>{toCapitalize(data?.questionType)}</td>
                        <td>{data?.options?.length}</td>
                        {/* <td>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipView}
                          >
                            <button
                              className="btn btn-outline-info btn-sm"
                              style={{ borderRadius: "10px" }}
                              // onClick={() => setViewDetail(true)}
                            >
                              <i className="bx bx-show mt-1"></i>
                            </button>
                          </OverlayTrigger>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <NoTableData
                      colSpan="4"
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "300px", width: `100%` }}
                    >
                      <span>No data Available</span>
                    </NoTableData>
                  )}
                </CustomTable>
                {/* table end */}
              </div>
            </div>
          </div>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

AddQuestion.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(AddQuestion)
