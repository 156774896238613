import React from 'react';
import RouteInfo from './InfoRoute';
import AmountAnalytic from './AnalyticAmount';
import StoreSellingAnalytic from './AnalyticStoreSelling';
import TableStoreData from './TableRouteStore';
import DeleteRoute from './DeleteRoute';

const DetailsRoute = () => {
    return (
        <div className="page-content">
            <RouteInfo/>
            <AmountAnalytic/>
            {/* <StoreSellingAnalytic/> */}
            <TableStoreData/>
            {/* <DeleteRoute/> */}
        </div>
    );
};

export default DetailsRoute;