import Breadcrumbs from "components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
//redux
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
import "../../assets/css/style.css"
import { getCategorys } from "../../store/category/actions"
import "./datatables.scss"

const TableCategory = ({ history }) => {
  const dispatch = useDispatch()

  const { categories, authtoken, categoryLoading } = useSelector(state => ({
    categories: state.CategoryReducer.categories.category,
    id: state.Login.id,
    authtoken: state.Login.token,
    categoryLoading: state.CategoryReducer.categoryLoading,
  }))
  // console.log('tabledata', products.data);

  useEffect(() => {
    dispatch(getCategorys(authtoken))
  }, [])
  console.log(categories)

  const tableData =
    categoryLoading &&
    categories.map((data, index) => {
      return {
        sl: index + 1,
        heading1: data?.name,
      }
    })

  const productData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },
      {
        label: "name",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div>
          <MetaTags>
            <title>Product | DDC</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col xl="12">
                <Row>
                  {/* storeData Render */}
                  {!categoryLoading ? (
                    <div className="text-center mt-5 pt-5">
                      <Spinner />
                    </div>
                  ) : (
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                Total Category
                              </p>
                              <h4 className="mb-0">{categories.length}</h4>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + "bx-copy-alt" + " font-size-24"
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )}

                  <Col md="3">
                    <Card className="card-info-list">
                      <CardBody>
                        <CardTitle className="mb-0 h4">Add Category</CardTitle>
                        <Row>
                          <Col>
                            <div className="mt-3 mb-2">
                              <Link
                                to="/category-add"
                                className="btn btn-primary btn-sm"
                              >
                                Add Category
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Breadcrumbs title="Home" breadcrumbItem="Category Details" />
            {categoryLoading ? (
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <MDBDataTable
                        responsive
                        noBottomColumns
                        striped
                        data={productData}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <div className="text-center mt-5 pt-5">
                <Spinner />
              </div>
            )}
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

TableCategory.propTypes = {
  history: PropTypes.object,
}

export default withRouter(TableCategory)
