import { combineReducers } from "redux"
import ActivityReducer from "./Activities/reducers"
import ForgetPassword from "./auth/forgetpwd/reducer"
// Authentication
import Login from "./auth/login/reducer"
import LoginError from "./auth/loginError/reducer"
import Profile from "./auth/profile/reducer"
//update profile password
import updateProfilePassword from "./auth/profilepwdupdate/reducer"
import Account from "./auth/register/reducer"
import updatePass from "./auth/updatePassword/reducer"
//verifyEmailSagaReducer
import verifyEmail from "./auth/verifyEmail/reducer"
import BrandReducer from "./Brand/reducers"
import CategoryReducer from "./category/reducers"
//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"
//Dashboard
import Dashboard from "./dashboard/reducer"
import GeoInformationReducer from "./GeoInformation/reducers"
import InventoryReducer from "./Inventory/reducers"
// Front
import ActivationReducer from "./Activation/reducers"
import AttendanceReducer from "./Attendance/reducers"
import Layout from "./layout/reducer"
import OfferReducer from "./Offer/reducers"
import OfficeReducer from "./office/reducers"
import OrderReducer from "./Order/reducers"
import DashboardReducer from "./OrgDashboard/reducers"
import PJPReducer from "./PJP/reducers"
import ProductReducer from "./Product/reducers"
import productData from "./ProductTable/reducer"
import StoreReducer from "./Stores/reducers"
import SurveyReducer from "./survey/reducers"
import SurveyListReducer from "./SurveyInfo/reducers"
import TargetReducer from "./Target/reducers"
import UnitReducer from "./Unit/reducers"
import UserReducer from "./users/reducers"
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  LoginError,
  updatePass,
  Account,
  updateProfilePassword,
  ForgetPassword,
  Profile,
  Dashboard,
  DashboardSaas,
  verifyEmail,
  GeoInformationReducer,
  BrandReducer,
  CategoryReducer,
  UserReducer,
  ProductReducer,
  OfficeReducer,
  UnitReducer,
  StoreReducer,
  OrderReducer,
  OfferReducer,
  PJPReducer,
  TargetReducer,
  DashboardReducer,
  InventoryReducer,
  ActivityReducer,
  productData,
  AttendanceReducer,
  SurveyListReducer,
  ActivationReducer,
  survey: SurveyReducer,
})

export default rootReducer
