import {
    GET_ATTENDANCE,
    GET_ATTENDANCE_SUCCESS,
    GET_ATTENDANCE_FAIL,
    GET_ATTENDANCE_EXCEL,
    GET_ATTENDANCE_EXCEL_SUCCESS,
    GET_ATTENDANCE_EXCEL_FAIL
  } from "./actionTypes"
    

  export const getAttendance = (authtoken, data, role) => ({
    type: GET_ATTENDANCE,
    payload: { authtoken, data, role }
  })
  
  export const getAttendanceSuccess = data => ({
    type: GET_ATTENDANCE_SUCCESS,
    payload: {data},
  })
  
  export const getAttendanceFail = error => ({
    type: GET_ATTENDANCE_FAIL,
    payload: error,
  })
  export const getAttendanceExcel = (authtoken, data) => ({
    type: GET_ATTENDANCE_EXCEL,
    payload: { authtoken, data }
  })
  
  export const getAttendanceExcelSuccess = data => ({
    type: GET_ATTENDANCE_EXCEL_SUCCESS,
    payload: {data},
  })
  
  export const getAttendanceExcelFail = error => ({
    type: GET_ATTENDANCE_EXCEL_FAIL,
    payload: error,
  })