import React, { useEffect } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"



import "../../pages/styles.css"
import SingleInventory from "./SingleInventory"
import { useRouteMatch } from "react-router"

import { Link, useHistory } from "react-router-dom"

const InventoryInfo = () => {
  const history = useHistory()

  const {
    params: { officeId, officeName },
  } = useRouteMatch("/inventory-info/:officeId/:officeName")
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Row>
                <Col md="3">
                  <Card className="card-info-list">
                    <CardBody>
                      <CardTitle className="mb-0 h5">Add Inventory</CardTitle>
                      <Row>
                        <Col>
                          <div className="mt-2 ">
                            <Link
                              // onClick={() =>
                              //  { history.push(`/addInventory/${officeId}`)}
                              // }
                              //  to = { `/addOfcProductInventory/${officeId}`}
                              className="btn btn-primary btn-sm mt-1"
                            >
                              Add
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="3">
                  <Card className="card-info-list">
                    <CardBody>
                      <CardTitle className="mb-0 h5">Product List</CardTitle>
                      <Row>
                        <Col>
                          <div className="mt-2 ">
                            <Link
                              // to="/inventoryProList"
                              className="btn btn-primary btn-sm mt-1"
                            >
                              View Details
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="3">
                  <Card className="card-info-list">
                    <CardBody>
                      <CardTitle className="mb-0 h5">Dp List</CardTitle>
                      <Row>
                        <Col>
                          <div className="mt-2 ">
                            <Link
                              // to="/inventoryDpList"
                              className="btn btn-primary btn-sm mt-1"
                            >
                              View Details
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <TableOffice/> */}
          <SingleInventory />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InventoryInfo
