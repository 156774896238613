import React, { Component, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import classNames from "classnames"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap"


import { Link } from "react-router-dom"

import StoreOrderAmountChart from "./ChartOrderAmount";

const StoreOrderAmount = () => {
  const [periodType, setPeriodType] = useState("yearly")

 
  return (
    <React.Fragment>
    <Card>
                <CardBody>
                 
                  <div className="d-sm-flex flex-wrap">
                  <h4 className="card-title mb-4">Pre Order Amount</h4>
              
                  </div>
               <StoreOrderAmountChart/>
                </CardBody>
              </Card>
  
    </React.Fragment>
  )
}

export default StoreOrderAmount
