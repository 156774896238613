import React from "react"
import { Card, Container, Table, Button } from "reactstrap"
const RouteTableSales = () => {
  return (
    <div>
        <div className="page-content">
      <Card>
        <Table hover>
          <thead className="dp-product-list-activity">
            <tr>
              <th>NO</th>
              <th>Route Name</th>
              <th>Area</th>
              <th>Region</th>
              <th>Territory</th>
              <th>View Details</th>
            </tr>
          </thead>
          <tbody>
            {([0, 1, 2, 3, 4, 5] || []).map((data, key) => {
              return (
                <>
                  <tr>
                    <th scope="row">1</th>
                    <td>Badda Route</td>
                    <td>Aftabnagar</td>
                    <td>Banashri</td>
                    <td>Badda</td>
                    <td>
                      {" "}
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded"
                      >
                        View Details
                      </Button>
                    </td>
                  </tr>
                </>
              )
            })}
          </tbody>
        </Table>
      </Card>
      </div>
    </div>
  )
}

export default RouteTableSales
