import React, { useEffect, useState } from "react";
import { Col, Card, CardBody, Table,Spinner } from "reactstrap";
import ReactApexChart from "react-apexcharts";

import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import {getStoreTopProduct} from '../../store/Stores/actions'
const getChartOptions = index => {
  var options = {
    chart: { sparkline: { enabled: !0 } },
    dataLabels: { enabled: !1 },
    colors: ["#556ee6"],
    plotOptions: {
      radialBar: {
        hollow: { margin: 0, size: "60%" },
        track: { margin: 0 },
        dataLabels: { show: !1 },
      },
    },
  };
  // switch (index) {
  //   case 1:
  //     options["colors"][0] = "#556ee6";
  //     break;
  //   case 2:
  //     options["colors"][0] = "#34c38f";
  //     break;
  //   case 3:
  //     options["colors"][0] = "#f46a6a";
  //     break;
  //   default:
  //     break;
  // }

  return options;
};

const TopProductStore = props => {
    const {
        params: { storeID },
      } = useRouteMatch('/store-analytics/:storeID');
     
    
      const dispatch = useDispatch();
    
      const {  authtoken, topStoreProductLoading, topStoreProducts} = useSelector(state => ({
        topStoreProducts: state.StoreReducer.topStoreProducts,
        id: state.Login.id,
        authtoken: state.Login.token,
        topStoreProductLoading: state.StoreReducer.topStoreProductLoading,
       
    }));
    useEffect(() => {
      dispatch(getStoreTopProduct(authtoken,storeID));
    }, []);
   


 console.log('storeWiseAmount',topStoreProducts);
 

 

  const [seletedMonth, setSeletedMonth] = useState("jan");

  const onChangeMonth = value => {
    setSeletedMonth(value);
    dispatch(getTopSellingProduct(value));
  };

  return (
    <React.Fragment>
       {topStoreProductLoading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
        <Card>
          <CardBody>
            <div className="clearfix mt-2">
       
              <h4 className="card-title mb-4">Top Pre Order product</h4>
            </div>

            <div className="text-muted text-center">
              <h6 className="mb-3 mt-3 text-success fw-bold">{topStoreProducts?.data?.bestsellingproducts[0]?._id}</h6>
              <h4>{topStoreProducts?.data?.bestsellingproducts[0]?.totalamount.toFixed(2)} Tk</h4>
              
            </div>

            <div className="table-responsive mt-4">
              <Table className="table align-middle mb-3">
              
                <tbody> 
                   {(topStoreProducts?.data?.bestsellingproducts || []).map((data, key) => {
                    const options = getChartOptions(key + 1);
                    return (
                      <tr key={key}>
                        <td>
                          <h5 className="font-size-14 mb-1">{data?._id}</h5>
                         
                        </td>

                        <td>
                          <div id="radialchart-1">
                            <ReactApexChart
                              options={options}
                              series={[50]}
                              // type="radialBar"
                              height={30}
                              width={30}
                              className="apex-charts"
                            />
                          </div>
                        </td>
                        <td>
                          <p className="text-muted mb-1">Amount<small></small></p>
                          <h5 className="mb-2">{data.totalamount.toFixed(2)}Tk</h5>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
                    )}
    </React.Fragment>
  );
};

export default TopProductStore;
