import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap"
//Import Breadcrumb
import Select from "react-select"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { addNewOffer } from "../../store/Offer/actions"
import { getProducts } from "../../store/Product/actions"

const moment = require("moment")

const CreateOffer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedProduct, setSelectedProduct] = useState(null)

  const { authtoken, id, products, offerLoading } = useSelector(state => ({
    products: state.ProductReducer.products,
    authtoken: state.Login.token,
    id: state.Login.id,
    offerLoading: state.ProductReducer.offerLoading,
  }))
  useEffect(() => {
    dispatch(getProducts(authtoken, id))
  }, [])

  const handleMultiChange = e => {
    console.log("hello multi")
    console.log(e)
    setSelectedProduct(Array.isArray(e) ? e.map(x => x._id) : [])
  }

  async function handleSubmit(event, errors, values) {
    event.preventDefault()
    let obj = {}
    if (values.title) {
      obj.title = values.title
    }
    if (values.category) {
      obj.category = values.category
    }
    if (values.expireDate) {
      obj.expireDate = values.expireDate
    }

    obj.products = selectedProduct

    obj.org = id
    const year = moment().format("YYYY")
    console.log(year);
    const selectedmonth = `${values.month}-${year}`

    obj.month = selectedmonth

    console.log(obj)
    dispatch(addNewOffer(obj, history, authtoken))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Create Offer</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="New Offer" breadcrumbItem=" New Offer" />
          {offerLoading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner />
            </div>
          ) : (
            <Row>
              <Col md={6} className="m-auto">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Create Offer</CardTitle>

                    <AvForm onSubmit={handleSubmit}>
                      <Row>
                        <Col md={12}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="example-offerTitle-input">
                              Offer Title
                            </Label>
                            <AvField
                              name="title"
                              id="example-offerTitle-input"
                              className="form-control"
                              type="text"
                              placeholder="Type offer title"
                              errorMessage="Enter  Offer Title"
                              validate={{ required: { value: true } }}
                            ></AvField>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="formrow-category">
                              Select Category
                            </Label>
                            <AvField
                              name="category"
                              id="formrow-category"
                              className="form-control"
                              type="select"
                              errorMessage="Select category"
                              validate={{ required: { value: true } }}
                            >
                              <option>Choose</option>
                              <option value="Discount">Discount</option>
                              <option value="Total Amount">Total Amount</option>
                              <option value="Launching">Launching</option>
                              <option value="Merchanding">Merchandising</option>
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroup className="mb-3">
                            <Label>Product</Label>
                            <Select
                              name="products"
                              cacheOptions
                              getOptionLabel={e => e.name}
                              getOptionValue={e => e._id}
                              options={products.data.product}
                              isClearable
                              onChange={handleMultiChange}
                              isMulti
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="formrow-month">Select Month</Label>
                            <AvField
                              name="month"
                              id="formrow-month"
                              className="form-control"
                              type="select"
                              errorMessage="Select month"
                              validate={{ required: { value: true } }}
                            >
                              <option>Choose</option>
                              <option value="01">January</option>
                              <option value="02">February</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">August</option>
                              <option value="09">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="example-expireDate-input">
                              Expire Date
                            </Label>
                            <div className="mb-3 row">
                              <div className="col-md-12">
                                <AvField
                                  name="expireDate"
                                  id="example-expireDate-input"
                                  className="form-control"
                                  type="date"
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div>
                        <button type="submit" className="btn btn-primary w-md">
                          save
                        </button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default CreateOffer
