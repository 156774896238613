import React, { useState, useEffect } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { useRouteMatch } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import CareDrink from "../../assets/images/drink.png"
import Select from "react-select"
import { addDamageInventory } from "../../store/Inventory/actions"
import { getDamageInventory } from "../../store/Inventory/actions"
const AddDamageQuantityInventory = props => {
  const history = useHistory()
  let damageInventory, productName, productQuantity, productPrice, productImage

  const {
    params: { productId },
  } = useRouteMatch("/AddDamageQuantityInventory/:productId")
  console.log(productId)
  const dispatch = useDispatch()

  const { damageInventories, authtoken, id, damageInventoryLoading ,officeId} = useSelector(
    state => ({
      damageInventories: state?.InventoryReducer?.damageInventories,
      authtoken: state.Login.token,
      id: state.Login.id,
      officeId: state.Login.office,
      damageInventoryLoading: state?.InventoryReducer?.damageInventoryLoading,
    })
  )
console.log(officeId);
  useEffect(() => {
    dispatch(getDamageInventory(authtoken, officeId))
  }, [])

  if (!damageInventoryLoading) {
    damageInventory = damageInventories?.data?.inventory[0].product
    {
      (damageInventory || []).map((data, key) => {
        if (data.productid === productId) {
          productName = data?.name
          productQuantity = data?.productQuantity
          productPrice = data?.price
          productImage = data?.image
        }
      })
    }
    console.log('damageInventories',damageInventories)
  }
  async function handleSubmit(event, errors, values) {
    event.preventDefault()
    let obj = {}

    if (values.productQuantity) {
      obj.productQuantity = values.productQuantity
    }
    obj.productid = productId

    console.log(obj)
    console.log(officeId);
    dispatch(addDamageInventory(obj,history,authtoken,officeId))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Add Inventory</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Add Inventory" breadcrumbItem="Add Inventory" />

          <Row>
            <Col md={6} className="m-auto">
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Add Inventory</CardTitle>
                  <Row>
                    <Col xl="7">
                      <div className="text-center p-4 border-end">
                        <div className="avatar-md mx-auto mb-3 mt-1">
                          <img  src={`${process.env.REACT_APP_S3}${productImage}`} className="w-100 h-100" /> 
                         
                        </div>
                        <h5 className="text-truncate">{productName}</h5>
                      </div>
                    </Col>

                    <Col xl="5">
                      <div className="p-4 text-center text-xl-start">
                        <Row>
                          <Col xs="12">
                            <div>
                              <h5 className="text-muted mb-2 text-truncate">
                                Total Skus
                              </h5>
                              <h5>{productQuantity}</h5>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>

                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="example-offerTitle-input">
                            Add Quantity
                          </Label>
                          <AvField
                            name="productQuantity"
                            id="example-offerTitle-input"
                            className="form-control"
                            type="number"
                            placeholder="Type Quantity"
                            errorMessage="Enter  Offer Title"
                            validate={{ required: { value: true } }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Add Inventory
                      </button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default AddDamageQuantityInventory
