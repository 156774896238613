import React,{useEffect} from "react"
import ReactApexChart from "react-apexcharts"
import {getStoreTopProduct} from '../../../store/Stores/actions'
import { useHistory, useRouteMatch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
const StoreOrderAmountChart = () => {
  const {
    params: { storeID },
  } = useRouteMatch('/store-analytics/:storeID');
 

  const dispatch = useDispatch();

  const {  authtoken, topStoreProductLoading, topStoreProducts} = useSelector(state => ({
    topStoreProducts: state.StoreReducer.topStoreProducts,
    id: state.Login.id,
    authtoken: state.Login.token,
    topStoreProductLoading: state.StoreReducer.topStoreProductLoading,
   
}));
useEffect(() => {
  dispatch(getStoreTopProduct(authtoken,storeID));
}, []);
let amountData,monthData
if(!topStoreProductLoading){
  amountData = topStoreProducts?.data?.amountdata
  monthData = topStoreProducts?.data?.month

}
console.log('amountData',amountData);
console.log('monthData',monthData);
console.log('topStoreProducts',topStoreProducts);
  const options = {
    chart: {
      height: 300,
      type: "bar",
      toolbar: {
        show: !1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "14%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      show: !0,
      width: 2,
      colors: ["transparent"],
    },
    series: [
      {
        name: "Amount",
        data: amountData || [],
      },
    ],
    xaxis: {
      categories: monthData || []
    },
    yaxis: {
      title: {
        text: "Tk(Amount)",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ["#556ee6"],
  }
  const series = [
    {
      name: "Amount",
      data: amountData || [],
    },
  ]

  return (
    <ReactApexChart options={options} series={series || []} type="bar" height="320" className="apex-charts" />
  )
}

export default StoreOrderAmountChart
