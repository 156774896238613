import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardTitle,
    CardBody,
} from "reactstrap"


import { Link } from "react-router-dom"


const GEOInfo = () => {
    return (
        <React.Fragment>
            <div >
                <MetaTags>
                    <title>DDC</title>
                </MetaTags>
                <Container fluid className='page-content'>
                    <Row>
                        <h5 className='mb-4 ms-2'>Add GEO Information</h5>
                        <Col xl="12">
                            <Row>

                                {/* storeData Render */}



                                

                                <Col md="3">
                                    <Card className='ms-2 card-info-list'>
                                        <CardBody >
                                            <CardTitle className="mb-0 h4">  Area List</CardTitle>
                                            <Row>
                                                <Col >
                                                    <div className="mt-3 ">
                                                        <Link
                                                            to="/area-list"
                                                            className="btn btn-primary btn-sm"
                                                        >
                                                         View Details
                                                        </Link>
                                                    </div>
                                                </Col>

                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    <Card className='card-info-list'>
                                        <CardBody >
                                            <CardTitle className="mb-0 h4"> Region List</CardTitle>
                                            <Row>
                                                <Col >
                                                    <div className="mt-3">
                                                        <Link
                                                            to="/region-list"
                                                            className="btn btn-primary btn-sm"
                                                        >
                                                           View Details
                                                        </Link>
                                                    </div>
                                                </Col>

                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    <Card className='card-info-list'>
                                        <CardBody >
                                            <CardTitle className="mb-0 h4"> Territory List</CardTitle>
                                            <Row>
                                                <Col >
                                                    <div className="mt-3">
                                                        <Link
                                                            to="/territory-list"
                                                            className="btn btn-primary btn-sm"
                                                        >
                                                           View Details
                                                        </Link>
                                                    </div>
                                                </Col>

                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>


                        </Col>
                    </Row>

                    {/* <BrandList/>    */}
                </Container>
            </div>


        </React.Fragment >
    );
};

export default GEOInfo;