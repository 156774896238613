import { map } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner, Table } from "reactstrap";
//Import Breadcrumb

import { getEditOrders } from '../../../store/Order/actions';



const DistEditedInvoice = props => {
  const dispatch = useDispatch();
  const {
    params: { invoiceID },
  } = useRouteMatch('/distEdited-invoice/:invoiceID');
  console.log(invoiceID);

  const { editOrderLoading, authtoken, dateLoading, id,editedInvoice } = useSelector(state => ({
    editedInvoice: state.OrderReducer.editedInvoice,
    id: state.Login.id,
    authtoken: state.Login.token,
    editOrderLoading: state.OrderReducer.editOrderLoading
}));

useEffect(() => {
  dispatch(getEditOrders(authtoken, invoiceID));
}, []);
  const printInvoice = () => {
    window.print();
  };
  console.log(editedInvoice);


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Invoice Detail | DDC
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
         
          {editOrderLoading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    <h4 className="float-end font-size-16">
                      Order # 342
                    </h4>
                    <div className="mb-4 ">
                    <p className='text-center fw-bold '>Order Invoice</p>
                    </div>
                    



                  </div>
                  <hr />

                  <Row>
                    <Col xs="6" className="mt-3">
                      <address>
                        <strong>Billed To:</strong>
                        <br />
                        {editedInvoice.data.singleOrder.shop.name}
                        <br />
                      {editedInvoice.data.singleOrder.shop.address}

                      </address>
                    </Col>
                    <Col xs="6" className="mt-3 text-end">
                      <address>
                        <strong>Order Date:</strong>
                        <br />
                        {editedInvoice.data.singleOrder.orderTakenDate}
                        <br />
                        <br />
                      </address>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                    </Col>

                    <Col xs="6" className=" text-end">
                      <address>
                        <strong>Dp Name:</strong>
                        <br />
                        {editedInvoice.data.singleOrder.dp.name}
                        <br />
                        <br />
                      </address>
                    </Col>
                  </Row>
                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 fw-bold">Order summary</h3>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-nowrap">
                      <thead>
                        <tr>
                          <th style={{ width: "70px" }}>No.</th>
                          <th >Item</th>
                         
                          <th className="text-end">Price</th>
                        </tr>
                      </thead>
                       <tbody>
                        {map(
                            editedInvoice.data.singleOrder.orderProducts,
                            (item, key) => (
                        <tr >
                          <td>{key+1}</td>
                          <td>{item.productName}</td>
                         
                          <td className="text-end">{item.total}</td>
                        </tr>
                            )
                          )} 
                        <tr>
                          <td colSpan="2" className="text-end">
                            Sub Total
                          </td>
                          <td className="text-end">
                          {editedInvoice.data.singleOrder.subTotal}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="text-end">
                              Vat
                          </td>
                          <td className="text-end">
                          {editedInvoice.data.singleOrder.vat}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="text-end">
                              Discount
                          </td>
                          <td className="text-end">
                          { editedInvoice.data.singleOrder.totalDiscount}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="text-end">
                             Due
                          </td>
                          <td className="text-end">
                        {editedInvoice.data.singleOrder.grandTotal - editedInvoice.data.singleOrder.collectAmount } 
                      
                          </td>
                        </tr>
                        {/* <tr>
                          <td colSpan="2" className="border-0 text-end">
                            <strong>Shipping</strong>
                          </td>
                          <td className="border-0 text-end">
                            323
                          </td>
                        </tr> */}
                         <tr>
                          <td colSpan="2" className="border-0 text-end">
                            <strong>Total</strong>
                          </td>
                          <td className="border-0 text-end">
                            <h4 className="m-0">
                            {editedInvoice.data.singleOrder.grandTotal}

                            </h4>
                          </td>
                        </tr>
                      </tbody> 
                    </Table>
                  </div>
                  <div className="d-print-none">
                    <div className="float-end">
                      <Link
                        to="#"
                        onClick={printInvoice}
                        className="btn btn-success  me-2"
                      >
                        <i className="fa fa-print" />
                      </Link>

                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
                    )}      
        </Container>
      </div>
    </React.Fragment>
  );
};

DistEditedInvoice.propTypes = {
  match: PropTypes.any,
};

export default withRouter(DistEditedInvoice);
