import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner
} from "reactstrap"

import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getBrands } from '../../store/Brand/actions'
import { getTopBrand } from "../../store/OrgDashboard/actions"

const BrandInfo = () => {
  const dispatch = useDispatch();
  const {brands,authtoken, orgid,loading,topBrands,topBrandLoading} = useSelector(state =>({
    brands: state.BrandReducer.brands.data, 
    topBrands: state.DashboardReducer.topBrands.data,
    authtoken: state.Login.token,
    orgid: state.Login.id,
    loading: state.BrandReducer.loading,
    topBrandLoading: state.DashboardReducer.topBrandLoading,
  }))
  useEffect(() => {
    dispatch(getBrands(authtoken, orgid));
  }, []);
  // useEffect(() => {
  //   dispatch(getTopBrand(authtoken))
  // }, [])
  // console.log(topBrands);
  
    return (
        <React.Fragment>
        <div >
          <MetaTags>
            <title>DDC</title>
          </MetaTags>
          <Container fluid>
          {loading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
            <Row>

              <Col xl="12">
                <Row>

                  {/* storeData Render */}
                 
                    <Col md="3">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Total Brands
                              </p>
                              <h4 className="mb-0">{brands?.length}</h4>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
        
              <Col md="3">
              <Card >
                  <CardBody >
                    <CardTitle className="mb-0 h4">Add Brand</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/brand-add"
                            className="btn btn-primary btn-md"
                          >
                            Add Brand
                          </Link>
                        </div>
                      </Col>
                     
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
              <Card >
                  <CardBody >
                    <CardTitle className="mb-0 h4">Brand Analytics</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/brand-info-data"
                            className="btn btn-primary btn-md"
                          >
                           Brand Analytics
                          </Link>
                        </div>
                      </Col>
                     
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
                 
                 
                </Row>
               

              </Col>
            </Row>
            
                    )}
          </Container>
        </div>


      </React.Fragment >
    );
};

export default BrandInfo;