
export const GET_SURVEY = "GET_SURVEY"
export const GET_SURVEY_SUCCESS = "GET_SURVEY_SUCCESS"
export const GET_SURVEY_FAIL = "GET_SURVEY_FAIL"

export const GET_SURVEY_SHOP = "GET_SURVEY_SHOP"
export const GET_SURVEY_SHOP_SUCCESS = "GET_SURVEY_SHOP_SUCCESS"
export const GET_SURVEY_SHOP_FAIL = "GET_SURVEY_SHOP_FAIL"


export const GET_SINGLE_SHOP_QUES = "GET_SINGLE_SHOP_QUES"
export const GET_SINGLE_SHOP_QUES_SUCCESS = "GET_SINGLE_SHOP_QUES_SUCCESS"
export const GET_SINGLE_SHOP_QUES_FAIL = "GET_SINGLE_SHOP_QUES_FAIL"


export const ADD_NEW_SURVEY = "ADD_NEW_SURVEY"
export const ADD_NEW_SURVEY_SUCCESS = "ADD_NEW_SURVEY_SUCCESS"
export const ADD_NEW_SURVEY_FAIL = "ADD_NEW_SURVEY_FAIL"