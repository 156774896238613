import SurveyActionTypes from "./actionTypes"

const INITIAL_STATE = {
  SurveyName: "",
  deleteSurvey: "",
  Survey: [],
  error: false,
  errorMsg: "",
  loading: false,
  singleSurvey: [],
  surveyQuestion: [],
  adding: false,
}

const SurveyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SurveyActionTypes.LOAD_SURVEY_START:
    case SurveyActionTypes.DELETE_SURVEY_START:
      return {
        ...state,
        loading: true,
      }
    case SurveyActionTypes.LOAD_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        Survey: action.payload,
      }
    case SurveyActionTypes.DELETE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSurvey: action.payload,
      }
    case SurveyActionTypes.DELETE_SURVEY_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      }

    case SurveyActionTypes.GET_SURVEY_DATA:
      return {
        ...state,
        loading: true,
      }
    case SurveyActionTypes.GET_SURVEY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        singleSurvey: action.payload,
      }
    case SurveyActionTypes.GET_SURVEY_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      }

    case SurveyActionTypes.GET_SURVEY_QUESTION:
      return {
        ...state,
        loading: true,
      }
    case SurveyActionTypes.GET_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,

        surveyQuestion: action.payload,
      }
    case SurveyActionTypes.GET_SURVEY_QUESTION_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      }

    case SurveyActionTypes.CREATE_SURVEY_QUESTION:
      return {
        ...state,
        adding: true,
      }
    case SurveyActionTypes.CREATE_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        adding: false,
        surveyQuestion: action.payload.data,
      }
    case SurveyActionTypes.CREATE_SURVEY_QUESTION_FAIL:
      return {
        ...state,
        adding: false,
        error: true,
        errorMsg: action.payload.message,
      }

    default:
      return state
  }
}

export default SurveyReducer
