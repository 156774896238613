import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner
} from "reactstrap"

import { Link } from "react-router-dom"
import { getRoute } from "../../../store/GeoInformation/actions";
import TableRoute from "./TableRoute";
import {getTopRegion} from '../../../store/OrgDashboard/actions'
import {getTopSR} from '../../../store/OrgDashboard/actions'
import '../../styles.css'
import RouteInfoTable from "./RouteInfoTable";



const DetailsRoute = () => {
  
 
  const dispatch = useDispatch();

  // console.log(routes.data.route[0]._id);


  useEffect(() => {
    dispatch(getRoute(authtoken,id))
  }, [])
  // useEffect(() => {
  //   dispatch(getTopRegion(authtoken));
  // }, []);
  // useEffect(() => {
  //   dispatch(getTopSR(authtoken));
  // }, []);

  const { routes, authtoken, loading,topSRs, id,routeloading ,topRouteLoading,topRoutes,topSrLoading} = useSelector(state => ({
    routes: state?.GeoInformationReducer?.routes,
    topRoutes: state.DashboardReducer.topRoutes.data,
    topSRs: state.DashboardReducer.topSRs.data,
    authtoken: state.Login.token,
    id: state.Login.id,
    loading: state.GeoInformationReducer.loading,
    routeloading: state.GeoInformationReducer.routeloading,
    topRouteLoading: state.DashboardReducer.topRouteLoading,
    topSrLoading: state.DashboardReducer.topSrLoading,
  }))
      
console.log(routes);
 
    return (
        <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>DDC</title>
          </MetaTags>
          <Container fluid>
            <h4 className='mb-4'> Route Information</h4>
            <Row>

              <Col xl="12">
                <Row>

                  {/* storeData Render */}
                 
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                          {!routeloading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Total Routes
                              </p>
                              <h4 className="mb-0">{routes?.data?.length}</h4>

                            </div>
                    )}
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                          {topRouteLoading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Top Selling Route
                              </p>
                              <h6 className="mb-2">{topRoutes?.data[0]?.routename}</h6>

                            </div>
                    )}
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> */}
                    {/* <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                          {topSrLoading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Top SR
                              </p>
                              <h5 className="mb-1">{topSRs?.test[0]?.name}</h5>

                            </div>
                    )}
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> */}
                  
              <Col md="3">
              <Card className='card-info-list'>
                  <CardBody >
                    <CardTitle className="mb-0 h3">Add New Route</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/add-route"
                            className="btn btn-primary btn-sm mt-2"
                          >
                            Add Route
                          </Link>
                        </div>
                      </Col>
                     
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
                 
                </Row>
               

              </Col>
            </Row>
            {/* <TableRoute routes={routes} routeloading={routeloading}/> */}
            <RouteInfoTable/>

         
          </Container>
        </div>


      </React.Fragment >
    );
};

export default DetailsRoute;