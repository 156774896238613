import { AvField } from "availity-reactstrap-validation"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Badge, Button, Container, Row, Spinner } from "reactstrap"
import { getSurvey } from "../../store/SurveyInfo/action"

const tableHead = [
  "No.",
  "Survey Title",
  "Created Date",
  "Survey Code",
 
  "Status",
  "View Details",
]
const SurveyTable = props => {
  console.log(props)
  const { history } = props
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()

  const { authtoken, surveyList, surveyListLoading } = useSelector(state => ({
    authtoken: state.Login.token,
    surveyList: state?.SurveyListReducer?.surveyList,
    surveyListLoading: state?.SurveyListReducer?.surveyListLoading,
  }))

  useEffect(() => {
    dispatch(getSurvey(authtoken))
  }, [currentPage])

  console.log("surveyList", surveyList)
  return (
    <React.Fragment>
      <MetaTags>
        <title>DDC</title>
      </MetaTags>
      <Container fluid>
        <CardComponent>
          <div className="user-info-details">
            <div className="attendance-date">
              <AvForm>
                <AvField
                  name="startTime"
                  id="example-time-input"
                  className="form-control mb-3"
                  type="search"
                  placeholder="Search.."
                  //   onChange={e => setValue(e.target.value)}
                ></AvField>
              </AvForm>
            </div>
          </div>
          <Row>
            <CustomTable
              paginationClass="paginationContainer text-right"
              //   data={userInfo?.users}
              tableHead={tableHead}
              setCurrentPage={setCurrentPage}
              //   page={pageNumber}
              //   currentPage={currentPage}
              isPagination
              //   productLoading={userInfoLoading}
            >
              {surveyListLoading ? (
                <tr style={{ width: "100%" }}>
                  <div
                    className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <Spinner />
                    </div>
                  </div>
                </tr>
              ) : surveyList?.survey.length ? (
                surveyList?.survey.map((survey, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <th scope="row">{idx + 1}</th>
                      <td>{survey?.surveyName}</td>
                      <td>{survey?.createdAt?.slice(0, 10)}</td>
                      <td>{survey?.surveyId}</td>
                      {/* <td>{store.phoneNumber}</td> */}
                   
                      <td>
                        <Badge className={"bg-success"}>Active</Badge>
                      </td>
                      {/* <td>
                        <Link
                          to="#"
                          className="text-success"
                          onClick={() => handleEditUser(user)}
                        >
                          <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                          />
                        </Link>
                      </td> */}
                      <td>
                        <Button
                          color="primary"
                          className="btn-sm btn-rounded"
                          onClick={() => {
                            history.push(`/set-question/${survey?._id}`)
                          }}
                        >
                          Create Question
                        </Button>
                        <Button
                          color="primary"
                          className="btn-sm btn-rounded ms-2"
                          onClick={() => {
                            history.push(`/survey-shop/${survey?._id}`)
                          }}
                        >
                         View
                        </Button>
                      </td>
                      
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <NoTableData
                  colSpan="9"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>Currently you have no Data. </span>
                </NoTableData>
              )}
            </CustomTable>

            {/* table end */}
          </Row>
        </CardComponent>
      </Container>
    </React.Fragment>
  )
}

SurveyTable.propTypes = {
  history: PropTypes.object,
}
export default withRouter(SurveyTable)
