import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getLineManager } from "../../store/users/actions"
import Select from "react-select"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getOffices } from "../../store/office/actions"
// import { addNewUser } from "../../store/users/action";
import { addNewUser } from "../../store/users/actions"
import { getRegion } from "../../store/GeoInformation/actions"
import {
  clearFilters,
  getAreaByRegion,
  getTerritoryByArea,
  onAreaFilterChange,
  onRegionFilterChange,
  onTerritoryFilterChange,
} from "../../store/actions.js"
const AddUser = () => {
  const [selectedLineManager, setSelectedLineManager] = useState(null)
  const [file, setfile] = useState("")
  const [testloading, setloading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [loadArea, setLoadArea] = useState(false)
  const [loadTerritory, setLoadTerritory] = useState(false)
  const [role, setRole] = useState()


  const dispatch = useDispatch()
  const history = useHistory()
  const { authtoken, loading, offices, id, userLoading,lineManager,lineManagerLoading,area,
    region,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    // selectedTerritory,
    // currentSelection,
    areaList,
    territoryList } = useSelector(
    state => ({
      authtoken: state.Login.token,
      id: state.Login.id,
      loading: state.OfficeReducer.loading,
      offices: state.OfficeReducer.offices.office,
      userLoading: state.UserReducer.userLoading,
      lineManager: state.UserReducer.lineManager,
      lineManagerLoading: state.UserReducer.lineManagerLoading,
      area: state.GeoInformationReducer.area.area,
      region: state.GeoInformationReducer.region.region,
      areaList: state.DashboardReducer.areaList,
      territoryList: state.DashboardReducer.territoryList,
      selectedRegion: state.DashboardReducer.selectedRegion,
      selectedArea: state.DashboardReducer.selectedArea,
      selectedTerritory: state.DashboardReducer.selectedTerritory,
    })
  )
  console.log(loading)
  console.log(offices)
  console.log('lineManager',lineManager);

  useEffect(() => {
    dispatch(getOffices(authtoken))
  }, [])
  const handleLineManager = value => {
    setSelectedLineManager(value._id)
  }
  useEffect(() => {
    // dispatch(getData())

    dispatch(getRegion(authtoken))
  }, [])
  useEffect(() => {
    if (Object?.keys(selectedRegion)?.length) {
      dispatch(getAreaByRegion(authtoken, selectedRegion?._id))
    }
  }, [loadArea])

  useEffect(() => {
    if (Object?.keys(selectedArea)?.length) {
      dispatch(getTerritoryByArea(authtoken, selectedArea?._id))
    }
  }, [loadTerritory])
  const handleRegion = data => {
    dispatch(onRegionFilterChange(data))
    setLoadArea(!loadArea)
  }

  const handleArea = data => {
    dispatch(onAreaFilterChange(data))
    setLoadTerritory(!loadTerritory)
  }
  const handleTerritory = data => {
    dispatch(onTerritoryFilterChange(data))
  }
  const handleChange = e => {
    setRole(e.target.value)


    if (e.target.value == "SR") {
      
      setIsDisabled(false)
    }
    if (e.target.value == "DP") {
      setIsDisabled(true)

     
    }
    if (e.target.value == "SS") {
      setIsDisabled(false)
    }
    if (e.target.value == "TO") {
      setIsDisabled(false)
    }
    if (e.target.value == "RBH") {
      setIsDisabled(true)
    }
    if (e.target.value == "DISTRIBUTOR") {
      setIsDisabled(true)
    }
  }
  console.log('role----',role);
  useEffect(() => {
    dispatch(getLineManager(authtoken, role))
  }, [role])
  console.log(lineManager);
  async function handleSubmit(event, errors, values) {
    console.log(event)
    event.preventDefault()
    setloading(true)
    let formdata = new FormData()
    console.log(values)

    let obj = {}
    if (
      values.name &&
      values.email &&
      values.office &&
      values.phoneNumber &&
      values.employeeId &&
      values.phoneNumber &&
      values.role &&
      values.password &&
      values.passwordConfirm

    ) {
      
      formdata.append("name", values.name)
      formdata.append("email", values.email)
      formdata.append("office", values.office)
      formdata.append("phoneNumber", values.phoneNumber)
      formdata.append("employeeId", values.employeeId)
      if(values.role === 'SR'){
        formdata.append("linemanager", selectedLineManager)
      }
      if(values.role === 'SS')
      {
        formdata.append("linemanager",  selectedLineManager)
      }
      if(values.role === 'TO')
      {
        formdata.append("linemanager",  selectedLineManager)
      }
      // else if(values.role === 'DISTRIBUTOR'){
      //   formdata.append("linemanager",  id)
      // }
      formdata.append("role", values.role)
      formdata.append("password", values.password)
      formdata.append("passwordConfirm", values.passwordConfirm)
      formdata.append("org", id)
      formdata.append("photo", file)
      formdata.append("region",selectedRegion._id )
      formdata.append("area", selectedArea._id)
      formdata.append("teritori", selectedTerritory._id)
  
  
      for (var key of formdata.entries()) {
        console.log(key[0] + ", " + key[1])
      }
  
      dispatch(addNewUser(formdata, history, authtoken))
    } 
  }


  console.log(userLoading)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Add User</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="User" breadcrumbItem=" Add User" />
          <Row>
            <Col md={6} className="m-auto">
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Add User</CardTitle>

                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Full name
                          </Label>
                          <AvField
                            name="name"
                            id="formrow-firstname-Input"
                            type="text"
                            className="form-control"
                            placeholder="Type User Name"
                            errorMessage="Enter Name"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="phoneNumber">Phone Number</Label>
                          <AvField
                            name="phoneNumber"
                            type="text"
                            className="form-control"
                            id="phoneNumber"
                            placeholder="Type phone Number"
                            errorMessage="Enter Phone Number"
                            required
                            validate={{
                              minLength: {
                                value: 11,
                                errorMessage: "Your phone must be 11 digits",
                              },
                              maxLength: {
                                value: 11,
                                errorMessage: "Your phone must be 11 digits",
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <AvField
                            name="email"
                            type="email"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Type Email address"
                            errorMessage="Enter Email"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value:
                                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                errorMessage: "Enter a Valid email",
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-InputState">
                            Select Role
                          </Label>
                          <AvField
                            name="role"
                            id="formrow-InputState"
                            className="form-control"
                            type="select"
                            errorMessage="Select Role"
                            onChange={e => handleChange(e)}
                            validate={{ required: { value: true } }}
                          >
                            <option value="">Select Role</option>

                            <option value="SR">SO</option>
                            <option value="DP">DSR</option>
                            <option value="SS">SS</option>
                            <option value="TO">TO</option>
                            <option value="RBH">RBH</option>

                            <option value="DISTRIBUTOR">Distributor</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="employeeId">Employee Id</Label>
                          <AvField
                            name="employeeId"
                            type="text"
                            className="form-control"
                            id="employeeId"
                            placeholder="Type Employee ID"
                            errorMessage="Type Employee ID"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      {loading ? (
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField type="select" name="office" label="Office">
                              <option>Not Selected</option>
                              {offices.map(index => {
                                return (
                                  <option key={index._id} value={index._id}>
                                    {index.name}
                                  </option>
                                )
                              })}
                            </AvField>
                          </div>
                        </Col>
                      ) : (
                        <p>hello</p>
                      )}
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-password-Input">
                            Password
                          </Label>
                          <AvField
                            name="password"
                            type="password"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder="Type Password"
                            errorMessage="Enter Password"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-confirmPassword-Input">
                            Confirm Password
                          </Label>
                          <AvField
                            name="passwordConfirm"
                            type="password"
                            className="form-control"
                            id="formrow-confirmPassword-Input"
                            placeholder="Type Confirm Password"
                            errorMessage="Confrim Password"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="linemanager">Supervisor</Label>
                          {!lineManagerLoading ? (
                            <Select
                              name="linemanager"
                              cacheOptions
                              isDisabled={isDisabled}
                              getOptionLabel={e => e.employeeId}
                              getOptionValue={e => e._id}
                              options={lineManager.users}
                              defaultOptions
                              onChange={handleLineManager}
                            />
                          ) : (
                            <span />
                          )}
                         
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="discount">Image</Label>
                          <AvField
                            type="file"
                            className="form-control"
                            id="formrow-brand-Input"
                            name="image"
                            onChange={e => {
                              setfile(e.target.files[0])
                            }}
                          />
                        </FormGroup>
                      </Col>
                          <div style={{ minWidth: "150px" }} className="me-3 mb-3">
                        <Label htmlFor="example-endTime-input">Region</Label>
                        <Select
                          name="region"
                          classNamePrefix="select2-selection"
                          // disabled={loadingCondition}
                          style={{ minWidth: "100px" }}
                          //   cacheOptions
                          getOptionLabel={e => e.name}
                          getOptionValue={e => e._id}
                          // defaultInputValue={}
                          placeholder={"Select Region"}
                          options={region}
                          onChange={handleRegion}
                        />
                      </div> 
                       {Object.keys(selectedRegion)?.length &&
                      areaList?.length ? (
                        <div
                          style={{ minWidth: "150px" }}
                          className="me-3 mb-3"
                        >
                          <Label htmlFor="example-endTime-input">Area</Label>
                          <Select
                            name="area"
                            classNamePrefix="select2-selection"
                            // disabled={loadingCondition}
                            style={{ minWidth: "100px" }}
                            //   cacheOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            // defaultInputValue={}
                            placeholder={"Select Area"}
                            options={areaList}
                            onChange={handleArea}
                          />
                        </div>
                      ) : null} 
 
                      {Object.keys(selectedArea)?.length &&
                      territoryList.length ? (
                        <div
                          style={{ minWidth: "150px" }}
                          className="me-3 mb-3"
                        >
                            <Label htmlFor="example-endTime-input">Territory</Label>
                          <Select
                            name="teritori"
                            classNamePrefix="select2-selection"
                            // disabled={loadingCondition}
                            style={{ minWidth: "100px" }}
                            //   cacheOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            // defaultInputValue={}
                            placeholder={"Select Territory"}
                            options={territoryList}
                            onChange={handleTerritory}
                          />
                        </div>
                      ) : null} 
                    </Row>
                    {!userLoading ? (
                      <div className="p-4 d-flex justify-content-center align-items-center p-2 mb-3 mt-5 mr-auto">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    ) : (
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          onSubmit={handleSubmit}
                        >
                          Save User
                        </button>
                      </div>
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* end row  */}
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default AddUser
