import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import { getRoute } from "../../store/GeoInformation/actions"
import { addNewStore } from "../../store/Stores/actions"
import { getOffices } from "../../store/office/actions"

const AddStore = () => {
    const [selectedOffice, setSelectedOffice] = useState(null)
  const [testloading, setloading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const { authtoken, id, routes, routeloading,offices } = useSelector(state => ({
    authtoken: state.Login.token,
    id: state.Login.id,
    routes: state.GeoInformationReducer.routes.data,
    offices: state.OfficeReducer.offices.office,
    routeloading: state.GeoInformationReducer.routeloading,
  }))
  console.log(routes)
  useEffect(() => {
    dispatch(getRoute(authtoken, id))
  }, [])
  useEffect(() => {
    dispatch(getOffices(authtoken))
  }, [])
  const handleChangeOffice = value => {
    setSelectedOffice(value._id)
  }
  console.log(offices);
  console.log(routeloading)
  console.log("addd", id)
  function handleSubmit(event, errors, values) {
    event.preventDefault()

    let obj = {}
    if (values.name) {
      obj.name = values.name
    }
    if (values.phoneNumber) {
      obj.phoneNumber = values.phoneNumber
    }
    if (values.shopCode) {
      obj.shopCode = values.shopCode
    }
    if (values.ownerName) {
      obj.ownerName = values.ownerName
    }
    if (values.phoneNumber) {
      obj.phoneNumber = values.phoneNumber
    }
    if (values.lat) {
      obj.lat = values.lat
    }
    if (values.lon) {
      obj.lon = values.lon
    }
    if (values.address) {
      obj.address = values.address
    }
    if (values.route) {
      obj.route = values.route
    }
    if (values.storeType) {
      obj.storeType = values.storeType
    }
    obj.office = selectedOffice
    obj.org = id
    console.log(obj)
    dispatch(addNewStore(obj, history, authtoken))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Add Store</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Add New Store" breadcrumbItem="Add New Store" />
          <Row>
            <Col md={6} className="m-auto">
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Add Store</CardTitle>

                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-name-Input">Store name</Label>
                          <AvField
                            name="name"
                            type="text"
                            className="form-control"
                            id="formrow-name-Input"
                            placeholder=" Store Name"
                            errorMessage="Enter Store Name"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-shopCode">Store Code</Label>
                          <AvField
                            name="shopCode"
                            type="text"
                            className="form-control"
                            id="formrow-shopCode"
                            placeholder=" Store Code"
                            errorMessage="Enter Store Code"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-ownerName-Input">
                            Owner Name
                          </Label>
                          <AvField
                            name="ownerName"
                            type="text"
                            className="form-control"
                            id="formrow-ownerName-Input"
                            placeholder=" Owner Name"
                            errorMessage="Enter Owner Name"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-InputStorePhone">
                            Phone Number
                          </Label>
                          <AvField
                            name="phoneNumber"
                            type="text"
                            className="form-control"
                            id="formrow-InputStorePhone"
                            placeholder="Store Phone Number"
                            errorMessage="Enter Phone Number"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-lat-Input">Lat</Label>
                          <AvField
                            name="lat"
                            type="text"
                            className="form-control"
                            id="formrow-lat-Input"
                            placeholder=" Lattitude"
                            errorMessage="Enter  Lattitude"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-lon-Input">lon</Label>
                          <AvField
                            name="lon"
                            type="text"
                            className="form-control"
                            id="formrow-lon-Input"
                            placeholder="Longitude"
                            errorMessage="Enter Longitude Name"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-ownerName-Input">
                            Address
                          </Label>
                          <AvField
                            name="address"
                            type="text"
                            className="form-control"
                            id="formrow-ownerName-Input"
                            placeholder="address"
                            errorMessage="Enter address Name"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>

                      {routeloading ? (
                        <Col md={6}>
                          <div className="mb-3">
                            <AvField type="select" name="route" label="Route">
                              <option>Not Selected</option>
                              {routes.route.map(index => {
                                return (
                                  <option key={index._id} value={index._id}>
                                    {index.name}
                                  </option>
                                )
                              })}
                            </AvField>
                          </div>
                        </Col>
                      ) : (
                        <p>hello</p>
                      )}
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-storeType-Input">
                            Store Type
                          </Label>
                          <AvField
                            name="storeType"
                            type="text"
                            className="form-control"
                            id="formrow-storeType-Input"
                            placeholder="Store Type"
                            errorMessage="Enter store Name"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="office">Office Name</Label>

                          <Select
                            name="office"
                            cacheOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            options={offices}
                            defaultOptions
                            onChange={handleChangeOffice}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div className="mb-3">
                          <div>
                            <Label htmlFor="formFilelg" className="form-label">
                              Owner Image
                            </Label>
                            <Input
                              className="form-control form-control-lg"
                              id="formFilelg"
                              type="file"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {testloading ? (
                      <div className="p-4 d-flex justify-content-center align-items-center p-2 mb-3 mt-5 mr-auto">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    ) : (
                      <>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Save Store
                          </button>
                        </div>
                      </>
                    )}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* end row  */}
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default AddStore
