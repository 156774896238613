import { toast } from "react-toastify";
import { call, delay, put, takeEvery } from "redux-saga/effects";
import { toaster } from "../../pages/Helpers/Custom/Toaster";
// import {
//   getActivationData,
//   getQuestionDetailsData,
//   updateActivationData,
//   updateQuestionData,
// } from "../../helpers/api_helper.js";
import { getData, postData } from "../../helpers/backend_helper.js";
import {
  addActivationFail,
  addActivationSuccess,
  getActivationDetails,
  getActivationDetailsFail,
  getActivationDetailsSuccess,
  getActivationsFail,
  getActivationsSuccess,
  getQuestionsDetailsFail,
  getQuestionsDetailsSuccess,
  updateActivationFail,
  updateQuestionFail,
} from "./actions";
import {
  ADD_ACTIVATION,
  GET_ACTIVATION_DETAILS,
  GET_ALL_ACTIVATION,
  GET_QUESTION_DETAILS,
  UPDATE_ACTIVATION,
  UPDATE_QUESTION,
} from "./actionTypes";

function* onAddNewActivation({ payload: { data, resetForm, authtoken } }) {
  try {
    const url = "/survey/question";
    const response = yield call(postData, url, data, authtoken);
    yield put(addActivationSuccess(response?.activation));
    toaster("success", "Question data added successfully!");
    if (response?.status === "success") {
      yield put(getActivationDetails(authtoken, response?.activation?._id));
    }
    resetForm && resetForm();
  } catch (error) {
    const message = error?.response?.data?.message || "Add Question failed";
    yield put(addActivationFail(message));
    toaster("error", message);
  }
}

// function* fetchActivations({
//   payload: { authtoken, orgId, status, list, currentPage },
// }) {
//   try {
//     delay(500);
//     const response = yield call(
//       getActivationData,
//       authtoken,
//       orgId,
//       status,
//       list,
//       currentPage
//     );
//     yield put(getActivationsSuccess(response));
//   } catch (error) {
//     yield put(getActivationsFail(error));
//     console.log("hi");
//   }
// }

// function* fetchActivationDetails({ payload: { authtoken, id } }) {
//   try {
//     const url = `/activation/get-activation-by-id/${id}`;
//     const response = yield call(getData, url, authtoken);
//     yield put(getActivationDetailsSuccess(response));
//   } catch (error) {
//     const message = error.response?.data?.message || "Failed to get activation";
//     toaster("error", message);
//     yield put(getActivationDetailsFail(message));
//   }
// }

// function* fetchQuestionDetails({ payload: { authtoken, id } }) {
//   try {
//     const response = yield call(getQuestionDetailsData, authtoken, id);
//     yield put(getQuestionsDetailsSuccess(response));
//     // console.log('line 15', response.data.users);
//   } catch (error) {
//     yield put(getQuestionsDetailsFail(error));
//     console.log("hi");
//   }
// }
// //EDIT Activation
// function* onUpdateActivation({ payload: { id, data, token, history } }) {
//   try {
//     const response = yield call(updateActivationData, id, data, token);
//     toast("🦄 Activation data updated successfully!", {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//     });
//     //yield put(getUserValue(token, 1, 10));
//     if (response.status === 200) {
//       // history.push("/user-list");
//     }
//   } catch (error) {
//     console.log(error.response);
//     yield put(updateActivationFail(error));
//   }
// }

// //EDIT Activation
// function* onUpdateQuestion({ payload: { id, data, token, history } }) {
//   try {
//     const response = yield call(updateQuestionData, id, data, token);
//     toast("🦄 Question data updated successfully!", {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//     });
//     //yield put(getUserValue(token, 1, 10));
//     if (response.status === 200) {
//       // history.push("/user-list");
//     }
//   } catch (error) {
//     console.log(error.response);
//     yield put(updateQuestionFail(error));
//   }
// }

function* ActivationSaga() {
  yield takeEvery(ADD_ACTIVATION, onAddNewActivation);
  // yield takeEvery(GET_ALL_ACTIVATION, fetchActivations);
  // yield takeEvery(GET_QUESTION_DETAILS, fetchQuestionDetails);
  // yield takeEvery(GET_ACTIVATION_DETAILS, fetchActivationDetails);
  // yield takeEvery(UPDATE_ACTIVATION, onUpdateActivation);
  // yield takeEvery(UPDATE_QUESTION, onUpdateQuestion);
}

export default ActivationSaga;
