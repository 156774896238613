import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardTitle,
    CardBody,
} from "reactstrap"

import { Link } from "react-router-dom"
// import ProductTable from "./ProductTable";



const ComboProductList = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DDC</title>
                </MetaTags>
                <Container fluid>
                    <Row>

                        <Col xl="12">
                            <Row>

                                {/* storeData Render */}

                                <Col md="3">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">
                                                        Total Product
                                                    </p>
                                                    <h4 className="mb-3">100</h4>

                                                </div>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i
                                                            className={
                                                                "bx " + "bx-copy-alt" + " font-size-24"
                                                            }
                                                        />

                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    <Card className="mini-stats-wid">
                                        <CardBody>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted fw-medium">
                                                        Top Selling Combo Product
                                                    </p>
                                                    <h5 className="mb-0">Fresh</h5>

                                                </div>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i
                                                            className={
                                                                "bx " + "bx-copy-alt" + " font-size-24"
                                                            }
                                                        />

                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    <Card >
                                        <CardBody >
                                            <p className="text-muted fw-medium mb-3">
                                                Top Selling Combo Product
                                            </p>
                                            <Row>
                                                <Col >
                                                    <div className="mt-2 ">
                                                        <Link
                                                            to="/product-add-analytic"
                                                            className="btn btn-primary btn-sm"
                                                        >
                                                            View Analytic <i className="mdi mdi-arrow-right ms-1"></i>
                                                        </Link>
                                                    </div>
                                                </Col>

                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="3">
                                    <Card >
                                        <CardBody >
                                        <p className="text-muted fw-medium mb-3">
                                               Add Combo Product
                                            </p>
                                            <Row>
                                                <Col >
                                                    <div className="mt-2 ">
                                                        <Link
                                                            to="/add-product"
                                                            className="btn btn-primary btn-sm"
                                                        >
                                                            Add Combo Product
                                                        </Link>
                                                    </div>
                                                </Col>

                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>


                        </Col>
                    </Row>



                </Container>
            </div>


        </React.Fragment >
    );
};

export default ComboProductList;