import { AvField } from "availity-reactstrap-validation"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import useDebounce from "Hooks/useDebounce"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Badge, Button, Card, Container, Row, Spinner } from "reactstrap"


const tableHead = [
  "No.",
  "Product name",
  "Return qty",
  "Qty Price",
  "Amount",

]
const TableDamaged = () => {
    const [currentPage, setCurrentPage] = useState(1)
  return (
    <React.Fragment>
      <MetaTags>
        <title>DDC</title>
      </MetaTags>
      <Container fluid>
        <CardComponent>
          <div className="attendance-date">
            <AvForm>
              <AvField
                name="startTime"
                id="example-time-input"
                className="form-control mb-3"
                type="search"
                placeholder="Search Product Name"
                //   defaultValue={value}
                //   onChange={onChange}
              ></AvField>
            </AvForm>
          </div>
          <Row>
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={[0,1,2]}
              tableHead={tableHead}
              setCurrentPage={setCurrentPage}
              page={5}
              currentPage={currentPage}
              isPagination
              // productLoading={allStoreLoading}
            >
              {false ? (
                <tr style={{ width: "100%" }}>
                  <div
                    className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <Spinner />
                    </div>
                  </div>
                </tr>
              ) : [0, 1, 3] ? (
                [0, 1, 3].map((store, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <th scope="row">{idx + 1}</th>
                      <td>m</td>
                      <td>n</td>
                      <td>o</td>
                      <td>p</td>
                      {/* <td>{store.phoneNumber}</td> */}
                  
                     
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <NoTableData
                  colSpan="9"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>Currently you have no Data. </span>
                </NoTableData>
              )}
            </CustomTable>

            {/* table end */}
          </Row>
        </CardComponent>
      </Container>
      <Card></Card>
    </React.Fragment>
  )
}

export default TableDamaged
