import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
    Card, CardBody,
    CardTitle, Col,
    Container, FormGroup, Label, Row
} from "reactstrap";
//Import Breadcrumb 
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { addNewBrand } from '../../store/Brand/actions';



const AddBrand = () => {
    const [file, setfile] = useState('')
    const history = useHistory()
    const dispatch = useDispatch()
    const { authtoken,id } = useSelector(state => ({
        authtoken: state.Login.token,
        id: state.Login.id,
      }))
      let formdata = new FormData();
    const singleFileUpload = async (data, authtoken) => {
        try {
            const response = await axios.post('https://fieldx-api.salesx-staging.xyz/api/v1/brand', data, { headers: { Authorization: `Bearer ${authtoken}` } })

            // setTimeout(() => {
            //     history.push("/materials")
            // }, 5000)

            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }
    function handleSubmit (event, errors, values) {
        event.preventDefault();
        let obj = {}
        obj.name = values.name;
        obj.org = id;
        console.log(file);
        // formdata.append('photo', file)
        formdata.append('name', values.name)
        formdata.append('org', id)
        formdata.append('photo', file)
        for (var key of formdata.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }
        console.log(formdata);
        console.log(obj);
        console.log(authtoken);
        // singleFileUpload(formdata, authtoken)
          dispatch(addNewBrand(formdata,history,authtoken))
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DDC | Add Brand</title>
                </MetaTags>
                <Container fluid={true} >
                    <Breadcrumbs title="Add Brand" breadcrumbItem="Add Brand" />
                    <Row>
                        <Col md={6} className='m-auto'>
                            <Card >
                                <CardBody >
                                    <CardTitle className="h4 mb-4">Add Brand</CardTitle>

                                    <AvForm onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-brand-Input">Brand Name</Label>
                                                    <AvField
                                                        type="text"
                                                        className="form-control"
                                                        id="formrow-brand-Input"
                                                        placeholder='Type Brand Name'
                                                        name="name"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-brand-Input">Photo</Label>
                                                    <AvField
                                                        type="file"
                                                        className="form-control"
                                                        id="formrow-brand-Input"
                                                        placeholder='Type Brand Name'
                                                        name="photo"
                                                        onChange={e => {setfile(e.target.files[0])}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                <button type="submit" className="btn btn-primary w-md">
                                                Add Brand
                                </button>          
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                    {/* end row  */}



                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    )
}
export default AddBrand;