import Breadcrumbs from "components/Common/Breadcrumb"
import { isEmpty, map } from "lodash"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter, useHistory } from "react-router-dom"

import { MDBDataTable } from "mdbreact"

import {
  Badge,
  Button,
  Col,
  Card,
  CardBody,
  Container,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap"

import { getOffices,storeOfficeData } from "../../store/office/actions.js"

const UserTableData = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { offices, authtoken, loading } = useSelector(state => ({
    offices: state.OfficeReducer.offices,
    authtoken: state.Login.token,
    loading: state.OfficeReducer.loading,
  }))

  useEffect(() => {
    dispatch(getOffices(authtoken))
  }, [])
  const handleEditOffice = data => {
    console.log("handleEditUser", data)
     dispatch(storeOfficeData("singleOffice", data))
    history.push("/editOffice/" + data._id)
  }

  const tableData =
    loading &&
    offices?.office?.map((office, index) => {
      return {
        sl: index + 1,
        heading1: office?.name,
        heading2: office?.startTime,
        heading3: office?.offTime,

        manage: (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                history.push(`/office-time/${office?._id}`)
              }}
            >
              Set Working day
            </Button>
          </>
        ),
        Edit: (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded"
               onClick={() => handleEditOffice(office)}
            >
              Edit office
            </Button>
          </>
        ),

        details: (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                history.push(`/inventory-info/${office?._id}/${office?.name}`)
              }}
            >
              View Details
            </Button>
          </>
        ),
      }
    })

  const StoreData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },
      {
        label: "Office Name",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Start Time",
        field: "heading2",
        sort: "asc",
        width: 150,
      },

      {
        label: "End Time",
        field: "heading3",
        sort: "asc",
        width: 150,
      },

      {
        label: "Edit",
        field: "Edit",
      },

      {
        label: "Set Working Day ",
        field: "manage",
      },
      {
        label: "View Details",
        field: "details",
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <MetaTags>
          <title>Office | DDC</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Office details" />
          {loading ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={StoreData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="text-center mt-5 pt-5">
              <Spinner />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(UserTableData)
