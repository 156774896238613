import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"

import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Container, Row, Spinner, Card } from "reactstrap"

import routeListDown from "../../assets/images/routeListDown.svg"
import { AvField, AvForm } from "availity-reactstrap-validation"
import userImage from "../../assets/images/userImage2.svg"
import "../../assets/css/style.css"

const tableHead = ["No.", "Name", "Value(BDT)", "Strike rate(%)", "BCP(%)","LPC","Action"]

const SrTargetTable = () => {


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <CardComponent>
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={[0, 1, 2, 3]}
                tableHead={tableHead}
                // setCurrentPage={setCurrentPage}
                page={10}
                // currentPage={currentPage}
                // isPagination
               
              >
                {false ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner />
                      </div>
                    </div>
                  </tr>
                ) : [0, 1, 2, 3].length ? (
                  [0, 1, 2, 3]?.map((data, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td>kkk</td>

                        <td>jjjj</td>
                        <td>uuu</td>
                        <td>jjjj</td>
                        <td>uuu</td>
                        <td>
                          <Button
                            className= "btn btn-info btn-sm btn-rounded w-50"
                              
                          >
                             Edit
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>Currently you have no Data. </span>
                  </NoTableData>
                )}
              </CustomTable>

              {/* table end */}
            </Row>
          </CardComponent>
        </Container>
        <Card>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default SrTargetTable
