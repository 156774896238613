import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container, FormGroup, Label,
    Row, Spinner
} from "reactstrap";
import { getBrands } from '../../../store/Brand/actions'
import { getCategorys } from '../../../store/category/actions';
import { addNewProduct } from '../../../store/Product/actions';
import { getUnits } from '../../../store/Unit/actions';
import { getProducts } from '../../../store/Product/actions';




const AddComboProduct = () => {
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedUnit, setSelectedUnit] = useState(null)
    const [testloading, setloading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const handleChangeBrand = (value) => {
        setSelectedBrand(value._id)
    }
    const handleChangeCategory = (value) => {
        setSelectedCategory(value._id)
    }
    const handleChangeUnit = (value) => {
        setSelectedUnit(value._id)
    }
    const handleChangeProduct = (value) => {
        setSelectedProduct(value._id)
    }
    useEffect(() => {
        dispatch(getBrands(authtoken, id))
        dispatch(getCategorys(authtoken))
        dispatch(getUnits(authtoken))
        dispatch(getProducts(authtoken,id));


    }, [])
    const { authtoken, id, brands, categories,testLoading ,products,loading} = useSelector(state => ({
        authtoken: state.Login.token,
        id: state.Login.id,
        brands: state.BrandReducer.brands.data,
        categories: state.CategoryReducer.categories.category,
        products: state.ProductReducer.products,
        units: state.UnitReducer.units.unit,
        loading: state.ProductReducer.loading,
        testLoading: state.ProductReducer.testLoading

    }))
    console.log('process', brands);

    async function handleSubmit(event, errors, values) {
        event.preventDefault();
        setloading(true);
        console.log(values);
        let obj = {}
        if (values.name) {
            obj.name = values.name
        }
        if (values.brand) {
            obj.brands = selectedBrand
        }

        if (values.unitPrice) {
            obj.unitPrice = values.unitPrice
        }

        if (values.maxDiscount) {
            obj.maxDiscount = values.maxDiscount
        }
        if (values.code) {
            obj.code = values.code
        }
        obj.product = selectedProduct
        obj.brand = selectedBrand
        obj.unitType = selectedUnit
        obj.category = selectedCategory
        obj.org = id;
        console.log(obj);
        dispatch(addNewProduct(obj, history, authtoken))
    }


  

  

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Combo Product | DDC</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Add Product" /> */}
                    {loading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (

                    <Row>
                        <Col md={10} className='m-auto'>
                            <Card >
                                <CardBody >
                                    <CardTitle className="h4 mb-4">Add Store</CardTitle>

                                    <AvForm onSubmit={handleSubmit} >
                                        <Row>
                                            <Col md={4}>
                                            <FormGroup className="mb-3" >
                                                        <Label >Product Name 1</Label>
                                                        <Select
                                                            name='product'
                                                            cacheOptions
                                                            getOptionLabel={e => e.name}
                                                            getOptionValue={e => e._id}
                                                            options={products.data.product}
                                                            isClearable
                                                            onChange={handleChangeProduct}

                                                        />
                                                    </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                            <FormGroup className="mb-3">
                                                    <Label htmlFor="brand">
                                                        Brand Name
                                                    </Label>


                                                    <Select
                                                        name='brand'
                                                        cacheOptions
                                                        getOptionLabel={e => e.name}
                                                        getOptionValue={e => e._id}
                                                        options={brands}
                                                        defaultOptions

                                                        onChange={handleChangeBrand}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                            <FormGroup className="mb-3">
                                                    <Label htmlFor="categoryname">
                                                        Category Name
                                                    </Label>
                                                    <Select
                                                        name='category'
                                                        cacheOptions
                                                        getOptionLabel={e => e.name}
                                                        getOptionValue={e => e._id}
                                                        options={categories}
                                                        defaultOptions

                                                        onChange={handleChangeCategory}
                                                    />

                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                            <FormGroup className="mb-3" >
                                                        <Label >Product Name 2</Label>
                                                        <Select
                                                            name='product'
                                                            cacheOptions
                                                            getOptionLabel={e => e.name}
                                                            getOptionValue={e => e._id}
                                                            options={products.data.product}
                                                            isClearable
                                                            onChange={handleChangeProduct}

                                                        />
                                                    </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                            <FormGroup className="mb-3">
                                                    <Label htmlFor="brand">
                                                        Brand Name
                                                    </Label>


                                                    <Select
                                                        name='brand'
                                                        cacheOptions
                                                        getOptionLabel={e => e.name}
                                                        getOptionValue={e => e._id}
                                                        options={brands}
                                                        defaultOptions

                                                        onChange={handleChangeBrand}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                            <FormGroup className="mb-3">
                                                    <Label htmlFor="categoryname">
                                                        Category Name
                                                    </Label>
                                                    <Select
                                                        name='category'
                                                        cacheOptions
                                                        getOptionLabel={e => e.name}
                                                        getOptionValue={e => e._id}
                                                        options={categories}
                                                        defaultOptions

                                                        onChange={handleChangeCategory}
                                                    />

                                                </FormGroup>
                                            </Col>

                                        </Row>

                                       
                                        
                                        
                                        {testloading ? (
                                            <div className="p-4 d-flex justify-content-center align-items-center p-2 mb-3 mt-5 mr-auto">
                                                <Spinner className="ms-2" color="primary" />
                                            </div>
                                        ) : (
                                        
                                        <>
                                        <div>
                                            <button type="submit" className="btn btn-primary w-md">
                                                Save Store
                                            </button>
                                        </div>
                                        </>
                                        )}
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                    )}
                </Container>
            </div>
        </React.Fragment >
    )
}

export default AddComboProduct
