

//GET
export const GET_OFFICES = "GET_OFFICES"
export const GET_OFFICES_SUCCESS = "GET_OFFICES_SUCCESS"
export const GET_OFFICES_FAIL = "GET_OFFICES_FAIL"
//GET SINGLE USER
export const ADD_NEW_OFFICE = "ADD_NEW_OFFICE"
export const ADD_OFFICE_SUCCESS = "ADD_OFFICE_SUCCESS"
export const ADD_OFFICE_FAIL = "ADD_OFFICE_FAIL"

export const UPDATE_OFFICE_WORKINGDAY = "UPDATE_OFFICE_WORKINGDAY"
export const UPDATE_OFFICE_WORKINGDAY_SUCCESS = "UPDATE_OFFICE_WORKINGDAY_SUCCESS"
export const UPDATE_OFFICE_WORKINGDAY_FAIL = "UPDATE_OFFICE_WORKINGDAY_FAIL"

export const STORE_OFFICE_DATA = "STORE_OFFICE_DATA"

export const SINGLE_OFFICE_INVENTORY = "SINGLE_OFFICE_INVENTORY"
export const SINGLE_OFFICE_INVENTORY_SUCCESS = "SINGLE_OFFICE_INVENTORY_SUCCESS"
export const SINGLE_OFFICE_INVENTORY_FAIL = "SINGLE_OFFICE_INVENTORY_FAIL"
