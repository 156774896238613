import PropTypes from "prop-types"
import React from "react"
import { Card, Col, Row } from "reactstrap"
import BrandImage from "../../assets/images/brand.png"
const BrandList = props => {
  return (
    <React.Fragment>
      <Col xl="4" sm="6">
        <Card>
          <Row>
            <Col xl="5">
              <div className="text-center p-4 border-end">
                <div className="avatar-md mx-auto mb-3 mt-1">
                  {props.brand.photo === "NA" ? (
                    <img src={BrandImage} className="w-100 h-100" />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_S3}${props?.brand?.photo}`}
                      className="w-100 h-100"
                    />
                  )}
                </div>
                <h6 className="text-truncate">{props?.brand?.name}</h6>
              </div>
            </Col>

            <Col xl="7">
              <div className="p-4 text-center text-xl-start">
                <Row>
                  <Col xs="6">
                    <div>
                      <p className="text-muted mb-2 text-truncate">Product</p>
                      <h5>{props?.brand?.totalProduct}</h5>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div>
                      <p className="text-muted mb-2 text-truncate">
                        Total <br /> Revenue
                      </p>
                      <h5>{props?.brand?.totalsell.toFixed(2)}</h5>
                    </div>
                  </Col>
                </Row>
                {/* <div className="mt-4">
                  <Link to="/analytic-brand" className="text-decoration-underline text-reset">See analytic <i className="mdi mdi-arrow-right"></i></Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </React.Fragment>
  )
}

BrandList.propTypes = {
  shop: PropTypes.object,
  brand: PropTypes.object,
  index: PropTypes.number,
}

export default BrandList
