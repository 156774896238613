import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router";

import { MDBDataTable } from "mdbreact";
import { getAllPJP } from "../../store/PJP/actions";
import AddFileUpload from 'components/Common/AddFileUpload';
import useExcelReader from '../../Hooks/useExcelReader';
import { post } from '../../helpers/api_helper';

import {
    Badge, Button, Col,
    Card,
    CardBody,
    Container,
    Row, Spinner
} from "reactstrap";

// import { getOrders } from '../../store/Order/actions';
// import { getRoute } from "../../store/GeoInformation/actions";




const PjpTable = props => {
    const dispatch = useDispatch();
        const history = useHistory()
        useEffect(() => {
            dispatch(getAllPJP(authtoken));
        }, []);
    
        const { pjps, authtoken, loading } = useSelector(state => ({
            pjps: state.PJPReducer.pjps,
            authtoken: state.Login.token,
            loading: state.PJPReducer.loading
        }));
    const tableData = !loading && pjps?.pjp?.map((pjp, index) => {
        return {
            sl: index + 1,
            heading1: pjp?.user?.name,
            heading2: pjp?.day.join(","),
           
           
        }
    })




    const PjpData = {
        columns: [
            {
                label: "No.",
                field: "sl",
                sort: "asc",
                width: 150,
            },

            {
                label: "User Name",
                field: "heading1",
                sort: "asc",
                width: 150,
            },
            {
                label: "Days",
                field: "heading2",
                sort: "asc",
                width: 150,
            },

       
          
         

        ],
        rows: tableData,
    }

    const { items, readExcel } = useExcelReader();
    console.log(`🦄 ~ file: Dashboard.js ~ line 21 ~ Dashboard ~ items`, items);
    const [results, setResults] = useState([]);
    const [isDone, setIsDone] = useState(false);
    const [open, setOpen] = useState(false);
    const [adding, setAdding] = useState(false);
    const toggle = () => setOpen(!open);
  
    console.log(` ~ results`, results, isDone);
    const handleSubmit = async () => {
      setAdding(true);
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        const data = {
          employeeId: element.employeeId,
          month: element.month,
          lpc: element.lpc,
          bcp: element.bcp,
          actualTarget: element.actualTarget,
          strikerate: element.strikerate
        };
      await post('/pjp/excel-pjp', data, { headers: { Authorization: `Bearer ${authtoken}` }}).then(response=>{
          setResults((prev) => [...prev, response]);
        }).catch((error)=>{
          console.log('error',error);
          setResults((prev) => [...prev, { status: 'failed' }]);
        }
        );
        if (i === items.length - 1) {
          setIsDone(true);
          setAdding(false);
          toaster('success', 'All data upload successful');
        }
      }
    };
  
    const tableHead1 = items?.[0] || {};

    return (
        <React.Fragment>
            {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
            <div >
                <MetaTags>
                    <title>PJP | DDC</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="PJP" breadcrumbItem="Set PJP" />
                    {!loading ? (
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>

                                        <MDBDataTable
                                            responsive
                                            noBottomColumns
                                            striped
                                            data={PjpData}
                                        />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};


export default withRouter(PjpTable);

