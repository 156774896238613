import Breadcrumbs from "components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
import { getStores } from "../../store/Stores/actions"

const StoreTableData = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { stores, authtoken, loading, id } = useSelector(state => ({
    stores: state.StoreReducer.stores,
    authtoken: state.Login.token,
    id: state.Login.id,
    loading: state.StoreReducer.loading,
  }))

  // const [modal, setModal] = useState(false);

  useEffect(() => {
    dispatch(getStores(authtoken, id))
  }, [])
  const handleEditStore = data => {
    history.push(`/edit-store/${data._id}`)
  }

  const tableData =
    !loading &&
    stores.shop.map((store, index) => {
      return {
        sl: index + 1,
        heading1: store.name,
        heading2: store.shopCode,
        heading3: store.address,
        heading4: store.ownerName,
        heading5: store.phoneNumber,

        manage: (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                history.push(`/store-analytics/${stores.shop[index]._id}`)
              }}
            >
              View
            </Button>
          </>
        ),
        status: (
          <>
            <Badge className={"bg-success"}>Active</Badge>
          </>
        ),
        action: (
          <React.Fragment>
            <div className="d-flex">
              <Link
                to="#"
                className="text-success"
                onClick={() => handleEditStore(store)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              </Link>
            </div>
          </React.Fragment>
        ),
      }
    })

  const StoreData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },
      {
        label: "Shop Name",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Shop Code",
        field: "heading2",
        sort: "asc",
        width: 150,
      },

      {
        label: "Route Name",
        field: "heading3",
        sort: "asc",
        width: 150,
      },
      {
        label: "Owner",
        field: "heading4",
        sort: "asc",
        width: 150,
      },
      {
        label: "Phone",
        field: "heading5",
        sort: "asc",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
      },

      {
        label: "View ",
        field: "manage",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <MetaTags>
          <title>Shop | DDC</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Shop" breadcrumbItem="Shop details" />
          {!loading ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={StoreData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="text-center mt-5 pt-5">
              <Spinner />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(StoreTableData)

// //Import Breadcrumb
// import Breadcrumbs from "components/Common/Breadcrumb";
// import { isEmpty, map } from "lodash";
// import React, { useEffect, useState } from "react";
// import MetaTags from "react-meta-tags";
// //redux
// import { useDispatch, useSelector } from "react-redux";
// import { Link, withRouter } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import {
//     Badge, Button, Col,
//     Container,
//     DropdownItem,
//     DropdownMenu,
//     DropdownToggle, Row, Spinner, Table, UncontrolledDropdown
// } from "reactstrap";
// import { getStores } from '../../store/Stores/actions';

// const StoreTableData = props => {

//     const dispatch = useDispatch();
//     const history = useHistory();

//     const { stores, authtoken, loading, id } = useSelector(state => ({
//         stores: state.StoreReducer.stores,
//         authtoken: state.Login.token,
//         id: state.Login.id,
//         loading: state.StoreReducer.loading,
//     }));

//     // const [modal, setModal] = useState(false);

//     useEffect(() => {
//         dispatch(getStores(authtoken, id));
//     }, []);

// console.log(stores);

//     return (
//         <React.Fragment>
//             <div >
//                 <MetaTags>
//                     <title>
//                         Store | DDC
//                     </title>
//                 </MetaTags>
//                 <Container fluid>
//                     {/* Render Breadcrumbs */}
//                     <Breadcrumbs title="Store" breadcrumbItem="Store Details" />
//                     {loading ? (
//                         <div className="text-center mt-5 pt-5">
//                             <Spinner />
//                         </div>
//                     ) : (

//                         <Row >
//                             <Col lg="12">
//                                 <div className="">
//                                     <div className="table-responsive">
//                                         <Table className="project-list-table table-nowrap align-middle table-borderless">
//                                             <thead>
//                                                 <tr>

//                                                     <th scope="col">Store Name</th>
//                                                     <th scope="col">Store Code</th>
//                                                     <th scope="col">Route Name</th>
//                                                     <th scope="col">Owner Name</th>
//                                                     <th scope="col">Phone Number</th>
//                                                     <th scope="col">Status</th>
//                                                     <th scope="col">Action</th>
//                                                     <th scope="col">View Details</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {map(stores, (store, index) => (
//                                                     <tr key={index}>

//                                                         <td>
//                                                             <p className="text-muted mb-0">{store.name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{store.shopCode}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{store.address}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{store.ownerName}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{store.phoneNumber}</p>
//                                                         </td>

//                                                         <td>
//                                                             <Badge className={"bg-success"}>
//                                                                 Active
//                                                             </Badge>
//                                                         </td>

//                                                         <td>
//                                                             <UncontrolledDropdown>
//                                                                 <DropdownToggle
//                                                                     href="#"
//                                                                     className="card-drop"
//                                                                     tag="i"
//                                                                 >
//                                                                     <i className="mdi mdi-dots-horizontal font-size-18" />
//                                                                 </DropdownToggle>
//                                                                 <DropdownMenu className="dropdown-menu-end">
//                                                                     <DropdownItem
//                                                                         href="#"
//                                                                     onClick={() => handleProductData(user)}
//                                                                     >
//                                                                         <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
//                                                                         Edit
//                                                                     </DropdownItem>
//                                                                     <DropdownItem
//                                                                         href="#"
//                                                                     // onClick={() => handleDeleteProject(user)}
//                                                                     >
//                                                                         <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
//                                                                         Delete
//                                                                     </DropdownItem>
//                                                                 </DropdownMenu>
//                                                             </UncontrolledDropdown>
//                                                         </td>
//                                                         {/* <td>
//                                                             <Link to="/store-analytics">
//                                                                 <Button
//                                                                     type="button"
//                                                                     color="primary"
//                                                                     className="btn-sm btn-rounded"

//                                                                 >
//                                                                     View Details
//                                                                 </Button>
//                                                             </Link>
//                                                         </td> */}
//                                                         <td>

//                                                         <Button
//                                                             type="button"
//                                                             color="primary"
//                                                             className="btn-sm btn-rounded"
//                                                             onClick={() => {
//                                                                 history.push(`/store-analytics/${stores[index]._id}`)
//                                                             }}

//                                                         >
//                                                               View Details
//                                                         </Button>

//                                                     </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </Table>

//                                     </div>
//                                 </div>
//                             </Col>

//                         </Row>
//                     )}
//                 </Container>
//             </div>

//         </React.Fragment >
//     );
// };

// export default withRouter(StoreTableData);
