import { AvField } from "availity-reactstrap-validation"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import useDebounce from "Hooks/useDebounce"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Badge, Button, Card, Container, Row, Spinner } from "reactstrap"
import { getUsersInfo, storeUserData } from "../../store/users/actions"
import XLSX from "xlsx"
import routeListDown from "../../assets/images/routeListDown.svg"
import { getUsers } from "helpers/backend_helper"
const tableHead = [
  "No.",
  "Name",
  "Role",
  "Email",
  "User ID",
  "Supervisor ID",
  "Status",
  "Edit",
  "Set Route",
]
const TableUserInfo = props => {
  const [currentPage, setCurrentPage] = useState(1)

  const [role, setRole] = useState("SR")
  const [value, setValue] = useState("")
  const [orderExcel, setOrderExcel] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  console.log("role", role)
  const { users, userInfo, authtoken, id, userInfoLoading, pageNumber } =
    useSelector(state => ({
      users: state.UserReducer,
      userInfo: state?.UserReducer?.userInfo,
      id: state.Login.id,
      pageNumber: state?.UserReducer?.userInfo?.pagenumber,
      authtoken: state.Login.token,
      userInfoLoading: state?.UserReducer?.userInfoLoading,
    }))

  const handleChangeRole = e => {
    setRole(e.target.value)
  }

  let totalPageNumber = Math.ceil(users?.users?.length / pageRange);
  const handleExcel = async () => {
    setOrderExcel(true)
    try {
      var response = await getUsers(authtoken, id)
      console.log("user excel", response)
    } catch (error) {}

    let newArray = []
    response?.data?.users?.forEach((user, index) => {
      let data = {}
      data.userName = user.name
      if (user.role === "SR") {
        data.role = "SO"
      }
      if (user.role === "DP") {
        data.role = "DSR"
      }
      if (user.role === "SS") {
        data.role = "SS"
      }
      if (user.role === "DISTRIBUTOR") {
        data.role = "DISTRIBUTOR"
      }

      data.employeeID = user.employeeId
      data.officeName = user?.office?.name
      data.officeId = user?.office?.distributioncode
      data.lineManager = user?.linemanager?.employeeId
      data.region = user?.region?.name
      data.area = user?.area?.name
      data.territory = user?.teritori?.name
      data.phoneNumber = user.phoneNumber

      newArray.push(data)
    })
    downloadxls(newArray)
  }
  const downloadxls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "User Data.xlsx")
    setOrderExcel(false)
  }

  useEffect(() => {
    dispatch(getUsersInfo(authtoken, id, currentPage, value, role))
  }, [value, currentPage, role])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value !== "") {
        console.log("value", value)
        dispatch(getUsersInfo(authtoken, id, currentPage, value, role))
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [value])
  console.log("userInfo", userInfo)
  const handleEditUser = data => {
    console.log("handleEditUser", data)
    dispatch(storeUserData("singleUser", data))
    history.push("/edituser/" + data._id)
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>DDC</title>
      </MetaTags>
      <Container fluid>
        <CardComponent>
          <div className="user-info-details">
            <div className="select-role">
              <select
                className="form-select m-auto"
                value={role}
                onChange={handleChangeRole}
              >
                <option value="SR">SO</option>
                <option value="SS">SS</option>
                <option value="DP">DSR</option>
                <option value="TO">TO</option>
                <option value="RBH">RBH</option>
              </select>
            </div>
            <div className="attendance-date">
              <AvForm>
                <AvField
                  name="startTime"
                  id="example-time-input"
                  className="form-control mb-3"
                  type="search"
                  placeholder="Search User ID"
                  onChange={e => setValue(e.target.value)}
                ></AvField>
              </AvForm>
            </div>
          </div>
          <Row>
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={userInfo?.users}
              tableHead={tableHead}
              setCurrentPage={setCurrentPage}
              page={pageNumber}
              currentPage={currentPage}
              isPagination
              productLoading={userInfoLoading}
            >
              {userInfoLoading ? (
                <tr style={{ width: "100%" }}>
                  <div
                    className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <Spinner />
                    </div>
                  </div>
                </tr>
              ) : userInfo?.users?.length ? (
                userInfo?.users?.map((user, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <th scope="row">{idx + 1}</th>
                      <td>{user.name}</td>
                      {user.role === "SR" ? (
                        <td>SO</td>
                      ) : user.role === "DP" ? (
                        <td>DSR</td>
                      ) : (
                        <td>{user.role}</td>
                      )}

                      <td>{user.email}</td>
                      <td>{user.employeeId}</td>
                      {/* <td>{store.phoneNumber}</td> */}
                      <td>{user?.linemanager?.employeeId}</td>
                      <td>
                        <Badge className={"bg-success"}>Active</Badge>
                      </td>
                      <td>
                        <Link
                          to="#"
                          className="text-success"
                          onClick={() => handleEditUser(user)}
                        >
                          <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                          />
                        </Link>
                      </td>
                      <td>
                        <Button
                          color="primary"
                          className="btn-sm btn-rounded"
                          onClick={() => {
                            history.push(
                              `/set-route/${user?._id}/${user?.name}`,
                              { data: user?.route }
                            )
                          }}
                        >
                          Set Route
                        </Button>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <NoTableData
                  colSpan="9"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>Currently you have no Data. </span>
                </NoTableData>
              )}
            </CustomTable>

            {/* table end */}
          </Row>
        </CardComponent>
      </Container>
      {orderExcel ? (
        <div className="text-center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <Card>
          <div className="route-excel-attendance">
            <div className="route-excel">
              <div>
                <p className="mb-0">Download Excelsheet</p>
              </div>
              <div>
                <button className="route-excel-download" onClick={handleExcel}>
                  {" "}
                  <img src={routeListDown}></img>Download
                </button>
              </div>
            </div>
          </div>
        </Card>
      )}
    </React.Fragment>
  )
}

export default TableUserInfo
