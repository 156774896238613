import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch } from "react-redux"
import { Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//import action
import { getChartsData as onGetChartsData } from "../../store/actions"
import AvgIncomeValue from "./AvgIncomeValue"
// Pages Components
import PropTypes from "prop-types"
import Overview from "./Overview"
import SellingOverview from "./SellingOverview"
import TopRegion from "./TopRegion"
import TopSR from "./TopSR"
import TotalSellngProduct from "./total-selling-product"

const MainDashboard = props => {
  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ]

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | DDC</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Overview />

          <Row>
            <Col md={8}>
              <SellingOverview />
            </Col>
            <Col md={4}>
              <TotalSellngProduct />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              {/* <BrandAnalytics/> */}
              <AvgIncomeValue />
            </Col>
            <Col md={4}>
              <TopRegion />
            </Col>

            <Col md={4}>
              <TopSR />
            </Col>
            {/* <Col md={4}>
            <TopDR/>
          </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

MainDashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(MainDashboard)
