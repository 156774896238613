import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import { addTargetData, getTargetData } from "../../helpers/backend_helper.js"
import {
  addTargetFail, addTargetSuccess,
  getAllTargetFail, getAllTargetSuccess, updateTargetSuccess, updateTargetFail
} from "./actions"
import {
  ADD_NEW_TARGET, GET_ALL_TARGET,UPDATE_TARGET
} from "./actionTypes"

function* onAddTarget({ payload: { data, history,authtoken } }) {
    try {
      console.log(data);
      const response = yield call(addTargetData, data, authtoken)

     
      console.log('hello',response);

      if (response.status === 'success') {
        console.log('test on 18', );
        yield put(addTargetSuccess(response))
        toast.success('success')
        history.push('/target')
 
      }
     
     
     
    } catch (error) {
      if (!error.response) {
        history.push('/target')
      console.log('line 20');
      } else {
        let message = error.response.data.message
       console.log('line 24');
        // console.log("error message,", error.response.data.message)
        yield put(addTargetFail(message))
        toast.error(message)
      }
    }
  }

  function* fetchTarget({ payload: { authtoken } }) {
    try {
      console.log('fetched area');
      const response = yield call(getTargetData, authtoken)
      console.log(response);
      yield put(getAllTargetSuccess(response))
    } catch (error) {
      yield put(getAllTargetFail(error))
    }
  }

  function* updateTarget({  payload: { formdata, authtoken, productID, history },
  }) {
    try {
      const response = yield call(updateProduct, formdata, authtoken, productID)
      console.log(`file: saga.js ~ line 95 ~ response`, response)
     yield put(updateTargetSuccess(response))
      if (response.status === "success") {
        // history.push("/details-product")
      }
    } catch (error) {
      // console.log("update error", error.response)
      yield put(updateTargetFail(error))
    }
  }
  
  function* targetSaga() {
    yield takeEvery(ADD_NEW_TARGET, onAddTarget)
   yield takeEvery(GET_ALL_TARGET, fetchTarget)
   yield takeEvery(UPDATE_TARGET, updateTarget)
   
  }
  
  export default targetSaga