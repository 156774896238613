import { AvField } from "availity-reactstrap-validation"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import useDebounce from "Hooks/useDebounce"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Badge, Button, Card, Container, Row, Spinner } from "reactstrap"
import { getDpActivity } from "../../store/Activities/actions"

const moment = require("moment")

const tableHead = ["No.", "Dp List", "Day Opening", "Reconciliation"]
const InventoryOrderDp = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  const history = useHistory()
  const today = moment().format("YYYY-MM-DD")
  const {
    authtoken,

    id,
    dpActivities,
    dpActivityLoading,
  } = useSelector(state => ({
    dpActivities: state.ActivityReducer.dpActivities,
    dpActivityLoading: state.ActivityReducer.dpActivityLoading,
    id: state.Login.id,
    authtoken: state.Login.token,
  }))
  useEffect(() => {
    dispatch(getDpActivity(authtoken, id, today))
  }, [])
  return (
    <React.Fragment>
      <MetaTags>
        <title>DDC</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
        <h4 className="mb-3">Dp List</h4>
          <CardComponent>
          
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={[0, 1, 2]}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                page={5}
                currentPage={currentPage}
                isPagination
                // productLoading={allStoreLoading}
              >
                {false ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner />
                      </div>
                    </div>
                  </tr>
                ) : [0, 1, 3] ? (
                  [0, 1, 3].map((store, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td>m</td>

                        {/* <td>{store.phoneNumber}</td> */}

                        <td>
                          <Button
                            color="primary"
                            className="btn-sm btn-rounded"
                            onClick={() => {
                              history.push(`/officeDayOpening`)
                            }}
                          >
                            Day Opening
                          </Button>
                        </td>
                        <td>
                          <Button
                            color="primary"
                            className="btn-sm btn-rounded"
                            //   onClick={() => {
                            //     history.push(
                            //       `/set-route/${user?.id}/${user?.userName}`,
                            //       { data: user?.route }
                            //     )
                            //   }}
                            onClick={() => {
                              history.push(`/officeReconciliation`)
                            }}
                          >
                            Reconciliation
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>Currently you have no Data. </span>
                  </NoTableData>
                )}
              </CustomTable>

              {/* table end */}
            </Row>
          </CardComponent>
        </Container>
      </div>
      <Card></Card>
    </React.Fragment>
  )
}

export default InventoryOrderDp
