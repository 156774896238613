import {
  GET_PRODUCT_DATA,
  GET_PRODUCT_DATA_FAIL,
  GET_PRODUCT_DATA_SUCCESSFUL,
  STORE_PRODUCT_DATA,
} from "./actionType"

export const getProductData = (token, query) => ({
  type: GET_PRODUCT_DATA,
  payload: { token, query },
})

export const getProductSuccessful = data => ({
  type: GET_PRODUCT_DATA_SUCCESSFUL,
  payload: data,
})

export const getProductFail = data => ({
  type: GET_PRODUCT_DATA_FAIL,
  payload: data,
})

export const storeProductData = (name, data) => ({
  type: STORE_PRODUCT_DATA,
  payload: { name, data },
})
