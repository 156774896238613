import { toast } from "react-toastify"
import { call, delay, put, takeEvery } from "redux-saga/effects"
import { addNewInventory,getNewInventory,getTodayActivity,
  getDpActivity,getDpOrderActivity,addDayOpening,getDpReconciliation,addDpReconciliation,getReconciliationStatusData,getReconciliationConfirmStatusData } from '../../helpers/backend_helper'
import { getNewHistorySuccess,getNewHistoryFail,getTodayActivitySuccess,getTodayActivityFail,
  getDpActivitySuccess,getDpActivityFail,getDpOrderActivitySuccess,getDpOrderActivityFail,addDpDayOpeningSuccess,addDpDayOpeningFail,
  getDpDayOpeningSuccess,getDpDayOpeningFail,getDpDayReconciliationSuccess,getDpDayReconciliationFail,addDpReconciliationSuccess,
  addDpReconciliationFail,getDpDayOpeningConfirmSuccess,
  getDpDayOpeningConfirmFail,getReconciliationConfirmSuccess,getReconciliationConfirmFail } from './actions'
import { GET_NEW_HISTORY,GET_TODAY_ACTIVITY,GET_DP_ACTIVITY,GET_DP_ORDER_ACTIVITY,
  GET_DP_DAY_OPENING,GET_DP_RECONCILIATION,
  ADD_DP_DAY_OPENING,ADD_DP_RECONCILIATION,GET_DP_DAY_OPENING_CONFIRM,GET_RECONCILIATION_CONFIRM} from './actionTypes'



  function* fetchHistory({ payload: { authtoken, id } }) {
    try {
      delay(500)
      const response = yield call(getNewInventory,authtoken, id)
      yield put(getNewHistorySuccess(response))
      // console.log('line 15', response.data.users);
    } catch (error) {
      yield put(getNewHistoryFail(error))
      console.log('hi');
    }
  }
  function* fetchTodayActivity({ payload: { authtoken, id, today } }) {
    try {
      delay(500)
      const response = yield call(getTodayActivity,authtoken, id, today)
    
      
      response.order.forEach((order,index)=>{
        order.editOrder = order.sumQuantity
      })
      console.log('26',response);
      yield put(getTodayActivitySuccess(response.order))
      // console.log('line 15', response.data.users);
    } catch (error) {
      yield put(getTodayActivityFail(error))
      console.log('hi');
    }
  }
  function* fetchDpActivity({ payload: { authtoken, id, today } }) {
    try {
      delay(500)
      const response = yield call(getDpActivity,authtoken, id, today)
      yield put(getDpActivitySuccess(response))
      // console.log('line 15', response.data.users);
    } catch (error) {
      yield put(getDpActivityFail(error))
      console.log('hi');
    }
  }
  function* fetchDpOpeningConfirm({ payload: { authtoken, dpId, date } }) {
    try {
      delay(500)
      const response = yield call(getReconciliationStatusData,authtoken, dpId, date)
      yield put(getDpDayOpeningConfirmSuccess(response))
      // console.log('line 15', response.data.users);
    } catch (error) {
      yield put(getDpDayOpeningConfirmFail(error))
      console.log('hi');
    }
  }
  function* fetchReconciliationConfirm({ payload: { authtoken, dpId, date } }) {
    try {
      delay(500)
      const response = yield call(getReconciliationConfirmStatusData,authtoken, dpId, date)
      yield put(getReconciliationConfirmSuccess(response))
      // console.log('line 15', response.data.users);
    } catch (error) {
      yield put(getReconciliationConfirmFail(error))
      console.log('hi');
    }
  }
  function* fetchDpOrderActivity({ payload: { authtoken, id, today } }) {
    console.log(authtoken);
    console.log(id);
    try {
      delay(500)
      const response = yield call(getDpOrderActivity,authtoken, id, today)
      console.log('response',response);

      let dayOpeningData = _.map(response?.test, function (item) {
        return _.merge(item, _.find(response?.inventory?.product, {productid : item.productid }))
      })
      console.log("dayOpeningData",dayOpeningData);

      dayOpeningData.forEach((order,index)=>{
        order.editOrder = order.sumQuantity
      })
      
      yield put(getDpOrderActivitySuccess(dayOpeningData))
      // console.log('line 15', response.data.users);
    } catch (error) {
      yield put(getDpOrderActivityFail(error))
      console.log('hi ERROR');
    }
  }
  function* fetchDpDayOpening({ payload: { authtoken, id, today } }) {
    console.log(authtoken);
    console.log(id);
    try {
      delay(500)
      const response = yield call(getDpOrderActivity,authtoken, id, today)
      yield put(getDpDayOpeningSuccess(response))
      // console.log('line 15', response.data.users);
    } catch (error) {
      yield put(getDpDayOpeningFail(error))
      console.log('hi');
    }
  }
  function* fetchDpReconciliation({ payload: { authtoken, id, today } }) {
    console.log(authtoken);
    console.log(id);
    try {
      delay(500)
      const response = yield call(getDpReconciliation,authtoken, id, today)
      console.log('response',response);
  
   
      yield put(getDpDayReconciliationSuccess(response))
      // console.log(response.reconciliationdata.products);
      
      console.log(response);
      // console.log('line 15', response.data.users);
    } catch (error) {
      yield put(getDpDayReconciliationFail(error))
      console.log('hi');
    }
  }


  function* onAddDayOpening({payload: {data, history, authtoken} }) {
  
    try {
      console.log(data);
      console.log(authtoken);
     
     
      const response = yield call(addDayOpening, data, authtoken)
      console.log('hi');
      console.log(response);
      yield put(addDpDayOpeningSuccess(response))
      toast('🦄 Success!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
       history.push("/inventoryMain")
     
       
    } catch (error) {
      
      if (!error.response) {
        toast('🦄 Success!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
         history.push("/inventoryMain")
      } else {
        let message = error.response.data.message
        yield put(addDpDayOpeningFail(message))
        // console.log("hi",message);
        toast.error(message)
      }
    }
  }


  function* onAddReconciliation({payload: { data,history,authtoken,officeId} }) {
  
    try {
      console.log(data);
      console.log(authtoken);
      console.log(officeId);
     
      const response = yield call(addDpReconciliation, data,authtoken,officeId)
      console.log('hi');
      console.log(response);
      yield put(addDpReconciliationSuccess(response))
      toast('🦄 successfull!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
       history.push("/inventoryMain")
     
       
    } catch (error) {
      
      if (!error.response) {
        toast('🦄 successfull!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
         history.push("/inventoryMain")
      } else {
        let message = error.response.data.message
        yield put(addDpReconciliationFail(message))
        // console.log("hi",message);
        toast.error(message)
      }
    }
  }




  function* ActivitySaga() {
    
    
    yield takeEvery(GET_NEW_HISTORY, fetchHistory)
    yield takeEvery(GET_TODAY_ACTIVITY, fetchTodayActivity)
    yield takeEvery(GET_DP_ACTIVITY, fetchDpActivity)
    yield takeEvery(GET_DP_ORDER_ACTIVITY, fetchDpOrderActivity)
    yield takeEvery(GET_DP_DAY_OPENING, fetchDpDayOpening)
    yield takeEvery(GET_DP_RECONCILIATION, fetchDpReconciliation)
    yield takeEvery(ADD_DP_DAY_OPENING, onAddDayOpening)
    yield takeEvery(ADD_DP_RECONCILIATION, onAddReconciliation)
    yield takeEvery(GET_DP_DAY_OPENING_CONFIRM, fetchDpOpeningConfirm)
    yield takeEvery(GET_RECONCILIATION_CONFIRM, fetchReconciliationConfirm)
 
  }
  
  export default ActivitySaga
  