import React, { Component, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import classNames from "classnames"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap"



import OrderAnalyticChart from "./ChartOrderAnalytic";

const AnalyticOrder = () => {
  const [periodType, setPeriodType] = useState("yearly")
  const [AreaType, setAreatype] = useState("Area")
  const [RegionType, setRegiontype] = useState("Region")
  const [TerritoryType, setTerritorytype] = useState("Territory")
  
 
  return (
    <React.Fragment>
    <Card className='page-content'>
                <CardBody>
                 <h4 className='mb-4'>Order Analytics</h4>
                  <div className="d-sm-flex flex-wrap">
                  <h5 className="card-title mb-4">Total Orders and Amount</h5>
                  <div className="ms-auto">
                      {/* <ul className="nav nav-pills">
                        <li className="nav-item me-2 ">
                          <Link
                            to="#"
                            className={classNames(
                              { active: RegionType === "Region" },
                              "nav-link","bg-dark"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("Region")
                            }}
                            id="one_month"
                          >
                            Region
                          </Link>{" "}
                        </li>
                        <li className="nav-item me-2 ">
                          <Link
                            to="#"
                            className={classNames(
                              { active: AreaType === "Area" },
                              "nav-link","bg-dark"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("Area")
                            }}
                            id="one_month"
                          >
                            Area
                          </Link>{" "}
                        </li>
                        <li className="nav-item me-2 ">
                          <Link
                            to="#"
                            className={classNames(
                              { active: TerritoryType === "Territory" },
                              "nav-link","bg-dark"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("Territory")
                            }}
                            id="one_month"
                          >
                            Territory
                          </Link>{" "}
                        </li>
                        
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link","bg-dark"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly")
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                <OrderAnalyticChart/>
                </CardBody>
              </Card>
  
    </React.Fragment>
  )
}

export default AnalyticOrder
