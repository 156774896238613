import { toast } from "react-toastify"
import { call, delay, put, takeEvery } from "redux-saga/effects"
import { addNewInventory,getNewInventory,addDamageInventoryData,getDamageInventoryData,
  getDamageActivityData,addDamageActivityData,getDamageReconciliationData , addDamageReconciliationData} from '../../helpers/backend_helper'
import { addNewInventorySuccess,addNewInventoryFail,getNewInventorySuccess,getNewInventoryFail,
  addDamageInventorySuccess,addDamageInventoryFail, getDamageInventorySuccess, getDamageInventoryFail,
  getDamageActivitySuccess,getDamageActivityFail,getDamageReconciliationSuccess,getDamageReconciliationFail,
  addDamageActivitySuccess,addDamageActivityFail,addDamageReconciliationSuccess,addDamageReconciliationFail
 } from './actions'
import { ADD_NEW_INVENTORY,GET_NEW_INVENTORY,
  ADD_DAMAGE_INVENTORY,GET_DAMAGE_INVENTORY,GET_DAMAGE_ACTIVITY,GET_DAMAGE_RECONCILIATION,
  ADD_DAMAGE_ACTIVITY,ADD_DAMAGE_RECONCILIATION
} from './actionTypes'


function* onAddNewInventory({payload: { data,history,authtoken,officeId} }) {
  
    try {
      console.log(data);
      console.log(authtoken);
      console.log(officeId);
     
      const response = yield call(addNewInventory, data,authtoken,officeId)
      console.log('hi');
      console.log(response);
      yield put(addNewInventorySuccess(response))
      toast('🦄 Product added successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
       history.push("/inventoryMain")
     
       
    } catch (error) {
      
      if (!error.response) {
        toast('🦄 Product added successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
         history.push("/inventoryMain")
      } else {
        let message = error.response.data.message
        yield put(addNewInventoryFail(message))
        // console.log("hi",message);
        toast.error(message)
      }
    }
  }
  function* onAddDamageInventory({payload: { data,history,authtoken,officeId} }) {
  
    try {
      console.log(data);
      console.log(authtoken);
      console.log(officeId);
     
      const response = yield call(addDamageInventoryData, data,authtoken,officeId)
      console.log('hi');
      console.log(response);
      yield put(addDamageInventorySuccess(response))
      toast('🦄 Product added successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
       history.push("/damage-inventory-main")
     
       
    } catch (error) {
      
      if (!error.response) {
        toast('🦄 Product added successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
         history.push("/damage-inventory-main")
      } else {
        let message = error.response.data.message
        yield put(addDamageInventoryFail(message))
        // console.log("hi",message);
        toast.error(message)
      }
    }
  }
 
  function* onAddDamageActivity({payload: {data, history, authtoken} }) {
  
    try {
      console.log(data);
      console.log(authtoken);
     
      const response = yield call(addDamageActivityData, data,authtoken)
      console.log('hi');
      console.log('response',response);
      yield put(addDamageActivitySuccess(response))
      toast('🦄   Damage compensation products have been given', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
       history.push("/damage-reconciliation")
     
       
    } catch (error) {
      
      if (!error.response) {
        toast('🦄Damage compensation products have been given', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push("/damage-reconciliation")
      } else {
        let message = error.response.data.message
        yield put(addDamageActivityFail(message))
        // console.log("hi",message);
        toast.error(message)
      }
    }
  }
  function* onAddDamageReconciliation({ payload: {data, history, authtoken, currentDate }, }) {
  
    try {
      console.log(data);
      console.log(authtoken);
      console.log(currentDate);
     
      const response = yield call(addDamageReconciliationData, data, authtoken, currentDate)
      console.log('hi');
      console.log('addDamageReconciliationData',response);
      yield put(addDamageReconciliationSuccess(response))
      toast('🦄 Damage compensation products have been returned !', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
       history.push("/damage-activity")
     
       
    } catch (error) {
      
      if (!error.response) {
        toast('🦄 Damage compensation products have been returned !', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
         history.push("/damage-activity")
      } else {
        let message = error.response.data.message
        yield put(addDamageReconciliationFail(message))
        // console.log("hi",message);
        toast.error(message)
      }
    }
  }
  function* fetchInventory({ payload: { authtoken, officeId } }) {
   
    try {
      console.log('hi',officeId);
      delay(500)
      const response = yield call(getNewInventory,authtoken, officeId)
      console.log(response);
      yield put(getNewInventorySuccess(response))
      // console.log('line 15', response.data.users);
      console.log(response);
    } catch (error) {
      yield put(getNewInventoryFail(error))
      console.log('hi');
    }
  }
  function* fetchDamageInventory({ payload: { authtoken, officeId } }) {
   
    try {
      console.log('hi',officeId);
      delay(500)
      const response = yield call(getDamageInventoryData,authtoken, officeId)
      console.log(response);
      yield put(getDamageInventorySuccess(response))
      // console.log('line 15', response.data.users);
      console.log(response);
    } catch (error) {
      yield put(getDamageInventoryFail(error))
      console.log('hi');
    }
  }
  function* fetchDamageActivity({  payload: { authtoken } }) {
   console.log('damage authtoken',authtoken);
   
    try {
     
      delay(500)
      const response = yield call(getDamageActivityData, authtoken)
      console.log('damage activity',response);
      response?.damageproduct?.forEach(data => {
        data.view = false
      })

    
      yield put(getDamageActivitySuccess(response))
      // console.log('line 15', response.data.users);
      console.log(response);
    } catch (error) {
      yield put(getDamageActivityFail(error))
      console.log('hi');
    }
  }
  function* fetchDamageReconciliation({ payload: { authtoken, currentDate } }) {
   
    try {
     
      delay(500)
      const response = yield call(getDamageReconciliationData,authtoken, currentDate)
    
      console.log('Reconcile response',response);
      response?.reconciled?.forEach(data => {
        data.view = false
      })
      yield put(getDamageReconciliationSuccess(response))
      // console.log('line 15', response.data.users);
      console.log(response);
    } catch (error) {
      yield put(getDamageReconciliationFail(error))
      console.log('hi');
    }
  }


  function* InventorySaga() {
    
    yield takeEvery(ADD_NEW_INVENTORY, onAddNewInventory)
    yield takeEvery(GET_NEW_INVENTORY, fetchInventory)
    yield takeEvery(ADD_DAMAGE_INVENTORY, onAddDamageInventory)
    yield takeEvery(GET_DAMAGE_INVENTORY, fetchDamageInventory)
    yield takeEvery(GET_DAMAGE_ACTIVITY, fetchDamageActivity)
    yield takeEvery(GET_DAMAGE_RECONCILIATION, fetchDamageReconciliation)
    yield takeEvery(ADD_DAMAGE_ACTIVITY, onAddDamageActivity)
    yield takeEvery(ADD_DAMAGE_RECONCILIATION, onAddDamageReconciliation)
    
  }
  
  export default InventorySaga
  