import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Card, CardBody,
    CardTitle, Col,
    Container, FormGroup,
    Label, Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getOffices } from "../../store/office/actions";
// import { addNewUser } from "../../store/users/action";
import { addNewUser } from "../../store/users/actions";
const AdminAddUser = () => {

    const [testloading, setloading] = useState([])
    const dispatch = useDispatch()
    const history = useHistory()
    const { authtoken, loading, offices,id } = useSelector(state => ({
        authtoken: state.Login.token,
        id: state.Login.id,
        loading: state.OfficeReducer.loading,
        offices: state.OfficeReducer.offices.office
    }))
    console.log(loading);
    console.log(offices);
    useEffect(() => {
         dispatch(getOffices(authtoken))
    },[])
    async function  handleSubmit  (event, errors, values)  {
        event.preventDefault()
        console.log(values);
        let obj = {}
        if (values.name && values.email && values.phoneNumber &&values.password&&values.passwordConfirm &&  values.role) {
            obj.name = values.name
            obj.email = values.email
        
            obj.employeeId = "SuperAdmin"
            obj.role = values.role 
            obj.phoneNumber = values.phoneNumber
            obj.password = values.password  
            obj.passwordConfirm = values.passwordConfirm
        obj.org = "618bb2bc47620f35f7ee9210";
        console.log(obj);
        dispatch(addNewUser(obj, history, authtoken))
    }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DDC | Add User</title>
                </MetaTags>
                <Container fluid={true} >
                    <Breadcrumbs title="Add New User" breadcrumbItem="Add New User" />
                    <Row>
                        <Col md={6} className='m-auto'>
                            <Card >
                                <CardBody >
                                    <CardTitle className="h4 mb-4">Add User</CardTitle>

                                    <AvForm onSubmit={handleSubmit} >
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-firstname-Input">Full name</Label>
                                                    <AvField
                                                        name="name"
                                                        type="text"
                                                        className="form-control"
                                                        id="formrow-firstname-Input"
                                                        placeholder='Type User Name'
                                                        errorMessage="Enter Name"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-phone-Input">Phone Number</Label>
                                                    <AvField
                                                        name="phoneNumber"
                                                        type="text"
                                                        className="form-control"
                                                        id="formrow-phone-Input"
                                                        placeholder='Type phone Number'
                                                        errorMessage="Enter Phone Number"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                         

                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-email-Input">Email</Label>
                                                    <AvField
                                                        name='email'
                                                        type="email"
                                                        className="form-control"
                                                        id="formrow-email-Input"
                                                        placeholder='Type Email address'
                                                        errorMessage="Enter Email"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-InputState">Select Role</Label>
                                                    <AvField
                                                        name='role'
                                                        id="formrow-InputState"
                                                        className="form-control"
                                                        type="select"
                                                        errorMessage="Select Role"
                                                        validate={{ required: { value: true } }}
                                                    >
                                                        <option value= "">Select Role</option>
                                                        <option value= "ORG">ORG</option>

                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            
                                               {loading ?
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="office"
                                                            label="Office"
                                                        >
                                                            <option>Not Selected</option>
                                                             {
                                                                offices.map(index => {
                                                                    return (
                                                                        <option key={index._id} value={index._id}>
                                                                            {index.name}
                                                                        </option>
                                                                    )
                                                                })} 
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                : <p>hello</p>}
                                            
                                        </Row>
                                        

                                        <Row>


                                            <Col md={6}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-password-Input">Password</Label>
                                                    <AvField
                                                        name='password'
                                                        type="password"
                                                        className="form-control"
                                                        id="formrow-password-Input"
                                                        placeholder='Type Password'
                                                        errorMessage="Enter Password"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-confirmPassword-Input">Confirm Password</Label>
                                                    <AvField
                                                        name='passwordConfirm'
                                                        type="password"
                                                        className="form-control"
                                                        id="formrow-confirmPassword-Input"
                                                        placeholder='Type Confirm Password'
                                                        errorMessage="Confrim Password"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>


                                        <div>
                                            <button type="submit" className="btn btn-primary w-md">
                                                Save User
                                            </button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                    {/* end row  */}



                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    )
}


export default AdminAddUser
