import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
//Import Breadcrumb 
import { useHistory, useRouteMatch } from "react-router";
import {
    Card, CardBody,
    CardTitle, Col,
    Container, Form, Row
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { updateOfficeworkingday } from "../../store/office/actions";



const workingDay = (props) => {
    console.log(props);
    const [day,setDay] = useState([])
   
    const [january, setJanuary] = useState('26')
    const [february, setFebruary] = useState('26')
    const [march, setMarch] = useState('26')
    const [april, setApril] = useState('26')
    const [may, setMay] = useState('26')
    const [june, setJune] = useState('26')
    const [july, setJuly] = useState('26')
    const [august, setAugust] = useState('26')
    const [september, setSeptember] = useState('26')
    const [october, setOctober] = useState('26')
    const [november, setNovember] = useState('26')
    const [december, setDecember] = useState('26')
    const dispatch = useDispatch()
    const history = useHistory()
    
    const {
        params: { officeid },
    } = useRouteMatch('/office-time/:officeid');
    console.log(officeid);
    const { authtoken} = useSelector(state => ({
        authtoken: state.Login.token,
        // id: state.Login.id,
       
    }))
    const workingDay = [
        {
            monthName: 'January',
            days: january

        },
        {
            monthName: 'February',
            days: february

        },
        {
            monthName: 'March',
            days: march

        },
        {
            monthName: 'April',
            days: april

        },
        {
            monthName: 'May',
            days: may

        },
        {
            monthName: 'June',
            days: june

        },
        {
            monthName: 'July',
            days: july

        },
        {
            monthName: 'August',
            days: august

        },
        {
            monthName: 'September',
            days: september

        },
        {
            monthName: 'October',
            days: october

        },
        {
            monthName: 'November',
            days: november

        },
        {
            monthName: 'December',
            days: december

        }

    ]
    const handleJanuary = (e)=>{ 
        setJanuary(e.target.value)
  
    }
    const handleFebruary = (e)=>{ 
        setFebruary(e.target.value)
  
    }
    const handleMarch = (e)=>{ 
        setMarch(e.target.value)
  
    }
    const handleApril = (e)=>{ 
        setApril(e.target.value)
  
    }
    const handleMay = (e)=>{ 
        setMay(e.target.value)
  
    }
    const handleJune = (e)=>{ 
        setJune(e.target.value)
  
    }
    const handleJuly = (e)=>{ 
        setJuly(e.target.value)
  
    }
    const handleAugust = (e)=>{ 
        setAugust(e.target.value)
  
    }
    const handleSeptember = (e)=>{ 
        setSeptember(e.target.value)
  
    }
    const handleOctober = (e)=>{ 
        setOctober(e.target.value)
  
    }
    const handleNovember = (e)=>{ 
        setNovember(e.target.value)
  
    }
    const handleDecember = (e)=>{ 
        setDecember(e.target.value)
  
    }
  
    const handleSubmit =(e)=>{
        e.preventDefault();
        // setDay(workingDay)
        const obj = {
            workingDay: workingDay
        }
     
        dispatch(updateOfficeworkingday(obj,history, authtoken, officeid )) 
    }
    
    
   
  


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DDC | Set Office Time</title>
                </MetaTags>
                <Container fluid={true} >
                    <Breadcrumbs title="Set Office Time" breadcrumbItem="Set Office Time" />
                    <Row>
                        <Col md={6} className='m-auto'>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h5 mb-4">Set Office Time</CardTitle>

                                    <Form className=" g-3 align-items-center " onSubmit={handleSubmit}>
                                        <div className='d-flex p-auto bg-light w-100 mb-4 '>
                                            <p className='m-3'>Month</p>
                                            <p className='ms-auto m-3'>Day</p>
                                        </div>
                                        <Row className='align-items-center'>
                                            <Col xs={8} >

                                                <p>January</p>

                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto"  onChange={handleJanuary}>
                                                    <option value="0">Choose</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>

                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='align-items-center'>
                                            <Col xs={8} >


                                                <p>February</p>


                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto" onChange={handleFebruary} >
                                                    <option value="0">Choose...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='align-items-center'>
                                            <Col xs={8} >


                                                <p>March</p>


                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto" onChange={handleMarch}>
                                                    <option value="0">Choose...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='align-items-center'>
                                            <Col xs={8} >


                                                <p>April</p>


                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto" onChange={handleApril}>
                                                    <option value="0">Choose...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='align-items-center'>
                                            <Col xs={8} >


                                                <p>May</p>


                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto" onChange={handleMay}>
                                                    <option value="0">Choose...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='align-items-center'>
                                            <Col xs={8} >


                                                <p>June</p>


                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto" onChange={handleJune}>
                                                    <option value="0">Choose...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='align-items-center'>
                                            <Col xs={8} >


                                                <p>July</p>


                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto" onChange={handleJuly}>
                                                    <option value="0">Choose...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='align-items-center'>
                                            <Col xs={8} >


                                                <p>August</p>


                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto" onChange={handleAugust}>
                                                    <option value="0">Choose...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='align-items-center'>
                                            <Col xs={8} >


                                                <p>September</p>


                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto" onChange={handleSeptember}>
                                                    <option value="0">Choose...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='align-items-center'>
                                            <Col xs={8} >


                                                <p>October</p>


                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto" onChange={handleOctober}>
                                                    <option value="0">Choose...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='align-items-center'>
                                            <Col xs={8} >


                                                <p>November</p>


                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto" onChange={handleNovember}>
                                                    <option value="0">Choose...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row className='align-items-center'>
                                            <Col xs={8} >


                                                <p>December</p>


                                            </Col>

                                            <Col xs={4}>

                                                <select defaultValue="0" className="form-select m-auto" onChange={handleDecember}>
                                                    <option value="0">Choose...</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <div className='me-auto'>
                                            <button type="submit" className="btn btn-primary w-md">
                                                Save
                                            </button>
                                        </div>




                                        {/* <Col xs={12}>
                                            <button type="submit" className="btn btn-primary w-md">Submit</button>
                                        </Col> */}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>



                    </Row>
                    {/* end row  */}



                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    )
}
export default workingDay;