import {
    GET_SURVEY,
    GET_SURVEY_SUCCESS,
    GET_SURVEY_FAIL,
    ADD_NEW_SURVEY,
    ADD_NEW_SURVEY_SUCCESS,
    ADD_NEW_SURVEY_FAIL,
    GET_SURVEY_SHOP,
    GET_SURVEY_SHOP_SUCCESS,
    GET_SURVEY_SHOP_FAIL,
    GET_SINGLE_SHOP_QUES,
    GET_SINGLE_SHOP_QUES_SUCCESS,
    GET_SINGLE_SHOP_QUES_FAIL
  } from "./actionTypes"

  export const getSurvey = (authtoken) => ({
    type: GET_SURVEY,
    payload: { authtoken },
  })
  
  export const getSurveySuccess = data => ({
    type: GET_SURVEY_SUCCESS,
    payload: data,
  })
  
  export const getSurveyFail = error => ({
    type: GET_SURVEY_FAIL,
    payload: error,
  })
  export const getSurveyShop = (authtoken,surveyId,limit,pageNo,startDate, endDate, value) => ({
    type: GET_SURVEY_SHOP,
    payload: { authtoken,surveyId,limit,pageNo,startDate, endDate, value },
  })
  
  export const getSurveyShopSuccess = data => ({
    type: GET_SURVEY_SHOP_SUCCESS,
    payload: data,
  })
  
  export const getSurveyShopFail = error => ({
    type: GET_SURVEY_SHOP_FAIL,
    payload: error,
  })

  export const getSingleShopQues = (authtoken, singleSurvey) => ({
    type: GET_SINGLE_SHOP_QUES,
    payload: { authtoken, singleSurvey },
  })
  
  export const getSingleShopQuesSuccess = data => ({
    type: GET_SINGLE_SHOP_QUES_SUCCESS,
    payload: data,
  })
  
  export const getSingleShopQuesFail = error => ({
    type: GET_SINGLE_SHOP_QUES_FAIL,
    payload: error,
  })
  export const addSurvey = (data, history, authtoken) => ({
    type: ADD_NEW_SURVEY,
    payload: { data, history, authtoken },
  })
  
  export const addSurveySuccess = data => ({
    type: ADD_NEW_SURVEY_SUCCESS,
    payload: data,
  })
  
  export const addSurveyFail = error => ({
    type: ADD_NEW_SURVEY_FAIL,
    payload: error,
  })