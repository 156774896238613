//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { map } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Spinner } from "reactstrap"
import { getBrandsStatus } from "../../../store/Brand/actions"

import { AvField, AvForm } from "availity-reactstrap-validation"
import BrandInfoAnalytic from "./BrandInfoAnalytic"

const BrandInfoData = props => {
  const [status, setStatus] = useState("preOrder")
  console.log("status", status)
  const dispatch = useDispatch()
  const { authtoken, brandStatus, brandStatusLoading } = useSelector(state => ({
    brandStatus: state.BrandReducer.brandStatus,
    authtoken: state.Login.token,
    brandStatusLoading: state.BrandReducer.brandStatusLoading,
  }))

  useEffect(() => {
    dispatch(getBrandsStatus(authtoken, status))
  }, [status])
  const handleChange = e => {
    setStatus(e.target.value)
  }
  console.log("gi", brandStatus)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Brand Analytics | DDC</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Home" breadcrumbItem="Brand Analytics" />

          <div className="attendance-date">
            <AvForm>
              <AvField
                name="startTime"
                id="example-time-input"
                className="form-control mb-3"
                type="select"
                onChange={e => handleChange(e)}
                // errorMessage="Enter Start Time"
                validate={{ required: { value: true } }}
              >
                <option value="preOrder">Pre Order</option>
                <option value="Delivery">Delivery</option>
              </AvField>
            </AvForm>
          </div>
          {brandStatusLoading ? (
            <div className="text-center mt-5 pt-5">
              <Spinner />
            </div>
          ) : (
            <Row>
              {map(brandStatus?.data?.brand || [], (brand, key) => (
                <BrandInfoAnalytic brand={brand} key={"_shop_" + key} />
              ))}
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

BrandInfoData.propTypes = {
  shops: PropTypes.array,
  onGetShops: PropTypes.func,
}

export default BrandInfoData
