import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts"

import {getStoreAnalytic} from '../../store/Stores/actions'
import { useDispatch, useSelector } from "react-redux";
const StoreAddAnalytic = () => {
  const dispatch = useDispatch();
  const {  authtoken,id,storeAnalytic,storeAnalyticLoading } = useSelector(state => ({
    storeAnalytic: state.StoreReducer.storeAnalytic,
    id: state.Login.id,
    authtoken: state.Login.token,
    storeAnalyticLoading: state.StoreReducer.storeAnalyticLoading,
   
})); 
let storeListDate,storeQuantity
if(!storeAnalyticLoading){
  storeListDate = storeAnalytic?.date
  storeQuantity = storeAnalytic?.quantity
}


useEffect(() => {
  dispatch(getStoreAnalytic(authtoken, id));
}, []);

  const options = {
    chart: {
      height: 300,
      type: "bar",
      toolbar: {
        show: !1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "5%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      show: !0,
      width: 2,
      colors: ["transparent"],
    },
    series: [
      {
        name: "Total store",
        data: storeQuantity || [],
      },
    ],
    xaxis: {
      title: {
        text: "(Date)",
      },
      categories: storeListDate || []
    },
    yaxis: {
      title: {
        text: "(Quantity)",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ["#556ee6"],
  }
  const series = [
    {
      name: "Quantity",
      data: storeQuantity || [],
    },
  ]

  return (
    <div className='page-content'>
        <h5>Store Add Analytic</h5>
        <ReactApexChart options={options} series={series || []} type="bar" height="320" className="apex-charts" />
    </div>
  )
}

export default StoreAddAnalytic