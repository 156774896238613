import React, { useState ,useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts"
import {getDashboardChart} from '../../../store/OrgDashboard/actions'
const OrderAnalyticChart = () => {
  const dispatch = useDispatch();
  const {  authtoken, chartloading,id ,overview,sellingOverviews,sellingOverviewLoading} = useSelector(state => ({
    overview: state.DashboardReducer.dashboardCharts.data,
    sellingOverviews:state.DashboardReducer.sellingOverviews.data,
    id: state.Login.id,
    authtoken: state.Login.token,
    sellingOverviewLoading:state.DashboardReducer.sellingOverviewLoading,
    chartloading: state.DashboardReducer.chartloading,
   
})); 
useEffect(() => {
  dispatch(getDashboardChart(authtoken));
}, []);

console.log(overview);
  const series = [
    {
      name: "Order",
      data: [34, 40, 28, 52, 42, 109, 100,12,32,13,76,43],
    },
    {
      name: "Amount",
      data: [32, 88, 34, 46, 34, 52, 41,89,32,14,68,12],
    },
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#556ee6", "#34c38f"],
    xaxis: {
      type: "year",
      categories: [
        "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
    />
  )
}

export default OrderAnalyticChart
