//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import {  useRouteMatch } from "react-router";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container, Row, Spinner, UncontrolledTooltip
} from "reactstrap";
import { getGeoRoute } from "../../../store/GeoInformation/actions";





const TableRouteGEO = props => {

  const history = useHistory()
  const dispatch = useDispatch();
  const {
    params: { geoID },
} = useRouteMatch('/table-route-geo/:geoID');
console.log(geoID);

  // console.log(routes.data.route[0]._id);


  const { geoRoute, authtoken, loading, id,geoRouteLoading } = useSelector(state => ({
    geoRoute: state.GeoInformationReducer.geoRoute,
    authtoken: state.Login.token,
    id: state.Login.id,
    loading: state.GeoInformationReducer.loading,
    geoRouteLoading: state.GeoInformationReducer.geoRouteLoading
  }))


useEffect(() => {
  dispatch(getGeoRoute(authtoken,geoID))
}, [])
console.log(geoRoute);
  const tableData = !geoRouteLoading && geoRoute?.data?.singlegeo?.routes?.map((route, index) => {
    return {
      sl: index + 1,
      heading1: route.routeCode,
      heading2: route.name,
      manage: (
        <>
          <Button
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => {
              history.push(`/route-details/${route._id}`)
            }}
          >
            View Details
          </Button>

        </>
      ),
    }
  })




  const productData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },
      {
        label: "Route Code",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "heading2",
        sort: "asc",
        width: 150,
      },
      {
        label: "View Details",
        field: "manage",
      },
    ],
    rows: tableData,
  }






  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div >
        <MetaTags>
          <title>Route | DDC</title>
        </MetaTags>
        <Container fluid className='page-content'>
          <Breadcrumbs title="Route" breadcrumbItem="Route details" />
          {!geoRouteLoading ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>

                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={productData}
                    />
                   
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="text-center mt-5 pt-5">
              <Spinner />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(TableRouteGEO);



