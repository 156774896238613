import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useSelector, useDispatch } from "react-redux"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
  Table,
  Button
} from "reactstrap"
import "../../assets/css/style.css"

import { Link } from "react-router-dom"
import pdfImage from "../../assets/images/pdf.svg"
import { getTodayActivity, getDpActivity } from "../../store/Activities/actions"
import { useRouteMatch } from "react-router";

const moment = require("moment")
const DateWiseProductList = () => {
  const today = moment().format('YYYY-MM-DD')
  const {
    params: { dataInfo },
} = useRouteMatch('/DateWiseProductList/:dataInfo');
console.log(dataInfo);
console.log(dataInfo);

  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState("tab1")
  const day = moment().format("DD")
  console.log(day)
  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1")
  }
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2")
  }
  const {
    TodayActivities,
    authtoken,
    todayActivityLoading,
    id,
    dpActivities,
    dpActivityLoading,
  } = useSelector(state => ({
    TodayActivities: state.ActivityReducer.TodayActivities,
    id: state.Login.id,
    authtoken: state.Login.token,
    todayActivityLoading: state.ActivityReducer.todayActivityLoading,
    dpActivities: state.ActivityReducer.dpActivities,
    dpActivityLoading: state.ActivityReducer.dpActivityLoading,
  }))

  useEffect(() => {
    dispatch(getTodayActivity(authtoken, id, dataInfo))
  }, [])
  useEffect(() => {
    dispatch(getDpActivity(authtoken, id, dataInfo))
  }, [])
  console.log(TodayActivities)
  let todayActivity, dpActivity
  if (!todayActivityLoading) {
    todayActivity = TodayActivities.data.order
    console.log(todayActivity)
  }
  if (!dpActivityLoading) {
    dpActivity = dpActivities.data.dpOrder
    console.log(dpActivity)
  }
  const printProductList = () => {
    window.print()
  }
  const printDpList = () => {
    window.print()
  }
//   function printDpProductList(url) {
//      var printWindow = window.open( url);
//      window.print(url);
// };
  console.log(dpActivity)
  return (
    <div>
      <div className="page-content">
        <Container fluid={true}>
          <h4 className="mb-4">{dataInfo}  </h4>
        
          <Card>
            <div className="activity-product">
              <ul className="nav alltab">
                <li
                  className={activeTab === "tab1" ? "active " : ""}
                  onClick={handleTab1}
                >
                  Product List
                </li>
                <li
                  className={activeTab === "tab2" ? "active" : ""}
                  onClick={handleTab2}
                >
                  Dp List
                </li>
              </ul>
              <div className="outlet">
                {activeTab === "tab1" ? (
                  <>
                    <div className="add-inventory-serach">
                      <div>
                        <input placeholder="search here"></input>
                      </div>
                      <div>
                        <Link
                          to="/activityProductList"
                          className="product-inventory-btn"
                          onClick={printProductList}
                        >
                          download pdf
                        </Link>
                      </div>
                    </div>
                    <Table hover className="product-activity-table">
                      <thead className="product-activity-head">
                        <tr>
                          <th>NO</th>
                          <th>Product List</th>
                          <th>Order Quantity</th>
                          <th>Order Quantity (x1.5)</th>
                          <th>View Details</th>
                        </tr>
                      </thead>
                      {todayActivityLoading ? (
                        <div className="text-center pt-5">
                          <Spinner />
                        </div>
                      ) : (
                        <tbody>
                          {(todayActivity || []).map((data, key) => {
                            return (
                              <>
                                <tr>
                                  <th scope="row">{key + 1}</th>
                                  <td>{data._id.productName}</td>
                                  <td>{data.sumQuantity}</td>
                                  <td>{Math.ceil(data.sumQuantity * 1.5)}</td>
                                  <td>
                                   <Link to={`/history-product-list/${data._id.productId}`}>
                                   <Button
                                      type="button"
                                      color="primary"
                                      className="btn-sm btn-rounded"
                                     
                                    >
                                      View Details
                                    </Button>
                                   </Link>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      )}
                    </Table>
                  </>
                ) : (
                  <>
                    <div>
                      <div className="add-inventory-serach">
                        <div>
                          <input placeholder="search here"></input>
                        </div>
                        <div>
                          <Link
                            to="/activityProductList"
                            className="product-inventory-btn"
                            onClick={printDpList}
                          >
                            Download Pdf
                          </Link>
                        </div>
                      </div>
                    </div>
                    <Table hover>
                      <thead className="dp-activity-head">
                        <tr>
                          <th>NO</th>
                          <th>Dp List </th>

                          <th className="dp-activity-view">View Details</th>
                        </tr>
                      </thead>
                      {todayActivityLoading ? (
                        <div className="text-center pt-5">
                          <Spinner />
                        </div>
                      ) : (
                        <tbody>
                          {(dpActivity || []).map((data, key) => {
                            return (
                              <>
                                <tr>
                                  <th scope="row">{key + 1}</th>
                                  <td>{data.userInfo[0].name}</td>

                                  <td>
                                  <div className="dp-activity-details">
                                    <div className="dp-download-pdf">
                                      {/* <div>
                                        <Link
                                         
                                          // className="product-inventory-btn"
                                          //  onClick={printDpProductList(`/activityToday/${data._id}`)}
                                        >
                                          <p className="download-pdf">
                                            {" "}
                                            <img
                                              src={pdfImage}
                                              className="pdfImg"
                                            ></img>
                                            Download Pdf
                                          </p>
                                        </Link>
                                      </div> */}
                                    </div>
                                    <div className="dp-activity-btn">
                                      <Link to={`/DateWiseDpProductList/${data._id}/${dataInfo}`}>
                                    
                                     
                                        <Button
                                           type="button"
                                           color="primary"
                                           className="btn-sm btn-rounded "
                                        >
                                          Details
                                        </Button>
                                      </Link>
                                    </div>
                                  </div>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      )}
                    </Table>
                  </>
                )}
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </div>
  )
}

export default DateWiseProductList
