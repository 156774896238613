import React, { useEffect } from "react"
import { makeQuery } from "helpers/Custom/makeQuery"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, Spinner } from "reactstrap"
import { getTopBrand } from "../../store/OrgDashboard/actions"

const TotalSellngProduct = props => {
  const dispatch = useDispatch()

  const { authtoken, topBrands, id, 
    topBrandLoading,   
    mainFilter,
    isSubFilterChanged,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    currentSelection, } = useSelector(state => ({
    topBrands: state.DashboardReducer.topBrands.data,

    id: state.Login.id,
    authtoken: state.Login.token,
    topBrandLoading: state.DashboardReducer.topBrandLoading,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    mainFilter: state.DashboardReducer.mainFilter,
    currentSelection: state.DashboardReducer.currentSelection,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
  }))
  useEffect(() => {
    const query = makeQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection
    )
    dispatch(getTopBrand(authtoken, query))
  }, [isSubFilterChanged])

  return (
    <React.Fragment>
      <Card className="top-brand">
        <CardBody>
          <div className="clearfix mt-2 d-flex">
            <h4 className="card-title mb-4">Top Six Brands</h4>
            {/* <Link to="/brand" className="ms-auto fw-bold">
              {" "}
              <p>See all</p>
            </Link> */}
          </div>

          <hr />
          {topBrandLoading ? (
            <div className="text-center  pt-5">
              <Spinner />
            </div>
          ) : (
            <div className="product-responsive">
              <div>
                <div>
                  {(topBrands?.bestsellingproducts?.slice(0, 6) || []).map(
                    (data, key) => {
                      return (
                        <div className="product-data-with-product" key={key}>
                          <div>
                            <div className="align-self-center me-3 product-image-list-view-hfjdksuryfh">
                              <img
                                src={`${process.env.REACT_APP_S3}${data?.brandname[0]?.photo}`}
                                // className="rounded-circle avatar-xs"
                                alt=""
                              />
                            </div>
                            <div>
                              <h6>{data?.brandname[0]?.name}</h6>
                            </div>
                          </div>

                          <div>
                            <div>
                              {/* <p className="text-muted mb-0 mt-1">Amount<small>(Tk)</small></p> */}
                              <h6 className="mb-0 mt-2 totalsell">
                                {data?.totalamount?.toLocaleString('en-US', {minimumFractionDigits: 2})} tk
                              </h6>
                              {/* <p className="mb-2 mt-2">
                              {" "}
                              <span className="pre-order-bg">0.3%</span>{" "}
                              <img src={arrowUp} alt="" /> vs Last month
                            </p> */}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TotalSellngProduct
