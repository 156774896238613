import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { getBrands } from "../../store/Brand/actions"
import { getCategorys } from "../../store/category/actions"
import { getProducts, updateProduct } from "../../store/Product/actions"
import { getUnits } from "../../store/Unit/actions"
import '../../assets/css/style.css'

const EditProduct = () => {
  const {
    params: { productID },
  } = useRouteMatch("/editProduct/:productID")

  const [file, setfile] = useState("")
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [testloading, setloading] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()

  const handleChangeBrand = value => {
    setSelectedBrand(value._id)
  }
  const handleChangeCategory = value => {
    setSelectedCategory(value._id)
  }
  const handleChangeUnit = value => {
    setSelectedUnit(value._id)
  }
  useEffect(() => {
    dispatch(getBrands(authtoken, LoginId))
    dispatch(getCategorys(authtoken))
    dispatch(getUnits(authtoken))
  }, [])

  const {
    authtoken,
    loading,
    offices,
    LoginId,
    brands,
    products,
    categories,
    units,
    testLoading,
  } = useSelector(state => ({
    products: state.ProductReducer,
    authtoken: state.Login.token,
    LoginId: state.Login.id,
    brands: state.BrandReducer.brands.data,
    categories: state.CategoryReducer.categories.category,
    units: state.UnitReducer.units.unit,
    testLoading: state.ProductReducer.testLoading,
  }))
  console.log(
    `file: EditProduct.js ~ line 74 ~ EditProduct ~ authtoken`,
    authtoken
  )
  const { data } = products.products
  const productEdit = data?.product?.find(p => p._id === productID)
  console.log(
    `file: EditProduct.js ~ line 77 ~ EditProduct ~ productEdit`,
    productEdit
  )

  if (!!productEdit === false) {
    history.push("/details-product")
  }

  useEffect(() => {
    setSelectedBrand(productEdit?.brand?._id || "")
    setSelectedCategory(productEdit?.category?._id || "")
    setSelectedUnit(productEdit?.unitType?._id || "")
  }, [productEdit])

  useEffect(() => {
    dispatch(getProducts(authtoken, LoginId))
  }, [])
  // console.log(products.data.product[0]._id);
  // const productEdit = products.data?.product.find(p => p._id === productID)
  // console.log('kjk',productEdit);
  async function handleSubmit(event, errors, values) {
    event.preventDefault()
    // setloading(true)
    const formData = new FormData()
    console.log('values', values);
    const { name, unitPrice, maxDiscount, code } = values
    const data = {
      name,
      brand: selectedBrand,
      code,
      unitPrice,
      maxDiscount,
      unitType: selectedUnit,
      category: selectedCategory,
      org: LoginId,
    }
    // console.log(values)
    formData.append("name", name)
    formData.append("unitPrice", unitPrice)

    formData.append("maxDiscount", maxDiscount || 0)
    formData.append("code", code)
    formData.append("brand", selectedBrand)
    if (file) {
      formData.append("photo", file)
    }
  
    // obj.brand = selectedBrand
    formData.append("unitType", selectedUnit)
    formData.append("category", selectedCategory)
    formData.append("org", LoginId)
    for (const key of formData.entries()) {
      console.log(key)
    }
    console.log(productID, authtoken, data)
    if (values && selectedBrand && selectedCategory && selectedUnit) {
    dispatch(updateProduct(formData, authtoken, productID, history))
    } else {
      console.log("Required Data Missing....")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Edit Product | DDC</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Add Product" /> */}

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle> Edit Product</CardTitle>
                  <CardSubtitle className="mb-4"></CardSubtitle>

                  <AvForm onSubmit={handleSubmit} className="m-auto">
                    <Row>
                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="productname">Product Name</Label>
                          <AvField
                            id="productname"
                            value={productEdit?.name}
                            name="name"
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="brand">Brand Name</Label>

                          <Select
                            name="brand"
                            cacheOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            options={brands}
                            // defaultOptions={}
                            value={brands?.filter(
                              data => data?._id === selectedBrand
                            )}
                            onChange={handleChangeBrand}
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="unitType">Unit Name</Label>

                          <Select
                            name="unitType"
                            cacheOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            options={units}
                            value={units?.filter(
                              data => data?._id === selectedUnit
                            )}
                            defaultOptions
                            onChange={handleChangeUnit}
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="priceInfo">Price</Label>
                          <AvField
                            id="priceInfo"
                            name="unitPrice"
                            value={productEdit?.unitPrice}
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="categoryname">Category Name</Label>

                          <Select
                            name="category"
                            cacheOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            options={categories}
                            value={categories?.filter(
                              data => data?._id === selectedCategory
                            )}
                            defaultOptions
                            onChange={handleChangeCategory}
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <Label htmlFor="productcode">Product Code</Label>
                          <AvField
                            id="productcode"
                            value={productEdit?.code}
                            name="code"
                            type="text"
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="model">Model No(Set by You)</Label>
                          <AvField
                            id="model"
                            value={productEdit?.model}
                            name="model"
                            type="text"
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="discount">Max Discount</Label>
                          <AvField
                            id="discount"
                            name="maxDiscount"
                            value={productEdit?.maxDiscount}
                            type="text"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <FormGroup className="mb-3">
                        <Label htmlFor="discount">Image</Label>
                        <AvField
                          type="file"
                          className="form-control"
                          id="formrow-brand-Input"
                          name="photo"
                            //  value={productEdit?.image}
                          onChange={e => {
                            setfile(e.target.files[0])
                          }}
                        />               
                      </FormGroup>
                      <img src={`https://production-fieldx.sgp1.digitaloceanspaces.com/${productEdit?.image}`} className='productEditImage'/>
                    </Row>

                    {testloading ? (
                      <div className="p-4 d-flex justify-content-center align-items-center p-2 mb-3 mt-5 mr-auto">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    ) : (
                      <>
                        <Col lg={6}></Col>
                        <div className="flex-wrap gap-2 col-md-12 text-center">
                          <Button type="submit" color="primary" className="">
                            Submit
                          </Button>
                        </div>
                      </>
                    )}
                    {/* <div className="d-flex flex-wrap gap-2">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn "
                                            >
                                                Save Changes
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="secondary"
                                                className=" "
                                            >
                                                Cancel
                                            </Button>
                                        </div> */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditProduct
