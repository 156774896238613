import { makeQuery } from "helpers/Custom/makeQuery"
import React, { useEffect, useState } from "react"
import Chart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Row, Spinner } from "reactstrap"
import arrowUp from "../../assets/images/arrow-up.svg"
import arrowDown from "../../assets/images/Shape.svg"
import {
  getDashboardChart,
  getSellingOverview,
} from "../../store/OrgDashboard/actions"
import "./Dashboard.css"

const SellingOverview = () => {
  const [chartFilter, setChartFilter] = useState("daily")
  const [chartFilter2, setChartFilter2] = useState("preorder")
  const dispatch = useDispatch()
  const {
    authtoken,
    chartloading,
    id,
    overview,
    sellingOverviews,
    sellingOverviewLoading,
    mainFilter,
    isSubFilterChanged,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    currentSelection,
  } = useSelector(state => ({
    overview: state.DashboardReducer.dashboardCharts.data,
    sellingOverviews: state.DashboardReducer.sellingOverviews.data,
    id: state.Login.id,
    authtoken: state.Login.token,
    sellingOverviewLoading: state.DashboardReducer.sellingOverviewLoading,
    chartloading: state.DashboardReducer.chartloading,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    mainFilter: state.DashboardReducer.mainFilter,
    currentSelection: state.DashboardReducer.currentSelection,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
  }))
  // console.log(sellingOverviews)

  // console.log("Query>>>>", queryMaker(mainFilter, selectedSubFilter))
  useEffect(() => {
    const query = makeQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection
    )
    dispatch(getSellingOverview(authtoken, query))
  }, [isSubFilterChanged])

  // console.log(
  //   "Query>>>>><",
  //   makeQuery(
  //     mainFilter,
  //     selectedRegion,
  //     selectedArea,
  //     selectedTerritory,
  //     currentSelection,
  //     chartFilter,
  //     chartFilter2
  //   )
  // )

  useEffect(() => {
    const query = makeQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection,
      chartFilter,
      chartFilter2
    )
    dispatch(getDashboardChart(authtoken, query))
  }, [isSubFilterChanged, chartFilter, chartFilter2])
  let amountData, unitData
  if (!chartloading) {
    var categories = overview?.categories
    amountData = overview?.series[0]?.data
    unitData = overview?.series[1]?.data
  }
  console.log(amountData, unitData)
  console.log("sellingOverviews", sellingOverviews)

  // console.log(categories)
  // const options = {
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "smooth",
  //     width: 3,
  //   },

  //   colors: ["#556ee6", "#34c38f"],
  //   xaxis: {
  //     type: "year",
  //     categories,
  //   },
  //   grid: {
  //     borderColor: "#f1f1f1",
  //   },
  //   tooltip: {
  //     x: {
  //       format: "dd/MM/yy HH:mm",
  //     },
  //   },
  // }

  // const { options, series } = props;

  // var options = {
  //   series: [{
  //   name: 'Income',
  //   type: 'column',
  //   data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
  // }, {
  //   name: 'Cashflow',
  //   type: 'column',
  //   data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
  // }, {
  //   name: 'Revenue',
  //   type: 'line',
  //   data: [20, 29, 37, 36, 44, 45, 50, 58]
  // }],
  //   chart: {
  //   height: 350,
  //   type: 'line',
  //   stacked: false
  // },
  // dataLabels: {
  //   enabled: false
  // },
  // stroke: {
  //   width: [1, 1, 4]
  // },
  // title: {
  //   text: '',
  //   align: 'left',
  //   offsetX: 110
  // },
  // xaxis: {
  //   categories: categories || [],
  // },
  // yaxis: [
  //   {
  //     axisTicks: {
  //       show: true,
  //     },
  //     axisBorder: {
  //       show: true,
  //       color: '#008FFB'
  //     },
  //     labels: {
  //       style: {
  //         colors: '#008FFB',
  //       }
  //     },
  //     title: {
  //       text: "Total Amount",
  //       style: {
  //         color: '#008FFB',
  //       }
  //     },
  //     tooltip: {
  //       enabled: true
  //     }
  //   },
  //   {
  //     seriesName: 'Income',
  //     opposite: true,
  //     axisTicks: {
  //       show: true,
  //     },
  //     axisBorder: {
  //       show: true,
  //       color: '#00E396'
  //     },
  //     labels: {
  //       style: {
  //         colors: '#00E396',
  //       }
  //     },

  //   },
  //   {
  //     seriesName: 'Revenue',
  //     opposite: true,
  //     axisTicks: {
  //       show: true,
  //     },
  //     axisBorder: {
  //       show: true,
  //       color: '#FEB019'
  //     },
  //     labels: {
  //       style: {
  //         colors: '#FEB019',
  //       },
  //     },
  //     title: {
  //       text: "Unit",
  //       style: {
  //         color: '#FEB019',
  //       }
  //     }
  //   },
  // ],
  // tooltip: {
  //   fixed: {
  //     enabled: true,
  //     position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
  //     offsetY: 30,
  //     offsetX: 60
  //   },
  // },
  // legend: {
  //   horizontalAlign: 'left',
  //   offsetX: 40
  // }
  // };
  var options = {
    series: [
      {
        name: "Amount",
        type: "line",
        data: amountData,
      },
      {
        name: "Unit",
        type: "column",
        data: unitData,
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "15%",
      },
    },
    colors: ["#34C38F", "#556EE6"],
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#34C38F", "#556EE6"],
    fill: {
      colors: undefined,
      opacity: 1,
      type: "solid",
    },
    stroke: {
      width: [4, 0],
    },
    title: {
      // text: 'XYZ - Stock Analysis (2009 - 2016)',
      align: "left",
      offsetX: 110,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
        },
        title: {
          text: "Amount",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        seriesName: "Unit",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#00E396",
        },
        labels: {
          style: {
            colors: "#00E396",
          },
        },
        title: {
          text: "Unit",
          style: {
            color: "#00E396",
          },
        },
      },
      // {
      //   seriesName: 'Revenue',
      //   opposite: true,
      //   axisTicks: {
      //     show: true,
      //   },
      //   axisBorder: {
      //     show: true,
      //     color: '#FEB019'
      //   },
      //   labels: {
      //     style: {
      //       colors: '#FEB019',
      //     },
      //   },
      //   title: {
      //     text: "Revenue (thousand crores)",
      //     style: {
      //       color: '#FEB019',
      //     }
      //   }
      // },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "center",
      offsetX: 40,
    },
  }
  console.log("overview", overview)

  const handleChartFilter = e => {
    // console.log("e", e.target.value)
    const value = e.target.value
    setChartFilter(value)
  }

  const handleChartFilter2 = e => {
    const value = e.target.value
    setChartFilter2(value)
  }

  return (
    <React.Fragment>
      {chartloading ? (
        <div className="text-center  pt-5">
          <Spinner />
        </div>
      ) : (
        <Card className="overview">
          <CardBody>
            <h4 className="card-title mb-4">Total Selling Overview</h4>
            {sellingOverviewLoading ? (
              <div className="text-center pt-5">--</div>
            ) : (
              <Row className="text-centerhgfds">
                <Col className="col-xl col-lg-3 col-md-4 col-sm-4 col-6">
                  <div>
                    <p className="text-muted mb-1">Today Order</p>
                    <h5>{Math.ceil(sellingOverviews?.todayorder)}</h5>
                    {sellingOverviews?.dailygrowth > 0 ? (
                      <p>
                        {" "}
                        <span className="pre-order-bg">
                          {sellingOverviews?.dailygrowth} %
                        </span>{" "}
                        <img src={arrowUp} alt="" /> <br /> vs Last Day
                      </p>
                    ) : (
                      <p>
                        {" "}
                        <span className="pre-order-bg-low">
                          {sellingOverviews?.dailygrowth} %
                        </span>{" "}
                        <img src={arrowDown} alt="" />
                        <br /> vs Last Day
                      </p>
                    )}
                  </div>
                </Col>
                <Col className="col-xl col-lg-3 col-md-4 col-sm-4 col-6">
                  <div>
                    <p className="text-muted mb-1">Pre Order (Tk)</p>
                    <h5>
                      {sellingOverviews?.todaytotalorderamount?.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                        }
                      ) || ""}{" "}
                      tk
                    </h5>
                    {sellingOverviews?.todayorderamountgrowthrate > 0 ? (
                      <p>
                        {" "}
                        <span className="pre-order-bg">
                          {Math.ceil(
                            sellingOverviews?.todayorderamountgrowthrate
                          )}{" "}
                          %
                        </span>{" "}
                        <img src={arrowUp} alt="" />
                        <br /> vs Last Day
                      </p>
                    ) : (
                      <p>
                        {" "}
                        <span className="pre-order-bg-low">
                          {Math.ceil(
                            sellingOverviews?.todayorderamountgrowthrate
                          )}{" "}
                          %
                        </span>{" "}
                        <img src={arrowDown} alt="" />
                        <br /> vs Last Day
                      </p>
                    )}
                  </div>
                </Col>{" "}
                <Col className="col-xl col-lg-3 col-md-4 col-sm-4 col-6">
                  <div>
                    <p className="text-muted mb-1">Pre Order (Tk)</p>
                    <h5>
                      {sellingOverviews?.currentmonthpreordervalue?.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                        }
                      ) || ""}{" "}
                    </h5>
                    {sellingOverviews?.monthlypreordergrowth > 0 ? (
                      <p>
                        {" "}
                        <span className="pre-order-bg">
                          {Math.ceil(sellingOverviews?.monthlypreordergrowth)} %
                        </span>{" "}
                        <img src={arrowUp} alt="" />
                        <br /> vs Last Month
                      </p>
                    ) : (
                      <p>
                        {" "}
                        <span className="pre-order-bg-low">
                          {Math.ceil(sellingOverviews?.monthlypreordergrowth)} %
                        </span>{" "}
                        <img src={arrowDown} alt="" />
                        <br /> vs Last Month
                      </p>
                    )}
                  </div>
                </Col>{" "}
                <Col className="col-xl col-lg-3 col-md-4 col-sm-4 col-6">
                  <div>
                    <p className="text-muted mb-1">Delivery (Tk)</p>
                    <h5>
                      {sellingOverviews?.currentmonth?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}{" "}
                    </h5>

                    {sellingOverviews?.monthlygrowth > 0 ? (
                      <p>
                        {" "}
                        <span className="pre-order-bg">
                          {sellingOverviews?.monthlygrowth} %
                        </span>{" "}
                        <img src={arrowUp} alt="" />
                        <br /> vs Last Month
                      </p>
                    ) : (
                      <p>
                        {" "}
                        <span className="pre-order-bg-low">
                          {sellingOverviews?.monthlygrowth} %
                        </span>{" "}
                        <img src={arrowDown} alt="" />
                        <br /> vs Last Month
                      </p>
                    )}
                  </div>
                </Col>
                <Col className="col-xl col-lg-3 col-md-4 col-sm-4 col-6">
                  <div>
                    <p className="text-muted mb-1">Delivery (Tk)</p>
                    <h5>
                      {sellingOverviews?.yearlyamount?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      }) || ""}{" "}
                    </h5>
                    {sellingOverviews?.yearlygrowth > 0 ? (
                      <p>
                        {" "}
                        <span className="pre-order-bg">
                          {sellingOverviews?.yearlygrowth} %
                        </span>{" "}
                        <img src={arrowUp} alt="" />
                        <br /> vs Last Year
                      </p>
                    ) : (
                      <p>
                        {" "}
                        <span className="pre-order-bg-low">
                          {sellingOverviews?.yearlygrowth} %
                        </span>{" "}
                        <img src={arrowDown} alt="" />
                        <br /> vs Last Year
                      </p>
                    )}
                  </div>
                </Col>{" "}
              </Row>
            )}
            <hr className="mb-2" />
            <div className="chart-dhata-show-graph-gfhdjskrhfnc ">
              <select
                onChange={handleChartFilter2}
                value={chartFilter2}
                id="graph-select-data-overview "
              >
                <option value="preorder">Pre Order</option>
                <option value="deliver">Delivery</option>
              </select>
              <select
                onChange={handleChartFilter}
                value={chartFilter}
                id="graph-select-data-overview"
              >
                <option value="daily">Daily</option>
                <option value="weekly">Week</option>
                <option value="monthly">Month</option>
              </select>
            </div>
            <div id="area-chart" dir="ltr">
              <Chart
                options={options}
                series={overview?.series || []}
                type="area"
                height={300}
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  )
}

// SellingOverview.propTypes = {
//     options: PropTypes.any,
//     series: PropTypes.any
//   };

export default SellingOverview
