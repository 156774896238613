import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import TableOffice from "./TableOffice";
import { getOffices } from "../../store/office/actions.js";
import '../../pages/styles.css'



const InfoOffice = () => {
  const dispatch = useDispatch();
  
  const { offices, authtoken, loading } = useSelector(state => ({
    offices: state.OfficeReducer.offices,
    authtoken: state.Login.token,
    loading: state.OfficeReducer.loading
  }));
console.log(loading);


  useEffect(() => {
    dispatch(getOffices(authtoken));
  }, []);
console.log(loading);
  return (
    <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>DDC</title>
          </MetaTags>
          <Container fluid>
          {!loading ? (
                        <div className="text-center  pt-5">
                            <Spinner />
                        </div>
                    ) : (
               
            <Row>

              <Col xl="12">
                <Row>

              
                 
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                            Distribution Offices (Total)
                              </p>
                              <h4 className="mb-0">{offices?.office?.length}</h4>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                   
              <Col md="3">
              <Card className='card-info-list'>
                  <CardBody >
                    <CardTitle className="mb-0 h5">Add Distribution Office</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/add-office"
                            className="btn btn-primary btn-sm mt-1"
                          >
                            Add 
                          </Link>
                        </div>
                      </Col>
                     
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
                 
                </Row>
               

              </Col>
            </Row>
                    )}
         <TableOffice/>
         
          </Container>
        </div>


      </React.Fragment >
  );
};

export default InfoOffice;

