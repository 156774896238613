import { makeQuery } from "helpers/Custom/makeQuery"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Row, Spinner } from "reactstrap"
import dueIcon from "../../assets/images/dueIcon.svg"
import handShake from "../../assets/images/hand-shake1.svg"
import productImage from "../../assets/images/keypad.png"
import volume from "../../assets/images/Mask.png"
import userImage from "../../assets/images/Shape.png"
import strike from "../../assets/images/strike.svg"
import lpc from "../../assets/images/writing1.svg"
import {
  getRevenueInfo,
  getTargetAnalysis,
} from "../../store/OrgDashboard/actions"
import "./Dashboard.css"

function Overview(props) {
  const dispatch = useDispatch()

  const {
    revInfo,
    authtoken,
    targetAnalysis,
    revInfoLoading,
    id,
    overview,
    overviewLoading,
    targetAnalysisLoading,
    mainFilter,
    isSubFilterChanged,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    currentSelection,
  } = useSelector(state => ({
    overview: state.DashboardReducer.dashboardOverviews.data,
    targetAnalysis: state.DashboardReducer.targetAnalysis,
    revInfo: state.DashboardReducer.revInfo,
    id: state.Login.id,
    authtoken: state.Login.token,
    overviewLoading: state.DashboardReducer.overviewLoading,
    revInfoLoading: state.DashboardReducer.revInfoLoading,
    targetAnalysisLoading: state.DashboardReducer.targetAnalysisLoading,
    mainFilter: state.DashboardReducer.mainFilter,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    currentSelection: state.DashboardReducer.currentSelection,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
  }))
  useEffect(() => {
    const query = makeQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection
    )
    dispatch(getRevenueInfo(authtoken, query))
  }, [isSubFilterChanged])
  useEffect(() => {
    const query = makeQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection
    )
    dispatch(getRevenueInfo(authtoken, query))
  }, [isSubFilterChanged])
  useEffect(() => {
    const query = makeQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection
    )
    dispatch(getTargetAnalysis(authtoken, query))
  }, [isSubFilterChanged])
  // console.log(
  //   "Query>>>>><",
  //   makeQuery(
  //     mainFilter,
  //     selectedRegion,
  //     selectedArea,
  //     selectedTerritory,
  //     currentSelection
  //   )
  // )

  let totalRevenue
  if (!revInfoLoading) {
    totalRevenue = revInfo?.data?.totalrevenueamount?.toFixed(2)
  }
  return (
    <div className="overview-page-gfhdjseygfdh">
      {revInfoLoading ? (
        <div className="text-center pt-5">
          <Spinner />
        </div>
      ) : (
        <React.Fragment>
          <div className="text-lg-center mt-4 mt-lg-0">
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-md-4 col-6">
                        <div className="overview-details">
                          <p className="text-muted text-truncate mb-2 fw-bold">
                            <img src={userImage} className="me-1" /> Total
                            Revenue (Tk)
                          </p>
                          {revInfo?.data?.length === 0 ? (
                            <h5 className="mb-0 ms-2 "> --</h5>
                          ) : (
                            <h5 className="mb-0 ms-2 ">
                              {" "}
                              {revInfo?.data?.totalrevenueamount?.toLocaleString(
                                "en-US",
                                { minimumFractionDigits: 2 }
                              )}{" "}
                            </h5>
                          )}
                        </div>
                      </Col>
                      <Col className="col-md-4 col-6">
                        <div className="overview-details">
                          <p className="text-muted text-truncate mb-2 fw-bold">
                            <img src={dueIcon} className="me-1" />
                            Total Dues (Tk)
                          </p>
                          <h5 className="mb-0">
                            {revInfo?.data?.totaldueamount?.toLocaleString(
                              "en-US",
                              { minimumFractionDigits: 2 }
                            )}{" "}
                          </h5>
                        </div>
                      </Col>
                      <Col className="col-md-4 col-6">
                        <div className="overview-details last-child-border ">
                          <p className="text-muted text-truncate mb-2 fw-bold">
                            <img src={productImage} className="me-1" />
                            Total Bounce
                          </p>
                          <h5 className="mb-0">
                            {revInfo?.data?.bounceTotalcount}
                          </h5>
                        </div>
                      </Col>
                      {/* <Col md="4">
                        <div className="overview-details last-child-border ">
                          <p className="text-muted text-truncate mb-2 fw-bold">
                            <img src={bounceImage} className="me-1" />
                            Total Bounce 
                          </p>
                          <h5 className="mb-0">
                            {revInfo?.data?.bounceTotalcount}
                          </h5>
                        </div>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {targetAnalysisLoading ? (
                <div className="text-center pt-5">
                  <Spinner />
                </div>
              ) : (
                <>
                  <Col md={6}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col className="col-md-3 col-6">
                            <div className="overview-details">
                              <p className="text-muted  mb-2 fw-bold">
                                <img src={handShake} className="me-1" />
                                AIV
                              </p>

                              <h5>{Math.ceil(targetAnalysis?.data?.bcp)} </h5>
                            </div>
                          </Col>
                          <Col className="col-md-3 col-6">
                            <div className="overview-details">
                              <p className="text-muted mb-2 fw-bold text-info">
                                <img src={strike} className="me-1" />
                                SR (%)
                              </p>
                              {Math.ceil(targetAnalysis?.data?.strikerate) >=
                              80 ? (
                                <h5 className="text-success">
                                  {Math.ceil(targetAnalysis?.data?.strikerate)}{" "}
                                  %
                                </h5>
                              ) : Math.ceil(targetAnalysis?.data?.strikerate) >=
                                59 ? (
                                <h5 className="text-warning">
                                  {Math.ceil(targetAnalysis?.data?.strikerate)}{" "}
                                  %
                                </h5>
                              ) : (
                                <h5 className="text-danger">
                                  {Math.ceil(targetAnalysis?.data?.strikerate)}{" "}
                                  %
                                </h5>
                              )}

                              {/* {
                            Math.ceil(overview?.strikerate) >=80 ? <h5 className="text-success">{Math.ceil(overview?.strikerate)} %</h5> :
                            Math.ceil(overview?.strikerate) >=59 ?<h5 className="text-warning">{Math.ceil(overview?.strikerate)} %</h5> :
                            <h5 className="text-danger">{Math.ceil(overview?.strikerate)} %</h5>
                          }
                           */}
                            </div>
                          </Col>
                          <Col className="col-md-3 col-6">
                            <div className="overview-details">
                              <p className="text-muted mb-2 fw-bold text-info">
                                <img src={volume} className="me-1" />
                                Vol.Ach (%)
                              </p>

                              <h5>
                                {Math.ceil(
                                  targetAnalysis?.data?.volumeachievement
                                )}
                                %
                              </h5>
                            </div>
                          </Col>
                          <Col className="col-md-3 col-6">
                            <div className="overview-details  last-child-border">
                              <p className="text-muted mb-2 fw-bold">
                                <img src={lpc} className="me-1" /> LPC
                              </p>

                              <h5 className="mb-0 ms-2 ">
                                {Math.ceil(targetAnalysis?.data?.lpc)}
                              </h5>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              )}

              {/* <Col md="2"  className='overview-details'>
                        <div>
                          <p className="text-muted text-truncate mb-2 fw-bold text-info">
                          <img src={productImage} className='me-1'/> 
                          LPC (%)
                          </p>
                          <h5 className="mb-0">{overview.teritoriesModel}</h5>
                        </div>
                      </Col> */}
            </Row>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default Overview
