//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {  useRouteMatch } from "react-router";
import {
    Badge, Button, Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Row, Spinner, Table, UncontrolledDropdown
} from "reactstrap";
import { getStores } from '../../../store/Stores/actions';
import { getRouteShop } from '../../../store/GeoInformation/actions';







const TableRouteStore = props => {

    const history = useHistory()
    const dispatch = useDispatch();
    const {
        params: { routeID },
    } = useRouteMatch('/route-details/:routeID');
    console.log(routeID);
    const { shopRoute, authtoken, id,RouteShopLoading,stores } = useSelector(state => ({
    
      shopRoute: state.GeoInformationReducer.shopRoute,
      authtoken: state.Login.token,
      id: state.Login.id,
      RouteShopLoading: state.GeoInformationReducer.RouteShopLoading,
      loading: state.StoreReducer.loading,
    }))
    
   

    // const [modal, setModal] = useState(false);
   
 
  
    useEffect(() => {
        dispatch(getRouteShop(authtoken, routeID));
    }, []);
  
  console.log('hellohihi',shopRoute);
 

    return (
        <React.Fragment>
            <div >
                <MetaTags>
                    <title>
                        Store | DDC
                    </title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Store" breadcrumbItem="Store Details" />
                    {RouteShopLoading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (

                        <Row >
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap align-middle table-borderless">
                                            <thead>
                                                <tr>

                                                    <th scope="col">Store Name</th>
                                                    <th scope="col">Store Code</th>
                                                    <th scope="col">Route Name</th>
                                                    <th scope="col">Owner Name</th>
                                                    <th scope="col">Phone Number</th>
                                                    <th scope="col">Status</th>
                                                   
                                                    <th scope="col">View Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {map(shopRoute.data.allshop, (store, index) => (
                                                    
                                                    <tr key={index}>
                                        

                                                        <td>
                                                            <p className="text-muted mb-0">{store.name}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-muted mb-0">{store.shopCode}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-muted mb-0">{store.address}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-muted mb-0">{store.ownerName}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-muted mb-0">{store.phoneNumber}</p>
                                                        </td>
                                                       



                                                        <td>
                                                            <Badge className={"bg-success"}>
                                                                Active
                                                            </Badge>
                                                        </td>
                                                       

                                              
                                                    
                                                        <td>

                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            className="btn-sm btn-rounded"
                                                            onClick={() => {
                                                                history.push(`/store-analytics/${store._id}`)
                                                            }}

                                                        >
                                                              View Details
                                                        </Button>

                                                    </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    )}
                </Container>
            </div>

        </React.Fragment >
    );
};

export default withRouter(TableRouteStore);
