import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import { image } from "helpers/api_helper"
import { toaster } from "pages/Helpers/Custom/Toaster"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Input, Label } from "reactstrap"
import { createSurveyQuestion } from "store/survey/actions"
import CustomInput2 from "../Common/CustomInput2"
import { printToLetter } from "../Helpers/Custom/printNumberToLetter"
import TextError from "../InputComponent/TextError"
import { createQuestionSchema2 } from "../Schemas/AddQuestionSchema"

const initialValues = {
  name: "",
  dependingQuetion: "",
  dependingOption: "",
  questionType: "",
  type: "independent",
  options: [
    {
      serial: "A",
      name: "",
      image: "",
      id: "",
    },
  ],
}
const optionTypes = [
  {
    name: "Single Choice",
    value: "Single",
  },
  {
    name: "Multiple Choice",
    value: "multiple",
  },
  {
    name: "Text field",
    value: "textarea",
  },
]

const StepTwo = () => {
  const { id } = useParams()
  let dispatch = useDispatch()
  const { adding, loading, surveyQuestion, authtoken } = useSelector(store => ({
    loading: store.survey.loading,
    singleSurvey: store.survey.singleSurvey,
    surveyQuestion: store.survey?.surveyQuestion,
    adding: store.survey.adding,
    authtoken: store.Login.token,
  }))

  const onSubmit = (values, { resetForm }) => {
    const {
      questionType,
      dependingQuetion,
      dependingOption,
      options,
      name,
      type,
      ...rest
    } = values
    let data = {
      ...rest,
      surveyId: id,
      questions: {
        name,
        questionType,
        serial: surveyQuestion?.length + 1,
        options:
          questionType === "text"
            ? []
            : options.map(option => {
                return {
                  serial: option.serial,
                  name: option.name,
                  image: option.image,
                  isvalues: false,
                }
              }),
      },
    }
    if (type === "dependent") {
      data.questions.dependingQuestion = dependingQuetion
      data.questions.dependingOption = dependingOption
    }
    console.log(`data`, data)
    dispatch(createSurveyQuestion(data, { token: authtoken, resetForm, id }))
  }

  const uploadImage = async (file, name, setFieldValue) => {
    try {
      const url = `/survey/upload-image`
      const formData = new FormData()
      formData.append("photo", file)
      const response = await image(url, formData, authtoken)
      console.log(`response>>>`, response)
      if (response.image) {
        setFieldValue(name + ".image", response?.image || "")
      }
    } catch (err) {
      toaster("error", "Upload Image failed")
    }
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={createQuestionSchema2}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue, setFieldTouched }) => (
          <Form className="mt-2">
            {console.log(`line 118 ~ StepTwo ~ values`, values, errors)}
            <Col md="12 mb-2">
              <Field
                name="type"
                type="select"
                component={CustomInput2}
                label={"Question Stutus"}
              >
                <option value="">Select Status</option>
                <option value="independent">Independent</option>
                <option value="dependent">Dependent</option>
              </Field>
            </Col>
            {values.type === "dependent" ? (
              <React.Fragment>
                <Col md="12 mb-2">
                  <Field
                    name="dependingQuetion"
                    type="select"
                    component={CustomInput2}
                    label={"Select Previous Question"}
                  >
                    <option value="">Select Question</option>
                    {surveyQuestion?.length > 0
                      ? surveyQuestion?.map((option, idx) => (
                          <option key={idx} value={option?._id}>
                            {option?.name}
                          </option>
                        ))
                      : null}
                  </Field>
                </Col>
                {values?.dependingQuetion ? (
                  <Col md="12 mb-2">
                    <Field
                      name="dependingOption"
                      type="select"
                      component={CustomInput2}
                      label={"Select Option"}
                      placeholder="Select Option"
                    >
                      <option value="">Select Option</option>
                      {surveyQuestion?.length > 0
                        ? surveyQuestion
                            ?.find(
                              data => data?._id === values?.dependingQuetion
                            )
                            ?.options?.map((option, idx) => (
                              <option key={idx} value={option?.value}>
                                {option?.name}
                              </option>
                            ))
                        : null}
                    </Field>
                  </Col>
                ) : null}
              </React.Fragment>
            ) : null}

            <Col md="12 mb-2">
              <Field
                name="name"
                label={`Write Question`}
                type="text"
                component={CustomInput2}
                placeholder="Type name"
              />
            </Col>

            <Col md="12 mb-2">
              <Field
                name="questionType"
                type="select"
                component={CustomInput2}
                label={"Question Type"}
                placeholder="Select Question Type"
              >
                <option value="">Select Question Type</option>
                {optionTypes?.map((option, idx) => (
                  <option key={idx} value={option?.value}>
                    {option?.name}
                  </option>
                ))}
              </Field>
            </Col>

            <Col md="12 mb-2">
              {values?.questionType !== "text" ? (
                <FieldArray
                  name="options"
                  render={arrayHelpers => (
                    <div>
                      {values?.options?.map((value, index) => (
                        <React.Fragment key={index}>
                          <Label>{`Option ${printToLetter(index + 1)}`}</Label>
                          <Row className="mb-2 position-relative">
                            <Col md="6">
                              <Field
                                name={`options.${index}.name`}
                                type={"text"}
                                // label={`Option ${printToLetter(index + 1)}`}
                                component={CustomInput2}
                                placeholder="Type option"
                              />
                              <ErrorMessage
                                name={`options.${index}.name`}
                                component={TextError}
                              />
                            </Col>
                            <Col md="6">
                              <Field
                                name={`options.${index}.image`}
                                type="file"
                              >
                                {({ field }) => (
                                  <React.Fragment>
                                    <Input
                                      type="file"
                                      name={`options.${index}.image`}
                                      onBlur={e => {
                                        setFieldTouched(
                                          `options.${index}.image`,
                                          true
                                        )
                                      }}
                                      onChange={e => {
                                        const { files } = e.target
                                        setFieldTouched(
                                          `options.${index}.image`,
                                          true
                                        )
                                        if (files.length > 0) {
                                          uploadImage(
                                            files[0],
                                            `options.${index}`,
                                            setFieldValue
                                          )
                                        }
                                      }}
                                    />
                                  </React.Fragment>
                                )}
                              </Field>
                              <ErrorMessage
                                name={`options.${index}.image`}
                                component={TextError}
                              />
                            </Col>
                            {index > 0 ? (
                              <div
                                className="position-absolute"
                                style={{
                                  top: "-45%",
                                  width: "initial",
                                  right: "0",
                                }}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <button
                                  className="btn-sm removeShadow"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    fontSize: "18px",
                                  }}
                                >
                                  <i className="bx bx-trash text-danger"></i>
                                </button>
                              </div>
                            ) : null}
                          </Row>
                        </React.Fragment>
                      ))}
                      <button
                        className="btn"
                        type="button"
                        style={{ color: "#058DE0", paddingLeft: "0px" }}
                        onClick={() =>
                          arrayHelpers.push({
                            name: "",
                            image: "",
                            id: "",
                            serial: printToLetter(values?.options.length + 1),
                          })
                        }
                      >
                        <i className="bx bx-plus"></i> Add Another Option
                      </button>
                    </div>
                  )}
                />
              ) : null}
            </Col>

            <div className="text-end mt-4">
              <button
                className="btn btn-primary"
                disabled={adding || loading}
                type="submit"
              >
                {adding ? "Submitting..." : "Add More Question"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default StepTwo
