const SurveyActionTypes = {
  LOAD_SURVEY_START: "LOAD_SURVEY_START",
  LOAD_SURVEY_SUCCESS: "LOAD_SURVEY_SUCCESS",
  LOAD_SURVEY_FAIL: "LOAD_SURVEY_FAIL",

  DELETE_SURVEY_START: "DELETE_SURVEY_START",
  DELETE_SURVEY_SUCCESS: "DELETE_SURVEY_SUCCESS",
  DELETE_SURVEY_FAIL: "DELETE_SURVEY_FAIL",

  GET_SURVEY_DATA: "GET_SURVEY_DATA",
  GET_SURVEY_DATA_SUCCESS: "GET_SURVEY_DATA_SUCCESS",
  GET_SURVEY_DATA_FAIL: "GET_SURVEY_DATA_FAIL",

  GET_SURVEY_QUESTION: "GET_SURVEY_QUESTION",
  GET_SURVEY_QUESTION_SUCCESS: "GET_SURVEY_QUESTION_SUCCESS",
  GET_SURVEY_QUESTION_FAIL: "GET_SURVEY_QUESTION_FAIL",

  CREATE_SURVEY_QUESTION: "CREATE_SURVEY_QUESTION",
  CREATE_SURVEY_QUESTION_SUCCESS: "CREATE_SURVEY_QUESTION_SUCCESS",
  CREATE_SURVEY_QUESTION_FAIL: "CREATE_SURVEY_QUESTION_FAIL",
}

export default SurveyActionTypes
