import React,{useEffect} from "react"
import ReactApexChart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux";
import { getTotalProductAmount } from '../../../store/Product/actions'

const ProductAddAnalyticChart = () => {

  const dispatch = useDispatch();
  const {  authtoken,id,totalAmountProduct,totalAmountLoading } = useSelector(state => ({
    totalAmountProduct: state.ProductReducer.totalAmountProduct,
    id: state.Login.id,
    authtoken: state.Login.token,
    totalAmountLoading: state.StoreReducer.totalAmountLoading,
   
})); 
let productDate,productQuantity
if(!totalAmountLoading){
  productDate = totalAmountProduct?.data?.date
  productQuantity = totalAmountProduct?.data?.quantity
}
console.log(productDate);
console.log(productQuantity);
console.log();
useEffect(() => {
  dispatch(getTotalProductAmount(authtoken));
}, []);
console.log(totalAmountProduct);
  const options = {
    chart: {
      height: 300,
      type: "bar",
      toolbar: {
        show: !1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "14%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      show: !0,
      width: 2,
      colors: ["transparent"],
    },
    series: [
      {
        name: "Quantity",
        data: productQuantity || [],
      },
    ],
    xaxis: {
      title: {
        text: "Date",
      },
      categories: productDate || []
    },
    yaxis: {
      title: {
        text: "Quantity",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ["#556ee6"],
  }
  const series = [
    {
      name: "Quantity",
      data: productQuantity || [],
    },
  ]

  return (
    <ReactApexChart options={options} series={series || []} type="bar" height="320" className="apex-charts" />
  )
}

export default ProductAddAnalyticChart
