import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useSelector, useDispatch } from "react-redux"
import { Card, Container, Col, Spinner, Table, Row, Button } from "reactstrap"
import "../../assets/css/style.css"
import inventoryAlmostOver from '../../assets/images/almostOverInventory.svg'
import inventoryOver from '../../assets/images/inventoryOver.svg'

import { Link, useHistory } from "react-router-dom"

import wallet from "../../assets/images/wallet.svg"
import "../../assets/css/style.css"
import {
  getDpOrderActivity,
  updateTodayActivity,
  addDpDayOpening,
  getDpDayReconciliation,
  updateReconciliation,
  addDpReconciliation,
  getDpDayOpeningConfirm,
  getReconciliationConfirm,
} from "../../store/Activities/actions"
import { useRouteMatch } from "react-router"
const moment = require("moment")
const DayOpeningDp = () => {
  const [collectAmount, setCollectAmount] = useState(0)
  const today = moment().format("YYYY-MM-DD")
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    params: { dpId },
  } = useRouteMatch("/dayOpening/:dpId")
  console.log(dpId)
  const {
    dpOrderActivities,
    authtoken,
    dpOrderActivityLoading,
    id,
    dpReconciliation,
    dayOpeningConfrim,
    reconciliationConfirm,
    dpReconciliationLoading,
    dayOpeningConfrimLoading,
    reconciliationConfirmLoading,
  } = useSelector(state => ({
    dpOrderActivities: state?.ActivityReducer?.dpOrderActivities,
    dpReconciliation: state?.ActivityReducer?.dpReconciliation.data,
    dayOpeningConfrim: state?.ActivityReducer?.dayOpeningConfrim,
    reconciliationConfirm: state?.ActivityReducer?.reconciliationConfirm,
    id: state.Login.id,
    authtoken: state.Login.token,
    dpOrderActivityLoading: state.ActivityReducer.dpOrderActivityLoading,
    dpReconciliationLoading: state.ActivityReducer.dpReconciliationLoading,
    dayOpeningConfrimLoading: state?.ActivityReducer?.dayOpeningConfrimLoading,
    reconciliationConfirmLoading:
      state?.ActivityReducer?.reconciliationConfirmLoading,
  }))
  useEffect(() => {
    dispatch(getDpOrderActivity(authtoken, dpId, today))
  }, [])
  useEffect(() => {
    dispatch(getDpDayReconciliation(authtoken, dpId, today))
  }, [])

  useEffect(() => {
    dispatch(getDpDayOpeningConfirm(authtoken, dpId, today))
  }, [])
  useEffect(() => {
    dispatch(getReconciliationConfirm(authtoken, dpId, today))
  }, [])

  let reconciliationData = _.map(dpReconciliation?.reconciliationdata?.products, function (item) {
    return _.merge(item, _.find( dpReconciliation?.test, {productid : item.productId }))
  })
  console.log('reconciliationData',reconciliationData); 
console.log('dpOrderActivities',dpOrderActivities);
console.log('dpReconciliation',dpReconciliation);

  let dpReconcliation

 
 
  
   

  if (!dpReconciliationLoading) {
    dpReconcliation = dpReconciliation
  }

   



  const [activeTab, setActiveTab] = useState("tab1")
  const day = moment().format("DD")

  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1")
  }
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2")
  }

  const printProductList = () => {
    window.print()
  }
  const printDpList = () => {
    window.print()
  }
  const handleSubmitQtyEdit = (e, idx) => {
    const oldValue = [...dpOrderActivities?.data]

    oldValue[idx].editOrder = e.target.value
    dispatch(updateTodayActivity({ data: oldValue }))
  }

  const handleSubmitReturnQty = (e, idx) => {
    const newValue = [...dpReconcliation]

    newValue[idx].editQuantity = e.target.value

    dispatch(updateReconciliation({ data: newValue }))
  }
  const handleChange = e => {

    setCollectAmount(e.target.value)
  }
  const handleDayOpeningSubmit = () => {
    let newArray = []
    let productArray = []
    let data = {}
    dpOrderActivities?.data?.map((order, index) => {
      let productData = {}
      data.dpid = dpId
      data.dateId = today
      productData.productId = order.productid
      productData.productname = order.productname
      productData.productqty = parseFloat(order.editOrder)
      productData.unitPrice = parseFloat(order.unitprice)
      productArray.push(productData)
      data.products = productArray
    })
    console.log("dataobj", data)
     dispatch(addDpDayOpening(data, history, authtoken))
  }

  const handleReconciliationSubmit = () => {
    let productArray = []
    let data = {}
    reconciliationData?.map((order, index) => {
      let productData = {}
      data.dpid = dpId
      data.dateId = today
      productData.productId = order.productId
      productData.productname = order.productname
      productData.productqty = order.productqty-order.sumQuantity
      productData.unitPrice = parseFloat(order.unitPrice)
      productArray.push(productData)
      data.products = productArray
    })
    data.receiveamount = dpReconciliation?.todayreciveAmount
    console.log("dataobj", data)
     dispatch(addDpReconciliation(data, history, authtoken))
  }
console.log('reconciliationData',reconciliationData);

  console.log(dpReconcliation)
  return (
    <div>
      <div className="page-content">
        <Container fluid={true}>
          <h4 className="mb-4">{day} January </h4>

          <Card>
            <div className="activity-product">
              <ul className="nav alltab">
                <li
                  className={activeTab === "tab1" ? "active " : ""}
                  onClick={handleTab1}
                >
                  Day Opening
                </li>
                <li
                  className={activeTab === "tab2" ? "active" : ""}
                  onClick={handleTab2}
                >
                  Reconciliation
                </li>
              </ul>
              <div className="outlet">
                {activeTab === "tab1" ? (
                  <>
                    <div className="add-inventory-serach">
                      <p><img src={inventoryAlmostOver} className='me-2'></img>Inventory Almost Over </p>
                      <p><img src={inventoryOver} className='me-2'></img>Inventory Over </p>
                      {/* <div>
                        <input placeholder="search here"></input>
                      </div> */}
                     
                      {/* <div>
                        <Link
                          to="/DayOpeningDp"
                          className="product-inventory-btn"
                          onClick={printProductList}
                        >
                          download pdf
                        </Link>
                      </div> */}
                    </div>
                    <Table hover>
                      <thead className="dp-product-list-activity">
                        <tr>
                          <th>NO</th>
                          <th>Product List</th>
                          <th>Order received</th>
                          <th>Dispatch Qty(x*1.5)</th>
                          <th>Current Inventory</th>

                          <th>Qty Edit</th>
                          <th>Qty Price</th>
                          <th>Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(dpOrderActivities?.data || []).map((data, key) => {
                          return (
                            <>
                              <tr>
                                <th scope="row">{key + 1}</th>
                                <td>{data.productname}</td>

                                {/* {
                                  data.sumQuantity >= data?.productQuantity ? 
                                  <td className="text-danger fw-bold">{data.sumQuantity}</td> :  <td>{data.sumQuantity}</td>
                                }
                                */}
                                <td>{data.sumQuantity}</td>
                                <td>{Math.ceil(data.sumQuantity * 1.5)}</td>
                                 {
                                  data?.productQuantity <= 0 ?  <td className="fw-bold text-danger">0</td> :
                                  data?.productQuantity <= 50 ? <td className="fw-bold text-warning">{data?.productQuantity} </td> :
                                  <td >{data?.productQuantity} </td>
                                }
                                
                                {/* <td>{data?.productQuantity}</td> */}

                                <td>
                                  {" "}
                                  <input
                                    type="number"
                                    value={data.editOrder}
                                    onChange={e => handleSubmitQtyEdit(e, key)}
                                  ></input>
                                </td>
                                <td>{data.price}</td>
                                <td>{Math.ceil(data.sumtotalPrice)}</td>

                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </Table>

                    {dayOpeningConfrim?.data?.status === false || dayOpeningConfrim.length? (
                      <span></span>
                    ) : (
                      <Button
                        onClick={handleDayOpeningSubmit}
                        className="btn btn-sm btn-info m-4"
                      >
                        Confirm Day Opening{" "}
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <div className="deliver-amount-list mt-5">
                      <Row>
                        <Col md={2}>
                          <div className="deliverd-amount">
                            <img src={wallet}></img>
                            <p>Delivered Amount</p>
                            {
                             isNaN(Math.ceil(dpReconciliation?.deliveredAmount))  ?
                              <p className="fw-bold">0</p> :
                              <p className="fw-bold">{Math.ceil(dpReconciliation?.deliveredAmount)}</p>
                            }
                           
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="deliverd-amount">
                            <img src={wallet}></img>
                            <p>Today <br/>Recived</p>
                            {
                             isNaN(dpReconciliation?.todayreciveAmount-dpReconciliation?.collecteddueAmount)  ?
                              <p className="fw-bold">0</p> :
                              <p className="fw-bold">{Math.ceil(dpReconciliation?.todayreciveAmount-dpReconciliation?.collecteddueAmount)}</p>
                            }
                           
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="deliverd-amount">
                            <img src={wallet}></img>
                            <p>Today <br/> Due</p>
                            {
                             isNaN(Math.ceil(dpReconciliation?.todayduePrice))  ?
                              <p className="fw-bold">0</p> :
                              <p className="fw-bold">{Math.ceil(dpReconciliation?.todayduePrice)}</p>
                            }
                           
                           
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="deliverd-amount">
                            <img src={wallet}></img>
                            <p>Previous due recived</p>
                            {
                             isNaN(Math.ceil(dpReconciliation?.collecteddueAmount))  ?
                              <p className="fw-bold">0</p> :
                              <p className="fw-bold">{Math.ceil(dpReconciliation?.collecteddueAmount)}</p>
                            }
                           
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="deliverd-amount">
                            <img src={wallet}></img>
                            <p>Total Recived <small>(Today Received + Due Receive)</small></p>
                            {
                              isNaN(dpReconciliation?.todayreciveAmount) ?
                              <p className="fw-bold">0</p> :  <p className="fw-bold">{Math.ceil(dpReconciliation?.todayreciveAmount)}</p>

                            }
                            
                          
                          </div>
                        </Col>
                      </Row>
                    </div> 
                    <div>
                      <div className="add-inventory-serach">
                        <div>
                          <input placeholder="search here"></input>
                        </div>
                        {/* <div>
                          <Link
                            to="/DayOpeningDp"
                            className="product-inventory-btn"
                            onClick={printDpList}
                          >
                            Download Pdf
                          </Link>
                        </div> */}
                      </div>
                    </div>

                    <Table hover>
                      <thead className="dp-activity-head">
                        <tr>
                          <th>NO</th>
                          <th>Product list</th>
                          <th>Day Opening</th>
                           <th>Qty Delivered</th>
                          <th>Qty Price</th>
                          <th>Delivered Amount</th>
                          <th>Return Qty</th>
                          {/* <th>Return Amount</th> */}
                          {/* <th>Closing Inventory</th> */}
                         
                        </tr>
                      </thead>

                      <tbody>
                        {(reconciliationData || []).map((data, key) => {
                          return (
                            <>
                              <tr>
                                <th scope="row">{key + 1}</th>
                                <td>{data.productname}</td>
                              
                                <td>{data.productqty}</td>
                                <td>{data.sumQuantity}</td>
                                <td>{data.unitPrice}</td>
                                {
                                  isNaN(data.sumQuantity * data.unitPrice) ?
                                  <td >0 </td> :
                                  <td > {Math.ceil(data.sumQuantity * data.unitPrice)} </td>
                                }
                            
                                {/* <td>{Math.ceil(data.sumQuantity * data.unitPrice)}</td> */}
                                {/* <td>8800</td> */}
                                {
                                  isNaN(data.productqty-data.sumQuantity) ?
                                  <td >0 </td> :
                                  <td > {data.productqty-data.sumQuantity} </td>
                                }
                            
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </Table>
                    <div className="ms-3">
                        <p> </p>
                        {
                          isNaN(Math.ceil(dpReconciliation?.todayreciveAmount)) ?
                          <h5 className="fw-bold mb-2"><span className="text-success">Collect amount :</span> 0</h5>
                          :
                          <h5 className="fw-bold mb-2"><span className="text-success">Collect amount :</span> {Math.ceil(dpReconciliation?.todayreciveAmount)}</h5>
                        }
                      
                        {/* <input
                          type="number"
                          className="me-3"
                          onChange={e => handleChange(e)}
                        ></input> */}
                      </div>
                    {reconciliationConfirm?.data?.status === false || reconciliationConfirm.length ===0 ? (
                      <span></span>
                    ) : (
                    <div>
                    
                      <Button
                        className="btn btn-sm btn-info m-3"
                        onClick={handleReconciliationSubmit}
                      >
                        Confirm Amount
                      </Button>
                    </div>
                      )}
                  </>
                )}
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </div>
  )
}

export default DayOpeningDp
