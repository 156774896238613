import React, {  useEffect, useState  } from "react"

import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner
} from "reactstrap"


import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import StoreOrderAmount from "./Charts/StoreOrderAmount";
import StoreOwner from "./StoreOwner";
import {getStoreTopProduct} from '../../store/Stores/actions'
import {getStore} from '../../store/Stores/actions'
import TopProductStore from "./TopProductsStore";

import '../../pages/styles.css'
const StoreAnalytics = () => {
  const {
    params: { storeID },
  } = useRouteMatch('/store-analytics/:storeID');
  console.log('hello', storeID);

  const dispatch = useDispatch();

  const {  authtoken, topStoreProductLoading, topStoreProducts,store,singleShopLoading} = useSelector(state => ({
    topStoreProducts: state.StoreReducer.topStoreProducts,
    store: state.StoreReducer.store.data,
    id: state.Login.id,
    authtoken: state.Login.token,
    singleShopLoading: state.StoreReducer.singleShopLoading,
   
}));
useEffect(() => {
  dispatch(getStoreTopProduct(authtoken,storeID));
}, []);
useEffect(() => {
  dispatch(getStore(authtoken,storeID));
}, []);
console.log('store',store);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        {singleShopLoading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
        <Container fluid>
          <h4 className='mb-3'>{store?.singleshop?.name}</h4>
         
          <Row>

            <Col xl="12">
              <Row>

                {/* storeData Render */}

                <Col md="3">
                  <Card className="mini-stats-wid card-info-list">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Pre Order Amount (Total)
                          </p>
                          <h5 className="mb-1">{store?.shopsalesum[0]?.totalamount.toFixed(2)} Tk</h5>

                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />

                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="mini-stats-wid card-info-list">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Store Type
                          </p>
                          <h4 className="mb-0"></h4>
                          <p className="fw-bold">{store?.singleshop?.storeType}</p>

                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />

                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
             

                <Col md="3">
                  <Card className='card-info-list'>
                    <CardBody >
                     
                      <Row>
                        <Col >
                          <div className="mt-2 text-center">
                            <Link
                              to="#"
                              className="btn btn-primary btn-md"
                            >
                           Discontinue
                            </Link>
                          </div>
                        </Col>

                      </Row>

                    </CardBody>
                  </Card>
                </Col>

              </Row>


            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <StoreOrderAmount />
            </Col>
            <Col md={4}>
              <TopProductStore />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <StoreOwner store ={store} />
            </Col>
        
          </Row>
                  


        </Container>
                    )}
      </div>


    </React.Fragment >
  );
};

export default StoreAnalytics;