import Breadcrumbs from "components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router"
import { useHistory, withRouter } from "react-router-dom"
import XLSX from "xlsx"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
import "../../../pages/DistributionDashboard/Order/TableDistDateOrder"
import { getDistDateOrder } from "../../../store/Order/actions"
// import { getRoute } from "../../store/GeoInformation/actions";
// import { getDateOrders } from "../../store/Order/actions"
const moment = require("moment")
const TableDistDateOrder = props => {
  const history = useHistory()
  const year = moment().format("MM-YYYY")

  const dispatch = useDispatch()

  const {
    distDateOrder,
    authtoken,
    dateLoading,
    orgId,
    officeId,
    distDateOrderLoading,
  } = useSelector(state => ({
    distDateOrder: state.OrderReducer.distDateOrder,
    orgId: state.Login.org,
    officeId: state.Login.office,
    authtoken: state.Login.token,
    distDateOrderLoading: state.OrderReducer.distDateOrderLoading,
  }))
  console.log("officeId", officeId)
  useEffect(() => {
    dispatch(getDistDateOrder(authtoken, orgId, officeId, year))
  }, [])

  console.log(distDateOrder)
  let singleSubTotal,
    singleCollectAmount,
    orderTotal = 0,
    singleOrderTotal,
    totalCollect = 0,
    due
    let allDateOrder
  if (!distDateOrderLoading) {
    allDateOrder =distDateOrder.data.allorder
    {
      ( allDateOrder || []).map((dateOrder, key) => {
        singleSubTotal = Math.ceil(dateOrder?.orderamount)
        singleCollectAmount = Math.ceil(dateOrder?.receiveamount)
        singleOrderTotal = Math.ceil(dateOrder?.orderamount)

        if (singleSubTotal <= singleCollectAmount) {
          distDateOrder.data.allorder[key].dueInfo = 0
        }
        if (singleSubTotal > singleCollectAmount) {
          distDateOrder.data.allorder[key].dueInfo =
            singleSubTotal - singleCollectAmount
        }
      })
    }
  }
  const tableData =
    !distDateOrderLoading &&
    distDateOrder?.data?.allorder?.map((dateOrder, index) => {
      return {
        sl: index + 1,
        heading1: dateOrder?._id?.datetostring,
        // heading2: route.shopnumber,
        heading2: Math.ceil(dateOrder?.orderamount),
        heading3: Math.ceil(dateOrder?.receiveamount),
        heading4: Math.ceil(dateOrder.dueInfo),
        heading5: dateOrder?.totalsr,
        heading6: dateOrder?.totaldp,

        manage: (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded btn-clr"
              onClick={() => {
                history.push(
                  `/distShopOrder/${officeId}/${dateOrder._id.datetostring}`
                )
              }}
            >
              View
            </Button>
          </>
        ),
        status: (
          <>
            <Badge className={"bg-success"}>Active</Badge>
          </>
        ),
      }
    })
    const handleExcel = () => {
      let dateOrder = []
      allDateOrder?.forEach((order, index) => {
        console.log('ORDER',order);
        let data = {}
        data.Date = order._id.datetostring
        data.orderAmount = order.orderamount
        data.receiveAmount = order.receiveamount
        data.Due = order.dueInfo
        data.totalDp = order.totaldp
        data.totalSR = order.totalsr
        dateOrder.push(data)
      })
      downloadxls(dateOrder)
    }
    const downloadxls = data => {
      console.log(XLSX.version)
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
      XLSX.writeFile(wb, "inventory.xlsx")
    }

  const orderData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },

      {
        label: "Date",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Amount(tk)",
        field: "heading2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Receive Amount(tk)",
        field: "heading3",
        sort: "asc",
        width: 150,
      },
      {
        label: "Due Amount(tk)",
        field: "heading4",
        sort: "asc",
        width: 150,
      },

      {
        label: "Assigned SR",
        field: "heading5",
        sort: "asc",
        width: 150,
      },
      {
        label: "Assigned DP",
        field: "heading6",
        sort: "asc",
        width: 150,
      },

      {
        label: "View",
        field: "manage",
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
        <div>
          <MetaTags>
            <title>Order | DDC</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Order"
              breadcrumbItem="please select shop for see orders"
            />
            <div>
              <div className="add-inventory-serach">
              
              
                <button className="product-inventory-btn"  onClick={handleExcel}>
                  Download xlsx
                </button>
              </div>
            </div>
            {!distDateOrderLoading ? (
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <MDBDataTable
                        responsive
                        noBottomColumns
                        striped
                        data={orderData}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <div className="text-center mt-5 pt-5">
                <Spinner />
              </div>
            )}
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(TableDistDateOrder)
