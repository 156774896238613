import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router"

import { getDetailsRoute } from "../../../store/GeoInformation/actions"
import "../../../assets/css/style.css"

const RouteInfo = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    params: { routeID },
  } = useRouteMatch("/route-details/:routeID")
  console.log(routeID)
  const { DetailsRoute, authtoken, id, DetailsRouteLoading } = useSelector(
    state => ({
      DetailsRoute: state.GeoInformationReducer.DetailsRoute.data,
      authtoken: state.Login.token,
      id: state.Login.id,
      DetailsRouteLoading: state.GeoInformationReducer.DetailsRouteLoading,
    })
  )

  useEffect(() => {
    dispatch(getDetailsRoute(authtoken, routeID))
  }, [])

  console.log("DetailsRoute", DetailsRoute)
  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        {DetailsRouteLoading ? (
          <div className="text-center pt-5">
            <Spinner />
          </div>
        ) : (
          <Container fluid>
            <h5 className="mb-3">{DetailsRoute?.singleroute?.name}</h5>
            <Row>
              <Col xl="12">
                <Row>
                  {/* storeData Render */}

                  <Col md="3">
                    <Card className="mini-stats-wid card-info-list">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Pre Order | Unit
                            </p>
                            {DetailsRoute?.Productsalesum?.length === 0 ? (
                              <h5 className="mb-0 fw-bold sell-list">
                                {" "}
                                0 Tk{" "}
                                <span className="text-danger route-sell">
                                  {" "}
                                  |{" "}
                                </span>{" "}
                               0 Pc
                              </h5>
                            ) : (
                              <h5 className="mb-0 fw-bold sell-list">
                                {" "}
                                {Math.ceil(
                                  DetailsRoute?.Productsalesum[0]?.Totalsale
                                )}
                                Tk{" "}
                                <span className="text-danger route-sell">
                                  {" "}
                                  |{" "}
                                </span>{" "}
                                {
                                  DetailsRoute?.Productsalesum[0]?.totalunit
                                }Pc{" "}
                              </h5>
                            )}
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx " + "bx-copy-alt" + " font-size-24"
                                }
                              />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card className="mini-stats-wid card-info-list">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Top Ordered Store
                            </p>
                            <h5 className="mb-0 fw-bold sell-list">
                              {DetailsRoute?.shopdescription?.shopname}
                            </h5>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx " + "bx-copy-alt" + " font-size-24"
                                }
                              />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="3">
                    <Card className="card-info-list">
                      <CardBody>
                        <Row>
                          <Col>
                            <div className="mt-2 text-center">
                              <Link to="#" className="btn btn-primary btn-md">
                                Discontinue
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default RouteInfo
