import Breadcrumbs from "components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
// import { getProducts } from '../../store/Product/actions';
import { getUsers, storeUserData } from "../../store/users/actions"

const UserTableData = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { users, authtoken, id, isLoading } = useSelector(state => ({
    users: state.UserReducer,
    id: state.Login.id,
    authtoken: state.Login.token,
    isLoading: state.UserReducer.isLoading,
  }))
  useEffect(() => {
    dispatch(getUsers(authtoken, id))
  }, [])
  console.log("kkkk", users)
  // console.log(users)
  if (isLoading) {
    {
      var newArray = []
      users.users.forEach((user, index) => {
        let data = {}
        data.userName = user.name
        if (user.role === "SR") {
          data.role = "SO"
        }
        if (user.role === "DP") {
          data.role = "DSR"
        }
        if (user.role === "SS") {
          data.role = "SS"
        }
        if (user.role === "DISTRIBUTOR") {
          data.role = "DISTRIBUTOR"
        }
        data.email = user.email
        data.id = user._id
        data.employeeId = user.employeeId
        data.route = user.route
        newArray.push(data)
      })
      // console.log(newArray)
    }
  }
  const handleEditUser = data => {
    console.log("handleEditUser", data)
    dispatch(storeUserData("singleUser", data))
    props.history.push("/edituser/" + data.id)
  }
  console.log(newArray)
  const tableData =
    isLoading &&
    newArray.map((user, index) => {
      return {
        sl: index + 1,
        heading1: user.userName,
        heading2: user.role,
        heading3: user.email,
        heading4: user.employeeId,

        status: (
          <>
            <Badge className={"bg-success"}>Active</Badge>
          </>
        ),
        action: (
          <React.Fragment>
            <div className="d-flex">
              <Link
                to="#"
                className="text-success"
                onClick={() => handleEditUser(user)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              </Link>
            </div>
          </React.Fragment>
        ),
        manage: (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                history.push(`/set-route/${user?.id}/${user?.userName}`, {
                  data: user?.route,
                })
              }}
            >
              Set Route
            </Button>
          </>
        ),
      }
    })

  const productData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },
      {
        label: "name",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Role",
        field: "heading2",
        sort: "asc",
        width: 150,
      },

      {
        label: "Email",
        field: "heading3",
        sort: "asc",
        width: 150,
      },
      {
        label: "User ID",
        field: "heading4",
        sort: "asc",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
      },
      {
        label: "Edit",
        field: "action",
      },
      {
        label: "Set Route",
        field: "manage",
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <MetaTags>
          <title>User | DDC</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="User details" />
          {isLoading ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={productData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="text-center mt-5 pt-5">
              <Spinner />
            </div>
          )}

          {/* <DataTable /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

UserTableData.propTypes = {
  history: PropTypes.object,
}

export default withRouter(UserTableData)
