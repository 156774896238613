import React, { Component, useEffect, useState } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import classNames from "classnames"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap"

import { Link } from "react-router-dom"



import BrandTotalAmount from "./BrandChart/BrandAmount";
import BrandStoreAmount from "./BrandChart/BrandStoreAmount";
import BrandRouteAmount from "./BrandChart/BrandRouteAmount";



const AnalyticBrand = () => {

  const [periodType, setPeriodType] = useState("yearly")
  const [revenueType, setRevenueType] = useState("region")
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <h4 className='mb-3'></h4>
          <Row>

            <Col xl="12">
              <Row>

                {/* storeData Render */}

                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <CardTitle className="text-muted fw-medium">
                            Sell Tk
                          </CardTitle>
                          <h4 className="mb-0"></h4>

                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />

                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <CardTitle className="text-muted fw-medium">
                            Top Selling Area
                          </CardTitle>
                          <h4 className="mb-0"></h4>

                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />

                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <CardTitle className="text-muted fw-medium">
                            Top Selling Store
                          </CardTitle>
                          <h4 className="mb-0"></h4>

                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />

                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card >
                    <CardBody >
                      <CardTitle className="text-muted fw-medium">Sell-off your product</CardTitle>
                      <Row>
                        <Col >
                          <div className="mt-2 ">
                            <Link
                              to=""
                              className="btn btn-primary btn-sm"
                            >
                              Sell Off
                            </Link>
                          </div>
                        </Col>

                      </Row>

                    </CardBody>
                  </Card>
                </Col>

              </Row>


            </Col>
          </Row>

          <BrandTotalAmount />
          <BrandStoreAmount />
          <BrandRouteAmount/>

        </Container>
      </div>


    </React.Fragment >
  )
}


export default AnalyticBrand;

