import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap"
import { Link } from "react-router-dom"
import TablePJP from "./TablePJP";





const PjpInfo = () => {



  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <h4 className='mb-3'>PJP List</h4>
          <Row>

            <Col xl="12">
              <Row>

                {/* storeData Render */}

                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Total PJP
                          </p>
                          <h4 className="mb-0">--</h4>

                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />

                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="3">
                  <Card >
                    <CardBody >
                      <CardTitle className="mb-0 h4">Create New PJP</CardTitle>
                      <Row>
                        <Col >
                          <div className="mt-2 ">
                            <Link
                              to="/pjp-create"
                              className="btn btn-primary btn-md"
                            >
                              Create PJP
                            </Link>
                          </div>
                        </Col>

                      </Row>

                    </CardBody>
                  </Card>
                </Col>


                {/* <Col md="3">
                  <Card >
                    <CardBody >
                      <CardTitle className="mb-0 h4">PJP add analytic</CardTitle>
                      <Row>
                        <Col >
                          <div className="mt-2 ">
                            <Link
                              to="/pjp-analytic"
                              className="btn btn-primary btn-md"
                            >
                              PJP analytic
                            </Link>
                          </div>
                        </Col>

                      </Row>

                    </CardBody>
                  </Card>
                </Col> */}

              </Row>


            </Col>
          </Row>

          <TablePJP />
        </Container>
      </div>


    </React.Fragment >
  );
};

export default PjpInfo;