import DistributorRoute from "authBasedRoute/DistributorRoute"
import AdminAddUser from "pages/Admin/AdminAddUser"
import AdminUserDetails from "pages/Admin/AdminUserDetails"
import TableAttendance from "pages/Attendence/TableAttendence"
import EmailVerifyPage from "pages/Authentication/EmailVerifyPage"
import ForgetPassword from "pages/Authentication/ForgetPassword"
import ForgetPasswordEmailVerify from "pages/Authentication/ForgetPasswordEmailVerify"
import Login from "pages/Authentication/Login"
import Register from "pages/Authentication/Register"
import UpdatePasswordPage from "pages/Authentication/UpdatePassword"
import AnalyticsDamage from "pages/Damaged/AnalyticsDamage"
import DamageActivity from "pages/Damaged/DamageActivity"
import DamageReconciliation from "pages/Damaged/DamagedReconciliation"
import DamageInfo from "pages/Damaged/DamageInfo"
import DistributorDashboard from "pages/Dashboard/DistributorDashboard"
import MainDashboard from "pages/Dashboard/MainDashboard"
import OrgDashboard from "pages/Dashboard/OrgDashboard"
import SampleSellingOverview from "pages/Dashboard/SampleSellingOverview"
import DistEditedInvoice from "pages/DistributionDashboard/Invoice/DistEditedInvoice"
import DistPreOrderInvoice from "pages/DistributionDashboard/Invoice/DistPreOrderInvoice"
import TableDistDateOrder from "pages/DistributionDashboard/Order/TableDistDateOrder"
import TableDistShopOrder from "pages/DistributionDashboard/Order/TableDistShopOrder"
import AddTerritory from "pages/GeoInformation/AddTerritory"
import AddGeo from "pages/GeoInformation/GEO/AddGeo"
import DetailsGEO from "pages/GeoInformation/GEO/DetailsGEO"
import GEOInfo from "pages/GeoInformation/GEO/GEOInfo"
import GEORouteTable from "pages/GeoInformation/GEO/GEORouteTable"
import DetailsRoute from "pages/GeoInformation/Route/DetailsRoute"
import RouteList from "pages/GeoInformation/Route/ListRoute"
import RouteInfoTable from "pages/GeoInformation/Route/RouteInfoTable"
import TableArea from "pages/GeoInformation/TableArea"
import TableRegion from "pages/GeoInformation/TableRegion"
import TableTerritory from "pages/GeoInformation/TableTerritory"
import ActivityDpProductList from "pages/Inventory/ActivityDpProductList"
import ActivityHistory from "pages/Inventory/ActivityHistory"
import ActivityProductList from "pages/Inventory/ActivityProductList"
import AddDamageInventory from "pages/Inventory/AddDamageInventory"
import AddDamageQuantityInventory from "pages/Inventory/AddDamageQuantityInventory"
import AddInventory from "pages/Inventory/AddInventory"
import AddProductInventory from "pages/Inventory/AddProductInventory"
import DamageInventoryMain from "pages/Inventory/DamageInventoryMain"
import DateWiseDpProductList from "pages/Inventory/DateWiseDpProductList"
import DateWiseProductList from "pages/Inventory/dateWiseProductList"
import DayOpeningDp from "pages/Inventory/DayOpeningDp"
import HistoryProductList from "pages/Inventory/HistoryProductList"
import InventoryMain from "pages/Inventory/InventoryMain"
import ProductInfoDetails from "pages/Inventory/ProductInfoDetails"
import InvoiceDetails from "pages/Invoice/PreOrderInvoice"
import AddOfferItem from "pages/Offer/AddOfferItem"
import CreateOffer from "pages/Offer/CreateOffer"
import DetailsOffer from "pages/Offer/DetailsOffer"
import AddOffice from "pages/Office/AddOffice"
import AddOfficeProductInventory from "pages/Office/AddOfficeProductInventory"
import AddQuantityInventory from "pages/Office/AddQuantityInventory"
import EditOffice from "pages/Office/EditOffice"
import InfoOffice from "pages/Office/InfoOffice"
import InventoryInfo from "pages/Office/InventoryInfo"
import InventoryOrderDp from "pages/Office/InventoryOrderDp"
import InventoryOrderProduct from "pages/Office/InventoryOrderProduct"
import OfficeDayOpening from "pages/Office/OfficeDayOpening"
import OfficeReconciliation from "pages/Office/OfficeReconciliation"
import OfficeTime from "pages/Office/OfficeTime"
import SingleInventory from "pages/Office/SingleInventory"
import AnalyticOrder from "pages/Order/Chart/OrderAnalytic"
import DateOrderList from "pages/Order/DateOrderList"
import OrderList from "pages/Order/OrderList"
import RouteOrderList from "pages/Order/RouteOrderList"
import TableRouteList from "pages/Order/TableRouteList"
import AddOverView from "pages/OverView/AddOverview"
import AnalyticsBrand from "pages/OverView/AnalyticsInfoBrand"
import Brand from "pages/OverView/Brand"
import AddBrand from "pages/OverView/BrandAdd"
import BrandInfoData from "pages/OverView/BrandAnalytics/BrandInfoData"
import BrandAddAnalyticChart from "pages/OverView/BrandChart/BrandAddAnalyticChart"
import AddCategory from "pages/OverView/CategoryAdd"
import TableCategory from "pages/OverView/TableCategory"
import TableUnit from "pages/OverView/TableUnit"
import Unit from "pages/OverView/UnitAdd"
import GraphPerformance from "pages/Performance/GraphPerformance"
import UserListPerform from "pages/Performance/UserListPerform"
import AddPjp from "pages/PJP/AddPjp"
import PjpAnalytic from "pages/PJP/AnalyticPJP"

import PjpCreate from "pages/PJP/PjpCreate"
import PjpInfo from "pages/PJP/PjpInfo"
import TablePjpTwo from "pages/PJP/TablePjpTwo"

import AddProduct from "pages/Product/AddProduct"
import ProductAnalytic from "pages/Product/AnalyticProduct"
import ProductAddAnalytic from "pages/Product/Chart/ProductAddAnalytic"
import AddComboProduct from "pages/Product/Combo Product/AddComboProduct"
import ComboProductList from "pages/Product/Combo Product/ListComboProduct"
import ProductDetails from "pages/Product/DetailsProduct"
import EditProduct from "pages/Product/EditProduct"
import ProductList from "pages/ProductList/ProductList"
import AddQuestion from "pages/Question/AddQuestion"
import AddRole from "pages/Roles/AddRole"
import RoleDetails from "pages/Roles/RoleDetails"
import DateTableSales from "pages/Sales/DateTableSales"
import RouteTableSales from "pages/Sales/RouteTableSales"
import EditStore from "pages/StoreDetails/EditStore"
import ExclusiveShop from "pages/StoreDetails/ExclusiveShop"
import SampleStoreTable from "pages/StoreDetails/SampleStoreTable"
import SetStore from "pages/StoreDetails/SetStore"
import AddStore from "pages/StoreDetails/StoreAdd"
import StoreAddAnalytic from "pages/StoreDetails/StoreAddAnalytic"
import StoreAnalytics from "pages/StoreDetails/StoreAnalytics"
import StoreInfo from "pages/StoreDetails/StoreInfo"
import SurveyAdd from "pages/Survey/SurveyAdd"
import SurveyInfo from "pages/Survey/SurveyInfo"
import SurveyShop from "pages/Survey/SurveyShop"
import SurveyTable from "pages/Survey/SurveyTable"
import setTarget from "pages/Target/SetTarget"
import SrTargetInfo from "pages/Target/SrTargetInfo"
import SrTargetSet from "pages/Target/SrTargetSet"
import SrTargetTable from "pages/Target/SrTargetTable"
import TargetList from "pages/Target/TargetList"
import AddUser from "pages/Users/AddUser"
import EditUser from "pages/Users/EditUser"
import RouteInfoUser from "pages/Users/RouteInfoUser"
import SetRoute from "pages/Users/SetRoute"
import TableUserInfo from "pages/Users/TableUserInfo"
import UserDetails from "pages/Users/UserDetails"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Route, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"
// action
import "react-toastify/dist/ReactToastify.css"
// Import scss
import "./assets/scss/theme.scss"
import AdminRoute from "./authBasedRoute/AdminRoute"
import OrgRoute from "./authBasedRoute/TeacherRoute"
import AddArea from "./pages/GeoInformation/AddArea"
import AddRegion from "./pages/GeoInformation/AddRegion"
import AddRoute from "./pages/GeoInformation/Route/AddRoute"
import EditedInvoice from "./pages/Invoice/Invoice"

const App = props => {
  return (
    <React.Fragment>
      <ToastContainer />
      <Switch>
        <AdminRoute exact path="/dashboard" component={MainDashboard} />
        <OrgRoute exact path="/orgdashboard" component={OrgDashboard} />
        <DistributorRoute
          exact
          path="/distributorDashboard"
          component={DistributorDashboard}
        />

        {/* User Information */}
        <OrgRoute exact path="/adduser" component={AddUser} />
        <OrgRoute exact path="/edituser/:id" component={EditUser} />
        <AdminRoute exact path="/adminUser" component={AdminAddUser} />
        <AdminRoute
          exact
          path="/adminUserDetails"
          component={AdminUserDetails}
        />

        <OrgRoute exact path="/user-details" component={UserDetails} />
        <OrgRoute exact path="/tableUserInfo" component={TableUserInfo} />

        <OrgRoute exact path="/route-info-user" component={RouteInfoUser} />
        <OrgRoute
          exact
          path="/set-route/:userid/:userName"
          component={SetRoute}
        />
        <OrgRoute exact path="/sales" component={ProductList} />
        <OrgRoute
          exact
          path="/product-add-analytic"
          component={ProductAddAnalytic}
        />
        <OrgRoute
          exact
          path="/sampleSelling"
          component={SampleSellingOverview}
        />
        {/* Product Information */}
        <OrgRoute exact path="/add-product" component={AddProduct} />
        <OrgRoute exact path="/details-product" component={ProductDetails} />
        <OrgRoute exact path="/product-analytic" component={ProductAnalytic} />
        <OrgRoute
          exact
          path="/editProduct/:productID"
          component={EditProduct}
        />
        <OrgRoute
          exact
          path="/product-analytic/:productID"
          component={ProductAnalytic}
        />
        <OrgRoute exact path="/combo-product" component={ComboProductList} />
        <OrgRoute exact path="/add-combo-product" component={AddComboProduct} />

        {/* GEO INFORMATION */}
        <OrgRoute exact path="/add-area" component={AddArea} />
        <OrgRoute exact path="/add-region" component={AddRegion} />
        <OrgRoute exact path="/add-territory" component={AddTerritory} />
        <OrgRoute exact path="/add-route" component={AddRoute} />
        <OrgRoute exact path="/add-geo" component={AddGeo} />
        <OrgRoute exact path="/details-geo" component={DetailsGEO} />
        <OrgRoute exact path="/area-list" component={TableArea} />
        <OrgRoute exact path="/region-list" component={TableRegion} />
        <OrgRoute exact path="/territory-list" component={TableTerritory} />
        {/* <OrgRoute exact  path="/details-geo/:geoID" component={DetailsGEO} /> */}
        <OrgRoute
          exact
          path="/table-route-geo/:geoID"
          component={GEORouteTable}
        />
        <OrgRoute exact path="/add-route" component={AddRoute} />
        <OrgRoute exact path="/route-list" component={RouteList} />
        <OrgRoute exact path="/route-details" component={DetailsRoute} />
        <OrgRoute
          exact
          path="/route-details/:routeID"
          component={DetailsRoute}
        />
        <OrgRoute exact path="/route-info-table" component={RouteInfoTable} />

        <OrgRoute exact path="/geo-info" component={GEOInfo} />

        {/* Office Information */}
        <OrgRoute exact path="/info-office" component={InfoOffice} />
        <OrgRoute exact path="/office-time/:officeid" component={OfficeTime} />

        <OrgRoute exact path="/add-office" component={AddOffice} />
        <OrgRoute exact path="/editOffice/:id" component={EditOffice} />

        <OrgRoute
          exact
          path="/addOfcProductInventory/:OfficeInfoId"
          component={AddOfficeProductInventory}
        />
        <OrgRoute
          exact
          path="/addQuantityInventory/:OfficeId/:productInfoId"
          component={AddQuantityInventory}
        />
        <OrgRoute
          exact
          path="/inventoryProList"
          component={InventoryOrderProduct}
        />
        <OrgRoute exact path="/inventoryDpList" component={InventoryOrderDp} />
        <OrgRoute exact path="/officeDayOpening" component={OfficeDayOpening} />
        <OrgRoute
          exact
          path="/officeReconciliation"
          component={OfficeReconciliation}
        />

        {/* Brand Information */}
        <OrgRoute exact path="/overview" component={AddOverView} />

        <OrgRoute exact path="/brand" component={Brand} />
        <OrgRoute exact path="/analytic-brand" component={AnalyticsBrand} />
        <OrgRoute exact path="/brand-info-data" component={BrandInfoData} />
        <OrgRoute
          exact
          path="/brand-add-analytic"
          component={BrandAddAnalyticChart}
        />
        <OrgRoute exact path="/brand-add" component={AddBrand} />
        <OrgRoute exact path="/category-add" component={AddCategory} />

        {/* Store Information */}
        {/* Category Information */}
        <OrgRoute exact path="/category-list" component={TableCategory} />

        {/* Unit Information */}
        <OrgRoute exact path="/unit-list" component={TableUnit} />

        <OrgRoute exact path="/add-store" component={AddStore} />
        <OrgRoute exact path="/edit-store/:id" component={EditStore} />
        <OrgRoute exact path="/store-info" component={StoreInfo} />
        <OrgRoute exact path="/store-analytics" component={StoreAnalytics} />
        <OrgRoute
          exact
          path="/store-analytics/:storeID"
          component={StoreAnalytics}
        />
        <OrgRoute exact path="/set-store" component={SetStore} />
        <OrgRoute
          exact
          path="/store-add-analytics"
          component={StoreAddAnalytic}
        />
        <OrgRoute
          exact
          path="/sample-store-table"
          component={SampleStoreTable}
        />
        <OrgRoute exact path="/exclusive-shop" component={ExclusiveShop} />

        {/* offer Information */}
        <OrgRoute exact path="/create-offer" component={CreateOffer} />
        <OrgRoute exact path="/details-offer" component={DetailsOffer} />
        <OrgRoute
          exact
          path="/add-offer-item/:offerid"
          component={AddOfferItem}
        />

        {/* Role Infromation */}
        <OrgRoute exact path="/add-role" component={AddRole} />
        <OrgRoute exact path="/Role-details" component={RoleDetails} />

        {/* Order information */}
        <OrgRoute exact path="/Order-List" component={OrderList} />

        <OrgRoute exact path="/Route-order" component={RouteOrderList} />
        <OrgRoute
          exact
          path="/Route-order/:routeID/:routeName"
          component={RouteOrderList}
        />
        <OrgRoute exact path="/date-order" component={DateOrderList} />
        <OrgRoute
          exact
          path="/date-order/:dateID/:routeID"
          component={DateOrderList}
        />
        <OrgRoute exact path="/route-order-list" component={TableRouteList} />
        <OrgRoute exact path="/analytic-order" component={AnalyticOrder} />

        {/* Target Set */}
        <OrgRoute exact path="/target" component={TargetList} />
        <OrgRoute
          exact
          path="/target-set/:userID/:userName"
          component={setTarget}
        />
        <OrgRoute
          exact
          path="/sr-target-info/:userID/:userName"
          component={SrTargetSet}
        />
        <OrgRoute exact path="/sr-target-table" component={SrTargetTable} />
        <OrgRoute exact path="/sr-target-info" component={SrTargetInfo} />

        {/* Unit Information */}
        <OrgRoute exact path="/unit-add" component={Unit} />

        {/* PJP Information */}
        <OrgRoute exact path="/pjp" component={PjpInfo} />
        <OrgRoute exact path="/pjp-create" component={PjpCreate} />
        <OrgRoute exact path="/pjp-analytic" component={PjpAnalytic} />
        <OrgRoute exact path="/pjp-add" component={AddPjp} />
        <OrgRoute exact path="/pjp-info-table" component={TablePjpTwo} />

        {/* Invoice */}

        <OrgRoute exact path="/invoice" component={InvoiceDetails} />
        <OrgRoute exact path="/invoice/:invoiceID" component={InvoiceDetails} />
        <OrgRoute
          exact
          path="/edited-invoice/:invoiceID"
          component={EditedInvoice}
        />
        <OrgRoute exact path="/routeSalesOrder" component={RouteTableSales} />
        <OrgRoute exact path="/DateSalesOrder" component={DateTableSales} />

        {/* attendance */}
        <OrgRoute exact path="/attendance" component={TableAttendance} />

        {/* Inventory */}
        <OrgRoute exact path="/office-inventory" component={SingleInventory} />
        <OrgRoute
          exact
          path="/inventory-info/:officeId/:officeName"
          component={InventoryInfo}
        />

        {/* Performance */}
        <OrgRoute
          exact
          path="/single-performance/:userPerformId"
          component={GraphPerformance}
        />
        <OrgRoute
          exact
          path="/user-list-performance"
          component={UserListPerform}
        />

        {/* Survey */}
        <OrgRoute exact path="/surveyList" component={SurveyTable} />
        <OrgRoute exact path="/surveyInfo" component={SurveyInfo} />
        <OrgRoute exact path="/surveyAdd" component={SurveyAdd} />
        <OrgRoute exact path="/survey-shop/:id" component={SurveyShop} />
        <OrgRoute exact path="/set-question/:id" component={AddQuestion} />

        <DistributorRoute
          exact
          path="/inventoryMain"
          component={InventoryMain}
        />
        <DistributorRoute
          exact
          path="/addInventory/:productId"
          component={AddInventory}
        />
        <DistributorRoute
          exact
          path="/addProductInventory"
          component={AddProductInventory}
        />
        {/* Activities */}
        <DistributorRoute
          exact
          path="/activityProductList"
          component={ActivityProductList}
        />
        <DistributorRoute
          exact
          path="/DateWiseProductList/:dataInfo"
          component={DateWiseProductList}
        />
        <DistributorRoute
          exact
          path="/DateWiseDpProductList/:dpId/:specificDate"
          component={DateWiseDpProductList}
        />
        {/* <OrgRoute exact  path="/activityDpList" component={ActivityDpList} /> */}
        <DistributorRoute
          exact
          path="/activityToday/:dpId"
          component={ActivityDpProductList}
        />
        <DistributorRoute
          exact
          path="/activityToday/:dpId/:singleDate"
          component={ActivityDpProductList}
        />
        <DistributorRoute
          exact
          path="/product-info/:productID"
          component={ProductInfoDetails}
        />
        <DistributorRoute
          exact
          path="/history-product-list/:productID"
          component={HistoryProductList}
        />
        <DistributorRoute
          exact
          path="/activityHistory"
          component={ActivityHistory}
        />
        <DistributorRoute
          exact
          path="/dayOpening/:dpId"
          component={DayOpeningDp}
        />
        <DistributorRoute
          exact
          path="/distDateOrder"
          component={TableDistDateOrder}
        />
        <DistributorRoute
          exact
          path="/distShopOrder/:distId/:date"
          component={TableDistShopOrder}
        />
        <DistributorRoute
          exact
          path="/distInvoice"
          component={DistPreOrderInvoice}
        />
        <DistributorRoute
          exact
          path="/distInvoice/:invoiceID"
          component={DistPreOrderInvoice}
        />
        <DistributorRoute
          exact
          path="/distEdited-invoice/:invoiceID"
          component={DistEditedInvoice}
        />
        <DistributorRoute
          exact
          path="/damage-products"
          component={DamageInfo}
        />
        <DistributorRoute
          exact
          path="/damage-analytics"
          component={AnalyticsDamage}
        />
        <DistributorRoute
          exact
          path="/damage-activity"
          component={DamageActivity}
        />
        <DistributorRoute
          exact
          path="/damage-reconciliation"
          component={DamageReconciliation}
        />
        <DistributorRoute
          exact
          path="/damage-inventory-main"
          component={DamageInventoryMain}
        />
        <DistributorRoute
          exact
          path="/AddDamageQuantityInventory/:productId"
          component={AddDamageQuantityInventory}
        />
        <DistributorRoute
          exact
          path="/add-damage-inventory"
          component={AddDamageInventory}
        />

        <Route exact path="/" component={Login} />
        <Route exact path="/emailverify" component={EmailVerifyPage} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot-password" component={ForgetPassword} />
        <Route
          exact
          path="/email-verify-forget-pass"
          component={ForgetPasswordEmailVerify}
        />
        <Route exact path="/update-password" component={UpdatePasswordPage} />
      </Switch>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
