import { getData, postData } from "helpers/backend_helper"
import { toaster } from "pages/Helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  createSurveyQuestionFail,
  createSurveyQuestionSuccess,
  getSurveyDataFail,
  getSurveyDataSuccess,
  getSurveyQuestion,
  getSurveyQuestionFail,
  getSurveyQuestionSuccess,
} from "./actions"
import SurveyActionTypes from "./actionTypes"

function* fetchSingleSurvey({ payload: { id, token } }) {
  console.log(`function*fetchSingleSurvey ~ id, token`, id, token)
  try {
    const url = `/survey?surveyId=${id}`
    const response = yield call(getData, url, token)
    console.log("response-saga=>", response)
    yield put(getSurveyDataSuccess(response?.survey))
  } catch (error) {
    const message = error.response?.data?.message || "Load single survey failed"
    toaster("error", message)
    yield put(getSurveyDataFail(message))
  }
}

function* fetchSurveyQuestion({ payload: { id, token } }) {
  try {
    const url = `/survey/question?surveyId=${id}`
    const response = yield call(getData, url, token)
    console.log("response-saga=>", response)
    yield put(getSurveyQuestionSuccess(response?.questions))
  } catch (error) {
    const message =
      error.response?.data?.message || "Load survey question failed"
    toaster("error", message)
    yield put(getSurveyQuestionFail(message))
  }
}

function* createSurveyQuestion({
  payload: {
    data,
    options: { token, resetForm, id },
  },
}) {
  try {
    const url = `/survey/question`
    const response = yield call(postData, url, data, token)
    console.log("response-saga=>", response)
    yield put(createSurveyQuestionSuccess(response))
    yield put(getSurveyQuestion(id, token))
    resetForm()
  } catch (error) {
    const message =
      error.response?.data?.message || "Load survey question failed"
    toaster("error", message)
    yield put(createSurveyQuestionFail(message))
  }
}

export default function* SurveySaga() {
  yield takeEvery(SurveyActionTypes.GET_SURVEY_DATA, fetchSingleSurvey)
  yield takeEvery(SurveyActionTypes.GET_SURVEY_QUESTION, fetchSurveyQuestion)
  yield takeEvery(
    SurveyActionTypes.CREATE_SURVEY_QUESTION,
    createSurveyQuestion
  )
}
