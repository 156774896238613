import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter, useHistory } from "react-router-dom"

import { MDBDataTable } from "mdbreact"
import useDebounce from "Hooks/useDebounce"
import XLSX from "xlsx"
import {
  Badge,
  Button,
  Col,
  Card,
  CardBody,
  Container,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap"
import { getRouteInfo } from "../../store/GeoInformation/actions.js"
import { getAllOrderExcelData } from "../../store/Order/actions"
import routeListDown from "../../assets/images/routeListDown.svg"
import {
  getAllOrderexcel,
  getDateRangeOrderexcel,
} from "../../helpers/backend_helper.js"
import { get } from '../../helpers/api_helper';
import moment from "moment"

const tableHead = ["No.", "Route Code", "Name", "View Details"]
const TableRouteList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageRange, setPageRange] = useState(50)
  const [orderExcel, setOrderExcel] = useState(false)
  const [orderRangeExcel, setOrderRangeExcel] = useState(false)
  const [loadingShopExcel, setLoadingShopExcel] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  )
  const [orderStartDate, setOrderStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [orderEndDate, setOrderEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )

  console.log(currentDate)
  const { value, onChange } = useDebounce(
    () => dispatch(getRouteInfo(authtoken, id, currentPage, value)),
    1000
  )
  const {
    routeInfo,
    authtoken,
    id,
    routeInfoLoading,
    pageNumber,
    allOrderLoading,
    allOrder,
    allOrderExcel
  } = useSelector(state => ({
    routeInfo: state?.GeoInformationReducer?.routeInfo,
    allOrder: state.OrderReducer.allOrder,
    allOrderExcel: state.OrderReducer.allOrderExcel,
    authtoken: state.Login.token,
    id: state.Login.id,
    pageNumber: state?.GeoInformationReducer?.routeInfo?.data?.pagenumber,
    routeInfoLoading: state?.GeoInformationReducer?.routeInfoLoading,
    allOrderLoading: state.OrderReducer.allOrderLoading,
  }))
console.log('allOrderExcel', allOrderExcel);
  useEffect(() => {
    dispatch(getRouteInfo(authtoken, id, currentPage, value))
  }, [currentPage])

  useEffect(() => {
    dispatch(getAllOrderExcelData(authtoken, orderStartDate, orderEndDate, currentPage, pageRange))
  }, [currentPage, orderStartDate, orderEndDate, pageRange])

  function convertDigitIn(str) {
    return str.split("-").reverse().join("-")
  }
  function convertDateToLocal(date){
    // console.log('date', date);
    var local_date= moment.utc(date).local().format('DD-MM-YYYY HH:mm:ss');
    // console.log('local_date');
    return local_date
  }

  const handleExcel = async () => {
    setOrderExcel(true)
    try {
      var response = await getAllOrderexcel(authtoken, currentDate)
      console.log("order sss excel", response)
    } catch (error) {}
    let newArray = []
    response?.todayallorder?.forEach((order, index) => {
      let data = {}
      data.Shop = order?.shop?.name
      data.SRName = order?.sr?.name
      data.EmployeeId = order?.sr?.employeeId

      data.orderTakenTime = order?.ordertakentime
      data.OrderTakenDate = order?.orderTakenDate
      data.OrderDeliveryDate = convertDigitIn(order?.deliveryDate)
      data.route = order?.route?.routeCode
      data.routeName = order?.route?.name
      data.officeName = order?.office?.name
      data.officeCode = order?.office?.distributioncode
      data.area = order?.area?.name
      data.region = order?.region?.name
      data.grandtotal = parseFloat(parseFloat(order?.grandTotal).toFixed(2))
      order.orderProducts.forEach((dt, idx) => {
        data[`ProductName-${idx}`] = dt?.productName
        data[`ProductQty-${idx}`] = Math.ceil(dt?.qty)
        data[`UnitPrice-${idx}`] = parseFloat(
          parseFloat(dt.unitPrice).toFixed(2)
        )
        data[`Total-${idx}`] = parseFloat(parseFloat(dt.total).toFixed(2))
      })
      console.log("excel sheet", data)
      newArray.push(data)
    })
    downloadxls(newArray)
  }
  // const handleRangeOrder = async () => {
  //   console.log("hhhh")
  //   setOrderRangeExcel(true)
  //   try {
  //     var response = await getDateRangeOrderexcel(
  //       authtoken,
  //       orderStartDate,
  //       orderEndDate
  //     )
  //     console.log("range excel", response)
  //   } catch (error) {}
  //   let newArray = []
  //   response?.orderData?.forEach((order, index) => {
  //     let data = {}
  //     data.Shop = order?.shop?.name
  //     data.SRName = order?.sr?.name
  //     data.EmployeeId = order?.sr?.employeeId
  //     // data.DPName = "Dhaka"
  //     data.orderTakenTime = order?.ordertakentime
  //     data.OrderTakenDate = order?.orderTakenDate
  //     data.OrderDeliveryDate = convertDigitIn(order?.deliveryDate)
  //     data.route = order?.route?.routeCode
  //     data.routeName = order?.route?.name
  //     data.officeName = order?.office?.name
  //     data.officeCode = order?.office?.distributioncode
  //     data.area = order?.area?.name
  //     data.region = order?.region?.name,
  //     data.grandtotal = parseFloat(parseFloat(order?.grandTotal).toFixed(2))
  //     // console.log('order.grandTotal',typeof (parseFloat(order.grandTotal)));
  //     order?.orderProducts?.forEach((dt, idx) => {
  //       data[`ProductName-${idx}`] = dt?.productName
  //       data[`ProductQty-${idx}`] = Math.ceil(dt?.qty)
  //       data[`UnitPrice-${idx}`] = parseFloat(
  //         parseFloat(dt?.unitPrice).toFixed(2)
  //       )
  //       data[`Total-${idx}`] = parseFloat(parseFloat(dt?.total).toFixed(2))
  //     })

  //     newArray.push(data)
  //   })
  //   downloadRangeXls(newArray)
  // }
  const downloadxls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "Shop Order.xlsx")
    setOrderExcel(false)
  }
  // const downloadRangeXls = data => {
  //   console.log(XLSX.version)
  //   const ws = XLSX.utils.json_to_sheet(data)
  //   const wb = XLSX.utils.book_new()
  //   XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
  //   XLSX.writeFile(wb, "Shop Order.xlsx")
  //   setOrderRangeExcel(false)
  // }
  const handleChangeDate = e => {
    const value = convertDigitIn(e.target.value)

    setCurrentDate(value)
  }
  const handleStartDate = e => {
    const value = e.target.value

    setOrderStartDate(value)
  }
  const handleEndDate = e => {
    const value = e.target.value

    setOrderEndDate(value)
  }
  const handleDatetoDateExcel = async () => {
    setLoadingShopExcel(true);
    let rangeOrderData = []
    console.log('kkkk');
    let totalPage = Math.ceil(allOrderExcel?.data?.pageNumber);
    for (let i = 0; i < totalPage; i++) {
      console.log('hello');
   
   
     await get(`order/dateTodateOrder?fromDate=${orderStartDate}&toDate=${orderEndDate}&pageNo=${i+1}&limit=${pageRange}`,{ headers: { Authorization: `Bearer ${authtoken}` } })
     .then(response=>{
       console.log('response',response );
        
        response?.orderData?.forEach((order, index) => {
          let data = {}
          data.Shop = order?.shop?.name
          data.SRName = order?.sr?.name
          data.EmployeeId = order?.sr?.employeeId
          // data.DPName = "Dhaka"
          data.orderTakenTime = order?.ordertakentime
          data.OrderTakenDate = order?.orderTakenDate
          data.OrderDeliveryDate = convertDigitIn(order?.deliveryDate)
          data.SyncDate = convertDateToLocal(order?.createdAt)
          data.route = order?.route?.routeCode
          data.routeName = order?.route?.name
          data.officeName = order?.office?.name
          data.officeCode = order?.office?.distributioncode
          data.area = order?.area?.name
          data.region = order?.region?.name,
          data.grandtotal = parseFloat(parseFloat(order?.grandTotal).toFixed(2))
          // console.log('order.grandTotal',typeof (parseFloat(order.grandTotal)));
          order?.orderProducts?.forEach((dt, idx) => {
            data[`ProductName-${idx}`] = dt?.productName
            data[`ProductQty-${idx}`] = Math.ceil(dt?.qty)
            data[`UnitPrice-${idx}`] = parseFloat(
              parseFloat(dt?.unitPrice).toFixed(2)
            )
            data[`Total-${idx}`] = parseFloat(parseFloat(dt?.total).toFixed(2))
          })
    
        
        
          rangeOrderData.push(data)
      
      

        })
      }).catch((error)=>{
        console.log('kkkk');
        // setResults((prev) => [...prev]);
      }
      );

 
    }

  
    downloadxls2(rangeOrderData)
 
  };

  const downloadxls2 = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "All Order List.xlsx")
    setLoadingShopExcel(false);
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>DDC</title>
      </MetaTags>
      <Container fluid>
        <CardComponent>
          <div className="attendance-date">
            <AvForm>
              <AvField
                name="startTime"
                id="example-time-input"
                className="form-control mb-3"
                type="search"
                defaultValue={value}
                placeholder="Search Route Name"
                onChange={onChange}
              ></AvField>
            </AvForm>
          </div>
          <Row>
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={routeInfo?.data?.routes}
              tableHead={tableHead}
              setCurrentPage={setCurrentPage}
              page={pageNumber}
              currentPage={currentPage}
              isPagination
              routeInfoLoading={routeInfoLoading}
            >
              {routeInfoLoading ? (
                <tr style={{ width: "100%" }}>
                  <div
                    className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <Spinner />
                    </div>
                  </div>
                </tr>
              ) : routeInfo?.data?.routes?.length ? (
                routeInfo?.data?.routes?.map((route, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <th scope="row">{idx + 1}</th>
                      <td>{route?.name}</td>
                      <td>{route?.routeCode}</td>

                      <td>
                        <Button
                          color="primary"
                          className="btn-sm btn-rounded"
                          onClick={() => {
                            history.push(
                              `/Route-order/${route._id}/${route.name}`
                            )
                          }}
                        >
                          View Details
                        </Button>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <NoTableData
                  colSpan="9"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>Currently you have no Data. </span>
                </NoTableData>
              )}
            </CustomTable>

            {/* table end */}
          </Row>
        </CardComponent>
      </Container>
      <Card>
        <div className="route-excel-attendance">
          {orderExcel ? (
            <div className="text-center route-excel">
              <Spinner />
            </div>
          ) : (
            <div className="route-excel">
              <div>
                <p className="mb-0">Download Excelsheet</p>
              </div>
              <div>
                <button className="route-excel-download" onClick={handleExcel}>
                  {" "}
                  <img src={routeListDown}></img>Download
                </button>
              </div>
            </div>
          )}
          <div className="date-order-excel">
            <AvForm>
              <AvField
                name="startTime"
                id="example-time-input"
                className="form-control"
                type="date"
                value={moment(new Date()).format("YYYY-MM-DD")}
                onChange={handleChangeDate}
                errorMessage="Enter Start Time"
                validate={{ required: { value: true } }}
              ></AvField>
            </AvForm>
          </div>
        </div>
      </Card>

      <Card>
        <div className="route-excel-attendance">
          {orderRangeExcel ? (
            <div className="text-center route-excel">
              <Spinner />
            </div>
          ) : (
            <div className="route-excel">
              <div>
                <p className="mb-0">Download Excelsheet</p>
              </div>
              <div>
                <button
                  className="route-excel-download"
                  disabled={loadingShopExcel} onClick={handleDatetoDateExcel}>{loadingShopExcel ? "Downloading..." : "Download All Order"}
          
    
                </button>
              </div>
            </div>
          )}

          <div className="date-order-excel">
            <AvForm className="range-excel download">
              <label htmlFor="range-excel download">From date</label>
              <AvField
                name="startTime"
                id="example-time-input"
                className="form-control mb-3"
                type="date"
                value={moment(new Date()).format("YYYY-MM-DD")}
                onChange={handleStartDate}
                errorMessage="Enter Start Time"
                validate={{ required: { value: true } }}
              ></AvField>
              <label htmlFor="range-excel download">To Date</label>
              <AvField
                name="startTime"
                id="example-time-input"
                className="form-control mb-3"
                type="date"
                value={moment(new Date()).format("YYYY-MM-DD")}
                onChange={handleEndDate}
                errorMessage="Enter Start Time"
                validate={{ required: { value: true } }}
              ></AvField>
            </AvForm>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default TableRouteList
