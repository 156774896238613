import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

const LoadingToRedirect = () => {
  const history = useHistory()
  const [count, setCount] = useState(5)

  const { token, email, userrole } = useSelector(state => ({ ...state.Login }))

  useEffect(() => {
    if (userrole == "ADMIN") {
      history.push("/dashboard")
    } else if (userrole == "ORG") {
      history.push("/orgdashboard")
    }
    else if (userrole == "DISTRIBUTOR") {
      history.push("/dashboard")
    }
     else {
      history.push("/")
    }

    const interval = setInterval(() => {
      //cleanup

      setCount(currentCount => --currentCount)
    }, 1000)
    //redirect once count is equal to 0
    count === 0 && history.push("/")
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="container p-5 text-center">
      <p>Redirecting you in {count} seconds</p>
    </div>
  )
}

export default LoadingToRedirect
