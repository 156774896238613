import { AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from 'react-router-dom';
import Select from "react-select";

import {
    Button, Card, CardBody,
    CardTitle, Col,
    Container, Label, Row, Spinner
} from "reactstrap";
//Import Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getRoute } from "../../store/GeoInformation/actions";
import { updateRouteUser } from "../../store/users/actions";
import RouteInfoUser from "./RouteInfoUser";

const SetRoute = () => {

    const [selectedvalue, setsetselectedvalue] = useState([])
    const [spinner, setspinner] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    console.log('kkkk',history?.location?.state);

    const {
        params: { userid,userName },
    } = useRouteMatch('/set-route/:userid/:userName');
    const { authtoken, routes, routeloading ,id} = useSelector(state => ({
        authtoken: state.Login.token,
        id: state.Login.id,
        routes: state.GeoInformationReducer.routes,
        routeloading: state.GeoInformationReducer.routeloading
    }))

    const hanldeSubmit = e => {
        e.preventDefault()
        setspinner(true)
        let obj = {}
        obj.routes = selectedvalue
        console.log(obj);
        console.log(selectedvalue);
        console.log(userid);
        dispatch(updateRouteUser(userid,obj, authtoken, history )) 
        

    }


    useEffect(() => {
        dispatch(getRoute(authtoken,id))
    }, [])


    const handleMultiChange = e => {
        console.log('hello multi');
        console.log(e);
        setsetselectedvalue(Array.isArray(e) ? e.map(x => x._id) : [])
    }


    return (
        <React.Fragment>
            <div className='page-content'>
                <MetaTags>
                    <title>DDC | Add Route</title>
                </MetaTags>
                <Container fluid={true} >

                    <Row>
                        <Col md={6} className='m-auto'>
                            <Card >
                                <CardBody >
                                    <CardTitle className="h4 mb-4">Set Route for<span className="text-warning"> {userName}</span></CardTitle>
                                    {!routeloading ? (
                                        <div className="text-center mt-5 pt-5">
                                            <Spinner />
                                        </div>
                                    ) : (
                                            <AvForm onSubmit={hanldeSubmit}>
                                            <div className="mb-3">

                                                    <Label className="control-label">
                                                        Multiple Route Select
                                                    </Label>
                                                    <Select
                                                        cacheOptions
                                                        getOptionLabel={e => e.routeCode}
                                                        getOptionValue={e => e._id}
                                                        options={routes?.data?.route}
                                                        isClearable
                                                        onChange={handleMultiChange}
                                                        isMulti
                                                    />
                                                
                                            </div>
                                                <div className="d-flex flex-wrap gap-2">
                                                    {spinner ? (
                                                        <Spinner />
                                                    ) : (
                                                        <>
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="btn "
                                                            >
                                                                Save Changes
                                                            </Button>
                                                           
                                                        </>
                                                    )}
                                                </div>
                                        </AvForm>
                                          )}
                                </CardBody>
                            </Card>
                        </Col>
                      
                    </Row>
                    <RouteInfoUser/>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default SetRoute;