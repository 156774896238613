import React, { useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { map } from "lodash"
import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Col, Container, Row, Spinner } from "reactstrap"
import { getBrands } from '../../store/Brand/actions'
import BrandInfo from "./BrandInfo"
//Import Card
import BrandList from "./BrandList"





const Brand = props => {

  const dispatch = useDispatch();
 const {brands,authtoken, orgid,loading} = useSelector(state =>({
   brands: state.BrandReducer.brands.data, 
   authtoken: state.Login.token,
   orgid: state.Login.id,
   loading: state.BrandReducer.loading
 }))
console.log("gi",brands);
 useEffect(() => {
  dispatch(getBrands(authtoken, orgid));
}, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Brand | DDC</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Home" breadcrumbItem="Brand" />
          <BrandInfo/>
          {loading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (

          <Row>
            {map(brands, (brand, key) => (
              <BrandList brand={brand} key={"_shop_" + key} />
            ))}
          </Row>
                    )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Brand.propTypes = {
  shops: PropTypes.array,
  onGetShops: PropTypes.func,
}

export default Brand;