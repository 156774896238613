import React from "react"
//i18n
import { withTranslation } from "react-i18next"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
//Import Breadcrumb
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"

const DistributorDashboard = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | DDC</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          {/* <h1>hello</h1> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

DistributorDashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(DistributorDashboard)
