import React, { useEffect } from "react";

import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  Spinner,
  CardBody,
} from "reactstrap"

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
// import TableGEO from "./TableGEO";
import TableGeoData from "./TableGeoData";
import { getGeo } from '../../../store/GeoInformation/actions';

import {getTopDP} from '../../../store/OrgDashboard/actions'

const DetailsGEO = () => {
 
  const dispatch = useDispatch();

  
      const { geo } = useSelector(state => ({ geo: state.GeoInformationReducer.geo.geo }))
      
      const { authtoken, id, geoloading,topDPs,topDpLoading } = useSelector(state => ({
           topDPs: state.DashboardReducer.topDPs,
          authtoken: state.Login.token,
          id: state.Login.id,
          geoloading: state.GeoInformationReducer.geoloading,
          topDpLoading:state.DashboardReducer.topDpLoading
      }))
      useEffect(() => {
                dispatch(getGeo(authtoken));
            }, []);
            useEffect(() => {
              dispatch(getTopDP(authtoken));
            }, []);

 
    return (
        <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>DDC</title>
          </MetaTags>
          <Container fluid>
            <Row>

              <Col xl="12">
                <Row>

                  {/* storeData Render */}
                 
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                          {!geoloading ? (
                        <div className="text-center mt-5 pt-5 text-sm">
                            <Spinner />
                        </div>
                    ) : (
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Total GEO
                              </p>
                              <h4 className="mb-0">{geo?.length}</h4>

                            </div>
                    )}
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
{/*          
                    <Col md="3">
                    {topDpLoading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Top DP
                              </p>
                              <h4 className="mb-0">{topDPs?.data?.data[0]?.routename}</h4>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                       )}
                    </Col> */}
                   
                  
              <Col md="3">
              <Card className='card-info-list'>
                  <CardBody >
                    <CardTitle className="mb-0 h4">Add New Geo</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/add-geo"
                            className="btn btn-primary btn-md"
                          >
                            Add GEO
                          </Link>
                        </div>
                      </Col>
                     
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
                 
                </Row>
               

              </Col>
            </Row>
           <TableGeoData/>

         
          </Container>
        </div>


      </React.Fragment >
    );
};

export default DetailsGEO;