import React, { useEffect } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner,
} from "reactstrap"


import "./AddOfferTime.css"
import { getOffersDetails } from "../../store/Offer/actions.js";
import {  useRouteMatch } from "react-router";
import cancel from '../../assets/images/cancel.svg'
const OfferList = () => {
    const {
        params: { offerid },
    } = useRouteMatch('/add-offer-item/:offerid');
    console.log(offerid);
  const dispatch = useDispatch()


  const { offerDetails, authtoken, offerDetailsLoading } = useSelector(state => ({
    offerDetails: state.OfferReducer.offerDetails.data,
    authtoken: state.Login.token,
    offerDetailsLoading: state.OfferReducer.offerDetailsLoading,
  }))
  useEffect(() => {
    dispatch(getOffersDetails(authtoken,offerid))
  }, [])
  console.log(offerDetails);


  return (
    <React.Fragment>
      <MetaTags>
        <title>DDC</title>
      </MetaTags>
      {offerDetailsLoading ? (
                        <div className="text-center pt-5">
                            <Spinner />
                        </div>
                    ) : (
      <Container  className='mb-5'>
      
       
     
        <Row >
        {/* <p className='fw-bold'>Offer list for Retailer</p> */}
            {
            offerDetails.singleoffer.retailerOffer.length!==0? <p className='fw-bold'>Offer list for retailer</p>
            :<p className='fw-bold'>No offer for retailer</p>
        } 
        
        {(offerDetails.singleoffer.retailerOffer || []).map((data, key) => {
              return (
          <Col md="6" key={key}>
          <div>
          <div className="offer-list d-flex mb-2">
              <div className="offer-details">
                <p  className="offer-info ">Offer Type:<small className='ms-2'>{data.type}</small></p>
                {data.type==='Total Amount'?
                <p className="offer-info ">Amount:<small className='ms-2'>{data.amount}</small></p>:
                <p className="offer-info ">Quantity:<small className='ms-2'>{data.freeProductQty}</small></p>
              }
              </div>
              <div>
                <p className="offer-info ">Offer : <small> {data.name} </small></p>
                {
                    data.name==='Discount Offer'? <p className="offer-info "><small>Discount: </small>{data.discount}</p>
                    :
                    data.name==='Flat offer'?<p className="offer-info ">Flat Discount: <small>{data.totalAmounDiscount}</small></p>
                    :<p className="offer-info ">Product Discount: <small>Fresh</small></p>
                }
           
              </div>
              
                  
            </div>
          </div>
          </Col>
             );
            })}
          
        </Row>
        <Row >
        {/* <p className='fw-bold'>Offer list for consumer</p> */}
        {
            offerDetails.singleoffer.consumerOffer.length!==0? <p className='fw-bold '>Offer list for consumer</p>
            :<p className='fw-bold mb-5 mt-3'>No offer for consumer</p>
        } 
        {(offerDetails.singleoffer.consumerOffer || []).map((data, key) => {
              return (
          <Col md="6" key={key}>
          <div>
          <div className="offer-list d-flex ms-3 mb-2">
              <div className="offer-details">
                <p  className="offer-info ">Offer Type:<small className='ms-2'>{data.type}</small></p>
                {data.type==='Total Ammount'?
                <p className="offer-info ">Amount:<small className='ms-2'>{data.amount}</small></p>:
                <p className="offer-info ">Amount:<small className='ms-2'>{data.amount}</small></p>
              }
              </div>
              <div>
                <p className="offer-info ">Offer : <small> {data.name} </small></p>
                {
                    data.name==='Discount Offer'? <p className="offer-info ">Total Discount: <small>{data.discount}</small></p>
                    :
                    data.name==='Flat offer'?<p className="offer-info ">Flat Discount: <small>{data.totalAmounDiscount}</small></p>
                    :<p className="offer-info ">Product Discount: <small>Fresh</small></p>
                }
           
              </div>
            </div>
          </div>
          </Col>
             );
            })}
          
        </Row>
       
                  
      </Container>
       )}
    </React.Fragment>
  )
}

export default OfferList
