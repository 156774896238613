import { all, fork, call, put, takeEvery } from "redux-saga/effects"

import { loginSuccess } from "../login/actions"

import { toast } from "react-toastify"
import { updatePasswordSuccess, updatePasswordFail } from "./actions"
import { UPDATE_PASSWORD } from "./actionTypes"
import { axiosUpdatePassword } from "helpers/api_helper"
const asyncUpdatePass = async (
  email,
  accountActivateToken,
  password,
  confirmPassword
) => {
  // console.log("from async await", email, accountActivateToken, password, confirmPassword)
  try {
    const response = await axiosUpdatePassword(
      email,
      accountActivateToken,
      password,
      confirmPassword
    )
    console.log(response.data)
    return response.data
  } catch (err) {
    console.log("res dta", err.response.data)
    return err.response.data
  }
}

function* workerUpdatePass({ payload: { values, history } }) {
  console.log("all value  from saga")

  console.log("history data", history)
  const { email, accountActivateToken, password, confirmPassword } = values
  try {
    const response = yield call(
      asyncUpdatePass,
      email,
      accountActivateToken,
      password,
      confirmPassword
    )
    if (response.status == "success") {
      yield put(updatePasswordSuccess(response.message))
      toast.success(response.message)
      history.push("/login")
    } else {
      yield put(updatePasswordFail(response.message))
      toast.error(response.message)
    }
  } catch (error) {
    // yield put(resetPasswordFail(error))
  }
  // console.log("all whole values ", email, accountActivateToken, password, confirmPassword)
}

function* watcherUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, workerUpdatePass)
}

function* updatePasswordSaga() {
  yield all([fork(watcherUpdatePassword)])
}
// function* accountSaga() {
//   yield all([fork(watchUserRegister)])
// }
export default updatePasswordSaga
