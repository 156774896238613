import { toast } from "react-toastify"
import { call, delay, put, takeEvery } from "redux-saga/effects"
import { getAttendanceData , getAllAttendanceData} from '../../helpers/backend_helper'
import {getAttendanceSuccess,getAttendanceFail,getAttendanceExcelSuccess, getAttendanceExcelFail } from './actions'
import {
    GET_ATTENDANCE,GET_ATTENDANCE_EXCEL
} from './actionTypes'

function* fetchAttendance({ payload: { authtoken, data, role } }) {
   
  try {
    delay(500)
    console.log(data);
    const response = yield call(getAttendanceData, authtoken, data, role)
    console.log('fetchAttendance',response);
    yield put(getAttendanceSuccess(response))
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getAttendanceFail(error))
   
  }
}
function* fetchAttendanceExcel({ payload: { authtoken, data } }) {
  console.log('data',data);
try {
  delay(500)
  console.log(data);
  const response = yield call(getAllAttendanceData, authtoken, data)
  console.log('fetchAttendance',response);
  yield put(getAttendanceExcelSuccess(response))
  // console.log('line 15', response.data.users);
} catch (error) {
  yield put(getAttendanceExcelFail(error))
  console.log('hi');
}
}





  
  function* AttendanceSaga() {

    yield takeEvery(GET_ATTENDANCE, fetchAttendance)
    yield takeEvery(GET_ATTENDANCE_EXCEL, fetchAttendanceExcel)
 
  }
  
  export default AttendanceSaga
  