import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import { addPjpData,getPjpData} from "../../helpers/backend_helper.js"
import { addPJPSuccess, addPJPFail, getAllPJPFail, getAllPJPSuccess } from "./actions"
import {
    ADD_NEW_PJP, GET_ALLPJP
} from "./actionTypes"

// function* onAddPJP({ payload: { data, history, authtoken } }) {
//     try {
//       const response = yield call(addPjpData, data, authtoken)

//       console.log(data);
//       console.log(response);

//       if (response.status === 'success') {
//         console.log('test on 18', );
//         yield put(addPJPSuccess(response))
//         toast.success('success')
//         history.push('/pjp')
 
//       }
     
     
     
//     } catch (error) {
//       if (!error.response) {
//         history.push('/pjp')
//       console.log('line 20');
//       } else {
//         let message = error.response.data.message
//        console.log('line 24');
//         // console.log("error message,", error.response.data.message)
//         yield put(addPJPFail(message))
//         toast.error(message)
//       }
//     }
//   }
  function* onAddPJP({ payload: { data, history, authtoken } }) {
  
    try {

     
      const response = yield call(addPjpData, data, authtoken)
      console.log('hi');
      console.log(response);
      yield put(addPJPSuccess(response))
      toast('🦄 Pjp added successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push('/pjp')
     
       
    } catch (error) {
      
      if (!error.response) {
        toast('🦄 pjp added successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push('/pjp')
      } else {
        let message = error.response.data.message
        yield put(addPJPFail(message))
        // console.log("hi",message);
        toast.error(message)
      }
    }
  }
  function* fetchPJP({ payload: { authtoken } }) {
    try {
      console.log('fetched area');
      const response = yield call(getPjpData, authtoken)
      console.log(response);
      yield put(getAllPJPSuccess(response))
    } catch (error) {
      yield put(getAllPJPFail(error))
    }
  }
  
  function* PJPSaga() {
    yield takeEvery(ADD_NEW_PJP, onAddPJP)
   yield takeEvery(GET_ALLPJP, fetchPJP)
   
  }
  
  export default PJPSaga