// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, withRouter } from "react-router-dom"
import { Alert, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"
// import images
import profile from "../../assets/images/profile-img.png"
// action
// import { userEmailVerify } from "../../store/actions"
import { verifyEmail } from "../../store/actions"






const ForgetPasswordEmailVerify = props => {
  const dispatch = useDispatch()

  let history = useHistory()
  const { loading, message, error } = useSelector(state => ({
    loading: state.verifyEmail.loading,
    message: state.verifyEmail.message,
    error: state.verifyEmail.error,
  }))

  function handleValidSubmit(event, values) {
    values.email = localStorage.getItem("useremail")
    console.log("hello ami jubair")
    dispatch(verifyEmail(values, history))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Email Verify</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to ieltsX.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    {error && error ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    ) : null}
                    {/* {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null} */}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <h3>Verify Your Email</h3>
                      <br />
                      <p>
                        We just mail a code to abcd@gmail.com keep this window
                        open while you check your inbox, then enter the code
                        below.{" "}
                      </p>
                      <div className="mb-3">
                        <AvField
                          name="verifycode"
                          label="Verification Code"
                          className="form-control"
                          placeholder="Type Your Code"
                          type="text"
                          required
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <div className="mt-3 d-grid">
                            {loading ? (
                              <h5 className="font-size-14 mb-3 text-center">
                                <Spinner />
                              </h5>
                            ) : (
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Verify Account
                              </button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Salesx. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by MAAC
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordEmailVerify.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordEmailVerify)
