import { AvField } from "availity-reactstrap-validation"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import useDebounce from "Hooks/useDebounce"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Badge, Button, Card, Container, Row, Spinner } from "reactstrap"


const tableHead = [
  "No.",
  "Product List",
  "Order received",
  "Dispatch Qty(x*1.5)",
  "Current Inventory",
  "Qty Edit",
  "Qty Price",
  "Total Price",
]
const OfficeDayOpening = () => {
    const [currentPage, setCurrentPage] = useState(1)
  return (
    <React.Fragment>
      <MetaTags>
        <title>DDC</title>
      </MetaTags>
    <div className="page-content">
    <Container fluid>
        <h4 className="mb-3">Day Opening</h4>
        <CardComponent>
 
          <Row>
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={[0,1,2]}
              tableHead={tableHead}
              setCurrentPage={setCurrentPage}
              page={5}
              currentPage={currentPage}
              isPagination
              // productLoading={allStoreLoading}
            >
              {false ? (
                <tr style={{ width: "100%" }}>
                  <div
                    className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <Spinner />
                    </div>
                  </div>
                </tr>
              ) : [0, 1, 3] ? (
                [0, 1, 3].map((store, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <th scope="row">{idx + 1}</th>
                      <td>name</td>
                      <td>m</td>
                      <td>n</td>
                      <td>p</td>
                      <td>
                      <input
                                    type="number"
                                    // value={data.editOrder}
                                    // onChange={e => handleSubmitQtyEdit(e, key)}
                                  ></input>
                      </td>
                      <td>n</td>
                      <td>p</td>
                   
                  
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <NoTableData
                  colSpan="9"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>Currently you have no Data. </span>
                </NoTableData>
              )}
            </CustomTable>

            {/* table end */}
          </Row>
        </CardComponent>
      </Container>
    </div>
      <Card></Card>
    </React.Fragment>
  )
}

export default OfficeDayOpening
