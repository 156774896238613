import React,{useEffect} from "react"
import ReactApexChart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux";


const AnalyticsDamage = () => {


  const options = {
    chart: {
      height: 300,
      type: "bar",
      toolbar: {
        show: !1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "14%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      show: !0,
      width: 2,
      colors: ["transparent"],
    },
    series: [
      {
        name: "Quantity",
        data: [0,1,2,3,4,5],
      },
    ],
    xaxis: {
      title: {
        text: "Date",
      },
      categories:[0,1,2,3,4,5]
    },
    yaxis: {
      title: {
        text: "Quantity",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ["#556ee6"],
  }
  const series = [
    {
      name: "Quantity",
      data: [0,1,2,3,4,5],
    },
  ]

  return (
   <div className="page-content">
        <ReactApexChart options={options} series={series || []} type="bar" height="320" className="apex-charts" />
   </div>
  )
}

export default AnalyticsDamage
