import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import Select from "react-select"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { addOffice } from "../../store/office/actions"
import { getRegion } from "../../store/GeoInformation/actions"
import {
  clearFilters,
  getAreaByRegion,
  getTerritoryByArea,
  onAreaFilterChange,
  onRegionFilterChange,
  onTerritoryFilterChange,
} from "../../store/actions.js"

const AddOffice = () => {
  const [loadArea, setLoadArea] = useState(false)
  const [loadTerritory, setLoadTerritory] = useState(false)
  useEffect(() => {
    dispatch(clearFilters())
  }, [])

  const history = useHistory()
  const dispatch = useDispatch()
  const {
    authtoken,
    id,
    area,
    region,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    // selectedTerritory,
    // currentSelection,
    areaList,
    territoryList,
  } = useSelector(state => ({
    area: state.GeoInformationReducer.area.area,
    authtoken: state.Login.token,
    id: state.Login.id,
    region: state.GeoInformationReducer.region.region,
    areaList: state.DashboardReducer.areaList,
    territoryList: state.DashboardReducer.territoryList,

    // currentSelection: state.DashboardReducer.currentSelection,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
  }))
  console.log("selectedRegion", selectedRegion)
  console.log("selectedArea", selectedArea)
  console.log("selectedTerritory", selectedTerritory)

  useEffect(() => {
    // dispatch(getData())

    dispatch(getRegion(authtoken))
  }, [])
  useEffect(() => {
    if (Object?.keys(selectedRegion)?.length) {
      dispatch(getAreaByRegion(authtoken, selectedRegion?._id))
    }
  }, [loadArea])

  useEffect(() => {
    if (Object?.keys(selectedArea)?.length) {
      dispatch(getTerritoryByArea(authtoken, selectedArea?._id))
    }
  }, [loadTerritory])
  const handleRegion = data => {
    dispatch(onRegionFilterChange(data))
    setLoadArea(!loadArea)
  }

  const handleArea = data => {
    dispatch(onAreaFilterChange(data))
    setLoadTerritory(!loadTerritory)
  }
  const handleTerritory = data => {
    dispatch(onTerritoryFilterChange(data))
  }
  function handleSubmit(event, errors, values) {
    event.preventDefault()

    let obj = {}
    if (values.name) {
      obj.name = values.name
    }
    if (values.address) {
      obj.address = values.address
    }
    if (values.lat) {
      obj.lat = values.lat
    }
    if (values.lon) {
      obj.lon = values.lon
    }

    if (values.startTime) {
      obj.startTime = values.startTime
    }
    if (values.offTime) {
      obj.offTime = values.offTime
    }
    obj.org = id
    obj.region = selectedRegion._id
    obj.area = selectedArea._id
    obj.teritori = selectedTerritory._id
   console.log(obj);

    dispatch(addOffice(obj, history, authtoken))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Add Office</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Add New Office" breadcrumbItem="Add New Office" />
          <Row>
            <Col md={6} className="m-auto">
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Add Office</CardTitle>

                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-officeName-Input">
                            Office name
                          </Label>
                          <AvField
                            name="name"
                            type="text"
                            className="form-control"
                            id="formrow-officeName-Input"
                            placeholder=" Office Name"
                            errorMessage="Enter Office Name"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-workingDay-Input">
                            Weekly Working Day
                          </Label>
                          <AvField
                            name="workingDay"
                            type="number"
                            className="form-control"
                            id="formrow-workingDay-Input"
                            placeholder="Type Working day"
                            errorMessage="Enter Working day"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-officeName-Input">
                            Address
                          </Label>
                          <AvField
                            name="address"
                            type="text"
                            className="form-control"
                            id="formrow-officeName-Input"
                            placeholder=" Enter Address"
                            errorMessage="Enter Address"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-Lattitude-Input">
                            Lattitude
                          </Label>
                          <AvField
                            name="lat"
                            type="text"
                            className="form-control"
                            id="formrow-Lattitude-Input"
                            placeholder="Enter Lattitude"
                            errorMessage="Enter Lattitude"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-Longitude">Longitude</Label>
                          <AvField
                            name="lon"
                            type="text"
                            className="form-control"
                            id="formrow-Longitude"
                            placeholder="Enter Longitude"
                            errorMessage="Enter Longitude"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="example-time-input">Start Time</Label>
                          <AvField
                            name="startTime"
                            id="example-time-input"
                            className="form-control"
                            type="time"
                            errorMessage="Enter Start Time"
                            validate={{ required: { value: true } }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                      {/* <AvField
                            name="startTime"
                            id="example-time-input"
                            className="form-control"
                            type="date"
                            value={moment(new Date()).format('yyyy-MM-DD')}
                            errorMessage="Enter Start Time"
                            validate={{ required: { value: true } }}
                          ></AvField> */}
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="example-endTime-input">
                            End Time
                          </Label>
                          <AvField
                            name="offTime"
                            id="example-time-input"
                            className="form-control"
                            type="time"
                            errorMessage="Enter End Time"
                            validate={{ required: { value: true } }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                  <div style={{ minWidth: "150px" }} className="me-3 mb-3">
                        <Label htmlFor="example-endTime-input">Region</Label>
                        <Select
                          name="region"
                          classNamePrefix="select2-selection"
                          // disabled={loadingCondition}
                          style={{ minWidth: "100px" }}
                          //   cacheOptions
                          getOptionLabel={e => e.name}
                          getOptionValue={e => e._id}
                          // defaultInputValue={}
                          placeholder={"Select Region"}
                          options={region}
                          onChange={handleRegion}
                        />
                      </div> 
                       {Object.keys(selectedRegion)?.length &&
                      areaList?.length ? (
                        <div
                          style={{ minWidth: "150px" }}
                          className="me-3 mb-3"
                        >
                          <Label htmlFor="example-endTime-input">Area</Label>
                          <Select
                            name="area"
                            classNamePrefix="select2-selection"
                            // disabled={loadingCondition}
                            style={{ minWidth: "100px" }}
                            //   cacheOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            // defaultInputValue={}
                            placeholder={"Select Area"}
                            options={areaList}
                            onChange={handleArea}
                          />
                        </div>
                      ) : null} 
 
                      {Object.keys(selectedArea)?.length &&
                      territoryList.length ? (
                        <div
                          style={{ minWidth: "150px" }}
                          className="me-3 mb-3"
                        >
                            <Label htmlFor="example-endTime-input">Territory</Label>
                          <Select
                            name="teritori"
                            classNamePrefix="select2-selection"
                            // disabled={loadingCondition}
                            style={{ minWidth: "100px" }}
                            //   cacheOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            // defaultInputValue={}
                            placeholder={"Select Territory"}
                            options={territoryList}
                            onChange={handleTerritory}
                          />
                        </div>
                      ) : null} 
                    </Row>

                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Save Office
                      </button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* end row  */}
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default AddOffice
