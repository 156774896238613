import React, { Component, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import classNames from "classnames"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap"


import { Link } from "react-router-dom"
import TotalAmountChart from "./TotalAmountChart";

const TotalAmountUnit = () => {
  const [periodType, setPeriodType] = useState("yearly")
  const [AreaType, setAreatype] = useState("Dhanmondi")
  
 
  return (
    <React.Fragment>
    <Card>
                <CardBody>
                 
                  <div className="d-sm-flex flex-wrap">
                  <h4 className="card-title mb-4">Total Amount and Unit</h4>
                  <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item me-2 ">
                          <Link
                            to="#"
                            className={classNames(
                              { active: AreaType === "Dhanmondi" },
                              "nav-link","bg-dark"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("Dhanmondi")
                            }}
                            id="one_month"
                          >
                            Dhanmondi
                          </Link>{" "}
                        </li>
                        
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link","bg-dark"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly")
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                 <TotalAmountChart/>
                </CardBody>
              </Card>
  
    </React.Fragment>
  )
}

export default TotalAmountUnit
