import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
//Include Both Helper File with needed methods
import { addNewSurvey, getSurveyData, getSurveyShopData, getShopQuesData } from "../../helpers/backend_helper.js"
import {
  addSurveyFail,
  addSurveySuccess,
  getSurveyFail,
  getSurveySuccess,
  getSurveyShopSuccess,
  getSurveyShopFail,
  getSingleShopQuesSuccess,
  getSingleShopQuesFail
} from "./action"

import { ADD_NEW_SURVEY, GET_SURVEY, GET_SURVEY_SHOP, GET_SINGLE_SHOP_QUES } from "./actionTypes"

function* onAddNewSurvey({ payload: { data, history, authtoken } }) {
  console.log("data", data)

  try {
    const response = yield call(addNewSurvey, data, authtoken)

    yield put(addSurveySuccess(response))
    toast("🦄 Survey added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
   history.push("/surveyInfo")
  } catch (error) {
    console.log("Hello line number 51")
    if (!error.response) {
      history.push("/surveyInfo")
    } else {
      let message = error.response.data.message
      // console.log("error message,", error.response.data.message)
      yield put(addSurveyFail(message))
      toast.error(message)
    }
  }
}

function* fetchSurvey({ payload: { authtoken } }) {
  try {
    const response = yield call(getSurveyData, authtoken)
    yield put(getSurveySuccess(response))

    console.log("response", response)
  } catch (error) {
    yield put(getSurveyFail(error))
    console.log("hi")
  }
}
function* fetchSurveyShop({ payload: { authtoken,surveyId,limit,pageNo,startDate, endDate, value } }) {
  try {
    const response = yield call(getSurveyShopData, authtoken,surveyId,limit,pageNo,startDate,endDate, value)
    yield put(getSurveyShopSuccess(response))

    console.log("response", response)
  } catch (error) {
    yield put(getSurveyShopFail(error))
    console.log("hi")
  }
}

function* fetchSingleShopQues({ payload: { authtoken, singleSurvey } }) {
  try {
    const response = yield call(getShopQuesData, authtoken, singleSurvey)
    yield put(getSingleShopQuesSuccess(response))

    console.log("response", response)
  } catch (error) {
    yield put(getSingleShopQuesFail(error))
    console.log("hi")
  }
}
function* SurveyListSaga() {
  yield takeEvery(GET_SURVEY, fetchSurvey)
  yield takeEvery(ADD_NEW_SURVEY, onAddNewSurvey)
  yield takeEvery(GET_SURVEY_SHOP, fetchSurveyShop)
  yield takeEvery(GET_SINGLE_SHOP_QUES, fetchSingleShopQues)
}

export default SurveyListSaga
