import { AvField } from "availity-reactstrap-validation"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import useDebounce from "Hooks/useDebounce"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button, Card, Container, Row, Spinner } from "reactstrap"
import { getAllStore, getStoresOrder } from "../../store/Stores/actions"
import { get } from '../../helpers/api_helper';
import { getStores } from "../../store/Stores/actions"
import routeListDown from "../../assets/images/routeListDown.svg"
import '../../assets/css/style.css'
import moment from "moment"
import XLSX from "xlsx"
const tableHead = [
  "No.",
  "Shop Name",
  "Shop Code",
  "Location",
  "Owner",
  "View",
]
const SampleStoreTable = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(100);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingShopExcel, setLoadingShopExcel] = useState(false);
  const { value, onChange } = useDebounce(
    () => dispatch(getAllStore(authtoken, id, currentPage, value)),
    1000
  )
  const { stores, allStore, authtoken, id, allStoreLoading, storeOrder } = useSelector(state => ({
    stores: state.StoreReducer.stores,
    allStore: state.StoreReducer.allStore,
    storeOrder: state.StoreReducer.storeOrder,
    authtoken: state.Login.token,
    id: state.Login.id,
    allStoreLoading: state.StoreReducer.allStoreLoading,
  }))
  console.log('storeOrder', storeOrder);

  useEffect(() => {
    dispatch(getAllStore(authtoken, id, currentPage, value))
  }, [currentPage])
  useEffect(() => {
    dispatch(getStoresOrder(authtoken, currentPage, pageRange))
  }, [currentPage, pageRange])

  // useEffect(() => {
  //   dispatch(getStores(authtoken, id))
  // }, [])

  // console.log("allStore", allStore)
  // console.log('kkkkkk',stores);
  // const handleExcel = () => {
  //   let newArray = []
  //   stores?.shop?.forEach((shop, index) => {
    
  //     let data = {}
  //     data.name = shop?.name
  //     data.storeType = shop?.storeType
  //     data.ownerName = shop?.ownerName
  //     data.phoneNumber = shop?.phoneNumber
  //     data.address = shop?.address
  //     data.shopCode = shop?.shopCode
  //     data.lat = shop?.lat
  //     data.lon = shop?.lon
 
 

  //     newArray.push(data)
  //   })
  //   downloadxls(newArray)
  // }
  // const downloadxls = data => {
  //   console.log(XLSX.version)
  //   const ws = XLSX.utils.json_to_sheet(data)
  //   const wb = XLSX.utils.book_new()
  //   XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
  //   XLSX.writeFile(wb, "shopData.xlsx")
  // }

  function convertDateToLocal(date){
    // console.log('date', date);
    var local_date= moment.utc(date).local().format('DD-MM-YYYY HH:mm:ss');
    // console.log('local_date');
    return local_date
  }
  const handleExcel = async () => {
    setLoadingExcel(true);
    let shopData = []
    console.log('kkkk');
    let totalPage = Math.ceil(allStore?.data?.pagenumber);
    for (let i = 0; i < totalPage; i++) {
      console.log('hello');
   
   
     await get(`shop/pagination?orgid=${id}&currentpage=${i+1}&shopname=${""}`,{ headers: { Authorization: `Bearer ${authtoken}` } })
     .then(response=>{
       console.log('response',response );
        
        response?.stores?.forEach((shop, index) => {
          let data = {}
          data.ShopName = shop?.name
          data.storeType = shop?.storeType
          data.shopCode  =  shop?.shopCode
          data.routeName  =  shop?.route?.name
          data.routeCode  =  shop?.route?.routeCode
          data.area  = shop?.route?.geo?.area?.name
          data.region  = shop?.route?.geo?.region?.name
          data.territory  = shop?.route?.geo?.teritori?.name
          data.address = shop?.address
          data.CreatedAt = convertDateToLocal(shop?.createdAt)
          data.CreatedBy_Name = shop?.createdBy?.name
          data.CreatedBy_Id = shop?.createdBy?.employeeId
          data.distributionOffice = shop?.office?.name
          data.distributionOfficeCode = shop?.office?.distributioncode
          data.lat = shop?.lat
          data.lon = shop?.lon
          data.ownerName = shop?.ownerName
          data.phoneNumber = shop?.phoneNumber
      
          shop?.route?.users?.forEach((dt, idx) => {
            if(dt?.role==="SR")
           {
            data[`UserName-${idx}`] = dt?.name
            data[`EmployeeId-${idx}`] = dt?.employeeId
          
           }
          
          })


    
        
        
          shopData.push(data)
      
      

        })
      }).catch((error)=>{
        console.log('kkkk');
        // setResults((prev) => [...prev]);
      }
      );

 
    }

  
    downloadxls(shopData)
 
  };

  const downloadxls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "Shop List.xlsx")
    setLoadingExcel(false);
  }
  const handleShopExcel = async () => {
    setLoadingShopExcel(true);
    let shopOrderData = []
    console.log('kkkk');
    let totalPage = Math.ceil(storeOrder?.pageNumber);
    for (let i = 0; i < totalPage; i++) {
      console.log('hello');
   
   
     await get(`shop/shop-preorder?pageNo=${i+1}&limit=${100}`,{ headers: { Authorization: `Bearer ${authtoken}` } })
     .then(response=>{
       console.log('response',response );
        
        response?.shoporder?.forEach((shop, index) => {
          let data = {}
          data.storeName = shop?.shop?.name
          data.storeType = shop?.shop?.storeType
          data.ownerName = shop?.shop?.ownerName
          data.address = shop?.shop?.address
          data.route = shop?.route?.name
          data.routeCode = shop?.route?.routeCode
          data.preOrder = shop?.preorder
          
    
        
        
          shopOrderData.push(data)
      
      

        })
      }).catch((error)=>{
        console.log('kkkk');
        // setResults((prev) => [...prev]);
      }
      );

 
    }

  
    downloadxls2(shopOrderData)
 
  };

  const downloadxls2 = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "Shop Order List.xlsx")
    setLoadingShopExcel(false);
  }
  return (
    <React.Fragment>
      
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <CardComponent>
            <div className="attendance-date">
              <AvForm>
                <AvField
                  name="startTime"
                  id="example-time-input"
                  className="form-control mb-3"
                  type="search"
                  placeholder="Search Shop Name"
                  defaultValue={value}
                  onChange={onChange}
                ></AvField>
              </AvForm>
            </div>
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={allStore}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                page={allStore?.data?.pagenumber}
                currentPage={currentPage}
                isPagination
                productLoading={allStoreLoading}
              >
                {allStoreLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner />
                      </div>
                    </div>
                  </tr>
                ) : allStore?.data?.stores?.length ? (
                  allStore?.data?.stores?.map((store, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td>{store.name}</td>
                        <td>{store.shopCode}</td>
                        <td>{store.address}</td>
                        <td>{store.ownerName}</td>
                        {/* <td>{store.phoneNumber}</td> */}
                        <td>
                          <Button
                            color="primary"
                            className="btn-sm btn-rounded"
                            onClick={() => {
                              history.push(`/store-analytics/${store._id}`)
                            }}
                          >
                            View Details
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>Currently you have no Data. </span>
                  </NoTableData>
                )}
              </CustomTable>

              {/* table end */}
            </Row>
          </CardComponent>
        </Container>
        <Card>
        {/* <div className="route-excel-attendance">
         <div className="route-excel">
              <div>
                <p className="mb-0">Download Excelsheet</p>
              </div>
              <div>
                <button className="route-excel-download" >
                  {" "}
                  <img src={routeListDown}></img>Download
                </button>
              </div>
            </div>
          
         </div> */}
          <button className="btn btn-info w-25 m-auto fw-bold mt-3"  disabled={loadingExcel} onClick={handleExcel}>{loadingExcel ? "Downloading..." : "Download All Shop List (Excel)"}</button>
          <button className="btn btn-info w-25 m-auto fw-bold mt-3 mb-3"  disabled={loadingShopExcel} onClick={handleShopExcel}>{loadingShopExcel ? "Downloading..." : "Download Shop List With Order (Excel)"}</button>
        </Card>
  
    </React.Fragment>
  )
}

export default SampleStoreTable
