import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
import { Row, Col, Alert, Card, CardBody, Container, Spinner } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useHistory, Link } from "react-router-dom"
import { updatePassword } from "store/auth/updatePassword/actions"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import "react-toastify/dist/ReactToastify.css"

import { ToastContainer, toast } from "react-toastify"

// import images
import profile from "../../assets/images/profile-img.png"
toast.configure()

const UpdatePasswordPage = props => {
  const dispatch = useDispatch()
  let history = useHistory()

  const { loading } = useSelector(state => ({
    loading: state.updatePass.loading,
  }))
  //check email
  // const validateEmail = email => {
  //   const re =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //   return re.test(String(email).toLowerCase())
  // }
  function handleValidSubmit(event, values) {
    const email = new URLSearchParams(location.search).get("email")
    const accountActivateToken = new URLSearchParams(location.search).get(
      "token"
    )
    console.log("email data", accountActivateToken)
    console.log(values)
    const { password, confirmPassword } = values
    if (password.length < 6) {
      toast.error("Password is too short")
    } else if (password !== confirmPassword) {
      toast.error("Password are not matched")
    } else if (!email || !accountActivateToken) {
      toast.error("Link is not valid")
    } else {
      values.email = email
      values.accountActivateToken = accountActivateToken
      console.log("from frontedn", history)
      dispatch(updatePassword(values, history))
    }

    //    if(!validateEmail(email)){
    //     setCheckError("Email is not valid")
    //     return;
    //   }else{
    //   dispatch(loginUser(values, history))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Update Password</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <ToastContainer />

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <h3>Update Password</h3>
                      <br />

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="New Password"
                          className="form-control"
                          placeholder="Enter Your Password"
                          type="password"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="confirmPassword"
                          label="Confirm Password"
                          className="form-control"
                          placeholder="Please Confirm Your Password"
                          type="password"
                          required
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <div className="mt-3 d-grid">
                            {loading ? (
                              <h5 className="font-size-14 mb-3 text-center">
                                <Spinner />
                              </h5>
                            ) : (
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Update Password
                              </button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpdatePasswordPage
