import React, { Component, useEffect, useState } from "react"

import classNames from "classnames"
import {  
  Card,
  CardBody,
} from "reactstrap"


import { Link } from "react-router-dom"
import AvgInvoiceValueChart from "./AvgInvoiceValueChart"




const AvgIncomeValue = () => {

  return (
    <React.Fragment>
      <Card >
      
        <CardBody>

          <AvgInvoiceValueChart />
        </CardBody>
      </Card>

    </React.Fragment>
  )
}

export default AvgIncomeValue
