import Breadcrumbs from "components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
import "../../assets/css/style.css"
import { getProducts } from "../../store/Product/actions"
import "./datatables.scss"

const ProductDataTable = ({ history }) => {
  const dispatch = useDispatch()

  const { products, authtoken, productLoading, id } = useSelector(state => ({
    products: state.ProductReducer.products,
    id: state.Login.id,
    authtoken: state.Login.token,
    productLoading: state.ProductReducer.productLoading,
  }))
  // console.log('tabledata', products.data);

  useEffect(() => {
    dispatch(getProducts(authtoken, id))
  }, [])
  console.log(products)

  const tableData =
    productLoading &&
    products?.data?.product.map((product, index) => {
      return {
        sl: index + 1,
        heading1: product?.name,
        heading2: product?.code,
        heading3: product?.category?.name,
        heading4: product?.brand?.name,
        heading5: product?.unitType?.name,
        heading6: product?.unitPrice,
        manage: (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded product-details-btn"
              onClick={() => {
                history.push(`/product-analytic/${product?._id}`)
              }}
            >
              View Details
            </Button>
          </>
        ),
        status: (
          <>
            <Badge className={"bg-success"}>Active</Badge>
          </>
        ),
        action: (
          <>
            <Link to={`/editProduct/${product._id}`} className="text-success">
              <i
                className="mdi mdi-pencil font-size-18 text-center"
                id="edittooltip"
              />
            </Link>
          </>
        ),
      }
    })

  const productData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },
      {
        label: "name",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "code",
        field: "heading2",
        sort: "asc",
        width: 150,
      },

      {
        label: "Category",
        field: "heading3",
        sort: "asc",
        width: 150,
      },
      {
        label: "Brand",
        field: "heading4",
        sort: "asc",
        width: 150,
      },
      {
        label: "Unit",
        field: "heading5",
        sort: "asc",
        width: 150,
      },
      {
        label: "Price",
        field: "heading6",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
      },

      {
        label: "View",
        field: "manage",
      },
      {
        label: "action",
        field: "action",
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <MetaTags>
          <title>Product | DDC</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Product" breadcrumbItem="Product details" />
          {productLoading ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={productData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="text-center mt-5 pt-5">
              <Spinner />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

ProductDataTable.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ProductDataTable)

// import { AvField, AvForm } from "availity-reactstrap-validation";
// //Import Breadcrumb
// import Breadcrumbs from "components/Common/Breadcrumb";
// import { isEmpty, map } from "lodash";
// import React, { useEffect, useState } from "react";
// import MetaTags from "react-meta-tags";
// //redux
// import { useDispatch, useSelector } from "react-redux";
// import { Link, withRouter,useHistory } from "react-router-dom";

// import {
//     Badge, Button, Col,
//     Container,
//     DropdownItem,
//     DropdownMenu,
//     DropdownToggle, Modal, ModalBody, ModalHeader, Row, Spinner, Table, UncontrolledDropdown
// } from "reactstrap";
// import { getProducts } from '../../store/Product/actions';

// const ProductDataTable = props => {

//     const dispatch = useDispatch();
//     const history = useHistory();

//     const { products, authtoken, loading,id } = useSelector(state => ({
//         products: state.ProductReducer.products,
//         id: state.Login.id,
//         authtoken: state.Login.token,
//         loading: state.ProductReducer.loading
//     }));
//     // console.log('tabledata', products.data);
//     console.log(products);

//     useEffect(() => {
//         dispatch(getProducts(authtoken,id));
//     }, []);

//     return (
//         <React.Fragment>
//             <div >
//                 <MetaTags>
//                     <title>
//                         Product List | DDC
//                     </title>
//                 </MetaTags>
//                 <Container fluid>
//                     {/* Render Breadcrumbs */}
//                     <Breadcrumbs title="Product" breadcrumbItem="Product List" />
//                     {loading ? (
//                         <div className="text-center mt-5 pt-5">
//                             <Spinner />
//                         </div>
//                     ) : (

//                         <Row >
//                             <Col lg="12">
//                                 <div className="">
//                                     <div className="table-responsive">
//                                         <Table className="project-list-table table-nowrap align-middle table-borderless">
//                                             <thead>
//                                                 <tr>

//                                                     <th scope="col">Product Name</th>
//                                                     <th scope="col">Product Code</th>
//                                                     <th scope="col">Category</th>
//                                                     <th scope="col">Brand</th>
//                                                     <th scope="col">Unit</th>
//                                                     <th scope="col">Price</th>
//                                                     <th scope="col">Status</th>
//                                                     <th scope="col">Action</th>
//                                                     <th scope="col">View Details</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {map(products.data.product, (product, index) => (
//                                                     <tr key={index}>

//                                                         <td>
//                                                             <p className="text-muted mb-0">{product.name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{product.code}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{product.category.name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{product.brand.name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{product.unitType.name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{product.unitPrice}</p>
//                                                         </td>

//                                                         <td>
//                                                             <Badge className={"bg-success"}>
//                                                                 Active
//                                                             </Badge>
//                                                         </td>

//                                                         <td>
//                                                             <UncontrolledDropdown>
//                                                                 <DropdownToggle
//                                                                     href="#"
//                                                                     className="card-drop"
//                                                                     tag="i"
//                                                                 >
//                                                                     <i className="mdi mdi-dots-horizontal font-size-18" />
//                                                                 </DropdownToggle>
//                                                                 <DropdownMenu className="dropdown-menu-end">
//                                                                     <DropdownItem
//                                                                         href="#"
//                                                                     // onClick={() => handleProductData(user)}
//                                                                     >
//                                                                         <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
//                                                                         Edit
//                                                                     </DropdownItem>
//                                                                     <DropdownItem
//                                                                         href="#"
//                                                                     // onClick={() => handleDeleteProject(user)}
//                                                                     >
//                                                                         <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
//                                                                         Delete
//                                                                     </DropdownItem>
//                                                                 </DropdownMenu>
//                                                             </UncontrolledDropdown>
//                                                         </td>
//                                                         <td>
//                                                         <Button
//                                                             type="button"
//                                                             color="primary"
//                                                             className="btn-sm btn-rounded"
//                                                             onClick={() => {
//                                                                 history.push(`/product-analytic/${product._id}`)
//                                                             }}

//                                                         >
//                                                               View Details
//                                                         </Button>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </Table>
//                                         {/* <Modal isOpen={modal} toggle={toggle}>
//                                         <ModalHeader toggle={toggle} tag="h4">
//                                             {!!isEdit ? "Edit Project" : "Add Project"}
//                                         </ModalHeader>
//                                         <ModalBody>
//                                             <AvForm onValidSubmit={handleValidProductSubmit}>
//                                                 <Row form>
//                                                     <Col xs={12}>

//                                                         <div className="mb-3">
//                                                             <AvField
//                                                                 name="name"
//                                                                 label="Name"
//                                                                 type="text"
//                                                                 errorMessage="Invalid Email"
//                                                                 validate={{
//                                                                     required: { value: true },
//                                                                 }}
//                                                                 value={productList.name || ""}
//                                                             />
//                                                         </div>

//                                                         <div className="mb-3">
//                                                             <AvField
//                                                                 name="email"
//                                                                 label="Email"
//                                                                 type="text"
//                                                                 errorMessage="Invalid Email"
//                                                                 validate={{
//                                                                     required: { value: true },
//                                                                 }}
//                                                                 value={productList.email || ""}
//                                                             />
//                                                         </div>
//                                                         <div className="mb-3">
//                                                             <AvField
//                                                                 name="role"
//                                                                 label="Role"
//                                                                 type="text"
//                                                                 errorMessage="Invalid Email"
//                                                                 validate={{
//                                                                     required: { value: true },
//                                                                 }}
//                                                                 value={productList.role || ""}
//                                                             />
//                                                         </div>

//                                                         <div className="mb-3">
//                                                             <AvField
//                                                                 name="status"
//                                                                 id="status1"
//                                                                 label="Status"
//                                                                 type="select"
//                                                                 errorMessage="Invalid Status"
//                                                                 validate={{
//                                                                     required: { value: true },
//                                                                 }}
//                                                                 className="form-select"
//                                                                 value={productList.status || "Completed"}
//                                                             >
//                                                                 <option>Active</option>
//                                                                 <option>Deactive</option>

//                                                             </AvField>
//                                                         </div>

//                                                         <div className="mb-3">
//                                                             <AvField
//                                                                 name="color"
//                                                                 label="Color"
//                                                                 type="select"
//                                                                 className="form-select"
//                                                                 errorMessage="Invalid Color"
//                                                                 validate={{
//                                                                     required: { value: true },
//                                                                 }}
//                                                                 value={productList.color || "success"}
//                                                             >
//                                                                 <option>success</option>

//                                                                 <option>danger</option>
//                                                             </AvField>
//                                                         </div>

//                                                     </Col>
//                                                 </Row>
//                                                 <Row>
//                                                     <Col>
//                                                         <div className="text-end">
//                                                             <button
//                                                                 type="submit"
//                                                                 className="btn btn-success save-user"
//                                                             >
//                                                                 Save
//                                                             </button>
//                                                         </div>
//                                                     </Col>
//                                                 </Row>
//                                             </AvForm>
//                                         </ModalBody>
//                                     </Modal> */}

//                                     </div>
//                                 </div>
//                             </Col>

//                         </Row>
//                     )}
//                 </Container>
//             </div>

//         </React.Fragment >
//     );
// };

// export default withRouter(ProductDataTable);

// import { AvField, AvForm } from "availity-reactstrap-validation";
//Import Breadcrumb
