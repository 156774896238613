// MetisMenu
import PropTypes from "prop-types"
import React, { useRef } from "react"
import { Dropdown } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link, NavLink, useRouteMatch, withRouter } from "react-router-dom"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
import "../../assets/css/style.css"
import activityImage from "../../assets/images/activityShape.svg"
import attendanceImage2 from "../../assets/images/attendance2.svg"
import overViewImg from "../../assets/images/chart.svg"
import damageReconcile from "../../assets/images/damageReconcile.svg"
import dashboardImage from "../../assets/images/home.svg"
import inventoryImage from "../../assets/images/inventoryImage.svg"
import geoImage from "../../assets/images/location.svg"
import pjpImage from "../../assets/images/map.svg"
import offerImage from "../../assets/images/offerImage.svg"
import officeImage from "../../assets/images/officeAdd.svg"
import productImg from "../../assets/images/product.svg"
import shopImg from "../../assets/images/shop.svg"
import targetImg from "../../assets/images/status-up.svg"
import orderImage from "../../assets/images/task-square.svg"
import userImage from "../../assets/images/userDashboardImg.svg"
const SidebarContent = props => {
  const addInventoryRoute = useRouteMatch("/addInventory/:productId")
  const dpActivityToday = useRouteMatch("/activityToday/:dpId")
  const historyProductList = useRouteMatch("/DateWiseProductList/:dataInfo")
  const DateWiseDpProductList = useRouteMatch(
    "/DateWiseDpProductList/:dpId/:specificDate"
  )
  const activityProductList = useRouteMatch("/product-info/:productID")
  const dateProductList = useRouteMatch("/history-product-list/:productID")
  const dateOrder = useRouteMatch("/Route-order/:routeID")
  const shopOrder = useRouteMatch("/date-order/:dateID/:routeID")
  const invoice = useRouteMatch("/invoice/:invoiceID")
  const editedInvoice = useRouteMatch("/edited-invoice/:invoiceID")
  const addOfferItem = useRouteMatch("/add-offer-item/:offerid")
  const targetSet = useRouteMatch("/target-set/:userID/:userName")
  const sRtargetSet = useRouteMatch("/sr-target-info/:userID/:userName")
  const productAnalytic = useRouteMatch("/product-analytic/:productID")
  const storeAnalytic = useRouteMatch("/store-analytics/:storeID")
  const specificRoute = useRouteMatch("/table-route-geo/:geoID")
  const routeDetails = useRouteMatch("/route-details/:routeID")
  const officeTime = useRouteMatch("/office-time/:officeid")
  const singleOfficeInventory = useRouteMatch(
    "/inventory-info/:officeId/:officeName"
  )
  const dayOpeningActivity = useRouteMatch("/dayOpening/:dpId")
  const editUser = useRouteMatch("/edituser/:id")

  const { userrole } = useSelector(state => ({
    userrole: state.Login.userrole,
  }))
  console.log(userrole)
  const ref = useRef()

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            {userrole == "ADMIN" && (
              <li>
                <Link to="/dashboard" className="">
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
            )}
            {userrole == "ORG" && (
              <li>
                <Link
                  to="/orgdashboard"
                  className={
                    props.location.pathname === "/orgdashboard"
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={dashboardImage} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Dashboard")}</span>
                </Link>
              </li>
            )}
            {/* {userrole == "DISTRIBUTOR" && (
              <li>
                <Link
                  to="/distributorDashboard"
                  className={
                    props.location.pathname === "/distributorDashboard"
                      ? "mm-active"
                      : ""
                  }
                >
                  <i className="bx bxs-dashboard"></i>
                  <span>{props.t("Distributor Dashboard")}</span>
                </Link>
              </li>
            )} */}
            {userrole == "ADMIN" && (
              <li>
                <Link to="/adminUserDetails" className="">
                  <i className="bx bx-user-circle" />

                  <span>{props.t("Admin User")}</span>
                </Link>
              </li>
            )}

            {userrole == "ORG" && (
              <li>
                <Link
                  to="/user-details"
                  className={
                    props.location.pathname === "/user-details" ||
                    props.location.pathname === "/adduser" ||
                    editUser?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={userImage} className="overview-img"></img>
                  <span className="overview-tag">{props.t("User")}</span>
                </Link>
              </li>
            )}
            {userrole == "ORG" && (
              <li>
                <Link
                  to="/attendance"
                  className={
                    props.location.pathname === "/attendance" ? "mm-active" : ""
                  }
                >
                  <img src={attendanceImage2} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Attendance")}</span>
                </Link>
              </li>
            )}

            {userrole == "ORG" && (
              <li>
                <span className="geo-info-list">
                  <span>
                    <img src={productImg}></img>
                    {/* <i className="bx bxs-dashboard" /> */}
                  </span>
                  <Dropdown className="">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="geo-dropdown"
                    >
                      <span
                        className={
                          props.location.pathname === "/add-product" ||
                          props.location.pathname === "/product-add-analytic" ||
                          props.location.pathname === "/category-add" ||
                          props.location.pathname === "/unit-add" ||
                          props.location.pathname === "/details-product" ||
                          props.location.pathname === "/category-list" ||
                          props.location.pathname === "/unit-list" ||
                          productAnalytic?.isExact === true
                            ? "mm-active"
                            : ""
                        }
                      >
                        Product
                      </span>
                    </Dropdown.Toggle>
                    <div>
                      <Dropdown.Menu className="area-dropdown-list">
                        <NavLink
                          to="/details-product"
                          activeClassName="admin-navlink"
                          className={
                            props.location.pathname === "/details-product"
                              ? "mm-active"
                              : ""
                          }
                        >
                          <i className="bx bxs-dashboard"></i>Product Details
                        </NavLink>{" "}
                        <NavLink
                          to="/category-list"
                          activeClassName="admin-navlink"
                          className={
                            props.location.pathname === "/category-list"
                              ? "mm-active"
                              : ""
                          }
                        >
                          <i className="bx bxs-dashboard"></i> Category
                        </NavLink>{" "}
                        <NavLink
                          to="/unit-list"
                          activeClassName="admin-navlink"
                          className={
                            props.location.pathname === "/unit-list"
                              ? "mm-active"
                              : ""
                          }
                        >
                          <i className="bx bxs-dashboard"></i>Unit
                        </NavLink>{" "}
                      </Dropdown.Menu>
                    </div>
                  </Dropdown>
                </span>
              </li>
            )}

            {userrole == "ORG" && (
              <li>
                <Link
                  to="/store-info"
                  className={
                    props.location.pathname === "/store-info" ||
                    props.location.pathname === "/add-store" ||
                    props.location.pathname === "/store-add-analytics" ||
                    storeAnalytic?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
                  {/* <i className="bx bx-store" /> */}
                  <img src={shopImg} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Store")}</span>
                </Link>
              </li>
            )}
            {userrole == "ORG" && (
              <li>
                <Link
                  to="/brand"
                  className={
                    props.location.pathname === "/overview" ||
                    props.location.pathname === "/brand" ||
                    props.location.pathname === "/brand-add" ||
                    props.location.pathname === "/brand-add-analytic" ||
                    props.location.pathname === "/brand-info-data"
                      ? "mm-active"
                      : ""
                  }
                >
                  {/* <i className="bx bx-store" /> */}
                  <img src={overViewImg} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Brand")}</span>
                </Link>
              </li>
            )}

            {userrole == "ORG" && (
              <li>
                <span className="geo-info-list">
                  <span>
                    <img src={geoImage}></img>
                  </span>
                  <Dropdown className="">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="geo-dropdown"
                    >
                      <span
                        className={
                          props.location.pathname === "/geo-info" ||
                          props.location.pathname === "/area-list" ||
                          props.location.pathname === "/add-area" ||
                          props.location.pathname === "/region-list" ||
                          props.location.pathname === "/add-region" ||
                          props.location.pathname === "/territory-list" ||
                          props.location.pathname === "/add-territory" ||
                          props.location.pathname === "/add-geo" ||
                          props.location.pathname === "/details-geo" ||
                          specificRoute?.isExact === true
                            ? "mm-active"
                            : ""
                        }
                      >
                        Geo Info
                      </span>
                    </Dropdown.Toggle>
                    <div>
                      <Dropdown.Menu className="area-dropdown-list">
                        <NavLink
                          to="/region-list"
                          activeClassName="admin-navlink"
                          className={
                            props.location.pathname === "/region-list"
                              ? "mm-active"
                              : ""
                          }
                        >
                          <i className="bx bx-map"></i> Region
                        </NavLink>{" "}
                        <NavLink
                          to="/area-list"
                          activeClassName="admin-navlink"
                          className={
                            props.location.pathname === "/area-list"
                              ? "mm-active"
                              : ""
                          }
                        >
                          <i className="bx bx-map"></i> Area
                        </NavLink>{" "}
                        <NavLink
                          to="/territory-list"
                          activeClassName="admin-navlink"
                          className={
                            props.location.pathname === "/territory-list"
                              ? "mm-active"
                              : ""
                          }
                        >
                          <i className="bx bx-map"></i> Territory
                        </NavLink>{" "}
                        <NavLink
                          to="/details-geo"
                          activeClassName="admin-navlink"
                          className={
                            props.location.pathname === "/details-geo"
                              ? "mm-active"
                              : ""
                          }
                        >
                          <i className="bx bx-map"></i>Geo Details
                        </NavLink>{" "}
                      </Dropdown.Menu>
                    </div>
                  </Dropdown>
                </span>
              </li>
            )}

            {userrole == "ORG" && (
              <li>
                <Link
                  to="/route-list"
                  className={
                    props.location.pathname === "/route-list" ||
                    props.location.pathname === "/add-route" ||
                    routeDetails?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
                  <img className="overview-img" src={geoImage}></img>
                  <span className="overview-tag">
                    {props.t("Route Details")}
                  </span>
                </Link>
              </li>
            )}

            {userrole == "ORG" && (
              <li>
                <Link
                  to="/info-office"
                  className={
                    props.location.pathname === "/info-office" ||
                    props.location.pathname === "/add-office" ||
                    officeTime?.isExact === true ||
                    singleOfficeInventory?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={officeImage} className="overview-img"></img>
                  <span className="overview-tag">
                    {props.t("Distribution Office")}
                  </span>
                </Link>
              </li>
            )}

            {userrole == "ORG" && (
              <li>
                <Link
                  to="/Order-List"
                  className={
                    props.location.pathname === "/Order-List" ||
                    props.location.pathname === "/analytic-order" ||
                    dateOrder?.isExact === true ||
                    shopOrder?.isExact === true ||
                    invoice?.isExact === true ||
                    editedInvoice?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={orderImage} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Order")}</span>
                </Link>
              </li>
            )}
            {userrole == "ORG" && (
              <li>
                <Link
                  to="/sales"
                  className={
                    props.location.pathname === "/sales" ? "mm-active" : ""
                  }
                >
                  <img src={orderImage} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Sales")}</span>
                </Link>
              </li>
            )}
            {userrole == "ORG" && (
              <li>
                <Link
                  to="/details-offer"
                  className={
                    props.location.pathname === "/details-offer" ||
                    props.location.pathname === "/create-offer" ||
                    addOfferItem?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={offerImage} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Offer")}</span>
                </Link>
              </li>
            )}
            {/* {userrole == "ORG" && (
              <li>
                <Link
                  to="/target"
                  className={
                    props.location.pathname === "/target" ||
                    targetSet?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
               
                  <img src={targetImg} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Target")}</span>
                </Link>
              </li>
            )} */}
            {/* {userrole == "ORG" && (
              <li>
                <Link
                  to="/target"
                  className={
                    props.location.pathname === "/target" ||
                    targetSet?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
               
                  <img src={targetImg} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Target")}</span>
                </Link>
              </li>
            )} */}
            {userrole == "ORG" && (
              <li>
                <Link
                  to="/target"
                  className={
                    props.location.pathname === "/target" ||
                    targetSet?.isExact === true ||
                    sRtargetSet?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
                  {/* <i className="bx bxs-dashboard"></i> */}
                  <img src={targetImg} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Target")}</span>
                </Link>
              </li>
            )}
            {userrole == "ORG" && (
              <li>
                <Link
                  to="/pjp"
                  className={
                    props.location.pathname === "/pjp" ||
                    props.location.pathname === "/pjp-create" ||
                    props.location.pathname === "/pjp-analytic"
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={pjpImage} className="overview-img"></img>
                  <span className="overview-tag">{props.t("PJP")}</span>
                </Link>
              </li>
            )}
            {/* {userrole == "ORG" && (
              <li>
                <Link
                  to="/user-list-performance"
                  className={
                    props.location.pathname === "/user-list-performance"
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={performanceImage} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Performance")}</span>
                </Link>
              </li>
            )} */}
            {/* {userrole == "ORG" && (
              <li>
                <Link to="/inventoryMain" className="">
                  <i className="bx bx-store" />
                  <span>{props.t("Inventory")}</span>
                </Link>

              </li>


            )} */}
            {/* {userrole == "ORG" && (
              <li>
                <Link to="/activityProductList" className="">
                  <i className="bx bx-store" />
                  <span>{props.t("Today Activity")}</span>
                </Link>

              </li>


            )} */}
            {/* {userrole == "ORG" && (
              <li>
                <Link to="/activityHistory" className="">
                  <i className="bx bx-store" />
                  <span>{props.t("History")}</span>
                </Link>

              </li>


            )} */}
            {/* 
            {userrole == "DISTRIBUTOR" && (
              <li>
                <Link
                  to="/activityHistory"
                  className={
                    props.location.pathname === "/activityHistory" ||
                    historyProductList?.isExact === true ||
                    DateWiseDpProductList?.isExact === true ||
                    dateProductList?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
                  <i className="bx bx-store" />
                  <span>{props.t("History")}</span>
                </Link>
              </li>
            )} */}
            {userrole == "ORG" && (
              <li>
                <Link
                  to="/surveyInfo"
                  className={
                    props.location.pathname === "/surveyInfo" ||
                    targetSet?.isExact === true ||
                    sRtargetSet?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
                  {/* <i className="bx bxs-dashboard"></i> */}
                  <img src={targetImg} className="overview-img"></img>
                  <span className="overview-tag">
                    {props.t("Survey Activity")}
                  </span>
                </Link>
              </li>
            )}
            {userrole == "DISTRIBUTOR" && (
              <li>
                <Link
                  to="/activityProductList"
                  className={
                    props.location.pathname === "/activityProductList" ||
                    dpActivityToday?.isExact === true ||
                    activityProductList?.isExact === true ||
                    dayOpeningActivity?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={activityImage} className="overview-img"></img>
                  <span className="overview-tag">
                    {props.t("Today Activity")}
                  </span>
                </Link>
              </li>
            )}
            {userrole == "DISTRIBUTOR" && (
              <li>
                <Link
                  to="/inventoryMain"
                  className={
                    props.location.pathname === "/inventoryMain" ||
                    props.location.pathname === "/addProductInventory" ||
                    addInventoryRoute?.isExact === true
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={inventoryImage} className="overview-img"></img>
                  <span className="overview-tag">{props.t("Inventory")}</span>
                </Link>
              </li>
            )}
            {userrole == "DISTRIBUTOR" && (
              <li>
                <Link
                  to="/damage-inventory-main"
                  className={
                    props.location.pathname === "/damage-inventory-main"
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={damageReconcile} className="overview-img"></img>
                  <span className="overview-tag">
                    {props.t("Damage Inventory")}
                  </span>
                </Link>
              </li>
            )}
            {/* {userrole == "DISTRIBUTOR" && (
              <li>
                <Link
                  to="/damage-products"
                  className={
                    props.location.pathname === "/damage-products"
                      ? "mm-active"
                      : ""
                  }
                >
                  <i className="bx bx-store" />
                  <span>{props.t("Damage Products")}</span>
                </Link>
              </li>
            )}
                  {userrole == "DISTRIBUTOR" && (
              <li>
                <Link
                  to="/distDateOrder"
                  className={
                    props.location.pathname === "/distDateOrder"
                      ? "mm-active"
                      : ""
                  }
                >
                  <i className="bx bx-store" />
                  <span>{props.t("Order")}</span>
                </Link>
              </li>
            )} */}
            {userrole == "DISTRIBUTOR" && (
              <li>
                <Link
                  to="/damage-activity"
                  className={
                    props.location.pathname === "/damage-activity"
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={damageReconcile} className="overview-img"></img>
                  <span className="overview-tag">
                    {props.t("Damage Activity")}
                  </span>
                </Link>
              </li>
            )}
            {userrole == "DISTRIBUTOR" && (
              <li>
                <Link
                  to="/damage-reconciliation"
                  className={
                    props.location.pathname === "/damage-reconciliation"
                      ? "mm-active"
                      : ""
                  }
                >
                  <img src={damageReconcile} className="overview-img"></img>
                  <span className="overview-tag">
                    {props.t("Damage Reconciliation")}
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarContent))
