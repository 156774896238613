import React from "react"

import PropTypes from "prop-types"
import { Card, CardBody, Row } from "reactstrap"
import storeImage from "../../assets/images/store.png"

const StoreOwner = props => {
  // console.log('props',props);
  // const {
  //   params: { storeID },
  // } = useRouteMatch('/store-analytics/:storeID');
  // console.log('hello', storeID);

  // const dispatch = useDispatch();

  //   const {  authtoken,store,loading} = useSelector(state => ({

  //     store: state.StoreReducer.store.data,
  //     id: state.Login.id,
  //     authtoken: state.Login.token,
  //     loading: state.StoreReducer.loading,

  // }));
  // useEffect(() => {
  //   dispatch(getStore(authtoken,storeID));
  // }, []);
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <h5>Owner</h5>
            <div className="text-lg-center ms-auto">
              <img
                src={storeImage}
                className="avatar-sm float-start float-lg-none rounded-circle mb-2"
                alt="img"
              />

              <h4 className="mb-1 font-size-15 text-truncate">
                {props?.store?.singleshop?.ownerName}
              </h4>
              <p> {props?.store?.singleshop?.phoneNumber}</p>
            </div>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

StoreOwner.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  validationType: PropTypes.object,
  rest: PropTypes.object,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  store: PropTypes.object,
}

export default StoreOwner
