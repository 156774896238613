//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import React from "react"

import MetaTags from "react-meta-tags"
//redux
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useHistory, withRouter } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"

const RouteData = props => {
  console.log("ddd", props)

  const history = useHistory()
  const dispatch = useDispatch()

  const tableData =
    props?.routeloading &&
    props?.routes.data.route.map((route, index) => {
      return {
        sl: index + 1,
        heading1: route.routeCode,
        heading2: route.name,
        manage: (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                history.push(`/route-details/${route._id}`)
              }}
            >
              View Details
            </Button>
          </>
        ),
      }
    })

  const productData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },
      {
        label: "Route Code",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "heading2",
        sort: "asc",
        width: 150,
      },
      {
        label: "View Details",
        field: "manage",
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <MetaTags>
          <title>Route | DDC</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Route" breadcrumbItem="Route details" />
          {props?.routeloading ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={productData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="text-center mt-5 pt-5">
              <Spinner />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

RouteData.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  validationType: PropTypes.object,
  rest: PropTypes.object,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  options: PropTypes.array,
  error: PropTypes.string,
  touched: PropTypes.bool,
  labelCol: PropTypes.object,
  wrapperCol: PropTypes.object,
  meta: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  routeloading: PropTypes.bool,
  routes: PropTypes.object,
}

export default withRouter(RouteData)
