import React from "react"
import ReactApexChart from "react-apexcharts"

const BrandTotalAmountChart = () => {
  const series = [
    {
      name: "Amount",
      data: [34, 40, 28, 52, 42, 109, 100,12,32,13,76,43],
    },
    {
      name: "Quantity",
      data: [32, 88, 34, 46, 34, 52, 41,89,32,14,68,12],
    },
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#556ee6", "#34c38f"],
    xaxis: {
      type: "year",
      categories: [
        "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
    />
  )
}

export default BrandTotalAmountChart
