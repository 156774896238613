import React, { useEffect, useState } from "react"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import DashboardFilter from "components/Dashboard/DashboardFilter"
import { tableQuery } from "helpers/Custom/makeQuery"
import { Link, useHistory } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, Container, Row, Spinner } from "reactstrap"
import { getProductData, storeProductData } from "store/ProductTable/action"
import XLSX from "xlsx"
import routeListDown from "../../assets/images/routeListDown.svg"
import deliveryImage from "../../assets/images/Delivergreen.svg"
import pendingImage from "../../assets/images/pending.svg"
import cancelImage from "../../assets/images/cancelReconciled.svg"
import { getDeliveryOrderExcel } from "../../store/Order/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"


import moment from "moment"
import Select from "react-select"
import {
  getDamageReconciliation,
  storeReconciledData,
  addDamageReconciliation
} from "../../store/Inventory/actions"
const tableHead = [
  "No.",
  "Shop List",
  "Route List",
  "Dp select",
  "Status",
  "Details",
  "Action",
]

const orderTableHead = ["No.", "Product List", "Total Qty"]

const DamageReconciliation = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedProduct, setSelectedProduct] = useState([])

  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  )

  const { damageReconciliation, damageReconciliationLoading, authtoken } =
    useSelector(state => ({
      damageReconciliation: state?.InventoryReducer?.damageReconciliation,
      damageReconciliationLoading:
        state?.InventoryReducer?.damageReconciliationLoading,

      authtoken: state.Login.token,
    }))
  useEffect(() => {
    dispatch(getDamageReconciliation(authtoken, currentDate))
  }, [currentDate])

  console.log("damageReconciliation", damageReconciliation)

  const handleDetails = (index, selectedItem) => {
    setSelectedProduct(selectedItem.orderProducts)
    const oldData = [...damageReconciliation?.data?.reconciled]

    oldData?.map((data, i) => {
      if (i === index) {
        data.view = !data.view
      } else {
        data.view = false
      }
      return data
    })
    dispatch(storeReconciledData("data", { sales: oldData, pagenumber: 1 }))
  }
  const handleChangeDate = e => {
    const value = convertDigitIn(e.target.value)

    setCurrentDate(value)
  }
  function convertDigitIn(str) {
    console.log("str", str.split("-").reverse().join("-"))
    return str.split("-").reverse().join("-")
  }
  const handleDamageReconciled = data => {
    console.log("handleDamageActivity", data)

    let obj = {}
    obj._id = data._id
    obj.damageproductid = data?.damageproductid
    obj.org = data.org
    obj.shop = data?.shop?._id
    obj.office = data?.office
    obj.route = data?.route?._id
    obj.area = data?.area
    obj.territory = data?.territory
    obj.region = data?.region
    obj.geo = data?.geo
    obj.dp = data?.dp?._id
    obj.returnDate = data?.returnDate
    obj.totalproducts = data?.totalproducts
    obj.orderProducts = data?.orderProducts
    obj.subTotal = data?.subTotal
    obj.status = data?.status
     console.log('damage reee',obj);

     dispatch(addDamageReconciliation(obj, history, authtoken, currentDate))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
        <h4 className="mb-3 fw-bold">Damage reconciliation</h4>
          <div className="attendance-date">
            <AvForm>
              <AvField
                name="startTime"
                id="example-time-input"
                className="form-control mb-3"
                type="date"
                value={moment(new Date()).format("YYYY-MM-DD")}
                onChange={handleChangeDate}
                errorMessage="Enter Start Time"
                validate={{ required: { value: true } }}
              ></AvField>
            </AvForm>
          </div>
          <CardComponent>
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                // data={products}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                page={1}
                currentPage={currentPage}
                isPagination
                // productLoading={productLoading}
              >
                {damageReconciliationLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner />
                      </div>
                    </div>
                  </tr>
                ) : damageReconciliation?.data?.length ? (
                  damageReconciliation?.data?.reconciled?.map((data, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td>{data?.shop?.name}</td>
                        <td>{data?.route?.name}</td>
                        <td>
                          {data?.dp?.name}
                          {/* <div
              //  style={{ minWidth: "150px" }}
              //   className="me-3"
              >
                <Select
                  
                  classNamePrefix="select2-selection"
                  // disabled={loadingCondition}
                  // style={{ minWidth: "100px" }}
                  //   cacheOptions
                //   getOptionLabel={e => e.name}
                //   getOptionValue={e => e._id}
                  // defaultInputValue={}
                  placeholder={"Select Dp"}
                //   options={areaList}
                //   onChange={handleArea}
                />
              </div> */}
                        </td>
                        {data.status === "Pending" ? (
                          <td>
                            {" "}
                            <span className="me-2">
                              <img src={pendingImage}></img>
                            </span>
                            {data.status}
                          </td>
                        ) : data.status === "Cancel" ? (
                          <td>
                            {" "}
                            <span className="me-2">
                              <img src={cancelImage}></img>
                            </span>
                           Cancelled
                          </td>
                        ) : (
                          <td>
                            {" "}
                            <span className="me-2">
                              <img src={deliveryImage}></img>
                            </span>
                            {data.status}
                          </td>
                        )}

                        <td>
                          <Button
                            className={`${
                              data.view
                                ? "btn btn-warning btn-sm btn-rounded w-75"
                                : "btn btn-info btn-sm btn-rounded w-75"
                            }`}
                            onClick={() => handleDetails(idx, data)}
                          >
                            {data.view ? "Hide" : "See Details"}
                          </Button>
                        </td>
                        {
                          data?.status === "Pending" ? 
                          <td>
                          <Button
                            className="btn btn-info btn-sm btn-rounded w-75"
                            onClick={() => handleDamageReconciled(data)}
                          >
                            Returned Product
                          </Button>
                        </td> :
                        <span></span>
                        }
                      
                      </tr>
                      {data?.view ? (
                        <React.Fragment>
                          <tr>
                            <td colSpan="9">
                              <div
                                className="table-responsive"
                                style={{
                                  height: "300px",
                                  overflowY: "scroll",
                                }}
                              >
                                <CustomTable
                                  paginationClass="paginationContainer text-right"
                                  //   data={selectedProduct}
                                  tableHead={orderTableHead}
                                  isPagination={false}
                                >
                                  {selectedProduct?.length ? (
                                    selectedProduct?.map((data, idx) => (
                                      <React.Fragment key={idx}>
                                        <tr>
                                          <th scope="row">{idx + 1}</th>
                                          <td>{data?.productName}</td>
                                          <td>{data?.qty}</td>
                                        </tr>
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    <NoTableData
                                      colSpan="9"
                                      className="d-flex justify-content-center align-items-center"
                                      style={{
                                        minHeight: "300px",
                                        width: `100%`,
                                      }}
                                    >
                                      <span>
                                        Currently you have no Product{" "}
                                      </span>
                                    </NoTableData>
                                  )}
                                </CustomTable>
                              </div>
                            </td>
                        
                          </tr>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>Currently you have no Data. </span>
                  </NoTableData>
                )}
              </CustomTable>

              {/* table end */}
            </Row>
          </CardComponent>
        </Container>
        {/* <Card>
     
         <div className="route-excel-attendance">
         <div className="route-excel">
              <div>
                <p className="mb-0">Download Excelsheet</p>
              </div>
              <div>
                <button className="route-excel-download" >
                  {" "}
                  <img src={routeListDown}></img>Download
                </button>
              </div>
            </div>
        
         </div>
       
          </Card> */}
      </div>
    </React.Fragment>
  )
}

export default DamageReconciliation
