import React, { useEffect,useState } from "react"
import { makeQuery } from "helpers/Custom/makeQuery"
import ReactApexChart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux"
import { getAvgInvoiceValue } from "../../store/OrgDashboard/actions"

const AvgInvoiceValueChart = () => {
  const dispatch = useDispatch()
  const [AivFilter, setAivFilter] = useState("thisWeek")

  const {
    avgInvoiceValue,
    authtoken,
    avgInvoiceValueLoading,
    mainFilter,
    isSubFilterChanged,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    currentSelection,
  } = useSelector(state => ({
    avgInvoiceValue: state?.DashboardReducer?.avgInvoiceValue,
    authtoken: state.Login.token,
    avgInvoiceValueLoading: state?.DashboardReducer?.avgInvoiceValueLoading,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    mainFilter: state.DashboardReducer.mainFilter,
    currentSelection: state.DashboardReducer.currentSelection,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
  }))
  useEffect(() => {
    const query = makeQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection,
      "",
      "",
      AivFilter
    )
    console.log('query',query);
    dispatch(getAvgInvoiceValue(authtoken, query))
  }, [isSubFilterChanged,AivFilter])

  let weekDay, average
  if (!avgInvoiceValueLoading) {
    weekDay = avgInvoiceValue?.data?.day
    average = avgInvoiceValue?.data?.average
  }
  const options = {
    chart: {
      height: 300,
      type: "bar",
      toolbar: {
        show: !1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "14%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      show: !0,
      width: 2,
      colors: ["transparent"],
    },
    series: [
      {
        name: "BDT",
        data: average || [],
      },
    ],
    xaxis: {
      title: {
        text: "Weekdays",
      },
      categories: weekDay || [],
    },
    yaxis: {
      title: {
        text: "BDT",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ["#556ee6"],
  }
  const series = [
    {
      name: "BDT",
      data: average || [],
    },
  ]
  const handleAivFilter = e => {
    // console.log("e", e.target.value)
    console.log('e.target.value',e.target.value);
    const value = e.target.value
    setAivFilter(value)
  }

  return (
    <>
      <div className="d-flex">
        <div>
          <h4 className="card-title ">Avg. Invoice Value</h4>
        </div>
        <div className="ms-auto">
          <div className="avg-invoice-value-chart">
            <select
            onChange={handleAivFilter}
            value={AivFilter}
            id="graph-select-data-overview"
            >
              <option value="thisWeek">This Week</option>
              <option value="LastWeek">Last Week</option>
              
            </select>
          </div>
        </div>
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="385"
        className="apex-charts"
      />
    </>
  )
}

export default AvgInvoiceValueChart
