import Breadcrumbs from "components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router"
import { useHistory, withRouter } from "react-router-dom"
import XLSX from "xlsx"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
import '../../pages/styles.css'
// import { getOrders } from '../../store/Order/actions';
// import { getRoute } from "../../store/GeoInformation/actions";
import { getDateOrders } from "../../store/Order/actions"
import routeListDown from "../../assets/images/routeListDown.svg"

const RouteOrderDataTable = props => {
  const {
    params: { dateID },
  } = useRouteMatch("/date-order/:routeID/:dateID")
  console.log("hello", dateID)
  const {
    params: { routeID },
  } = useRouteMatch("/date-order/:routeID/:dateID")
  console.log("route", routeID)
  const history = useHistory()
  const dispatch = useDispatch()

  const { dateOrders, authtoken, dateLoading, id } = useSelector(state => ({
    dateOrders: state.OrderReducer.dateOrders,
    id: state.Login.id,
    authtoken: state.Login.token,
    dateLoading: state.OrderReducer.dateLoading,
  }))

  useEffect(() => {
    dispatch(getDateOrders(authtoken, id, routeID, dateID))
  }, [])
let allShopOrder, singleSubTotal, singleCollectAmount,orderTotal = 0,  singleOrderTotal , totalCollect = 0,due
if(!dateLoading){
  allShopOrder = dateOrders?.data?.allorders
  {
    (allShopOrder || []).map((dateOrder, key) => {
      singleSubTotal = Math.ceil(parseFloat(dateOrder?.subTotal))
      singleCollectAmount = Math.ceil(parseFloat(dateOrder?.collectAmount))
      singleOrderTotal = Math.ceil( dateOrder?.grandTotal)
      orderTotal = orderTotal+ singleSubTotal
      totalCollect = totalCollect+singleCollectAmount
      console.log(singleSubTotal);
      console.log(singleCollectAmount);
      if (singleSubTotal<=singleCollectAmount) {
        dateOrders.data.allorders[key].dueInfo = 0 
      }
      if(singleSubTotal>singleCollectAmount){
        dateOrders.data.allorders[key].dueInfo =  singleSubTotal - singleCollectAmount
      }
    });
  }
}
console.log(orderTotal);
console.log(totalCollect);
due = orderTotal-totalCollect
console.log(due);
localStorage.setItem('shopTotalCollect', totalCollect);
localStorage.setItem('shopTotalDue', due);
const handleExcel = () => {
  let newArray = []
  allShopOrder?.forEach((order, index) => {
    console.log('ORDER',order);
    let data = {}
    data.ShopName = order.shop.name
    data.SRName = order.sr.name
    data.DpName = order.dp.name
    data.OrderDate = order.orderTakenDate
    data.DeliveryDate = order.deliveryDate
     order.orderProducts.forEach((dt, idx) => {
      data[`ProductName-${idx+1}`] = dt.productName
      data[`ProductQty-${idx+1}`] = dt.qty
      data[`UnitPrice-${idx+1}`] = dt.unitPrice
      data[`Total-${idx+1}`] = dt.total
    })
    data.GrandTotal = order.grandTotal
    data.CollectAmount = order.collectAmount
    newArray.push(data)
  })
  downloadxls(newArray)
}
const downloadxls = data => {
  console.log(XLSX.version)
  const ws = XLSX.utils.json_to_sheet(data)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
  XLSX.writeFile(wb, "Shop Order.xlsx")
}
console.log(dateOrders);
  const tableData =
    !dateLoading &&
    dateOrders.data.allorders.map((dateOrder, index) => {
      return {
        sl: index + 1,
        heading1: dateOrder?.shop?.name,
        heading2: Math.ceil(dateOrder?.subTotal),
        heading3: Math.ceil(dateOrder?.collectAmount),
        // heading4: Math.ceil(dateOrder.dueInfo),
        heading4: 0,
        heading5: dateOrder?.sr?.name,
        // heading6: dateOrder?.dp?.name,

        manage: (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded btn-clr"
              onClick={() => {
                history.push(`/invoice/${dateOrder._id}`)
              }}
            >
              Invoice
            </Button>
          </>
        ),
        status: (
          <>
            <Badge className={"bg-success"}>Active</Badge>
          </>
        ),
      }
    })

  const orderData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },

      {
        label: "Shop List",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Amount",
        field: "heading2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Received Amount",
        field: "heading3",
        sort: "asc",
        width: 150,
      },
      {
        label: "Due Amount",
        field: "heading4",
        sort: "asc",
        width: 150,
      },

      {
        label: "SO Name",
        field: "heading5",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "DSR name",
      //   field: "heading6",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Invoice",
        field: "manage",
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <MetaTags>
          <title>Order | DDC</title>
        </MetaTags>
        <Container fluid>
         <h5 className="mb-3">Shop List</h5>
          {!dateLoading ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={orderData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="text-center pt-5">
              <Spinner />
            </div>
          )}
                <Card>
            <div className="route-excel">
            <div>
              <p className="mb-0">Download Excelsheet</p>
            </div>
            <div>
              <button className="route-excel-download" onClick={handleExcel}>
                {" "}
                <img src={routeListDown}></img>Download
              </button>
            </div>
          </div>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(RouteOrderDataTable)

// import React, { useEffect } from "react";
// import { map } from "lodash";
// import MetaTags from "react-meta-tags";
// //redux
// import { useDispatch, useSelector } from "react-redux";
// import { useHistory, useRouteMatch } from "react-router";
// import { Link, withRouter } from "react-router-dom";
// import {
//     Button, Col,
//     Container, Row, Spinner, Table
// } from "reactstrap";
// import { getDateOrders } from '../../store/Order/actions';

// const DateOrderTable = props => {
//     const {
//         params: { dateID },
//     } = useRouteMatch('/date-order/:dateID');
//     console.log('hello', dateID);

//     const history = useHistory()
//     const dispatch = useDispatch();

//     const { dateOrders, authtoken, dateLoading, id } = useSelector(state => ({
//         dateOrders: state.OrderReducer.dateOrders,
//         id: state.Login.id,
//         authtoken: state.Login.token,
//         dateLoading: state.OrderReducer.dateLoading
//     }));

//     useEffect(() => {
//         dispatch(getDateOrders(authtoken, id, dateID));
//     }, []);

//     return (
//         <React.Fragment>
//             <div >
//                 <MetaTags>
//                     <title>
//                         Order List | DDC
//                     </title>
//                 </MetaTags>
//                 <Container fluid>
//                     {/* Render Breadcrumbs */}
//                     {/* <Breadcrumbs title="Role" breadcrumbItem="Role Details" /> */}
//                     <h5>Please Select date for see orders</h5>
//                     {dateLoading ? (
//                         <div className="text-center mt-5 pt-5">
//                             <Spinner />
//                         </div>
//                     ) : (
//                         <Row >
//                             <Col lg="12">
//                                 <div className="">
//                                     <div className="table-responsive">
//                                         <Table className="project-list-table table-nowrap align-middle table-borderless">
//                                             <thead>
//                                                 <tr>

//                                                     <th scope="col">Shop List</th>

//                                                     <th scope="col">Receive final Amount(tk)</th>
//                                                     <th scope="col">SR Name</th>
//                                                     <th scope="col">DP Name</th>

//                                                     <th scope="col">View Details</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {map(dateOrders.data.allorders, (dateOrder, index) => (
//                                                     <tr key={index}>

//                                                         <td>
//                                                             <p className="text-muted mb-0">{dateOrder.shop.name}</p>
//                                                         </td>

//                                                         <td>
//                                                             <p className="text-muted mb-0"> {dateOrder.subTotal}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0"> {dateOrder.sr.name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0"> {dateOrder.dp.name}</p>
//                                                         </td>

//                                                         <td>

//                                                             <Button
//                                                                 type="button"
//                                                                 color="primary"
//                                                                 className="btn-sm btn-rounded"
//                                                                 onClick={() => {
//                                                                     history.push(`/invoice/${dateOrder._id}`)
//                                                                 }}

//                                                             >
//                                                                 Invoice
//                                                             </Button>

//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </Table>

//                                     </div>
//                                 </div>
//                             </Col>

//                         </Row>
//                     )}
//                 </Container>
//             </div>

//         </React.Fragment >
//     );
// };

// export default withRouter(DateOrderTable);
