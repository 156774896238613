import React, { useEffect, useState } from "react"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"

import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"

import { Link, useHistory } from "react-router-dom"
import { Button, Card, Container, Row, Spinner } from "reactstrap"
import {
  getDamageActivity,
  storeProductData,
  addDamageActivity,
} from "../../store/Inventory/actions"
import XLSX from "xlsx"
import routeListDown from "../../assets/images/routeListDown.svg"

import moment from "moment"
import Select from "react-select"
const tableHead = [
  "No.",
  "Shop List",
  "Route List",
  "Dp select",
  "View",
  "Action",
]

const orderTableHead = ["No.", "Product List", "Total Qty"]

const DamageActivity = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedProduct, setSelectedProduct] = useState([])
  const { damageActivity, damageActivityLoading, authtoken } = useSelector(
    state => ({
      damageActivity: state?.InventoryReducer?.damageActivity,
      damageActivityLoading: state?.InventoryReducer?.damageActivityLoading,

      authtoken: state.Login.token,
    })
  )
  useEffect(() => {
    dispatch(getDamageActivity(authtoken))
  }, [])

  const handleDamageActivity = data => {
    console.log("handleDamageActivity", data)

    let obj = {}
    obj._id = data._id
    obj.org = data.org
    obj.shop = data?.shop?._id
    obj.office = data?.office
    obj.route = data?.route?._id
    obj.area = data?.area
    obj.territory = data?.territory
    obj.region = data?.region
    obj.geo = data?.geo
    obj.dp = data?.dp?._id
    obj.returnDate = data?.returnDate
    obj.totalproducts = data?.totalproducts
    obj.orderProducts = data?.orderProducts
    obj.subTotal = data?.subTotal
    obj.status = data?.status
    // console.log('damage reee',obj);

    dispatch(addDamageActivity(obj, history, authtoken))
  }
  const handleDetails = (index, selectedItem) => {
    setSelectedProduct(selectedItem.orderProducts)
    const oldData = [...damageActivity?.data?.damageproduct]

    oldData?.map((data, i) => {
      if (i === index) {
        data.view = !data.view
      } else {
        data.view = false
      }
      return data
    })
    dispatch(storeProductData("data", { sales: oldData, pagenumber: 1 }))
  }

  console.log("damageActivity", damageActivity)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <h4 className="mb-3 fw-bold">Damage Activity</h4>
          <CardComponent>
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                // data={products}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                page={1}
                currentPage={currentPage}
                isPagination
                // productLoading={productLoading}
              >
                {damageActivityLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner />
                      </div>
                    </div>
                  </tr>
                ) : damageActivity?.data?.damageproduct?.length ? (
                  damageActivity?.data?.damageproduct.map((data, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td>{data?.shop?.name}</td>
                        <td>{data?.route?.name}</td>
                        <td>
                          {data?.dp?.name}
                          {/* <div
              //  style={{ minWidth: "150px" }}
              //   className="me-3"
              >
                <Select
                  
                  classNamePrefix="select2-selection"
                  // disabled={loadingCondition}
                  // style={{ minWidth: "100px" }}
                  //   cacheOptions
                //   getOptionLabel={e => e.name}
                //   getOptionValue={e => e._id}
                  // defaultInputValue={}
                  placeholder={"Select Dp"}
                //   options={areaList}
                //   onChange={handleArea}
                />
              </div> */}
                        </td>

                        <td>
                          <Button
                            className={`${
                              data.view
                                ? "btn btn-warning btn-sm btn-rounded w-50"
                                : "btn btn-info btn-sm btn-rounded w-50"
                            }`}
                            onClick={() => handleDetails(idx, data)}
                          >
                            {data.view ? "Hide" : "See Details"}
                          </Button>
                        </td>
                        {data?.flag === 1 ? (
                          <td>
                            <Button
                              className="btn btn-info btn-sm btn-rounded"
                              onClick={() => handleDamageActivity(data)}
                            >
                              Confirm
                            </Button>
                          </td>
                        ) : (
                          <span></span>
                        )}
                      </tr>
                      {data.view ? (
                        <React.Fragment>
                          <tr>
                            <td colSpan="9">
                              <div
                                className="table-responsive"
                                style={{
                                  height: "300px",
                                  overflowY: "scroll",
                                }}
                              >
                                <CustomTable
                                  paginationClass="paginationContainer text-right"
                                  data={selectedProduct}
                                  tableHead={orderTableHead}
                                  isPagination={false}
                                >
                                  {selectedProduct?.length ? (
                                    selectedProduct?.map((data, idx) => (
                                      <React.Fragment key={idx}>
                                        <tr>
                                          <th scope="row">{idx + 1}</th>
                                          <td>{data?.productName}</td>
                                          <td>{data?.qty}</td>
                                        </tr>
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    <NoTableData
                                      colSpan="9"
                                      className="d-flex justify-content-center align-items-center"
                                      style={{
                                        minHeight: "300px",
                                        width: `100%`,
                                      }}
                                    >
                                      <span>
                                        Currently you have no Product{" "}
                                      </span>
                                    </NoTableData>
                                  )}
                                </CustomTable>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>Currently you have no Data. </span>
                  </NoTableData>
                )}
              </CustomTable>

              {/* table end */}
            </Row>
          </CardComponent>
        </Container>
        {/* <Card>
          <div className="route-excel-attendance">
            <div className="route-excel">
              <div>
                <p className="mb-0">Download Excelsheet</p>
              </div>
              <div>
                <button className="route-excel-download">
                  {" "}
                  <img src={routeListDown}></img>Download
                </button>
              </div>
            </div>
          </div>
        </Card> */}
      </div>
    </React.Fragment>
  )
}

export default DamageActivity
