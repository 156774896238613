import CardComponent from "components/Common/CardComponent"
import DashboardFilter from "components/Dashboard/DashboardFilter"
import React from "react"
//i18n
import { withTranslation } from "react-i18next"
import MetaTags from "react-meta-tags"
import { useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AvgIncomeValue from "./AvgIncomeValue"
// Pages Components
import PropTypes from "prop-types"
import Overview from "./Overview"
import PreOrder from "./PreOrder"
import SampleSellingOverview from "./SampleSellingOverview"
import TopBrands from "./TopBrands"
import TopRegion from "./TopRegion"
import TopSR from "./TopSR"
import TotalSellngProduct from "./total-selling-product"

const OrgDashboard = props => {
  const data = useSelector(state => state?.DashboardReducer)
  // const handleChange = () => {
  //   window.print()
  // }
  // console.log(`file: OrgDashboard.js ~ line 21 ~ data`, data)
  console.log("rendering>>>>>>")
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | DDC</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <div className="filter">
            <CardComponent>
              <DashboardFilter title={"Bangladesh"} />
            </CardComponent>
          </div>

          <Overview />

          <div className="sjhdfjkdhfgdhbjkgvbsjhdgfghb">
            <Row>
              <div className="col-lg-8 col-md-7">
                {/* <SellingOverview /> */}
                <SampleSellingOverview />
              </div>
              <div className="col-lg-4 col-md-5">
                <TotalSellngProduct />
              </div>
            </Row>
          </div>
          <div className="sjhdfjkdhfgdhbjkgvbsjhdgfghb">
            <Row>
              <Col md={4}>
                {/* <BrandAnalytics /> */}
                <AvgIncomeValue />
              </Col>

              <Col md={4}>
                <TopRegion />
              </Col>
              <Col md={4}>
                <TopSR />
              </Col>
            </Row>
          </div>
          <div className="sjhdfjkdhfgdhbjkgvbsjhdgfghb">
            <Row>
              <Col md={7}>
                <TopBrands />
              </Col>

              <Col md={5}>
                <PreOrder />
              </Col>
              {/* <Card>
            <div className="route-excel">
              <div>
                <p className="mb-0">Download Excelsheet</p>
              </div>
              <div>
                <button className="route-excel-download" onClick={handleChange}>
                  {" "}
                  <img ></img>Download
                </button>
              </div>
            </div>
          </Card> */}

              {/* <Col>
           <TopDP/>
           </Col> */}
            </Row>
          </div>

          {/* <Row>
         
            <Col md={7}>
              <Map />
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

OrgDashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(OrgDashboard)
