import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import Select from "react-select"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getOffices } from "../../store/office/actions"
import { getLineManager, getUsers, updateUser } from "../../store/users/actions"
import {
  getRegion,
  getArea,
  getTerritroy,
} from "../../store/GeoInformation/actions"
import {
  onAreaFilterChange,
  onRegionFilterChange,
  onTerritoryFilterChange,
} from "../../store/actions.js"
import { updateOfficeworkingday } from "../../store/office/actions"

const EditOffice = () => {
  const { id: officeID } = useParams()
  const [areaData, setAreaData] = useState(null)
  const [regionData, setRegionData] = useState(null)
  const [territoryData, setTerritoryData] = useState(null)


  const dispatch = useDispatch()
  const history = useHistory()
  const {
    authtoken,
    offices,
    users,
    lineManager,
    id,
    usersList,
    areaInfo,
    areaList,
    territoryList,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    regionInfo,
    territoryInfo,
  } = useSelector(state => ({
    authtoken: state.Login.token,
    offices: state.OfficeReducer?.offices?.office,

    id: state.Login.id,

    offices: state.OfficeReducer.offices,
    areaInfo: state.GeoInformationReducer.area.area,
    regionInfo: state.GeoInformationReducer.region.region,
    territoryInfo: state.GeoInformationReducer.territory.territory,
    areaList: state.DashboardReducer.areaList,
    territoryList: state.DashboardReducer.territoryList,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
  }))
  useEffect(() => {
    dispatch(getRegion(authtoken))
    dispatch(getArea(authtoken))
    dispatch(getTerritroy(authtoken))
  }, [])

  const editOffice =
    offices && offices?.office?.find(office => office._id === officeID)
    console.log('editOffice',editOffice);
 
  useEffect(() => {
    setRegionData(editOffice?.region?._id  || "")
    setAreaData(editOffice?.area?._id  || "")
    setTerritoryData(editOffice?.teritori?._id  || "")
  }, [editOffice])

  useEffect(() => {
    dispatch(getOffices(authtoken))
  }, [])

  const handleRegion = value => {
    console.log("value", value)
    setRegionData(value._id)
    // dispatch(onRegionFilterChange(data))
    // setLoadArea(!loadArea)
  }

  const handleArea = value => {
    setAreaData(value._id)
    // dispatch(onAreaFilterChange(data))
    // setLoadTerritory(!loadTerritory)
  }
  const handleTerritory = value => {
    setTerritoryData(value._id)
    // dispatch(onTerritoryFilterChange(data))
  }

  async function handleSubmit(event, errors, values) {
    event.preventDefault()
    console.log("values 6767", values)

     let newData = {}
     newData.name = values.name
     newData.address = values.address
     newData.lat = values.lat
     newData.lon = values.lon
     newData.startTime = values.startTime
     newData.offTime = values.offTime

    if(regionData){
      newData.region = regionData

    }
    if(areaData){
      newData.area = areaData

    }
    if(territoryData){
      newData.teritori = territoryData

    }



    console.log(`file: EditUser.js ~ line 142 ~ handleSubmit ~ data`, newData)
     dispatch(updateOfficeworkingday(newData, history, authtoken, officeID ))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Add User</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Edit Office" breadcrumbItem="Edit Office" />
          <Row>
            <Col md={6} className="m-auto">
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Add Office</CardTitle>

                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-officeName-Input">
                            Office name
                          </Label>
                          <AvField
                            name="name"
                            type="text"
                            className="form-control"
                            id="formrow-officeName-Input"
                            placeholder=" Office Name"
                            value={editOffice?.name || ""}
                            errorMessage="Enter Office Name"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-officeName-Input">
                            Address
                          </Label>
                          <AvField
                            name="address"
                            type="text"
                            className="form-control"
                            id="formrow-officeName-Input"
                            placeholder=" Enter Address"
                            errorMessage="Enter Address"
                            value={editOffice?.address || ""}
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-Lattitude-Input">
                            Lattitude
                          </Label>
                          <AvField
                            name="lat"
                            type="text"
                            className="form-control"
                            id="formrow-Lattitude-Input"
                            placeholder="Enter Lattitude"
                            errorMessage="Enter Lattitude"
                            value={editOffice?.lat || ""}
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-Longitude">Longitude</Label>
                          <AvField
                            name="lon"
                            type="text"
                            className="form-control"
                            id="formrow-Longitude"
                            placeholder="Enter Longitude"
                            errorMessage="Enter Longitude"
                            value={editOffice?.lon || ""}
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="example-time-input">Start Time</Label>
                          <AvField
                            name="startTime"
                            id="example-time-input"
                            className="form-control"
                            type="time"
                            errorMessage="Enter Start Time"
                            value={editOffice?.startTime || ""}
                            validate={{ required: { value: true } }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                      {/* <AvField
                            name="startTime"
                            id="example-time-input"
                            className="form-control"
                            type="date"
                            value={moment(new Date()).format('yyyy-MM-DD')}
                            errorMessage="Enter Start Time"
                            validate={{ required: { value: true } }}
                          ></AvField> */}
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="example-endTime-input">
                            End Time
                          </Label>
                          <AvField
                            name="offTime"
                            id="example-time-input"
                            className="form-control"
                            type="time"
                            errorMessage="Enter End Time"
                            value={editOffice?.offTime || ""}
                            validate={{ required: { value: true } }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                      <div style={{ minWidth: "150px" }} className="me-3 mb-3">
                        <Label htmlFor="example-endTime-input">Region</Label>
                        <Select
                          name="region"
                          classNamePrefix="select2-selection"
                          // disabled={loadingCondition}
                          style={{ minWidth: "100px" }}
                          //   cacheOptions
                          options={regionInfo}
                          getOptionLabel={e => e.name}
                          getOptionValue={e => e._id}
                          value={regionInfo?.filter(
                            data => data?._id === regionData
                          )}
                          placeholder={"Select Region"}
                          onChange={handleRegion}
                        />
                      </div>

                      <div style={{ minWidth: "150px" }} className="me-3 mb-3">
                        <Label htmlFor="example-endTime-input">Area</Label>
                        <Select
                          name="area"
                          classNamePrefix="select2-selection"
                          // disabled={loadingCondition}
                          style={{ minWidth: "100px" }}
                          //   cacheOptions
                          getOptionLabel={e => e.name}
                          getOptionValue={e => e._id}
                          // defaultInputValue={}
                          value={areaInfo?.filter(
                            data => data?._id === areaData
                          )}
                          placeholder={"Select Area"}
                          options={areaInfo}
                          onChange={handleArea}
                        />
                      </div>

                      <div style={{ minWidth: "150px" }} className="me-3 mb-3">
                        <Label htmlFor="example-endTime-input">Territory</Label>
                        <Select
                          name="teritori"
                          classNamePrefix="select2-selection"
                          // disabled={loadingCondition}
                          style={{ minWidth: "100px" }}
                          value={territoryInfo?.filter(
                            data => data?._id === territoryData
                          )}
                          getOptionLabel={e => e.name}
                          getOptionValue={e => e._id}
                          // defaultInputValue={}
                          placeholder={"Select Territory"}
                          options={territoryInfo}
                          onChange={handleTerritory}
                        />
                      </div>
                    </Row>

                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Update Office
                      </button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* end row  */}
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default EditOffice
