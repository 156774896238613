import React, { Component, useEffect, useState } from "react"

import classNames from "classnames"
import {  
  Card,
  CardBody,
} from "reactstrap"


import { Link } from "react-router-dom"
import PjpAnalyticChart from "./ChartAnalyticPjp";

const PjpAnalytic = () => {
  const [periodType, setPeriodType] = useState("yearly")


  return (
    <React.Fragment>
      <Card className='page-content'>
      
        <CardBody>

          <div className="d-sm-flex flex-wrap ">
            <h4 className="card-title mb-4">PJP Add Analytic</h4>
            <div className="ms-auto">
             
              <ul className="nav nav-pills">


                <li className="nav-item">
                  <Link
                    to="#"
                    className={classNames(
                      { active: periodType === "yearly" },
                      "nav-link", "bg-dark"
                    )}
                    onClick={() => {
                      onChangeChartPeriod("yearly")
                    }}
                    id="one_month"
                  >
                    2021
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <PjpAnalyticChart />
        </CardBody>
      </Card>

    </React.Fragment>
  )
}

export default PjpAnalytic
