
import {
  ADD_NEW_OFFICE, ADD_OFFICE_FAIL,
  ADD_OFFICE_SUCCESS, GET_OFFICES,
  GET_OFFICES_FAIL, GET_OFFICES_SUCCESS,
  UPDATE_OFFICE_WORKINGDAY, UPDATE_OFFICE_WORKINGDAY_FAIL, UPDATE_OFFICE_WORKINGDAY_SUCCESS,
  STORE_OFFICE_DATA,
  SINGLE_OFFICE_INVENTORY,
  SINGLE_OFFICE_INVENTORY_SUCCESS,
  SINGLE_OFFICE_INVENTORY_FAIL
} from "./actionTypes"
export const getOffices = (authtoken) => ({
  type: GET_OFFICES,
  payload: {authtoken},
})

export const getOfficesSuccess = data => ({
  type: GET_OFFICES_SUCCESS,
  payload: data,
})

export const getOfficesFail = error => ({
  type: GET_OFFICES_FAIL,
  payload: error,
})
export const addOffice = (data, history, authtoken) => ({
  type: ADD_NEW_OFFICE,
  payload: { data, history, authtoken },
})

export const addOfficeSuccess = message => ({
  type: ADD_OFFICE_SUCCESS,
  payload: { message },
})

export const addOfficeFail = message => ({
  type: ADD_OFFICE_FAIL,
  payload: { message },
})


export const updateOfficeworkingday = (data, history, authtoken, officeid) => ({
  type: UPDATE_OFFICE_WORKINGDAY,
  payload: { data, history, authtoken,officeid },
})

export const updateOfficeworkingdaySuccess = message => ({
  type: UPDATE_OFFICE_WORKINGDAY_SUCCESS,
  payload: { message },
})

export const updateOfficeworkingdayFail = message => ({
  type: UPDATE_OFFICE_WORKINGDAY_FAIL,
  payload: { message },
})

export const storeOfficeData = (name, data) => ({
  type: STORE_OFFICE_DATA,
  payload: { name, data },
})

export const getOfficesInventory = (authtoken, officeId) => ({
  type: SINGLE_OFFICE_INVENTORY,
  payload: {authtoken, officeId},
})

export const getOfficesInventorySuccess = data => ({
  type: SINGLE_OFFICE_INVENTORY_SUCCESS,
  payload: data,
})

export const getOfficesInventoryFail = error => ({
  type: SINGLE_OFFICE_INVENTORY_FAIL,
  payload: error,
})
