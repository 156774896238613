import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import DashboardFilter from "components/Dashboard/DashboardFilter"
import { tableQuery } from "helpers/Custom/makeQuery"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Container, Row, Spinner, Card } from "reactstrap"
import { getProductData, storeProductData } from "store/ProductTable/action"
import routeListDown from "../../assets/images/routeListDown.svg"
import { AvField, AvForm } from "availity-reactstrap-validation"
import userImage from "../../assets/images/userImage2.svg"
import {getAllAttendanceData,getRangeAttendance} from '../../helpers/backend_helper'
import '../../assets/css/style.css'
import {
  getAttendance,
  getAttendanceExcel,
} from "../../store/Attendance/actions"
import XLSX from "xlsx"
import moment from "moment"
const tableHead = ["No.", "Name", "Status", "Time","Role", "image"]

const TableAttendance= () => {
  
  const dispatch = useDispatch()
  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  )
  const [role, setRole] = useState("SR")
  console.log(currentDate)
  const [excelAttendance, setExcelAttendance] = useState(false)
  const [excelRangeAttendance, setExcelRangeAttendance] = useState(false)
  const [attendanceStartDate, setAttendanceStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [attendanceEndDate, setAttendanceEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )


  const {
    attendance,
    attendanceLoading,
    authtoken,
    attendanceExcelLoading,
    attendanceExcel,
  } = useSelector(state => ({
    attendance: state.AttendanceReducer.attendance,
    attendanceExcel: state.AttendanceReducer.attendanceExcel,
    authtoken: state.Login.token,
    attendanceLoading: state.AttendanceReducer.attendanceLoading,
    attendanceExcelLoading: state.AttendanceReducer.attendanceExcelLoading,
  }))
console.log('attendanceExcel',attendanceExcel);
  // useEffect(() => {
  //   dispatch(getAttendanceExcel(authtoken, currentDate))
  // }, [currentDate])


  function convertDigitIn(str) {
    console.log("str", str.split("-").reverse().join("-"))
    return str.split("-").reverse().join("-")
  }

  const handleChangeRole = e => {
    setRole(e.target.value)
  }
  const handleChangeDate = e => {
    const value = convertDigitIn(e.target.value)

    setCurrentDate(value)
  }
  const handleStartDate = e => {
    const value = e.target.value

    setAttendanceStartDate(value)
  }
  const handleEndDate = e => {
    const value = e.target.value

    setAttendanceEndDate(value)
  }
  const handleExcel = async () => {
    setExcelAttendance(true)
    try {
      var response = await getAllAttendanceData(authtoken, currentDate)
      console.log("Attendance excel pppp", response)
    } catch (error) {}
    let newArray = []
    response?.objectarr.forEach((user, index) => {
      console.log('user', user);
      let data = {}
      data.Date = currentDate
      data.Name = user?.name
      data.employeeID = user?.employeeId
      data.officeCode = user?.office?.distributioncode
      if( user?.userinfo?.role === 'SR'){
        data.role = "SO"
      }
      else{
        data.role = user?.userinfo?.role
      }
     
      data.InTime = user?.intime
      data.area = user?.area?.name
      data.region = user?.region?.name
      data.territory = user?.teritori?.name
      if (user?.attanadancecheck === true) {
        data.Attendance = "Present"
      }
      if (user?.attanadancecheck === false) {
        data.Attendance = "Absent"
      }
      if (user?.isLate === true) {
        data.Status = "Late"
      }
      if (user?.isLate === false) {
        data.Status = "On Time"
      }

      newArray.push(data)
    })
    downloadxls(newArray)
  }
  const handleRangeAttendance = async () => {
    console.log("hhhh")
    setExcelRangeAttendance(true)
    try {
      var response = await getRangeAttendance(
        authtoken,
        attendanceStartDate,
        attendanceEndDate
      )
      console.log("range excel", response)
    } catch (error) {}
    let newArray = []
    response?.attendanceData?.forEach((user, index) => {
      console.log('user', user);
      let data = {}
      data.Date = user?.dateid
      data.Name = user?.userId?.name
      data.employeeID = user?.userId?.employeeId
      data.officeName = user?.office?.name
      data.lineManager = user?.linemanager?.employeeId
      data.officeCode = user?.office?.distributioncode
      if( user?.role === 'SR'){
        data.role = "SO"
      }
      else{
        data.role = user?.role
      }
      data.Attendance = "Present"
      data.InTime = user?.intime
      data.area = user?.area?.name
      data.region = user?.region?.name
      data.territory = user?.teritori?.name
     
   

      newArray.push(data)
    })
 
    downloadRangeXls(newArray)
  }
  const downloadxls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "AttendanceData.xlsx")
    setExcelAttendance(false)
  }
  const downloadRangeXls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "AttendanceData.xlsx")
    setExcelRangeAttendance(false)
  }
 
  useEffect(() => {
    console.log("useEffect", currentDate)
    dispatch(getAttendance(authtoken, currentDate, role))
  }, [currentDate, role])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <CardComponent>
            {/* filter */}
            <Row className="pb-3">{/* <DashboardFilter title={""} /> */}</Row>
            <div className="user-info-details">
            <div className="select-role">
              <select
                className="form-select m-auto"
                value={role}
                onChange={handleChangeRole}
              >
                <option value="SR">SO</option>
                <option value="SS">SS</option>
               
                <option value="TO">TO</option>
                <option value="RBH">RBH</option>
              </select>
            </div>
            {/* table */}
            <div className="attendance-date">
              <AvForm>
                <AvField
                  name="startTime"
                  id="example-time-input"
                  className="form-control mb-3"
                  type="date"
                  value={moment(new Date()).format("YYYY-MM-DD")}
                  onChange={handleChangeDate}
                  errorMessage="Enter Start Time"
                  validate={{ required: { value: true } }}
                ></AvField>
              </AvForm>
            </div>
            </div>
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={attendance?.data?.todaygivenattandance}
                tableHead={tableHead}
                // setCurrentPage={setCurrentPage}
                // page={pageNumber}
                // currentPage={currentPage}
                // isPagination
              >
                {attendanceLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner />
                      </div>
                    </div>
                  </tr>
                ) : attendance?.data?.todaygivenattandance?.length ? (
                  attendance?.data?.todaygivenattandance?.map((data, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td>{data?.userId?.name}</td>

                        {data?.isLate === true ? (
                          <td className="text-warning fw-bold">Late</td>
                        ) : (
                          <td className="text-success fw-bold">On Time</td>
                        )}
                        <td>{data?.intime}</td>
                        {
                          data?.role === "SR" ? <td>SO</td> : <td>{data?.role}</td>
                        }
                        <td>
                          <img src={`${process.env.REACT_APP_S3}${data?.photo}`} className='attendance-user-image img-fluid attendance-img'></img>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>Currently you have no Data. </span>
                  </NoTableData>
                )}
              </CustomTable>

              {/* table end */}
            </Row>
          </CardComponent>
        </Container>
        <Card>
          <div className="route-excel">
            <div>
              <p className="mb-0">Download Excelsheet</p>
            </div>
            {excelAttendance ? (
            <div className="text-center route-excel">
              <Spinner />
            </div>
          ) : (
            <div>
              <button className="route-excel-download" onClick={handleExcel}>
                {" "}
                <img src={routeListDown}></img>Download
              </button>
            </div>
          )}
          </div>
        </Card>
        <Card>
        <div className="route-excel-attendance">
          {excelRangeAttendance ? (
            <div className="text-center route-excel">
              <Spinner />
            </div>
          ) : (
            <div className="route-excel">
              <div>
                <p className="mb-0">Download Excelsheet</p>
              </div>
              <div>
                <button
                  className="route-excel-download"
                  onClick={handleRangeAttendance}
                >
                  {" "}
                  <img src={routeListDown}></img>Download
                </button>
              </div>
            </div>
          )}

          <div className="date-order-excel">
            <AvForm className="range-excel download">
              <label htmlFor="range-excel download">From date</label>
              <AvField
                name="startTime"
                id="example-time-input"
                className="form-control mb-3"
                type="date"
                value={moment(new Date()).format("YYYY-MM-DD")}
                onChange={handleStartDate}
                errorMessage="Enter Start Time"
                validate={{ required: { value: true } }}
              ></AvField>
              <label htmlFor="range-excel download">To Date</label>
              <AvField
                name="startTime"
                id="example-time-input"
                className="form-control mb-3"
                type="date"
                value={moment(new Date()).format("YYYY-MM-DD")}
                onChange={handleEndDate}
                errorMessage="Enter Start Time"
                validate={{ required: { value: true } }}
              ></AvField>
            </AvForm>
          </div>
        </div>
      </Card>
      </div>
    </React.Fragment>
  )
}

export default TableAttendance

