import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { map } from "lodash"
import { Link, Route, useHistory } from "react-router-dom"
import { Container, Row, Spinner, Card, Col, Button } from "reactstrap"
import XLSX from "xlsx"
import CareDrink from "../../assets/images/drink.png"
import "../../assets/css/style.css"
import { useDispatch, useSelector } from "react-redux"
import { getNewInventory } from "../../store/Inventory/actions"
const InventoryMain = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [search, setSearch] = useState("")
  const { inventories, authtoken, inventoryLoading, officeId } = useSelector(
    state => ({
      inventories: state.InventoryReducer.inventories,
      authtoken: state.Login.token,
      officeId: state.Login.office,
      inventoryLoading: state.InventoryReducer.inventoryLoading,
    })
  )
  console.log(officeId)
  console.log(inventories)
  useEffect(() => {
    dispatch(getNewInventory(authtoken, officeId))
  }, [])
  let allInventory
  if (!inventoryLoading) {
    allInventory = inventories.data?.inventory[0]?.product
  }
  console.log('allInventory',allInventory);
  const handleChange = event => {
    console.log(event.target.value)
    setSearch(event.target.value)
  }
  const handleExcel = () => {
    let newArray = []
    allInventory.forEach((order, index) => {
      let data = {}
      data.Product = order.name
      data.Quantity = order.productQuantity
      data.Price = order.price
      newArray.push(data)
    })
    downloadxls(newArray)
  }
  const downloadxls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "inventory.xlsx")
  }
  console.log(allInventory)
  console.log(inventories)
  console.log(inventoryLoading)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Inventory | DDC</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Inventory" breadcrumbItem="Inventory" />
          <div>
            <div className="add-inventory-serach">
              <div>
                <input
                  placeholder="search here"
                  onChange={event => handleChange(event)}
                ></input>
              </div>
              <div>
                <Link
                  to="/addProductInventory"
                  className="product-inventory-btn"
                >
                  Add Inventory
                </Link>
              </div>
              <button className="product-inventory-btn" onClick={handleExcel}>
                Download xlsx
              </button>
            </div>
          </div>
          {inventoryLoading ? (
            <div className="text-center pt-5">
              <Spinner />
            </div>
          ) : (
            <Row>
              {allInventory
                ?.filter(item => {
                  if (search === "") {
                    return item
                  } else if (
                    item.name.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return item
                  }
                })
                .map((data, key) => {
                  return (
                    <>
                      <Col xl="4" sm="6">
                        <Card>
                          <div>
                            <Row>
                              <Col xl="6">
                                <div className="text-center p-4 border-end">
                                  <div className="avatar-md mx-auto mb-3 mt-1">
                                    <img
                                      src={`${process.env.REACT_APP_S3}${data.image}`}
                                      className="w-100 h-100"
                                    />
                                  </div>
                                  <h5 className="text-truncate">{data.name}</h5>
                                </div>
                              </Col>

                              <Col xl="6">
                                <div className="p-4 text-center text-xl-start total-skus">
                                  <Row>
                                    <Col xs="12">
                                      <div className="inventory-content">
                                        <h6 className="text-muted mb-2 text-truncate">
                                          Total Skus
                                        </h6>
                                        <h5>{data.productQuantity}</h5>
                                        {/* {
                                          data.productQuantity <=0?
                                          <h5 className="text-danger">0</h5>
                                          :
                                          <h5>{data.productQuantity}</h5>
                                        } */}
                                      
                                        <h6 className="text-muted mb-2 text-truncate">
                                          Price
                                        </h6>
                                        <h5>{data.price}</h5>
                                      </div>
                                    </Col>
                                    <div className="mt-4 ">
                                      <Link
                                        to={{
                                          pathname: `/addInventory/${allInventory[key].productid}`,
                                          allInventory: allInventory,
                                        }}
                                        className="sku-btn"
                                      >
                                        Add SKUs
                                      </Link>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </Col>
                    </>
                  )
                })}
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InventoryMain
