import React from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import "../../assets/css/style.css"
import { Link } from "react-router-dom"
import pdfImage from "../../assets/images/pdf.svg"
const moment = require("moment")
const year = moment().format("DD-YYYY")


const historyList = 
  [
   
   
    {

        "title": "February 1, 2022",
        "date": '2022-02-01',

        
    },
    {

      "title": "February 2, 2022",
      "date": '2022-02-02',

      
  },
  {

    "title": "February 3, 2022",
    "date": '2022-02-03',

    
},
{

  "title": "February 4, 2022",
  "date": '2022-02-04',

  
},
{

  "title": "February 5, 2022",
  "date": '2022-02-05',

  
},
{

  "title": "February 6, 2022",
  "date": '2022-02-06',

  
},
{

  "title": "February 7, 2022",
  "date": '2022-02-07',

  
},
{

  "title": "February 8, 2022",
  "date": '2022-02-08',

  
},
{

  "title": "February 9, 2022",
  "date": '2022-02-09',

  
},
{

  "title": "February 10, 2022",
  "date": '2022-02-10',

  
},
{

  "title": "February 11, 2022",
  "date": '2022-02-11',

  
},
{

  "title": "February 12, 2022",
  "date": '2022-02-12',

  
},
{

  "title": "February 13, 2022",
  "date": '2022-02-13',

  
},
{

  "title": "February 14, 2022",
  "date": '2022-02-14',

  
},
{

  "title": "February 15, 2022",
  "date": '2022-02-15',

  
},
{

  "title": "February 16, 2022",
  "date": '2022-02-16',

  
},
{

  "title": "February 17, 2022",
  "date": '2022-02-17',

  
},
{

  "title": "February 18, 2022",
  "date": '2022-02-18',

  
},
{

  "title": "February 19, 2022",
  "date": '2022-02-19',

  
},

{

  "title": "February 20, 2022",
  "date": '2022-02-20',

  
},

{

  "title": "February 21, 2022",
  "date": '2022-02-21',

  
},

{

  "title": "February 22, 2022",
  "date": '2022-02-22',

  
},

{

  "title": "February 23, 2022",
  "date": '2022-02-23',

  
},

{

  "title": "February 24, 2022",
  "date": '2022-02-24',

  
},

{

  "title": "February 25, 2022",
  "date": '2022-02-25',

  
},

{

  "title": "February 26, 2022",
  "date": '2022-02-26',

  
},
{

  "title": "February 27, 2022",
  "date": '2022-02-27',

  
},
{

  "title": "February 28, 2022",
  "date": '2022-02-28',

  
},




]

const ActivityHistory = () => {
  return (
    <div>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="History" breadcrumbItem="History" />
          <Card>
         <div className="history-month-year">
             <div className="history-month">
             <select defaultValue="0" className="form-select m-auto">
             
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="9">October</option>
              <option value="9">November</option>
              <option value="9">December</option>
            </select>
             </div>
             <div className="history-month">
             <select defaultValue="0" className="form-select m-auto">
          
              <option value="1">2022</option>
              <option value="2">2023</option>
              <option value="3">2024</option>
              <option value="4">2025</option>
              <option value="5">2026</option>
              <option value="6">2027</option>
              <option value="7">2028</option>
              <option value="8">2029</option>
              <option value="9">2030</option>
            </select>
             </div>
         </div>
            <Table hover>
              <thead className="history-head">
                <tr>
                  <th>NO</th>
                  <th>Date</th>
                  <th>View Details</th>
                </tr>
              </thead>
              <tbody>
              {(historyList|| []).map((data, key) => {
                        return(
                          <>
                <tr>
                  <th scope="row">{key+1}</th>
                  <td>{data.title}</td>

                  <td>
                    <Link to={`/DateWiseProductList/${data.date}`}>
                    <button type="submit" className="btn btn-primary btn-sm btn-rounded">
                      View Details
                    </button>
                    </Link>
                  </td>
                </tr>
                </>
               );
              })}
                

              </tbody>
            </Table>
          </Card>
        </Container>
      </div>
    </div>
  )
}

export default ActivityHistory
