import React, { useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import {  useRouteMatch } from "react-router";
import { getProduct } from '../../../store/Product/actions'
import { useDispatch, useSelector } from "react-redux";
const TotalAmountChart = () => {
  const {
    params: { productID },
} = useRouteMatch('/product-analytic/:productID');
console.log('hello', productID);

const dispatch = useDispatch();

const { authtoken, product ,loading} = useSelector(state => ({
  product: state.ProductReducer.product.data,

  id: state.Login.id,
  authtoken: state.Login.token,
  loading: state.ProductReducer.loading
}));
useEffect(() => {
  dispatch(getProduct(authtoken, productID));
}, []);
console.log(product);
let date,productPrice,productQuantity
if(!loading){
  date= product?.date
  productPrice = product?.productprice
  productQuantity = product?.productquantity

}
console.log(date,productPrice,productQuantity);
var options = {
  series: [
    {
      name: "Amount",
      type: "line",
      data: productPrice,
    },
    {
      name: "Unit",
      type: "column",
      data: productQuantity,
    },
  ],
  plotOptions: {
    bar: {
      columnWidth: "15%",
    },
  },
  colors: ["#34C38F", "#556EE6"],
  chart: {
    height: 350,
    type: "line",
    stacked: false,
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#34C38F", "#556EE6"],
  fill: {
    colors: undefined,
    opacity: 1,
    type: "solid",
  },
  stroke: {
    width: [4, 0],
  },
  title: {
    // text: 'XYZ - Stock Analysis (2009 - 2016)',
    align: "left",
    offsetX: 110,
  },
  xaxis: {
    categories: date,
  },
  yaxis: [
    {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#34C38F",
      },
      labels: {
        style: {
          colors: "#34C38F",
        },
      },
      title: {
        text: "Amount",
        style: {
          color: "#34C38F",
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    {
      seriesName: "Unit",
      opposite: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#556EE6",
      },
      labels: {
        style: {
          colors: "#556EE6",
        },
      },
      title: {
        text: "Unit",
        style: {
          color: "#556EE6",
        },
      },
    },
    // {
    //   seriesName: 'Revenue',
    //   opposite: true,
    //   axisTicks: {
    //     show: true,
    //   },
    //   axisBorder: {
    //     show: true,
    //     color: '#FEB019'
    //   },
    //   labels: {
    //     style: {
    //       colors: '#FEB019',
    //     },
    //   },
    //   title: {
    //     text: "Revenue (thousand crores)",
    //     style: {
    //       color: '#FEB019',
    //     }
    //   }
    // },
  ],
  tooltip: {
    fixed: {
      enabled: true,
      position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 30,
      offsetX: 60,
    },
  },
  legend: {
    horizontalAlign: "center",
    offsetX: 40,
  },
}
  return (
    <ReactApexChart
    options={options}
    series={options?.series || []}
      type="area"
      height="350"
    />
  )
}

export default TotalAmountChart
