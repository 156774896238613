import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { map } from "lodash"
import { Link, Route } from "react-router-dom"
import { Container, Row, Spinner, Card, Col, Button } from "reactstrap"
import XLSX from "xlsx"
import CareDrink from "../../assets/images/drink.png"
import "../../assets/css/style.css"
import { getOfficesInventory } from "../../store/office/actions.js"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useRouteMatch } from "react-router"
const SingleInventory = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [search, setSearch] = useState("")

  const {
    params: { officeId, officeName },
  } = useRouteMatch("/inventory-info/:officeId/:officeName")

  const { officeInventory, authtoken, officeInventoryLoading } = useSelector(
    state => ({
      officeInventory: state?.OfficeReducer?.officeInventory,
      authtoken: state.Login.token,
      officeInventoryLoading: state?.OfficeReducer?.officeInventoryLoading,
    })
  )
  console.log("officeName", officeName)
  useEffect(() => {
    dispatch(getOfficesInventory(authtoken, officeId))
  }, [])

  console.log("officeInventory", officeInventory)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Inventory | DDC</title>
      </MetaTags>

      <Container fluid>
        {officeInventoryLoading ? (
          <div className="text-center mt-5 pt-5">
            <Spinner />
          </div>
        ) : (
          <Row>
            <h5 className="mb-3">
              Inventory List For{" "}
              <span className="fw-bold text-warning"> {officeName} </span>{" "}
            </h5>
            {(officeInventory?.inventory[0]?.product || []).map((data, key) => {
              return (
                <>
                  <Col xl="4" sm="6">
                    <Card>
                      <div>
                        <Row>
                          <Col xl="6">
                            <div className="text-center p-4 border-end">
                              <div className="avatar-md mx-auto mb-3 mt-1">
                                <img
                                  src={`${process.env.REACT_APP_S3}${data?.image}`}
                                  className="w-100 h-100"
                                />
                              </div>
                              <h5 className="text-truncate">{data?.name}</h5>
                            </div>
                          </Col>

                          <Col xl="6">
                            <div className="p-4 text-center text-xl-start total-skus">
                              <Row>
                                <Col xs="12">
                                  <div className="inventory-content">
                                    <h6 className="text-muted mb-2 text-truncate">
                                      Total Skus
                                    </h6>
                                    <h5>{data?.productQuantity}</h5>
                                    <h6 className="text-muted mb-2 text-truncate">
                                      Price
                                    </h6>
                                    <h5>{data?.price}</h5>
                                  </div>
                                </Col>
                                {/* <div className="mt-4 ">
                                  <Link
                                    to={`/addQuantityInventory/${officeId}/${data?.productid}`}
                                    className="sku-btn"
                                  >
                                    Add SKUs
                                  </Link>
                                </div> */}
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                </>
              )
            })}
          </Row>
        )}
      </Container>
    </React.Fragment>
  )
}

export default SingleInventory
