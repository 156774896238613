import axios from "axios"
import { del, get, image, imageUpdate, patch, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
// export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
// export const getUser = () => get(url.GET_USERS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
// export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
// export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
}

//Users
//Competitive Products

export const getCompetitiveProducts = () => get(url.COMPETITIVE_PRODUCT)

export const addNewCompetitiveProduct = product =>
  post(url.COMPETITIVE_PRODUCT, product)

export const updateCompetitiveProduct = (id, product) =>
  patch(`${url.COMPETITIVE_PRODUCT}/${id}`, product)

export const deleteCompetitiveProduct = product =>
  del(`${url.COMPETITIVE_PRODUCT}/${product}`)
//stores

export const getStores = () => get(url.STORES_DATA)

export const getStore = id => get(`${url.STORES_DATA}/${id}`)

export const addStore = data => post(url.STORES_DATA, data)

export const updateStore = (id, data, token) =>
  patch(`${url.STORES_DATA}/${id}`, data, token)

export const deleteStore = data => del(`${url.STORES_DATA}/${data}`)

//products

export const deleteProduct = data => del(`${url.PRODUCTS_DATA}/${data}`)

// Products

//single data retrevive for selection

export const getusername = () => get(url.USERS_NAME)

export const getstorename = () => get(url.STORES_NAME)

// sales target saga

export const getallSalestarget = () => get(url.SALES_TARGET)

export const getSalestarget = id => get(`${url.SALES_TARGET}/${id}`)

export const addSalestarget = data => post(url.SALES_TARGET, data)

export const updateSalestarget = (id, data) =>
  patch(`${url.SALES_TARGET}/${id}`, data)

export const deleteSalestarget = data => del(`${url.SALES_TARGET}/${data}`)

//attandance

export const getAttandanceDataonDate = date =>
  get(`${url.ATTANDANCE_TRACKER}/${date}`)

//Users
export const getUsers = (authtoken, orgid) =>
  get(`${url.USERS_DATA}/allusers/${orgid}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getUsersPerformance = (authtoken, orgid) =>
  get(`${url.USERS_DATA}/allusers/${orgid}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getUser = (id, authtoken) =>
  get(`${url.USERS_DATA}/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const addUser = (data, authtoken) =>
  image(url.USERS_DATA, data, authtoken)

export const getLineManager = (authtoken, role) =>
  get(`${url.USERS_DATA}/linemanagerid/${role}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getSampleDataExcel = (authtoken, role) =>
  get(`${url.SAMPLE_DATA}/ordertotal`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// post(url.USERS_DATA, data, { headers: { Authorization: `Bearer ${authtoken}` } })

export const updateUser = (id, data, authtoken) =>
  patch(`${url.USERS_DATA}/${id}`, data, authtoken)

export const updateRouteUser = (id, data, authtoken) =>
  patch(`${url.USERS_DATA}/setrouteforusers/${id}`, data, authtoken)

export const deleteUser = (id, authtoken) =>
  del(`${url.USERS_DATA}/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const deleteUserRoute = (authtoken, userId, routeId) =>
  get(`${url.Route}/updatesetroute/${userId}/${routeId}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getAllUserInfo = (
  authtoken,
  orgid,
  pagenumber,
  useremployee,
  role
) =>
  get(
    `${url.USERS_DATA}/userpaging?orgid=${orgid}&currentpage=${pagenumber}&useremployee=${useremployee}&role=${role}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )
//Post
export const postRegiondata = (data, authtoken) =>
  post(url.REGION, data, { headers: { Authorization: `Bearer ${authtoken}` } })
export const postAreadata = (data, authtoken) =>
  post(url.AREA, data, { headers: { Authorization: `Bearer ${authtoken}` } })
export const postTerritorydata = (data, authtoken) =>
  post(url.TERRITORY, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const postGeodata = (data, authtoken) =>
  post(url.GEO, data, { headers: { Authorization: `Bearer ${authtoken}` } })
export const postRoutedata = (data, authtoken) =>
  post(url.Route, data, { headers: { Authorization: `Bearer ${authtoken}` } })

//Get
export const getRegiondata = authtoken =>
  get(url.REGION, { headers: { Authorization: `Bearer ${authtoken}` } })
export const getAreadata = authtoken =>
  get(url.AREA, { headers: { Authorization: `Bearer ${authtoken}` } })
export const getTerritorydata = authtoken =>
  get(url.TERRITORY, { headers: { Authorization: `Bearer ${authtoken}` } })
export const getGeodata = authtoken =>
  get(url.GETALLGEO, { headers: { Authorization: `Bearer ${authtoken}` } })
export const getRoutedata = (authtoken, orgid) =>
  get(`${url.Route}/router/${orgid}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getAllRouteInfo = (authtoken, orgid, pagenumber, routename) =>
  get(
    `${url.Route}/routepagination?orgid=${orgid}&currentpage=${pagenumber}&routename=${routename}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )

export const getDetailsRoutedata = (authtoken, routeID) =>
  get(`${url.Route}/bestsellingshop/${routeID}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getRouteShop = (authtoken, routeID) =>
  get(`${url.Route}/allrouteshop/${routeID}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getGeoRoute = (authtoken, geoID) =>
  get(`${url.GEO}/singlegeo/${geoID}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// Office
export const getofficedata = authtoken =>
  get(url.OFFICE, { headers: { Authorization: `Bearer ${authtoken}` } })
export const postofficedata = (data, authtoken) =>
  post(url.OFFICE, data, { headers: { Authorization: `Bearer ${authtoken}` } })
export const updateofficedata = (data, authtoken, officeid) =>
  patch(`${url.OFFICEWORKING}/${officeid}`, data, authtoken)

export const getSingleOfficeInventory = (authtoken, officeId) =>
  get(`${url.INVENTORY_DATA}/getInventory/${officeId}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

//Stores
export const getStoresedata = (authtoken, orgid) =>
  get(`${url.SHOP}/shop/${orgid}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getSearchStoresData = (authtoken, name) =>
  get(`${url.SHOP}/shopsearch?shopname=${name}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getAllStoresData = (authtoken, orgid, pagenumber, shopName) =>
  get(
    `${url.SHOP}/pagination?orgid=${orgid}&currentpage=${pagenumber}&shopname=${shopName}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )

export const getStoredata = (authtoken, id) =>
  get(`${url.SHOP}/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getStoreAddExcel = (authtoken, id) =>
  get(`${url.SHOP}/storeAddExcel/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const addStoresedata = (data, authtoken) =>
  post(url.SHOP, data, { headers: { Authorization: `Bearer ${authtoken}` } })
export const getStoreTopProduct = (authtoken, id) =>
  get(`${url.SHOP}/storewisebestproduct/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const updateStoreData = (id, data, token) =>
  patch(`/shop/${id}`, data, token)

// export const updateStore
export const getStoreAnalyticData = (authtoken, id) =>
  get(`${url.SHOP}/storeaddanalytics/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getShopOrderExcel = (authtoken, pageNo, limit) =>
  get(`${url.SHOP}/shop-preorder?pageNo=${pageNo}&limit=${limit}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
//Brand Information

export const getBrandsdata = (authtoken, orgid) =>
  get(`${url.BRAND}/brand/${orgid}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getBrandsdataStatus = (authtoken, status) =>
  get(`${url.BRAND}/brandpreandrevenue/${status}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const addBrandsdata = (data, authtoken) =>
  image(url.BRAND, data, authtoken)

//Products
export const getSingleProduct = (authtoken, id) =>
  get(`${url.PRODUCTS_DATA}/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getAllProducts = (authtoken, orgid) =>
  get(`${url.PRODUCTS_DATA}/product/${orgid}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const addProduct = (data, authtoken) =>
  image(url.PRODUCTS_DATA, data, authtoken)
export const updateProduct = (data, authtoken, id) =>
  imageUpdate(`${url.PRODUCTS_DATA}/${id}`, data, authtoken)
// export const updateofficedata = (data, authtoken, officeid) =>
// patch(`${url.OFFICEWORKING}/${officeid}`, data, authtoken)
export const getProductQuantityData = authtoken =>
  get(`${url.PRODUCTS_DATA}/productanalytic`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// Category Information
export const getCategorydata = authtoken =>
  get(url.CATEGORY, { headers: { Authorization: `Bearer ${authtoken}` } })
export const addCategorydata = (data, authtoken) =>
  post(url.CATEGORY, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// Unit Information
export const getUnitData = authtoken =>
  get(url.UNIT, { headers: { Authorization: `Bearer ${authtoken}` } })
export const addUnitData = (data, authtoken) =>
  post(url.UNIT, data, { headers: { Authorization: `Bearer ${authtoken}` } })

// Order
export const getAllOrders = (authtoken, id) =>
  get(`${url.ORDERS_DATA}/orgorder/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getAllOrderExcel = (authtoken, fromDate, toDate, pageNo, limit) =>
  get(
    `${url.ORDERS_DATA}/dateTodateOrder?fromDate=${fromDate}&toDate=${toDate}&pageNo=${pageNo}&limit=${limit}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )
export const getAllRouteOrders = (authtoken, orgid, route, date) =>
  get(`${url.ORDERS_DATA}/totalorder/${orgid}/${route}/${date}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getAllDateOrders = (authtoken, orgid, routeID, date) =>
  get(`${url.ORDERS_DATA}/dayshop/${orgid}/${routeID}/${date}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getDistDateOrders = (authtoken, orgid, distId, date) =>
  get(`${url.ORDERS_DATA}/totalorderoffice/${orgid}/${distId}/${date}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDistShopOrders = (authtoken, orgid, distId, date) =>
  get(`${url.ORDERS_DATA}/dayofficeshop/${orgid}/${distId}/${date}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getInvoice = (authtoken, id) =>
  get(`${url.ORDERS_DATA}/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getEditedOrder = (authtoken, id) =>
  get(`${url.ORDERS_DATA}/editedorderDetails/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getAllOrderexcel = (authtoken, currentDate) =>
  get(`${url.ORDERS_DATA}/orderconvertedtoxlsx/${currentDate}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDeliveryOrderExcel = (authtoken, currentDate) =>
  get(`${url.ORDERS_DATA}/todaysalesorderxlxs/${currentDate}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDateRangeOrderexcel = (authtoken, toDate, endDate) =>
  get(`${url.ORDERS_DATA}/orderRange?startDate=${toDate}&endDate=${endDate}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getRangeSalesexcel = (authtoken, toDate, endDate) =>
  get(
    `${url.ORDERS_DATA}/orderSalesRange?startDate=${toDate}&endDate=${endDate}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )

// Attendance
export const getAttendanceData = (authtoken, dateid, role) =>
  get(`${url.ATTENDANCE_DATA}/datewiseattendance/${dateid}/${role}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getAllAttendanceData = (authtoken, dateid) =>
  get(`${url.ATTENDANCE_DATA}/todayattendanceuser/${dateid}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getRangeAttendance = (authtoken, toDate, endDate) =>
  get(
    `${url.ATTENDANCE_DATA}/download-date-range-atteandce?startDate=${toDate}&endDate=${endDate}`,
    {
      headers: { Authorization: `Bearer ${authtoken}` },
    }
  )
// Offer
export const addConsumerOffer = (data, authtoken, id) =>
  post(`${url.OFFERS_DATA}/createConsumeroffer/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const addRetailerOffer = (data, authtoken, id) =>
  post(`${url.OFFERS_DATA}/createReatileroffer/${id}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const addOffersdata = (data, authtoken) =>
  post(url.OFFERS_DATA, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getAllOffer = authtoken =>
  get(url.GET_OFFERS_DATA, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDetailsOffer = (authtoken, id) =>
  get(`${url.OFFERS_DATA}/offerDetails/${id}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
// export const getSingleOffer = (id, authtoken) => get(`${url.PRODUCTS_DATA}/${id}`, { headers: { Authorization: `Bearer ${authtoken}` } })

// PJP
export const addPjpData = (data, authtoken) =>
  post(url.PJP_DATA, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getPjpData = authtoken =>
  get(url.PJP_DATA, { headers: { Authorization: `Bearer ${authtoken}` } })

export const addTargetData = (data, authtoken) =>
  post(url.TARGET_DATA, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getTargetData = authtoken =>
  get(url.TARGET_DATA, { headers: { Authorization: `Bearer ${authtoken}` } })

// Dashboard
export const getDashBoardOverviewData = authtoken =>
  get(`${url.DASHBOARD_DATA}/dashboardbanner`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDashBoardChartData = (authtoken, query) =>
  get(`${url.DASHBOARD_DATA}/getdashboardchart${query ? `?${query}` : ""}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDashBoardSellingOverview = (authtoken, query) =>
  get(`${url.DASHBOARD_DATA}/sumvalue${query ? `?${query}` : ""}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getTopProdutcsData = (authtoken, query) =>
  get(`${url.DASHBOARD_DATA}/bestsell${query ? `?${query}` : ""}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getTotalSRData = (authtoken, query) =>
  get(`${url.DASHBOARD_DATA}/totalvisit${query ? `?${query}` : ""}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getTotalDPData = (authtoken, orgid) =>
  get(`${url.DASHBOARD_DATA}/totaldp/${orgid}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getTopSRData = (authtoken, query) =>
  get(`${url.DASHBOARD_DATA}/topsr${query ? `?${query}` : ""}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getTopDPData = authtoken =>
  get(`${url.DASHBOARD_DATA}/topthreedp`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getTopBrandData = (authtoken, query) =>
  get(`${url.DASHBOARD_DATA}/topthreebrand${query ? `?${query}` : ""}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getTopRegionData = (authtoken, query) =>
  get(`${url.DASHBOARD_DATA}/topthreeroute${query ? `?${query}` : ""}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getPreOrderDelivery = (authtoken, query) =>
  get(`${url.DASHBOARD_DATA}/dashboarddelivery${query ? `?${query}` : ""}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getAvgInvoiceValueData = (authtoken, query) =>
  get(`${url.DASHBOARD_DATA}/averageinvoicevalues${query ? `?${query}` : ""}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getTargetAnalysis = (authtoken, query) =>
  get(`${url.DASHBOARD_DATA}/dashboardbannertwo${query ? `?${query}` : ""}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDashboardAnalysisOne = (authtoken, query) =>
  get(`${url.DASHBOARD_DATA}/dashboardbannerone${query ? `?${query}` : ""}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// Inventory

export const addNewInventory = (data, authtoken, officeId) =>
  post(`${url.INVENTORY_DATA}/${officeId}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const addDamageInventoryData = (data, authtoken, officeId) =>
  post(`${url.INVENTORY_DATA}/damageinventory/${officeId}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getNewInventory = (authtoken, officeId) =>
  get(`${url.INVENTORY_DATA}/getInventory/${officeId}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDamageInventoryData = (authtoken, officeId) =>
  get(`${url.INVENTORY_DATA}/getdamageInventory/${officeId}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
// Damage Activity

export const getDamageActivityData = authtoken =>
  get(`${url.DAMAGE_DATA}/getdamage`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDamageReconciliationData = (authtoken, dateId) =>
  get(`${url.DAMAGE_DATA}/getdamageproductweb/${dateId}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const addDamageActivityData = (data, authtoken) =>
  post(`${url.DAMAGE_DATA}/postdamageproduct`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const addDamageReconciliationData = (data, authtoken, dateId) =>
  post(`${url.DAMAGE_DATA}/returndamageproductweb/${dateId}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
/////

export const getBounceRateData = (authtoken, officeId) =>
  get(`${url.INVENTORY_DATA}/getInventory/${officeId}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getReconciliationStatusData = (authtoken, dpId, date) =>
  get(`${url.ACTIVITY_DATA}/getreconcialiationstatus/${dpId}/${date}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getReconciliationConfirmStatusData = (authtoken, dpId, date) =>
  get(`${url.ACTIVITY_DATA}/reconcialiationreceivestatus/${dpId}/${date}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// filter

export const getFilterData = (authtoken, url) =>
  get(url, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// Activity
export const getTodayActivity = (authtoken, id, today) =>
  get(`${url.ACTIVITY_DATA}/${today}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getDpActivity = (authtoken, id, today) =>
  get(`${url.ACTIVITY_DATA}/dplist/${today}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDpOrderActivity = (authtoken, dpId, today) =>
  get(`${url.ACTIVITY_DATA}/dporder/${dpId}/${today}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const addDayOpening = (data, authtoken) =>
  post(`${url.ACTIVITY_DATA}/reconciliation`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDpReconciliation = (authtoken, dpId, today) =>
  get(`${url.ACTIVITY_DATA}/getreconcialiation/${dpId}/${today}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const addDpReconciliation = (data, authtoken) =>
  post(`${url.ACTIVITY_DATA}/reconcilationConfirmation`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// Distribution Dashboard
export const getDistDashBoardOverviewData = authtoken =>
  get(`${url.DASHBOARD_DATA}/dashboardbanner`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDistTopProdutcsData = authtoken =>
  get(`${url.DASHBOARD_DATA}/bestsell`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDistTopBrandData = authtoken =>
  get(`${url.DASHBOARD_DATA}/topthreebrand`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDistTopSRData = authtoken =>
  get(`${url.DASHBOARD_DATA}/topsr`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDistTopDPData = authtoken =>
  get(`${url.DASHBOARD_DATA}/topthreedp`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDistSellingOverview = authtoken =>
  get(`${url.DASHBOARD_DATA}/sumvalue`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDsitDashBoardChartData = authtoken =>
  get(`${url.DASHBOARD_DATA}/getdashboardchart`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getDistTopRegionData = authtoken =>
  get(`${url.DASHBOARD_DATA}/topthreeroute`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// Survey

export const addNewSurvey = (data, authtoken) =>
  image(url.SURVEY_DATA, data, authtoken)

export const getSurveyData = authtoken =>
  get(`${url.SURVEY_DATA}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  export const getSurveyShopData = (authtoken,surveyId,limit,pageNo, startDate, endDate, value) =>
  get(`${url.SURVEY_DATA}/survey-result?surveyId=${surveyId}&limit=${limit}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&search=${value}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  export const getShopQuesData = (authtoken,singleSurvey) =>
  get(`${url.SURVEY_DATA}/single-survey/${singleSurvey}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getLaparge = authtoken =>
  get(`${url.laparge}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const postData = (url, data, authToken) => {
  return post(url, data, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
}
export const getData = (url, authToken) =>
  get(url, {
    headers: { Authorization: `Bearer ${authToken}` },
  })

export const patchData = (url, data, authToken) => {
  return patch(url, data, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
}

export const deleteData = (url, authToken) =>
  del(url, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
