import React, { useEffect } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { Card, Container, Table } from "reactstrap"
import "../../assets/css/style.css"
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router"
import { Link } from "react-router-dom"
import { getDpOrderActivity } from "../../store/Activities/actions"
import { CSVLink, CSVDownload } from "react-csv"
const moment = require("moment")

let _ = require('lodash');

const DateWiseDpProductList = () => {
  const dispatch = useDispatch()
  // const today = moment().format('YYYY-MM-DD')
  const {
    params: { dpId },
  } = useRouteMatch("/DateWiseDpProductList/:dpId")
  console.log(dpId)
  const {
    params: { specificDate },
  } = useRouteMatch("/DateWiseDpProductList/:dpId/:specificDate")
  console.log(specificDate)
  const { dpOrderActivities, authtoken, dpOrderActivityLoading, id } =
    useSelector(state => ({
      dpOrderActivities: state.ActivityReducer.dpOrderActivities,
      id: state.Login.id,
      authtoken: state.Login.token,
      dpOrderActivityLoading: state.ActivityReducer.dpOrderActivityLoading,
      // dpOrderActivityLoading: state.ActivityReducer.dpOrderActivityLoading,

    }))
  useEffect(() => {
    dispatch(getDpOrderActivity(authtoken, dpId, specificDate))
  }, [])
  let dpOrder
  let temptest 
  let arr 
  let distinct
  if (!dpOrderActivityLoading) {
    dpOrder = dpOrderActivities.data.order
    temptest = dpOrderActivities?.data?.historycheck?.products
    arr = dpOrderActivities.data.test
  }
  {
    !dpOrderActivityLoading?(distinct = _.map(temptest, function(item) {
      return _.merge(item, _.find(arr, { 'productname' : item.productname }));
  })): (console.log("not getting"))
  }
  {
    (dpOrder || []).map((data, key) => {
      console.log(key);
      dpOrder[key].productName =  data._id.productName

     
        // productName = data.name
        // productQuantity = data.productQuantity
        // productPrice = data.price
        // productImage = data.image
    
    })
  }
  console.log('hihi',distinct);
  const printDpProductList = () => {
    window.print()
  }
  console.log(dpOrder)
  console.log(dpOrderActivities)
  console.log(dpOrderActivityLoading)
  return (
    <div>
      <div>
        <div className="page-content">
          <Container fluid={true}>
            <h4 className="mb-4">January 2022</h4>
            {/* <Breadcrumbs title="Add Inventory" breadcrumbItem="Add Inventory" /> */}
            <Card>
              <div>
                <div className="add-inventory-serach">
                  <div>
                    <input placeholder="search here"></input>
                  </div>
                  <div>
                    <Link
                      to={`/DateWiseDpProductList/${dpId}/${specificDate}`}
                      className="product-inventory-btn"
                      onClick={printDpProductList}
                    >
                      Download Pdf
                    </Link>
                  </div>
                  <div>
                  
                    
                      <CSVLink data={distinct||[]}  className="product-inventory-btn">Download CSV</CSVLink>
                   
                  </div>
                  
                </div>
              </div>

              <Table hover>
                <thead className="dp-product-list-activity">
                  <tr>
                    <th>NO</th>
                    <th>Product List</th>
                    <th>Order received</th>
                    <th>Qty Price</th>
                    <th>Amount</th>
                    <th>Delivered Quantity</th>
                    <th>Delivered Price</th>
                    <th>Reconciliation</th>
                  
                   
                  </tr>
                </thead>
                <tbody>
                  {(distinct || []).map((data, key) => {
                    return (
                      <>
                        <tr>
                          <th scope="row">{key + 1}</th>
                          <td>{data.productname}</td>
                          <td>{data.productqty}</td>
                          <td>{data.unitPrice}</td>
                          <td>{data.productqty*data.unitPrice}</td>
                          <td>
                          {data.sumQuantity? (data.sumQuantity): ("-")}
                          </td>
                          <td>
                          {data.sumQuantity? (data?.sumQuantity*data.unitPrice): ("-")}
                          </td>
                          
                          <td>
                          {data.sumQuantity? (data?.sumQuantity*data.unitPrice): ("-")}
                          </td>
                       
                         
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </Table>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default DateWiseDpProductList
