import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import DashboardFilter from "components/Dashboard/DashboardFilter"
import { tableQuery } from "helpers/Custom/makeQuery"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, Container, Row, Spinner } from "reactstrap"
import { getProductData, storeProductData } from "store/ProductTable/action"
import XLSX from "xlsx"
import routeListDown from "../../assets/images/routeListDown.svg"

import { AvField, AvForm } from "availity-reactstrap-validation"
import { getDeliveryOrderExcel,getRangeSalesexcel } from "../../helpers/backend_helper"
import moment from "moment"
const tableHead = [
  "No.",
  "Shop Name",
  "SO Name",
  "Grand Total",
  "Order taken Date",
  "Delivery Date",
  "View Details",
]

const orderTableHead = [
  "No.",
  "Brand Name",
  "Product Name",
  "Quantity",
  "Total",
]

const ProductList = () => {
  const dispatch = useDispatch()
  const [orderExcel, setOrderExcel] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedProduct, setSelectedProduct] = useState([])
  const [orderRangeExcel, setOrderRangeExcel] = useState(false)
  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  )
  const [orderStartDate, setOrderStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [orderEndDate, setOrderEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const {
    authToken,
    deliveryExcel,
    mainFilter,
    isSubFilterChanged,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    currentSelection,
    products,
    pageNumber,
    productLoading,
    deliveryExcelLoading,
  } = useSelector(state => ({
    overview: state.DashboardReducer.dashboardCharts.data,
    deliveryExcel: state.OrderReducer.deliveryExcel,
    authToken: state.Login.token,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    mainFilter: state.DashboardReducer.mainFilter,
    currentSelection: state.DashboardReducer.currentSelection,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
    products: state.productData?.data?.sales,
    pageNumber: state.productData?.data?.pagenumber,
    productLoading: state.productData?.loading,
    deliveryExcelLoading: state.OrderReducer.deliveryExcelLoading,
  }))

  console.log(
    `file: DataTable.js ~ line 12 ~ DataTable ~ currentPage`,
    tableQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection,
      currentPage
    )
  )

  useEffect(() => {
    const query = tableQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection,
      currentPage
    )
    dispatch(getProductData(authToken, query))
  }, [isSubFilterChanged, currentPage])
  const handleDetails = (index, selectedItem) => {
    setSelectedProduct(selectedItem.orderProducts)
    const oldData = [...products]
    console.log("oldData", oldData)
    oldData?.map((data, i) => {
      console.log("index", index)

      if (i === index) {
        data.view = !data.view
      } else {
        data.view = false
      }
      return data
    })
    dispatch(
      storeProductData("data", { sales: oldData, pagenumber: pageNumber })
    )
  }
  function convertDigitIn(str) {
    console.log("str", str.split("-").reverse().join("-"))
    return str.split("-").reverse().join("-")
  }
  const handleChangeDate = e => {
    const value = convertDigitIn(e.target.value)

    setCurrentDate(value)
  }
  const handleExcel = async () => {
    console.log("hello")
    setOrderExcel(true)
    try {
      console.log("hello 343")
      var response = await getDeliveryOrderExcel(authToken, currentDate)
      console.log("excel", response)
    } catch (error) {}
    let newArray = []
    response?.todayallorder?.forEach((order, index) => {
      let data = {}
      data.Shop = order?.shop?.name
      data.DSRName = order?.sr?.name
      data.DSRName = order?.dp?.name
      data.officeName = order?.office?.name
      data.officeId = order?.office?.distributioncode
      data.lineManager = order?.linemanager?.employeeId
      data.region = order?.region?.name
      data.area = order?.area?.name
      data.territory = order?.teritori?.name
      data.OrderTakenDate = order?.orderTakenDate
      data.OrderDeliveryDate = order?.deliverdate
      data.grandtotal =  parseFloat(parseFloat(order?.grandTotal).toFixed(2))
      order.orderProducts.forEach((dt, idx) => {
        data[`ProductName-${idx}`] = dt?.productName
        data[`ProductQty-${idx}`] = Math.ceil(dt?.qty)
        data[`UnitPrice-${idx}`] =  parseFloat(parseFloat(dt?.unitPrice).toFixed(2))
        data[`Total-${idx}`] = parseFloat(parseFloat(dt?.total).toFixed(2))
      })
      console.log(data)
      newArray.push(data)
    })
    downloadxls(newArray)
  }
  const handleRangeOrder = async () => {
    console.log("hhhh")
    setOrderRangeExcel(true)
    try {
      var response = await getRangeSalesexcel(
        authToken,
        orderStartDate,
        orderEndDate
      )
      console.log("range excel", response)
    } catch (error) {}
    let newArray = []
    response?.orderData?.forEach((order, index) => {
      let data = {}
      data.Shop = order?.shop?.name
      data.SRName = order?.sr?.name
      data.EmployeeId = order?.sr?.employeeId
      // data.DPName = "Dhaka"
      data.orderDeliveryTime = order?.orderdeliverytime
      data.OrderTakenDate = order?.orderTakenDate
      data.OrderDeliveryDate = convertDigitIn(order.deliveryDate)
      data.route = order?.route?.routeCode
      data.routeName = order?.route?.name
      data.officeName = order?.office?.name
      data.officeCode = order?.office?.distributioncode
      ;(data.area = order?.area?.name),
        (data.region = order?.region?.name),
        (data.grandtotal = parseFloat(parseFloat(order?.grandTotal).toFixed(2)))
      // console.log('order.grandTotal',typeof (parseFloat(order.grandTotal)));
      order.orderProducts.forEach((dt, idx) => {
        data[`ProductName-${idx}`] = dt?.productName
        data[`ProductQty-${idx}`] = Math.ceil(dt?.qty)
        data[`UnitPrice-${idx}`] = parseFloat(parseFloat(dt?.unitPrice).toFixed(2))
        data[`Total-${idx}`] = parseFloat(parseFloat(dt?.total).toFixed(2))
      })

      newArray.push(data)
    })
    downloadRangeXls(newArray)
  }

  const downloadRangeXls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "Sales Report.xlsx")
    setOrderRangeExcel(false)
  }
  const downloadxls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "Delivery Order.xlsx")
    setOrderExcel(false)
  }
  const handleStartDate = e => {
    const value = e.target.value

    setOrderStartDate(value)
  }
  const handleEndDate = e => {
    const value = e.target.value

    setOrderEndDate(value)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <CardComponent>
            {/* filter */}
            <Row className="pb-3">
              <DashboardFilter title={""} />
            </Row>
            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={products}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                page={pageNumber}
                currentPage={currentPage}
                isPagination
                productLoading={productLoading}
              >
                {productLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner />
                      </div>
                    </div>
                  </tr>
                ) : products?.length ? (
                  products?.map((data, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td>{data?.shop?.name}</td>
                        <td>{data?.sr?.name}</td>
                        <td>{data?.grandTotal}</td>
                        <td>{data?.orderTakenDate}</td>
                        <td>{data?.deliveryDate}</td>
                        <td>
                          <Button
                            className={`${
                              data.view
                                ? "btn btn-warning btn-sm btn-rounded w-100"
                                : "btn btn-info btn-sm btn-rounded w-100"
                            }`}
                            onClick={() => handleDetails(idx, data)}
                          >
                            {data.view ? "Hide" : "See Details"}
                          </Button>
                        </td>
                      </tr>
                      {data.view ? (
                        <React.Fragment>
                          <tr>
                            <td colSpan="9">
                              <div
                                className="table-responsive"
                                style={{
                                  height: "300px",
                                  overflowY: "scroll",
                                }}
                              >
                                <CustomTable
                                  paginationClass="paginationContainer text-right"
                                  data={selectedProduct}
                                  tableHead={orderTableHead}
                                  isPagination={false}
                                >
                                  {selectedProduct?.length ? (
                                    selectedProduct?.map((data, idx) => (
                                      <React.Fragment key={idx}>
                                        <tr>
                                          <th scope="row">{idx + 1}</th>
                                          <td>{data?.brandName}</td>
                                          <td>{data?.productName}</td>
                                          <td>{data?.qty}</td>
                                          <td>{data?.total}</td>
                                        </tr>
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    <NoTableData
                                      colSpan="9"
                                      className="d-flex justify-content-center align-items-center"
                                      style={{
                                        minHeight: "300px",
                                        width: `100%`,
                                      }}
                                    >
                                      <span>
                                        Currently you have no Product{" "}
                                      </span>
                                    </NoTableData>
                                  )}
                                </CustomTable>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>Currently you have no Data. </span>
                  </NoTableData>
                )}
              </CustomTable>

              {/* table end */}
            </Row>
          </CardComponent>
        </Container>
        <Card>
          <div className="route-excel-attendance">
            {orderExcel ? (
              <div className="text-center  route-excel">
                <Spinner />
              </div>
            ) : (
              <div className="route-excel">
                <div>
                  <p className="mb-0">Download Excelsheet</p>
                </div>
                <div>
                  <button
                    className="route-excel-download"
                    onClick={handleExcel}
                  >
                    {" "}
                    <img src={routeListDown}></img>Download
                  </button>
                </div>
              </div>
            )}
            <div className="date-order-excel">
              <AvForm>
                <AvField
                  name="startTime"
                  id="example-time-input"
                  className="form-control"
                  type="date"
                  value={moment(new Date()).format("YYYY-MM-DD")}
                  onChange={handleChangeDate}
                  errorMessage="Enter Start Time"
                  validate={{ required: { value: true } }}
                ></AvField>
              </AvForm>
            </div>
          </div>
        </Card>
        <Card>
          <div className="route-excel-attendance">
            {orderRangeExcel ? (
              <div className="text-center route-excel">
                <Spinner />
              </div>
            ) : (
              <div className="route-excel">
                <div>
                  <p className="mb-0">Download Excelsheet</p>
                </div>
                <div>
                  <button
                    className="route-excel-download"
                   onClick={handleRangeOrder}
                  >
                    {" "}
                    <img src={routeListDown}></img>Download
                  </button>
                </div>
              </div>
            )}

            <div className="date-order-excel">
              <AvForm className="range-excel download">
                <label htmlFor="range-excel download">From date</label>
                <AvField
                  name="startTime"
                  id="example-time-input"
                  className="form-control"
                  type="date"
                  value={moment(new Date()).format("YYYY-MM-DD")}
                  onChange={handleStartDate}
                  errorMessage="Enter Start Time"
                  validate={{ required: { value: true } }}
                ></AvField>
                <label htmlFor="range-excel download">To Date</label>
                <AvField
                  name="startTime"
                  id="example-time-input"
                  className="form-control mb-3"
                  type="date"
                  value={moment(new Date()).format("YYYY-MM-DD")}
                  onChange={handleEndDate}
                  errorMessage="Enter Start Time"
                  validate={{ required: { value: true } }}
                ></AvField>
              </AvForm>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default ProductList
