import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { addSurvey } from "../../store/SurveyInfo/action"
import { toast } from "react-toastify";

const SurveyAdd = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [file, setfile] = useState("")
  const { authtoken, loading, id,adding } = useSelector(state => ({
    authtoken: state.Login.token,
    id: state.Login.id,
    adding: state.SurveyListReducer.adding 

  }))

  async function handleSubmit(event, errors, values) {
    console.log(values.name)
    event.preventDefault()
    let obj = new FormData()

    if (values.name && file) {

      dispatch(addSurvey(obj, history, authtoken))
    }
    else{
      toast("Please Select all the field", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Create Survey</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="User" breadcrumbItem="Create Survey" />
          <Row>
            <Col md={6} className="m-auto">
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4 mb-4">Survey Name</CardTitle> */}

                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Survey name
                          </Label>
                          <AvField
                            name="name"
                            id="formrow-firstname-Input"
                            type="text"
                            className="form-control mb-2"
                            placeholder="Type Survey Name"
                            errorMessage="Enter Survey"
                            validate={{ required: { value: true } }}
                          />
                          <Label htmlFor="discount">Image</Label>
                          <AvField
                            type="file"
                            className="form-control"
                            id="formrow-brand-Input"
                            name="photo"
                            onChange={e => {
                              setfile(e.target.files[0])
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* {false ? (
                      <div className="p-4 d-flex justify-content-center align-items-center p-2 mb-3 mt-5 mr-auto">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    ) : ( */}
                      <div>
                        <button
                         disabled={adding}
                          type="submit"
                          className="btn btn-primary w-md"
                          onSubmit={handleSubmit}
                        >
                         {adding ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    {/* )} */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* end row  */}
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default SurveyAdd
