import Breadcrumbs from "components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router"
import { useHistory, withRouter } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
import '../../../pages/DistributionDashboard/Order/TableDistShopOrder'
// import { getOrders } from '../../store/Order/actions';
// import { getRoute } from "../../store/GeoInformation/actions";
// import { getDateOrders } from "../../store/Order/actions"
import { getDistShopOrders } from '../../../store/Order/actions';
import XLSX from "xlsx"
const TableDistShopOrder = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    params: { date },
  } = useRouteMatch("/distShopOrder/:distId/:date")
  console.log("hello", date)



  const { distShopOrder, authtoken, distShopOrderLoading,orgId,officeId } = useSelector(state => ({
    distShopOrder: state.OrderReducer.distShopOrder,
    orgId: state.Login.org,
    officeId: state.Login.office,
    authtoken: state.Login.token,
    distShopOrderLoading: state.OrderReducer.distShopOrderLoading,
  }))

  useEffect(() => {
    dispatch(getDistShopOrders(authtoken, orgId, officeId, date))
  }, [])
console.log(distShopOrder);
let singleSubTotal, singleCollectAmount,orderTotal = 0,  singleOrderTotal , totalCollect = 0,due,allShopOrder
if(!distShopOrderLoading){
  allShopOrder =distShopOrder.data.allorders
  {
    (allShopOrder || []).map((dateOrder, key) => {
      singleSubTotal = Math.ceil(parseFloat(dateOrder?.grandTotal))
      singleCollectAmount = Math.ceil(parseFloat(dateOrder?.collectAmount))
      singleOrderTotal = Math.ceil( dateOrder?.grandTotal)

    
      if (singleSubTotal<=singleCollectAmount) {
        distShopOrder.data.allorders[key].dueInfo = 0 
      }
      if(singleSubTotal>singleCollectAmount){
        distShopOrder.data.allorders[key].dueInfo =  singleSubTotal - singleCollectAmount
      }
    });
  }
}
  const tableData =
  !distShopOrderLoading &&
  distShopOrder.data.allorders.map((dateOrder, index) => {
      return {
        sl: index + 1,
        heading1: dateOrder?.shop?.name,
        heading2: Math.ceil(dateOrder?.grandTotal),
        heading3: Math.ceil(dateOrder?.collectAmount),
        heading4: Math.ceil(dateOrder.dueInfo),
        heading5: dateOrder?.sr?.name,
        heading6: dateOrder?.dp?.name,

        manage: (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded btn-clr"
              onClick={() => {
                history.push(`/DistInvoice/${dateOrder._id}`)
              }}
            >
              Invoice
            </Button>
          </>
        ),
        status: (
          <>
            <Badge className={"bg-success"}>Active</Badge>
          </>
        ),
      }
    })

  const orderData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },

      {
        label: "Shop List",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Amount(tk)",
        field: "heading2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Receive Amount(tk)",
        field: "heading3",
        sort: "asc",
        width: 150,
      },
      {
        label: "Due Amount(tk)",
        field: "heading4",
        sort: "asc",
        width: 150,
      },

      {
        label: "SR Name",
        field: "heading5",
        sort: "asc",
        width: 150,
      },
      {
        label: "DP Name",
        field: "heading6",
        sort: "asc",
        width: 150,
      },

      {
        label: "View",
        field: "manage",
      },
    ],
    rows: tableData,
  }
  const handleExcel = () => {
    let newArray = []
    allShopOrder?.forEach((order, index) => {
      console.log('ORDER',order);
      let data = {}
      data.ShopName = order.shop.name
      data.SRName = order.sr.name
      data.DpName = order.dp.name
      data.OrderDate = order.orderTakenDate
      data.DeliveryDate = order.deliveryDate
       order.orderProducts.forEach((dt, idx) => {
        data[`ProductName-${idx+1}`] = dt.productName
        data[`ProductQty-${idx+1}`] = dt.qty
        data[`UnitPrice-${idx+1}`] = dt.unitPrice
        data[`Total-${idx+1}`] = dt.total
      })
      data.GrandTotal = order.grandTotal
      data.CollectAmount = order.collectAmount
      newArray.push(data)
    })
    downloadxls(newArray)
  }
  const downloadxls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "inventory.xlsx")
  }

  return (
    <React.Fragment>
     <div className="page-content">
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <MetaTags>
          <title>Order | DDC</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Order"
            breadcrumbItem="please select shop for see orders"
          />
          <div>
              <div className="add-inventory-serach">
              
              
                <button className="product-inventory-btn"  onClick={handleExcel}>
                  Download xlsx
                </button>
              </div>
            </div>
          {!distShopOrderLoading ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={orderData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            ) : (
              <div className="text-center pt-5">
                <Spinner />
              </div>
            )}
        </Container>
      </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(TableDistShopOrder)

