import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import Select from "react-select";
import {
    Card, CardBody,
    CardTitle, Col,
    Container, FormGroup,
    Label, Row, Spinner
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { addConsumerOffer, addRetailerOffer } from "../../store/Offer/actions";
import { getProducts } from '../../store/Product/actions';
import './AddOfferTime.css';
import OfferList from "./offerList";


const AddOfferItem = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [offerFor, setOfferFor] = useState('');
    const [offerType, setOfferType] = useState('');
    const [offerName, setOfferName] = useState('');
    const {
        params: { offerid },
    } = useRouteMatch('/add-offer-item/:offerid');

    const { authtoken, id, productLoading, products } = useSelector(state => ({
        products: state.ProductReducer.products,
        authtoken: state.Login.token,
        id: state.Login.id,
        productLoading: state.ProductReducer.productLoading
    }))
    // console.log(products.data.product);

    function changeOfferType(e) {
        const offerType = document.getElementById('offerType')
        if (offerType.value == 'Total Amount') {
            document.getElementById('price').style.display = 'block'
            document.getElementById('totalQuantity').style.display = 'none'

        }
        if (offerType.value == 'Fixed Quantity') {
            document.getElementById('price').style.display = 'none'
            document.getElementById('totalQuantity').style.display = 'block'

        }
        setOfferType(e.target.value)


    }
   
    function changeOffer(e) {
        const offer = document.getElementById('offer')
        if (offer.value == 'Discount Offer') {
            document.getElementById('totaldiscount').style.display = 'block'
            document.getElementById('flattotal').style.display = 'none'
            document.getElementById('totalproduct').style.display = 'none'
            document.getElementById('freeProdQty').style.display = 'none'
        }
        if (offer.value == 'Product Offer') {
            document.getElementById('totalproduct').style.display = 'block'
            document.getElementById('freeProdQty').style.display = 'block'
            document.getElementById('totaldiscount').style.display = 'none'
            document.getElementById('flattotal').style.display = 'none'

        }
        if (offer.value == 'Flat offer') {
            document.getElementById('flattotal').style.display = 'block'
            document.getElementById('totaldiscount').style.display = 'none'
            document.getElementById('totalproduct').style.display = 'none'
            document.getElementById('freeProdQty').style.display = 'none'
        }
        setOfferName(e.target.value)
      

    }
    useEffect(() => {
        dispatch(getProducts(authtoken,id));
    }, []);


    const handleChangeProduct = (value) => {
        setSelectedProduct(value._id)
    }
   
    async function handleSubmit(event, errors, values) {
        event.preventDefault()
        let obj = {}         
        if (values.amount) {
            obj.amount = values.amount
        }
        if (values.quantity) {
            obj.quantity = values.quantity
        }
                 
        if (values.discount) {
            obj.discount = values.discount
        }
       if(values.product){
           obj.product=selectedProduct
       }
       if(values.freeProductQty){
        obj.freeProductQty=values.freeProductQty
    }
               
        if (values.totalAmounDiscount) {
            obj.totalAmounDiscount = values.totalAmounDiscount
        }
        
        // obj.product = selectedProduct
        obj.type =offerType
        obj.name = offerName
       
        if (offerFor === 'Consumer') {
            console.log(obj);
            console.log('inside of consumer');
            dispatch(addConsumerOffer(obj, history, authtoken, offerid))
        } else {
            console.log(obj);
            console.log('retailer');
            dispatch(addRetailerOffer(obj, history, authtoken, offerid))
         }
      


    }

    return (
        <React.Fragment>

            <div className="page-content">
                <MetaTags>
                    <title>DDC | Create Offer</title>
                </MetaTags>
                <Container fluid={true} >
                    <Breadcrumbs title="Create Offer" breadcrumbItem=" Create Offer" />
                    {!productLoading ? (
                        <div className="text-center pt-5">
                            <Spinner />
                        </div>
                    ) : (
                        <Row>
                            <Col md={6} className='m-auto'>
                                <Card >
                                    <CardBody >
                                        <CardTitle className="h4 mb-4">Offer Item</CardTitle>

                                        <AvForm onSubmit={handleSubmit}  >
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="example-offerFor-input">Offer For</Label>
                                                        <div className="mb-3 row">
                                                            <div className="col-md-12">
                                                                <select onChange={(e) => {
                                                                    setOfferFor(e.target.value)
                                                                    console.log(e);
                                                                }} className="form-select">
                                                                    <option value=''>Consumer/Retailer</option>
                                                                    <option value='Consumer'>Consumer</option>
                                                                    <option value='Retailer'>Retailer</option>

                                                                </select>
                                                            </div>
                                                        </div>



                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="example-offerType-input">Offer Type</Label>
                                                        <div className="mb-3 row">
                                                            <div className="col-md-12">
                                                                <select className="form-select" id='offerType' name='type' onChange={changeOfferType} 
                                                                >
                                                              
                                                                    <option value=''>Total amount/Fixed Quantity</option>
                                                                    <option value='Total Amount'>Total amount</option>
                                                                    <option value='Fixed Quantity'>Fixed Quantity</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                      
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={12} >
                                                    <FormGroup className="mb-3" id="price" >
                                                        <Label htmlFor="amount">Amount</Label>
                                                        <AvField

                                                            name="amount"
                                                            type="number"
                                                            className="form-control"

                                                            placeholder='Type Amount'
                                                            errorMessage="Enter Amount"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup className="mb-3" id="totalQuantity">
                                                        <Label >Quantity</Label>
                                                        <AvField
                                                            name="quantity"
                                                            type="number"
                                                            className="form-control"

                                                            placeholder='Type Quantity'
                                                            errorMessage="Enter Quantity"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="formrow-offer">Offer</Label>
                                                        <div className="mb-3 row">
                                                            <div className="col-md-12">
                                                                <select className="form-select" id='offer' name='name' onChange={changeOffer} >
                                                                    <option value='choose'>Discount/Product/Flat</option>
                                                                    <option value='Discount Offer'>Discount</option>
                                                                    <option value='Product Offer'>Product</option>
                                                                    <option value='Flat offer'>Flat</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col md={12}>
                                                    <FormGroup className="mb-3" id="totaldiscount">
                                                        <Label htmlFor="discount">Discount</Label>
                                                        <AvField
                                                            name="discount"
                                                            type="number"
                                                            className="form-control"

                                                            placeholder='Type Total Discount'
                                                            errorMessage="Enter Discount Amount"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col md={12}>
                                                    <FormGroup className="mb-3" id="totalproduct">
                                                        <Label >Product</Label>
                                                        <Select
                                                            name='product'
                                                            cacheOptions
                                                            getOptionLabel={e => e.name}
                                                            getOptionValue={e => e._id}
                                                            options={products.data.product}
                                                            isClearable
                                                            onChange={handleChangeProduct}

                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup className="mb-3" id="flattotal">
                                                        <Label htmlFor="discount">Flat</Label>
                                                        <AvField
                                                            name="totalAmounDiscount"
                                                            type="text"
                                                            className="form-control"

                                                            placeholder='Type Flat Discount'
                                                            errorMessage="Enter Flat Discount"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup className="mb-3" id="freeProdQty">
                                                        <Label >Free Quantity</Label>
                                                        <AvField
                                                            name="freeProductQty"
                                                            type="number"
                                                            className="form-control"

                                                            placeholder='Type Quantity'
                                                            errorMessage="Enter Quantity"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <div>
                                                <button type="submit" className="btn btn-primary w-md">
                                                    Submit
                                                </button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>


                        </Row>

                    )}


                </Container>
                {/* container-fluid */}
            </div>
           <OfferList/>
        </React.Fragment >
    )

}





export default AddOfferItem