import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner
} from "reactstrap"


import { Link } from "react-router-dom"
import StoreTableData from './TableStoreData'
import { useDispatch, useSelector } from "react-redux";
import '../../pages/styles.css'
import { getStores } from '../../store/Stores/actions';
import SampleStoreTable from "./SampleStoreTable";


const StoreInfo = () => {
      
    const dispatch = useDispatch();

    const { stores, authtoken, loading, id } = useSelector(state => ({
        stores: state.StoreReducer.stores,
        authtoken: state.Login.token,
        id: state.Login.id,
        loading: state.StoreReducer.loading,
    }));
    useEffect(() => {
      dispatch(getStores(authtoken, id));
  }, []);

console.log('bbbbb',stores);
 
    return (
        <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>DDC</title>
          </MetaTags>
          <Container fluid>
            <h4 className='mb-3'>Store List</h4>
            {loading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (

            <Row>

              <Col xl="12">
                <Row>

                  {/* storeData Render */}
                 
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Total Store
                              </p>
                              <h4 className="mb-0">{stores?.shop}</h4>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                            Top Ordered Store
                              </p>
                              <h6 className="mb-0">{stores?.shopname[0]?.storename[0]?.name}</h6>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
              <Card className='card-info-list' >
                  <CardBody >
                    <CardTitle className="mb-0 h4">Store add Analytics</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/store-add-analytics"
                            className="btn btn-primary btn-md"
                          >
                            View Analytics
                          </Link>
                        </div>
                      </Col>
                     
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
                 
                    
              <Col md="3">
              <Card className='card-info-list'>
                  <CardBody >
                    <CardTitle className="mb-0 h4">Add New Store</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/add-store"
                            className="btn btn-primary btn-md"
                          >
                            Add Store
                          </Link>
                        </div>
                      </Col>
                     
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
                 
                </Row>
               

              </Col>
            </Row>
                    )}
        <SampleStoreTable/>
         
          </Container>
        </div>


      </React.Fragment >
    );
};

export default StoreInfo;