import {
    ADD_NEW_PJP,
    ADD_PJP_SUCCESS,
    ADD_PJP_FAIL,
    GET_ALLPJP,
    GET_ALLPJP_SUCCESS,
    GET_ALLPJP_FAIL
  } from "./actionTypes"

  export const addNewPJP = (data, history, authtoken) => ({
    type: ADD_NEW_PJP,
    payload: { data, history , authtoken},
  })
  
  export const addPJPSuccess = data => ({
    type: ADD_PJP_SUCCESS,
    payload: data,
  })
  
  export const addPJPFail = error => ({
    type: ADD_PJP_FAIL,
    payload: error,
  })
  export const getAllPJP = (authtoken) => ({
    type: GET_ALLPJP,
    payload: { authtoken }
  })
  
  export const getAllPJPSuccess = data => ({
    type: GET_ALLPJP_SUCCESS,
    payload: data,
  })
  
  export const getAllPJPFail = error => ({
    type: GET_ALLPJP_FAIL,
    payload: error,
  })