import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import useDebounce from "Hooks/useDebounce"
//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";

import { MDBDataTable } from "mdbreact";

import {
    Badge, Button, Col,
    Card,
    CardBody,
    Container,
    Row, Spinner, Table, UncontrolledDropdown
} from "reactstrap";
 import { getRouteInfo } from '../../../store/GeoInformation/actions.js';

const tableHead = ["No.", "Name", "Route Code", "View Details"]
const RouteInfoTable = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [currentPage, setCurrentPage] = useState(1)
    const { value, onChange } = useDebounce(
      () => dispatch(getRouteInfo(authtoken, id, currentPage, value)),
      1000
    )
    const { routeInfo, authtoken, id,routeInfoLoading,pageNumber} = useSelector(state => ({
      routeInfo: state?.GeoInformationReducer?.routeInfo,
      authtoken: state.Login.token,
      id: state.Login.id,
      pageNumber: state?.GeoInformationReducer?.routeInfo?.data?.pagenumber,
      routeInfoLoading: state?.GeoInformationReducer?.routeInfoLoading,
    }))
 
    useEffect(() => {
      dispatch(getRouteInfo(authtoken, id, currentPage, value))
    }, [currentPage])


  return (
    <React.Fragment>
     
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <CardComponent>
          <div className="attendance-date">
              <AvForm>
                <AvField
                  name="startTime"
                  id="example-time-input"
                  className="form-control mb-3"
                  type="search"
                  defaultValue={value}
                  placeholder = 'Search Route Name'
                  onChange = {onChange}
                ></AvField>
              </AvForm>
            </div>
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={routeInfo?.data?.routes}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                page={pageNumber}
                currentPage={currentPage}
                isPagination
                routeInfoLoading={routeInfoLoading}
              >
                {routeInfoLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner />
                      </div>
                    </div>
                  </tr>
                ) : routeInfo?.data?.routes?.length ? (
                    routeInfo?.data?.routes?.map((route, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td>{route?.name}</td>
                        <td>{route?.routeCode}</td>
                      
                     
                        <td>
                          <Button
                              color="primary"
                              className="btn-sm btn-rounded"
          
                            onClick={() => {
                              history.push(`/route-details/${route?._id}`)
                            }}
                              
                          >
                              View Details
                          </Button>
                        </td>
                        
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>Currently you have no Data. </span>
                  </NoTableData>
                )}
              </CustomTable>

              {/* table end */}
            </Row>
          </CardComponent>
        </Container>
        <Card>
        </Card>
    </React.Fragment>
  )
}

export default RouteInfoTable
