import {
  ADD_AREA_FAIL,
  ADD_AREA_SUCCESS,
  ADD_GEO_FAIL,
  ADD_GEO_SUCCESS,
  ADD_NEW_AREA,
  ADD_NEW_GEO,
  ADD_NEW_REGION,
  ADD_NEW_TERRITORY,
  ADD_REGION_FAIL,
  ADD_REGION_SUCCESS,
  ADD_ROUTE_FAIL,
  ADD_ROUTE_SUCCESS,
  ADD_TERRITORY_FAIL,
  ADD_TERRITORY_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  GET_AREAS_FAIL,
  GET_AREAS_SUCCESS,
  GET_DATA,
  GET_DETAILS_ROUTES_FAIL,
  GET_DETAILS_ROUTES_SUCCESS,
  GET_GEOS_FAIL,
  GET_GEOS_SUCCESS,
  GET_GEO_ROUTES_FAIL,
  GET_GEO_ROUTES_SUCCESS,
  GET_REGIONS_FAIL,
  GET_REGIONS_SUCCESS,
  GET_ROUTES_FAIL,
  GET_ROUTES_SUCCESS,
  GET_ROUTE_STORE_FAIL,
  GET_ROUTE_STORE_SUCCESS,
  GET_TERRITORYS_FAIL,
  GET_TERRITORYS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  GET_ROUTES_INFO_SUCCESS,
  GET_ROUTES_INFO_FAIL
} from "./actionTypes"

const INIT_STATE = {
  geo: [],
  area: [],
  region: [],
  territory: [],
  routes: [],
  geoRoute: [],
  error: {},
  DetailsRoute: [],
  shopRoute: [],
  routeInfo:[],
  loading: true,
  isLoading: true,
  arealoading: false,
  territoryload: false,
  regionloading: false,
  geoloading: false,
  routeloading: false,
  DetailsRouteLoading: true,
  RouteShopLoading: true,
  geoRouteLoading: true,
  routeInfoLoading:true
}

const GeoInformationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA: {
      return {
        ...state,
        error: {},
        isLoading: true,
      }
    }

    case ADD_NEW_AREA: {
      return {
        ...state,
        error: {},
        isLoading: true,
      }
    }

    case ADD_AREA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        area: [...state.area, action.payload],
      }

    case ADD_AREA_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case ADD_NEW_REGION: {
      return {
        ...state,
        error: {},
        isLoading: true,
      }
    }
    case ADD_REGION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        region: [...state.region, action.payload],
      }

    case ADD_REGION_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case ADD_NEW_REGION: {
      return {
        ...state,
        error: {},
        isLoading: true,
      }
    }
    case ADD_NEW_TERRITORY: {
      return {
        ...state,
        error: {},
        isLoading: true,
      }
    }
    case ADD_TERRITORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        territory: [...state.territory, action.payload],
      }

    case ADD_TERRITORY_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case ADD_ROUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        routes: [...state.routes, action.payload],
      }

    case ADD_ROUTE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case ADD_NEW_GEO: {
      return {
        ...state,
        error: {},
        isLoading: true,
      }
    }
    case ADD_GEO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        geo: [...state.geo, action.payload],
      }

    case ADD_GEO_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    case GET_ROUTES_SUCCESS:
      return {
        ...state,
        routes: action.payload,
        routeloading: true,
      }
    case GET_ROUTES_FAIL:
      return {
        ...state,

        error: action.payload,
        loading: true,
        //change here
      }
    case GET_GEO_ROUTES_SUCCESS:
      return {
        ...state,
        geoRoute: action.payload,
        geoRouteLoading: false,
      }
    case GET_GEO_ROUTES_FAIL:
      return {
        ...state,

        error: action.payload,
        geoRouteLoading: true,
        //change here
      }
    case GET_DETAILS_ROUTES_SUCCESS:
      return {
        ...state,
        DetailsRoute: action.payload,
        DetailsRouteLoading: false,
      }
    case GET_DETAILS_ROUTES_FAIL:
      return {
        ...state,

        error: action.payload,
        DetailsRouteLoading: true,
        //change here
      }
    case GET_ROUTE_STORE_SUCCESS:
      return {
        ...state,
        shopRoute: action.payload,
        RouteShopLoading: false,
      }
    case GET_ROUTE_STORE_FAIL:
      return {
        ...state,

        error: action.payload,
        RouteShopLoading: true,
        //change here
      }

    case GET_AREAS_SUCCESS:
      return {
        ...state,
        area: action.payload,
        arealoading: true,
        isLoading: false,
      }
    case GET_AREAS_FAIL:
      return {
        ...state,
        arealoading: false,
        error: action.payload,
        //change here
      }
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        region: action.payload,
        regionloading: true,
        isLoading: false,
      }
    case GET_REGIONS_FAIL:
      return {
        ...state,
        regionloading: false,
        error: action.payload,
        //change here
      }
    case GET_TERRITORYS_SUCCESS:
      return {
        ...state,
        territory: action.payload,
        territoryload: true,
        isLoading: false,
      }
    case GET_TERRITORYS_FAIL:
      return {
        ...state,
        territoryload: false,
        error: action.payload,
        //change here
      }
    case GET_GEOS_SUCCESS:
      return {
        ...state,
        geo: action.payload,
        geoloading: true,
      }
    case GET_GEOS_FAIL:
      return {
        ...state,
        geoloading: false,
        error: action.payload,
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: true,
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      }

    case GET_USER_FAIL:
      return {
        ...state,
        loading: true,
        error: action.payload,
        //change here
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: true,
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product.id.toString() !== action.payload.id.toString()
        ),
        loading: true,
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case GET_ROUTES_INFO_SUCCESS:
        return {
          ...state,
          routeInfo: action.payload,
          routeInfoLoading: false,
        }
      case GET_ROUTES_INFO_FAIL:
        return {
          ...state,
  
          error: action.payload,
          routeInfoLoading: true,
        }

    default:
      return state
  }
}

export default GeoInformationReducer
