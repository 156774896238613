import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import Select from "react-select"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { addNewArea, getRegion } from "../../store/GeoInformation/actions"

const AddArea = () => {
  const [selectedregion, setselectedregion] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const { authtoken, id, region, regionloading } = useSelector(state => ({
    region: state.GeoInformationReducer.region.region,
    authtoken: state.Login.token,
    id: state.Login.id,
    regionloading: state.GeoInformationReducer.regionloading,
  }))
  useEffect(() => {
    dispatch(getRegion(authtoken))
  }, [])
  function handleSubmit(event, errors, values) {
    event.preventDefault()
    let obj = {}
    obj.name = values.name
    obj.region = selectedregion
    obj.org = id
    console.log(values)
    console.log(authtoken)
    console.log(obj)
     dispatch(addNewArea(obj, history, authtoken))
  }
  const handleChangeregion = value => {
    setselectedregion(value._id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Add Area</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Add Area" breadcrumbItem="Add Area" />
          <Row>
            <Col md={6} className="m-auto">
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Add Area</CardTitle>

                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-yourRegion-Input">
                            Area Name
                          </Label>
                          <AvField
                            type="text"
                            className="form-control"
                            id="formrow-yourRegion-Input"
                            placeholder="Type Area Name"
                            name="name"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {regionloading ? (
                        <Col md={12}>
                          <label>Region</label>
                          <div className="mb-3">
                            <Select
                              cacheOptions
                              getOptionLabel={e => e.name}
                              getOptionValue={e => e._id}
                              options={region}
                              defaultOptions
                              onChange={handleChangeregion}
                            />
                          </div>
                        </Col>
                      ) : (
                        <p>hello</p>
                      )}
                    </Row>
                    <button type="submit" className="btn btn-primary w-md">
                      Add Area
                    </button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* end row  */}
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}
export default AddArea
