// //Import Breadcrumb
// import Breadcrumbs from "components/Common/Breadcrumb";
// import { map } from "lodash";
// import React, { useEffect } from "react";
// import MetaTags from "react-meta-tags";
// //redux
// import { useDispatch, useSelector } from "react-redux";
// import { Link, withRouter } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
// import { Badge, Button, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown ,Spinner} from "reactstrap";
// import { getOffers } from "../../store/Offer/actions.js";






// const OfferTable = props => {

//   const dispatch = useDispatch();
//   const history = useHistory()

//   const { offers, authtoken, loading } = useSelector(state => ({
//       offers: state.OfferReducer.offers.data,
//       authtoken: state.Login.token,
//       loading: state.OfferReducer.loading
//   }));
//   useEffect(() => {
//     dispatch(getOffers(authtoken));
// }, []);

// // console.log(offers.data.offer);
// console.log(loading);


//   return (
//     <React.Fragment>
//       {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
//       <div >
//         <MetaTags>
//           <title>Offer |  DDC</title>
//         </MetaTags>
//         <Container fluid>
//           <Breadcrumbs title="Offer List" breadcrumbItem="Offer" />
//           {loading ? (
//                         <div className="text-center mt-5 pt-5">
//                             <Spinner />
//                         </div>
//                     ) : (
               
//           <Row>
//             <Col lg="12">
//               <div className="">
//                 <div className="table-responsive">
//                   <Table className="project-list-table table-nowrap align-middle table-borderless">
//                     <thead>
//                       <tr>

//                         <th scope="col">Offer Title</th>
//                         <th scope="col">Category</th>
//                         <th scope="col">Expire Date</th>
//                         <th scope="col">Status</th>
//                         <th scope="col">Add Offer Item</th>

//                         <th scope="col">Action</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {map(offers.offer, (offer, index) => (
//                         <tr key={index}>

//                           <td>
//                             <p className="text-muted mb-0">{offer.title}</p>
//                           </td>
//                           <td>
//                             <p className="text-muted mb-0">  {offer.category}</p>
//                           </td>
//                           <td>
//                             <p className="text-muted mb-0">{offer.expireDate}</p>
//                           </td>
//                           <td>
//                             <Badge className={"bg-success"}>
//                               Active
//                             </Badge>
//                           </td>
//                           <td>
                          
//                               <Button
//                                 type="button"
//                                 color="primary"
//                                 className="btn-sm btn-rounded"
//                                 onClick={() => {
//                                   history.push(`/add-offer-item/${offer._id}`)
//                                 }}

//                               >
//                                 Add Offer Item
//                               </Button>
                            
//                           </td>
//                           <td>
//                             <UncontrolledDropdown>
//                               <DropdownToggle
//                                 href="#"
//                                 className="card-drop"
//                                 tag="i"
//                               >
//                                 <i className="mdi mdi-dots-horizontal font-size-18" />
//                               </DropdownToggle>
//                               <DropdownMenu className="dropdown-menu-end">
//                                 <DropdownItem
//                                   href="#"
//                                 // onClick={() => handleProductData(user)}
//                                 >
//                                   <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
//                                   Edit
//                                 </DropdownItem>
//                                 <DropdownItem
//                                   href="#"
//                                 // onClick={() => handleDeleteProject(user)}
//                                 >
//                                   <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
//                                   Delete
//                                 </DropdownItem>
//                               </DropdownMenu>
//                             </UncontrolledDropdown>
//                           </td>







//                         </tr>
//                       ))}
//                     </tbody>
//                   </Table>

//                 </div>
//               </div>
//             </Col>
//           </Row>
//            )}




//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };



// export default withRouter(OfferTable);
import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";

import { MDBDataTable } from "mdbreact";

import {
    Badge, Button, Col,
    Card,
    CardBody,
    Container,
   Row, Spinner
} from "reactstrap";

import { getOffers } from "../../store/Offer/actions.js";



const OrderDataTable = props => {
 
  const dispatch = useDispatch();
  const history = useHistory()

  const { offers, authtoken, loading } = useSelector(state => ({
      offers: state.OfferReducer.offers.data,
      authtoken: state.Login.token,
      loading: state.OfferReducer.loading
  }));
  useEffect(() => {
    dispatch(getOffers(authtoken));
}, []);

  

    const tableData = !loading && offers?.offer?.map((offer, index) => {
        return {
            sl: index + 1,
            heading1: offer?.title,
            heading2: offer?.category,
            heading3: offer?.expireDate,
           

            manage: (
                <>
                    <Button
                        color="primary"
                        className="btn-sm btn-rounded"
                        onClick={() => {
                          history.push(`/add-offer-item/${offer?._id}`)
                        }}
                    >
                    Add Offer Item
                    </Button>


                </>

            ),
            status: (
                <>

                    <Badge className={"bg-success"}>
                        Active
                    </Badge>

                </>

            ),
        }
    })




    const offerData = {
        columns: [
            {
                label: "No.",
                field: "sl",
                sort: "asc",
                width: 150,
              },
        
            {
                label: "Offer Title",
                field: "heading1",
                sort: "asc",
                width: 150,
            },
            {
                label: "Category",
                field: "heading2",
                sort: "asc",
                width: 150,
            },

            {
                label: "Expire Date",
                field: "heading3",
                sort: "asc",
                width: 150,
            },
          


            {
                label: "View Details",
                field: "manage",
            },


        ],
        rows: tableData,
    }






    return (
        <React.Fragment>
            {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
            <div >
                <MetaTags>
                    <title>Offer | DDC</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Offer" breadcrumbItem="Offer details" />
                    {!loading ? (
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>

                                        <MDBDataTable
                                            responsive
                                            noBottomColumns
                                            striped
                                            data={offerData}
                                        />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};


export default withRouter(OrderDataTable);


