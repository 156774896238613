import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner
} from "reactstrap"

import { Link } from "react-router-dom"
import OrderTableData from "./TableOrder";

import { getOrders } from '../../store/Order/actions';
import '../../pages/styles.css'

import { getTopRegion } from '../../store/OrgDashboard/actions'
import TableRouteList from "./TableRouteList";
const OrderList = () => {

  const dispatch = useDispatch()
  const { orders, authtoken, loading, id, topRouteLoading,topRoutes } = useSelector(state => ({
    // topRoutes: state.DashboardReducer.topRoutes.data,
    orders: state.OrderReducer.orders,
    authtoken: state.Login.token,
    id: state.Login.id,
    loading: state.OrderReducer.loading,
    // topRouteLoading: state.DashboardReducer.topRouteLoading,
  }));
  useEffect(() => {
    dispatch(getOrders(authtoken, id));
  }, []);
  // useEffect(() => {
  //   dispatch(getTopRegion(authtoken));
  // }, []);
 

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Order</title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div className="text-center pt-5">
              <Spinner />
            </div>
          ) : (

            <Row>
              <h4 className='mb-3'>Order </h4>

              <Col xl="12">
                <Row>

                  {/* storeData Render */}

                  <Col md="3">
                    <Card className="mini-stats-wid card-info-list">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Received orders (Total)
                            </p>
                            <h4 className="mb-0">{orders?.data?.length}</h4>

                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx " + "bx-copy-alt" + " font-size-24"
                                }
                              />

                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* <Col md="3">
                    <Card className="mini-stats-wid card-info-list">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                                Received orders (Today)
                            </p>
                            <h5 className="mb-1">{orders?.data?.todayorder}</h5>

                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx " + "bx-copy-alt" + " font-size-24"
                                }
                              />

                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="3">
                    <Card className="mini-stats-wid card-info-list">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              Top order routes
                            </p>
                            {topRouteLoading ? (
                              <div className="text-center mt-5 pt-5">
                               --
                              </div>
                            ) : (
                              <h6 className="mb-2">{topRoutes?.data[0]?.routename}</h6>
                            )}
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx " + "bx-copy-alt" + " font-size-24"
                                }
                              />

                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col> */}

                  {/* <Col md="3">
                    <Card className='card-info-list' >
                      <CardBody >
                        <CardTitle className="mb-0 h4">Order Analytics</CardTitle>
                        <Row>
                          <Col >
                            <div className="mt-2 ">
                              <Link
                                to="/analytic-order"
                                className="btn btn-primary btn-md"
                              >
                                See Analytics
                              </Link>
                            </div>
                          </Col>

                        </Row>

                      </CardBody>
                    </Card>
                  </Col> */}

                </Row>


              </Col>
            </Row>
          )}
          {/* <OrderTableData /> */}
          <TableRouteList/>
        </Container>
      </div>


    </React.Fragment >
  );
};

export default OrderList;