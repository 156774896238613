import React, { Component, useEffect, useState } from "react"

import classNames from "classnames"
import {  
  Card,
  CardBody,
} from "reactstrap"


import { Link } from "react-router-dom"


import ProductAddAnalyticChart from "./ProductAddAnalyticChart";

const ProductAddAnalytic = () => {
  const [periodType, setPeriodType] = useState("yearly")


  return (
    <React.Fragment>
      <Card className='page-content'>
      
        <CardBody>

          <div className="d-sm-flex flex-wrap ">
            <h4 className="card-title mb-4">Total Pre Order Product Quantity (Current Month)</h4>
            <div className="ms-auto">
             
          
            </div>
          </div>
          <ProductAddAnalyticChart />
        </CardBody>
      </Card>

    </React.Fragment>
  )
}

export default ProductAddAnalytic
