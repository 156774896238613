import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner
} from "reactstrap"

import { Link } from "react-router-dom"
// import ProductTable from "./ProductTable";
import ProductDataTable from'./TableProductData'

import { getProducts } from '../../store/Product/actions';
import {getTopProduct} from '../../store/OrgDashboard/actions'
import '../../pages/styles.css'

const ProductDetails = () => {
  
  const dispatch = useDispatch();

  const { products, authtoken, productLoading,id,topProducts,topProductLoading } = useSelector(state => ({
       topProducts: state.DashboardReducer.topProducts,
      products: state.ProductReducer.products,
      id: state.Login.id,
      authtoken: state.Login.token,
      productLoading: state.ProductReducer.productLoading,
      topProductLoading: state.DashboardReducer.topProductLoading,
  }));
  useEffect(() => {
    dispatch(getProducts(authtoken,id));
}, []);
useEffect(() => {
  dispatch(getTopProduct(authtoken));
}, []);

console.log(topProducts);
    return (
        <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>DDC</title>
          </MetaTags>
          <Container fluid>
          {!productLoading ? (
                        <div className="text-center pt-5">
                            <Spinner />
                        </div>
                    ) : (
            <Row>

              <Col xl="12">
                <Row>

                  {/* storeData Render */}
                 
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Total Products
                              </p>
                              <h5 className="mb-2">{products?.data?.length}</h5>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                          {topProductLoading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Top Selling Product
                              </p>
                              <p className="mb-1 fw-bold">{topProducts?.data?.bestsellingproducts[0]?._id}</p>

                            </div>
                    )}
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> */}
                    <Col md="3">
              <Card className='card-info-list'>
                  <CardBody >
                    <CardTitle className="mb-0 h5">Product Analytics</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/product-add-analytic"
                            className="btn btn-primary btn-md"
                          >
                            View Analytics <i className="mdi mdi-arrow-right ms-1"></i>
                          </Link>
                        </div>
                      </Col>
                    
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
              <Card className='card-info-list'>
                  <CardBody >
                    <CardTitle className="mb-0 h4">Add Product</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/add-product"
                            className="btn btn-primary btn-md"
                          >
                            Add Product
                          </Link>
                        </div>
                      </Col>
                     
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
                 
                </Row>
               

              </Col>
            </Row>
                    )}

         <ProductDataTable/>
          </Container>
        </div>


      </React.Fragment >
    );
};

export default ProductDetails;