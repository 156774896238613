import React, { useEffect } from "react"
import { makeQuery } from "helpers/Custom/makeQuery"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Spinner } from "reactstrap"
import arrowUp from "../../assets/images/arrow-up.svg"
import jar1 from "../../assets/images/jar1.png"
import arrowDown from "../../assets/images/Shape.svg"
import shop from "../../assets/images/Vector(1).svg"
import Vector from "../../assets/images/Vector.svg"
import { getPreDelivery } from "../../store/OrgDashboard/actions"

function PreOrder(props) {
  const dispatch = useDispatch()

  const {
    authtoken,
    totalSR,
    preOrderDeliveryLoading,
    id,
    topSRs,
    preOrderDelivery,
    mainFilter,
    isSubFilterChanged,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    currentSelection,
  } = useSelector(state => ({
    preOrderDelivery: state.DashboardReducer.preOrderDelivery.data,
    id: state.Login.id,
    authtoken: state.Login.token,
    preOrderDeliveryLoading: state.DashboardReducer.preOrderDeliveryLoading,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    mainFilter: state.DashboardReducer.mainFilter,
    currentSelection: state.DashboardReducer.currentSelection,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
  }))

  useEffect(() => {
    const query = makeQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection
    )
    dispatch(getPreDelivery(authtoken, query))
  }, [isSubFilterChanged])

  // console.log("preOrderDelivery", preOrderDelivery)

  return (
    <React.Fragment>
      {preOrderDeliveryLoading ? (
        <div className="text-center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <Card className="pre-order">
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="ms-2">
                <h5 className="card-title ">Pre order vs. Delivery</h5>
              </div>
            </div>

            <hr />

            <div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item pre-order-list">
                  <div className="py-0">
                    <div className="font-size-14 d-flex">
                      <div className="">
                        <div className="delivery-image d-">
                          <img
                            src={Vector}
                            className="rounded-circle avatar-xs align-self-center me-3 "
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="ms-3">
                        <h5>{Math.ceil(preOrderDelivery?.todaycollected).toLocaleString()} Tk</h5>

                        {preOrderDelivery?.todaygrowthrate > 0 ? (
                          <p>
                            {" "}
                            <span className="pre-order-bg">
                              {Math.ceil(preOrderDelivery?.todaygrowthrate)} %
                            </span>{" "}
                            <img src={arrowUp} alt="" /> vs Last Day
                          </p>
                        ) : (
                          <p>
                            {" "}
                            <span className="pre-order-bg-low">
                              {Math.ceil(preOrderDelivery?.todaygrowthrate)} %
                            </span>{" "}
                            <img src={arrowDown} alt="" /> vs Last Day
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <hr /> */}
                </li>
              </ul>
            </div>

            <div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item pre-order-list">
                  <div className="py-0">
                    <div className="font-size-14 d-flex">
                      <div className="">
                        <div className="delivery-image d-">
                          <img
                            src={jar1}
                            className="rounded-circle avatar-xs align-self-center me-3 "
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="ms-3">
                        <h5>
                          {Math.ceil(preOrderDelivery?.previousweekcollected).toLocaleString()}{" "}
                          Tk
                        </h5>
                        {preOrderDelivery?.previousweekgrowthrate > 0 ? (
                          <p>
                            {" "}
                            <span className="pre-order-bg">
                              {Math.ceil(preOrderDelivery?.previousweekgrowthrate)} %
                            </span>{" "}
                            <img src={arrowUp} alt="" /> vs Last Week
                          </p>
                        ) : (
                          <p>
                            {" "}
                            <span className="pre-order-bg-low">
                              {Math.ceil(preOrderDelivery?.previousweekgrowthrate)} %
                            </span>{" "}
                            <img src={arrowDown} alt="" /> vs Last Week
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <hr /> */}
                </li>
              </ul>
            </div>

            <div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item pre-order-list">
                  <div className="py-0">
                    <div className="font-size-14 d-flex">
                      <div className="">
                        <div className="delivery-image d-">
                          <img
                            src={shop}
                            className="rounded-circle avatar-xs align-self-center me-3 "
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="ms-3">
                        <h5>
                          {Math.ceil(preOrderDelivery?.lastmonthcollected).toLocaleString()} Tk
                        </h5>

                        {preOrderDelivery?.monthgrowthrate > 0 ? (
                          <p>
                            {" "}
                            <span className="pre-order-bg">
                              {Math.ceil(preOrderDelivery?.monthgrowthrate)} %
                            </span>{" "}
                            <img src={arrowUp} alt="" /> vs Last Month
                          </p>
                        ) : (
                          <p>
                            {" "}
                            <span className="pre-order-bg-low">
                              {Math.ceil(preOrderDelivery?.monthgrowthrate)} %
                            </span>{" "}
                            <img src={arrowDown} alt="" /> vs Last Month
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <hr /> */}
                </li>
              </ul>
            </div>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  )
}

export default PreOrder
