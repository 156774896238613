
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, useHistory, useLocation,useRouteMatch } from "react-router-dom";

import {
    Badge, Button, Col,
    Card,
    CardBody,
    Container,
    Row, Spinner, Table, UncontrolledDropdown
} from "reactstrap";
import { getAllStore } from '../../store/Stores/actions';
import closeRoute from '../../assets/images/closeRoute.svg'
import {deleteRouteUser} from '../../store/users/actions'
const tableHead = ["No.", "Route Name", "Route Code","Action"]
const RouteInfoUser = () => {
    const {state} = useLocation()
    console.log('state',state);
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    params: { userid,userName },
} = useRouteMatch('/set-route/:userid/:userName');

const { authtoken} = useSelector(state => ({
  authtoken: state.Login.token,

}))

const onChangeRoute = (e,id) =>{
  e.preventDefault()
  window.confirm("Are you sure, you want to delete this Route?") &&
    dispatch(deleteRouteUser(authtoken, userid, id))
  // e.target.parentNode.parentNode.style.display = "none"
  history.push("/user-details")
 

}

  return (
    <React.Fragment>

     
     
            <Row>
            <Col md={6} className='m-auto'>
                <h5 className="mb-3 mt-2">Selected Route List</h5>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={state?.data}
                tableHead={tableHead}
                // setCurrentPage={setCurrentPage}
                page={10}
                // currentPage={currentPage}
                // isPagination
                // loading={loading}
              >
                {  state?.data?.length ? (
                    state?.data?.map((route, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td>{route?.name}</td>
                        <td>{route?.routeCode}</td>
                      
                        <td>
                         <img src={closeRoute}
                         onClick= {(e)=> onChangeRoute (e,route._id)}
                         ></img>
                        </td>
                        
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>Currently you have no Route. </span>
                  </NoTableData>
                )}
              </CustomTable>

              {/* table end */}
              </Col>
            </Row>
     
    </React.Fragment>
  )
}

export default RouteInfoUser
