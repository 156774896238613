import * as Yup from "yup"

const createQuestionSchema = Yup.object({
  name: Yup.string().required("This value is Required"),
  questionType: Yup.string().required("This value is Required"),
  options: Yup.array().of(
    Yup.object({
      serial: Yup.string().required("This value is Required"),
      // name: Yup.string().when("questionType", {
      //   is: val => val == "single" || val == "multiple",
      //   then: Yup.string().required("This value is Required"),
      // }),
      // image: Yup.string().when("questionType", {
      //   is: val => val === "single" || val === "multiple",
      //   then: Yup.string().required("Image is Required"),
      // }),
      name: Yup.string().test(
        "name",
        "Option name is required",
        (val, context) => {
          const { questionType } = context?.from?.[1]?.value
          return questionType === "text" || val ? true : false
        }
      ),
      image: Yup.string().test(
        "image",
        "Option image is required",
        (val, context) => {
          const { questionType } = context?.from?.[1]?.value
          return questionType === "text" || val ? true : false
        }
      ),
    })
  ),
})

const createQuestionSchema2 = Yup.object({
  name: Yup.string().required("This value is Required"),
  questionType: Yup.string().required("This value is Required"),
  options: Yup.array().of(
    Yup.object({
      serial: Yup.string().required("This value is Required"),
      name: Yup.string().test(
        "name-test",
        "Option name is required",
        (val, context) => {
          const { questionType } = context?.from?.[1]?.value
          console.log(`line 48 ~ questionType`, questionType, val)
          return questionType === "text" || val ? true : false
        }
      ),
      image: Yup.string().test(
        "image-test",
        "Option image is required",
        (val, context) => {
          const { questionType } = context?.from?.[1]?.value
          return questionType === "text" || val ? true : false
        }
      ),
    })
  ),
  dependingQuetion: Yup.string().when("type", {
    is: type => type === "dependent",
    then: Yup.string().required("This value is Required"),
  }),
  dependingOption: Yup.string(),
})
console.log(`line 46 ~ createQuestionSchema2`, createQuestionSchema2)

// createQuestionSchema2.validateSync(_rule, {
//   context: { rule: _rule },
// })

export { createQuestionSchema, createQuestionSchema2 }
