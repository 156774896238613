import React from "react"
import { Card, Container, Table, Button } from "reactstrap"

const DateTableSales = () => {
    return (
        <div>
        <div className="page-content">
      <Card>
        <Table hover>
          <thead className="dp-product-list-activity">
            <tr>
              <th>NO</th>
              <th>Date</th>
              <th>Order Amount(tk)</th>
              <th>Received Amount(tk)</th>
              <th>Due Amount(tk)</th>
              <th>Assigned SR</th>
              <th>Assigned DP</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {([0, 1, 2, 3, 4, 5] || []).map((data, key) => {
              return (
                <>
                  <tr>
                    <th scope="row">1</th>
                    <td>30-01-2022</td>
                    <td>275</td>
                    <td>0</td>
                    <td>275</td>
                    <td>2</td>
                    <td>2</td>
                    <td>
                      {" "}
                      <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded"
                      >
                        View Details
                      </Button>
                    </td>
                  </tr>
                </>
              )
            })}
          </tbody>
        </Table>
      </Card>
      </div>
    </div>
    );
};

export default DateTableSales;