import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { getBrands } from "../../store/Brand/actions"
import { getCategorys } from "../../store/category/actions"
import { addNewProduct } from "../../store/Product/actions"
import { getUnits } from "../../store/Unit/actions"

const AddProduct = () => {
  const [file, setfile] = useState("")
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedUnit, setSelectedUnit] = useState(null)
  // const [testloading, setloading] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()
  const [brandRequired, setBrandRequired] = useState(true)
  const handleChangeBrand = value => {
    setSelectedBrand(value._id)
    setBrandRequired(false)
  }
  const handleChangeCategory = value => {
    setSelectedCategory(value._id)
  }
  const handleChangeUnit = value => {
    setSelectedUnit(value._id)
  }
  useEffect(() => {
    dispatch(getBrands(authtoken, id))
    dispatch(getCategorys(authtoken))
    dispatch(getUnits(authtoken))
  }, [])

  console.log(selectedBrand)
  const { authtoken, id, brands, categories, units, testLoading } = useSelector(
    state => ({
      authtoken: state.Login.token,
      id: state.Login.id,
      brands: state.BrandReducer.brands.data,
      categories: state.CategoryReducer.categories.category,
      units: state.UnitReducer.units.unit,
      testLoading: state.ProductReducer.testLoading,
    })
  )
  console.log("process", brands)

  async function handleSubmit(event, errors, values) {
    event.preventDefault()
    let formdata = new FormData()
    console.log(values)
    let obj = {}
    if (values.maxDiscount === "") {
      values.maxDiscount = 0
    }
    if (
      values.name &&
      values.unitPrice &&
      values.code &&
      selectedBrand &&
      selectedUnit &&
      selectedCategory
    ) {
      formdata.append("name", values.name)
      formdata.append("brand", selectedBrand)
      formdata.append("unitPrice", values.unitPrice)
      formdata.append("maxDiscount", values.maxDiscount)
      formdata.append("code", values.code)
      formdata.append("photo", file)
      formdata.append("unitType", selectedUnit)
      formdata.append("category", selectedCategory)
      formdata.append("org", id)
      console.log('formdata',formdata);
       dispatch(addNewProduct(formdata, history, authtoken))
    }

    // if (values.name) {
    //     formdata.append('name', values.name)
    //     obj.name = values.name
    // }
    // if (values.brand) {
    //     formdata.append('brand', selectedBrand)
    //     obj.brand = selectedBrand
    // }

    // if (values.unitPrice) {
    //     formdata.append('unitPrice', values.unitPrice)
    //     obj.unitPrice = values.unitPrice
    // }

    // if (values.maxDiscount) {
    //     formdata.append('maxDiscount', values.maxDiscount)
    //     obj.maxDiscount = values.maxDiscount
    // }

    // if (values.code) {
    //     formdata.append('code', values.code)
    //     obj.code = values.code
    // }
    // formdata.append('brand', selectedBrand)
    // formdata.append('photo', file)
    // // obj.brand = selectedBrand
    // formdata.append('unitType',selectedUnit )
    // // JSON.stringify
    // obj.unitType = selectedUnit
    // formdata.append('category', selectedCategory)

    // obj.category = selectedCategory
    // formdata.append('org', id)
    // obj.org = id;
    // for (var key of formdata.entries()) {
    //     console.log(key[0] + ', ' + key[1]);
    // }
    // console.log(obj);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Product | DDC</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Add Product" /> */}

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Add Product</CardTitle>
                  <CardSubtitle className="mb-4"></CardSubtitle>

                  <AvForm onSubmit={handleSubmit} className="m-auto">
                    <Row>
                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="productname">Product Name</Label>
                          <AvField
                            id="productname"
                            name="name"
                            type="text"
                            className="form-control"
                            errorMessage="Please enter product name"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="brand">Brand Name</Label>

                          <Select
                            name="brand"
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            options={brands}
                            required
                            errorMessage="Please enter product name"
                            // validate={{ required: { value: true } }}
                            onChange={handleChangeBrand}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="unitType">Unit Name</Label>

                          <Select
                            name="unitType"
                            cacheOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            options={units}
                            defaultOptions
                            onChange={handleChangeUnit}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="priceInfo">Price</Label>
                          <AvField
                            id="priceInfo"
                            name="unitPrice"
                            type="text"
                            className="form-control"
                            errorMessage="Please enter price"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="categoryname">Category Name</Label>

                          <Select
                            name="category"
                            cacheOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            options={categories}
                            defaultOptions
                            onChange={handleChangeCategory}
                            required
                          />
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <Label htmlFor="productcode">Product Code</Label>
                          <AvField
                            id="productcode"
                            name="code"
                            type="text"
                            className="form-control"
                            errorMessage="Please enter product code"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="model">Model No(Set by You)</Label>
                          <AvField
                            id="model"
                            name="model"
                            type="text"
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Label htmlFor="discount">Max Discount</Label>
                          <AvField
                            id="discount"
                            name="maxDiscount"
                            type="text"
                            className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <FormGroup className="mb-3">
                        <Label htmlFor="discount">Image</Label>
                        <AvField
                          type="file"
                          className="form-control"
                          id="formrow-brand-Input"
                          name="photo"
                          onChange={e => {
                            setfile(e.target.files[0])
                          }}
                        />
                      </FormGroup>
                    </Row>

                    {!testLoading ? (
                      <div className="p-4 d-flex justify-content-center align-items-center p-2 mb-3 mt-5 mr-auto">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    ) : (
                      <>
                        <Col lg={6}></Col>
                        <div className="flex-wrap gap-2 col-md-12 text-center">
                          <Button type="submit" color="primary" className="">
                            Submit
                          </Button>
                        </div>
                      </>
                    )}
                    {/* <div className="d-flex flex-wrap gap-2">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn "
                                            >
                                                Save Changes
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="secondary"
                                                className=" "
                                            >
                                                Cancel
                                            </Button>
                                        </div> */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddProduct
