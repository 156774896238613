import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import {
    Card, CardBody,
    CardTitle, Col,
    Container, FormGroup,
    Label, Row, Spinner
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getRoute } from "../../store/GeoInformation/actions";
import { addNewPJP } from '../../store/PJP/actions';
import { getUsers } from '../../store/users/actions';

const moment = require("moment")

const PjpCreate = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [selectedRoute, setSelectedRoute] = useState(null)
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedMulti, setselectedMulti] = useState([])
    const [selectedSR, setSetselectedSR] = useState([])

    const year = moment().format("YYYY")
    console.log(year);

    const day = [
        "Saturday",
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
    ]
    useEffect(() => {

        let availableday = []
        if (day) {
            day.forEach(element => {
                let obj = {}

                obj.label = element
                obj.value = element
                availableday.push(obj)
            })
            //stored from reducer
            setSelectedOptions(availableday)
        }

    }, []);


    const handleChangeRoute = (value) => {
        setSelectedRoute(value._id)
    }
    const handleChangeSR = (value) => {
        setSetselectedSR(value._id)
    }
    useEffect(() => {
        dispatch(getUsers(authtoken,id));
    }, []);
    useEffect(() => {
        dispatch(getRoute(authtoken,id))
    }, [])
   
    const { routes, authtoken, id, routeloading ,users} = useSelector(state => ({
        routes: state.GeoInformationReducer.routes,
        users: state.UserReducer,
        authtoken: state.Login.token,
        id: state.Login.id,
        routeloading: state.GeoInformationReducer.routeloading
    }))
 console.log('routes',routes);
    const handleMultiChange = e => {
        setselectedMulti(Array.isArray(e) ? e.map(x => x.value) : [])
    }

    function handleSubmit(event, errors, values) {
        event.preventDefault()

        let obj = {}
        if (selectedMulti) {
            obj.day = selectedMulti
        }
        if(values.month){
            obj.month = values.month

        }
        // obj.month = "11-2021"
        obj.route = selectedRoute
        obj.user = selectedSR
        obj.org = id
        obj.isRoute= true
        console.log(obj);
        dispatch(addNewPJP(obj, history, authtoken))

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DDC | Add PJP</title>
                </MetaTags>
                <Container fluid={true} >
                    <Breadcrumbs title="Add New PJP" breadcrumbItem="Add New PJP" />
                    {!routeloading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
                        <Row>
                            <Col md={6} className='m-auto'>
                                <Card >
                                    <CardBody >
                                        <CardTitle className="h4 mb-4">Add PJP</CardTitle>

                                        <AvForm onSubmit={handleSubmit} >
                                        <Row>
                                                <Col md={12}>
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="route">
                                                            Select SR
                                                        </Label>


                                                        <Select 
                                                            name='user'
                                                            cacheOptions
                                                            getOptionLabel={e => e.name}
                                                            getOptionValue={e => e._id}
                                                            options={users.users}
                                                            defaultOptions

                                                            onChange={handleChangeSR}
                                                        />

                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="route">
                                                            Route
                                                        </Label>


                                                        <Select 
                                                            name='route'
                                                            cacheOptions
                                                            getOptionLabel={e => e.routeCode}
                                                            getOptionValue={e => e._id}
                                                            options={routes.data.route}
                                                            defaultOptions

                                                            onChange={handleChangeRoute}
                                                        />

                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>
                                            <Col md={12}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-month">Select Month</Label>
                                                    <AvField
                                                        name='month'
                                                        id="formrow-month"
                                                        className="form-control"
                                                        type="select"
                                                        errorMessage="Select month"
                                                        validate={{ required: { value: true } }}
                                                    >
                                                        <option>Choose</option>
                                                        <option value={`01-${year}`}>January</option>
                                                        <option value={`02-${year}`}>February</option>
                                                        <option value={`03-${year}`}>March</option>
                                                        <option value={`04-${year}`}>April</option>
                                                        <option value={`05-${year}`}>May</option>
                                                        <option value={`06-${year}`}>June</option>
                                                        <option value={`07-${year}`}>July</option>
                                                        <option value={`08-${year}`}>August</option>
                                                        <option value={`09-${year}`}>September</option>
                                                        <option value={`10-${year}`}>October</option>
                                                        <option value={`11-${year}`}>November</option>
                                                        <option value={`12-${year}`}>December</option>

                                                        
                                                    </AvField>
                                               
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <label className="control-label">Selceted day</label>
                                                        <Select
                                                            value={selectedOptions.filter(obj =>
                                                                selectedMulti.includes(obj.value)
                                                            )}
                                                            options={selectedOptions}
                                                            isMulti
                                                            isClearable
                                                            onChange={handleMultiChange}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </Col>

                                            </Row>






                                            <div>
                                                <button type="submit" className="btn btn-primary w-md">
                                                    Save Pjp
                                                </button>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>


                        </Row>


                    )}

                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    )
}


export default PjpCreate
