import { makeQuery } from "helpers/Custom/makeQuery"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row, Spinner, Table } from "reactstrap"
import { getTopProduct } from "../../store/OrgDashboard/actions"

const getChartOptions = index => {
  const { REACT_APP_S3 } = process.env
  // console.log(REACT_APP_S3)
  var options = {
    chart: { sparkline: { enabled: !0 } },
    dataLabels: { enabled: !1 },
    colors: ["#556ee6"],
    plotOptions: {
      radialBar: {
        // hollow: { margin: 0, size: "60%" },
        track: { margin: 0 },
        dataLabels: { show: 1 },
        hollow: {
          size: "70%",
        },
      },
      total: {
        show: true,
        showAlways: true,
      },
    },
  }
  // var options2 = {
  //   chart: { sparkline: { enabled: !0 } },
  //   dataLabels: { enabled: !1 },
  //   colors: ["#556ee6"],
  //   plotOptions: {
  //     radialBar: {
  //       // hollow: { margin: 0, size: "60%" },
  //       track: { margin: 0 },
  //       dataLabels: { show: 1 },
  //       hollow: {
  //         size: "70%",
  //       },
  //     },
  //     total: {
  //       show: true,
  //       showAlways: true,
  //     },
  //   },
  // }
  switch (index) {
    case 1:
      options["colors"][0] = "#556ee6"
      break
    case 2:
      options["colors"][0] = "#34c38f"
      break
    case 3:
      options["colors"][0] = "#f46a6a"
      break
    default:
      break
  }

  return options
}

const TotalSellngProduct = props => {
  const dispatch = useDispatch()

  const {
    authtoken,
    topProductLoading,
    topProducts,
    mainFilter,
    isSubFilterChanged,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    currentSelection,
  } = useSelector(state => ({
    topProducts: state.DashboardReducer.topProducts,
    id: state.Login.id,
    authtoken: state.Login.token,
    topProductLoading: state.DashboardReducer.topProductLoading,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    mainFilter: state.DashboardReducer.mainFilter,
    currentSelection: state.DashboardReducer.currentSelection,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
  }))
  useEffect(() => {
    const query = makeQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection
    )
    dispatch(getTopProduct(authtoken, query))
  }, [isSubFilterChanged])

  // console.log(topProducts)

  const [seletedMonth, setSeletedMonth] = useState("Dec")

  const onChangeMonth = value => {
    setSeletedMonth(value)
    dispatch(getTopSellingProduct(value))
  }
  console.log(topProducts)
  return (
    <React.Fragment>
      {topProductLoading ? (
        <div className="text-center  pt-5">
          <Spinner />
        </div>
      ) : (
        <Card className="top-product">
          <CardBody className="top-product-dkjhsgjhdklsjfhjbn">
            <div className="clearfix mt-2">
              {/* <div className="float-end ">
                <div className="input-group input-group-sm ">
                  <select
                    className="form-select form-select-sm"
                    value={seletedMonth}
                    onChange={e => {
                      onChangeMonth(e.target.value)
                    }}
                  >
                    <option value="dec">Dec</option>
                    <option value="jan">Jan</option>
                    <option value="feb">Feb</option>
                    <option value="march">March</option>
                  </select>
                  <label className="input-group-text">Month</label>
                </div>
              </div> */}
              <div className="clearfix mt-2 d-flex product-title hfbjhdkshf">
                <h4 className="card-title kdfjbfdn mb-4">
                  Top Selling Product
                </h4>
                <Link to="/details-product" className=" fw-bold ms-3 djhfbdfj">
                  {" "}
                  <div className="me-end">
                    <p>See all</p>
                  </div>
                </Link>
              </div>
            </div>
            {topProducts?.data?.bestsellingproducts?.length === 0 ? (
              <Col md={5}>
                <div className="text-muted ">
                  <h6 className=" mt-3 text-danger fw-bold">
                    No products Available
                  </h6>

                  {/* <p className="mt-1">
                  {" "}
                  <span className="pre-order-bg">0.2%</span>{" "}
                  <img src={arrowUp} alt="" /> vs Last month
                </p> */}
                </div>
              </Col>
            ) : (
              <>
                <Row>
                  <Col md={3}>
                    <div className="text-muted ">
                      <img
                        src={`${process.env.REACT_APP_S3}${topProducts?.data?.bestsellingproducts?.[0]?.product?.[0]?.image}`}
                        alt=""
                        className="img-fluid single-prod-image"
                      />
                    </div>
                  </Col>
                  <Col md={9}>
                    <div className="text-muted">
                      <h6 className=" mt-3 fw-bold top-selling-product-title">
                        {topProducts?.data?.bestsellingproducts?.[0]?._id}
                      </h6>
                      <h5 className="mt-1 fw-bold">
                        {topProducts?.data?.bestsellingproducts?.[0]?.totalamount.toLocaleString(
                          "en-US",
                          { minimumFractionDigits: 2 }
                        )}{" "}
                        tk
                      </h5>
                      {/* <p className="mt-1">
                  {" "}
                  <span className="pre-order-bg">0.2%</span>{" "}
                  <img src={arrowUp} alt="" /> vs Last month
                </p> */}
                    </div>
                  </Col>
                  <Col md={4}>
                    {/* <div id="radialchart-1">
                <ReactApexChart
                  options={options2}
                  series={[50]}
                  type="radialBar"
                  height={10}
                  width={10}
                  className="apex-charts"
                />
              </div> */}
                  </Col>
                </Row>
                <hr />
                <div className="table-responsive">
                  <Table className="table align-middle">
                    <tbody>
                      {(topProducts?.data?.bestsellingproducts || []).map(
                        (data, key) => {
                          const options = getChartOptions(key + 1)
                          return (
                            <tr key={key} className="">
                              <td>
                                <div className="align-self-center me-3 product-image-list-view-hfjdksuryfh">
                                  <img
                                    src={`${process.env.REACT_APP_S3}${data?.product?.[0]?.image}`}
                                    // className="rounded-circle avatar-xs w-100 "
                                    alt=""
                                  />
                                </div>
                              </td>
                              <td>
                                <h5 className="font-size-14 ">{data?._id}</h5>
                                {/* <p className="mb-2 mt-2">
                            {" "}
                            <span className="pre-order-bg">0.2%</span>{" "}
                            <img src={arrowUp} alt="" />{" "}
                          </p>  */}
                              </td>
                              {/* 
                        <td>
                          <div id="radialchart-1">
                            <ReactApexChart
                              options={options}
                              series={[70]}
                              type="radialBar"
                              height={40}
                              width={40}
                              className="apex-charts"
                            />
                          </div>
                        </td> */}

                              <td>
                                <div>
                                  <p className="text-muted mb-0 mt-1">
                                    Amount<small>(Tk)</small>
                                  </p>
                                  <h6 className="mb-0 mt-0">
                                    {data?.totalamount?.toLocaleString(
                                      "en-US",
                                      { minimumFractionDigits: 2 }
                                    )}
                                  </h6>
                                </div>
                              </td>
                            </tr>
                          )
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            )}

            {/* <div className='text-center '>
             <Link to='/details-product'><Button className='btn btn-info btn-sm'>See All Products</Button></Link>
             </div> */}
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  )
}

export default TotalSellngProduct
