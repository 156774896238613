import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  CardText,
  Spinner
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom"

 import TotalAmountUnit from "./InventoryProductChart/TotalAmountUnit.js";
 import StoreAmountUnit from "./InventoryProductChart/StoreAmountUnit.js";
// import DeleteProduct from "./DeleteProduct";
import {  useRouteMatch } from "react-router";
import { getProduct } from '../../store/Product/actions'
import '../../pages/styles.css'
const ProductInfoDetails = () => {
  const {
    params: { productID },
} = useRouteMatch('/product-info/:productID');
console.log('hello', productID);

const dispatch = useDispatch();

const { authtoken, product ,loading} = useSelector(state => ({
  product: state.ProductReducer.product.data,

  id: state.Login.id,
  authtoken: state.Login.token,
  loading: state.ProductReducer.loading
}));
useEffect(() => {
  dispatch(getProduct(authtoken, productID));
}, []);

return (
  <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>DDC</title>
      </MetaTags>
      {loading ? (
                        <div className="text-center pt-5">
                            <Spinner />
                        </div>
                    ) : (
      <Container fluid>
        <h4 className='mb-3'>{product?.singleproduct?.name}</h4>
        <Row>

          <Col xl="12">
            <Row>

              {/* storeData Render */}

              <Col md="3">
                <Card className="mini-stats-wid card-info-list">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          Total Sales and Quantity
                        </p>
                        <h6 className="mb-1 fw-bold">{Math.ceil(product?.totalsale?.totalamount)} tk. {Math.ceil(product?.totalsale?.productquantitysale)} Pc. </h6>

                      </div>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i
                            className={
                              "bx " + "bx-copy-alt" + " font-size-24"
                            }
                          />

                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="mini-stats-wid card-info-list">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          Top Selling Area
                        </p>
                        <h6 className="mb-0 fw-bold"></h6>

                      </div>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i
                            className={
                              "bx " + "bx-copy-alt" + " font-size-24"
                            }
                          />

                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="mini-stats-wid card-info-list">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          Top Ordered Store
                        </p>
                        <h6 className="mb-0 fw-bold">{product?.topstorename?.name}</h6>

                      </div>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i
                            className={
                              "bx " + "bx-copy-alt" + " font-size-24"
                            }
                          />

                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md="3">
                <Card className='card-info-list'>
                  <CardBody >
                    <CardTitle className="mb-0 h4">Sell-Off Product</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/add-product"
                            className="btn btn-primary btn-md"
                          >
                            Sell off
                          </Link>
                        </div>
                      </Col>

                    </Row>

                  </CardBody>
                </Card>
              </Col>

            </Row>


          </Col>
        </Row>


        <Row>
          <Col md={12}>
            <TotalAmountUnit />
          </Col>

        </Row>
        <Row>
          <Col md={12}>
            <StoreAmountUnit />
          </Col>

        </Row>
        {/* <DeleteProduct /> */}


      </Container>
                    )}
    </div>


  </React.Fragment >
);
};

export default ProductInfoDetails;