import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner
} from "reactstrap"

import { Link } from "react-router-dom"
import OrderDataTable from "./TableRouteOrder";
import TableDateOrder from "./TableDateOrder";
import { getDateOrders } from '../../store/Order/actions';
import '../../pages/styles.css'


const DateOrderList = () => {

  const {
    params: { dateID },
  } = useRouteMatch('/date-order/:dateID');
 
  const {
    params: { routeID },
  } = useRouteMatch('/date-order/:dateID/:routeID');
  console.log(routeID);

  const dispatch = useDispatch();

  const { dateOrders, authtoken, dateLoading, id } = useSelector(state => ({
    dateOrders: state.OrderReducer.dateOrders,
    id: state.Login.id,
    authtoken: state.Login.token,
    dateLoading: state.OrderReducer.dateLoading
  }));


  useEffect(() => {
    dispatch(getDateOrders(authtoken, id, dateID, routeID));
  }, []);
  console.log('hihihihi',dateOrders);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Order</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <h4 className='mb-3'>Order List</h4>
            <Col xl="12">
              <Row>

                {/* storeData Render */}

                <Col md="3">
                  <Card className="mini-stats-wid card-info-list">
                    <CardBody>
                      <div className="d-flex">
                        {dateLoading ? (
                          <div className="text-center pt-5">
                           <p>--</p>
                          </div>
                        ) : (
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                               Received Orders (Today)
                            </p>
                            <h4 className="mb-0">{dateOrders?.data?.length}</h4>

                          </div>
                        )}
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />

                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="3">
                  <Card className="mini-stats-wid card-info-list">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Top ordered store
                          </p>
                          <h4 className="mb-0">{dateOrders?.data?.ordershopname[0]?.shopname[0]?.name}</h4>

                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />

                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                               Received Amount
                              </p>
                              <h5 className="mb-1">{localStorage.getItem('shopTotalCollect')}</h5>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                               Due Amount
                              </p>
                              {/* <h5 className="mb-1">{localStorage.getItem('shopTotalDue')<0? 0:localStorage.getItem('shopTotalDue')}</h5> */}
                              <h5>0</h5>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>



              </Row>


            </Col>
          </Row>

          <TableDateOrder />
        </Container>
      </div>


    </React.Fragment >
  );
};

export default DateOrderList;