import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import {
    Card, CardBody,
    CardTitle, Col,
    Container, FormGroup,
    Label, Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { addNewRoute, getArea, getGeo, getRegion, getTerritroy } from "../../../store/GeoInformation/actions";
import { getUsers } from "../../../store/users/actions";



const AddRoute = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const day = [
        "Saturday",
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
    ]
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedMulti, setselectedMulti] = useState([])
    useEffect(() => {
        dispatch(getArea(authtoken))
        dispatch(getTerritroy(authtoken))
        dispatch(getRegion(authtoken))
        dispatch(getGeo(authtoken))

    }, []);
    useEffect(() => {

        let availableday = []
        if (day) {
            day.forEach(element => {
                let obj = {}

                obj.label = element
                obj.value = element
                availableday.push(obj)
            })
            //stored from reducer
            setSelectedOptions(availableday)
        }

    }, []);
    const handleMultiChange = e => {
        setselectedMulti(Array.isArray(e) ? e.map(x => x.value) : [])
    }
    const { authtoken, area, region, territory, arealoading, id,
        territoryload, regionloading, geo, geoloading } = useSelector(state => ({
            authtoken: state.Login.token,
            id: state.Login.id,
            area: state.GeoInformationReducer.area.area,
            region: state.GeoInformationReducer.region.region,
            territory: state.GeoInformationReducer.territory.territory,
            arealoading: state.GeoInformationReducer.arealoading,
            territoryload: state.GeoInformationReducer.territoryload,
            regionloading: state.GeoInformationReducer.regionloading,
            geo: state.GeoInformationReducer.geo.geo,
            geoloading: state.GeoInformationReducer.geoloading,
        }))

    
    function handleSubmit(event, errors, values) {
        event.preventDefault();
        let object = {}
        if (values.name) {
            object.name = values.name
        }
        if (values.routeCode) {
            object.routeCode = values.routeCode
        }
        if (values.geo) {
            object.geo = values.geo
        }
        if (values.users) {
            object.users = values.users
        }
        if (selectedMulti) {
            object.availableDay = selectedMulti
        }
        object.org = id;
        console.log(object);
        dispatch(addNewRoute(object, history, authtoken))
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DDC | Add Route</title>
                </MetaTags>
                <Container fluid={true} >
                    <Breadcrumbs title="Add Route" breadcrumbItem="Add Route" />
                    <Row>
                        <Col md={6} className='m-auto'>
                            <Card >
                                <CardBody >
                                    <CardTitle className="h4 mb-4">Add Route</CardTitle>

                                    <AvForm onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-routeName-Input">Route Name</Label>
                                                    <AvField
                                                        name="name"
                                                        type="text"
                                                        className="form-control"
                                                        id="formrow-routeName-Input"
                                                        placeholder='Type Route Name'
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-routeCode-Input">Route Code</Label>
                                                    <AvField
                                                        name="routeCode"
                                                        type="text"
                                                        className="form-control"
                                                        id="formrow-routeCode-Input"
                                                        placeholder='Type Route Code'
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                         <Row>
                                            {geoloading ?
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="geo"
                                                            label="Geo"
                                                        >
                                                            <option>Not Selected</option>
                                                            {
                                                                geo.map(index => {
                                                                    return (
                                                                        <option key={index._id} value={index._id}>
                                                                            {index.name}
                                                                        </option>
                                                                    )
                                                                })}
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                : <p>hello</p>}

                                        </Row> 
{/* 
                                        <Row>
                                            {!loading ?
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="users"
                                                            label="Users"
                                                        >
                                                            <option>Not Selected</option>
                                                            {
                                                                users.data.users.map(index => {
                                                                    return (
                                                                        <option key={index._id} value={index._id}>
                                                                            {index.name}
                                                                        </option>
                                                                    )
                                                                })}
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                : <p>hello</p>} 

                                                            </Row>*/}
                                         <Row>
                                            <Col lg={12}>
                                                <div className="mb-6">
                                                    <label className="control-label">Selceted day</label>
                                                    <Select
                                                        value={selectedOptions.filter(obj =>
                                                            selectedMulti.includes(obj.value)
                                                        )}
                                                        options={selectedOptions}
                                                        isMulti
                                                        isClearable
                                                        onChange={handleMultiChange}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>

                                        </Row> 
                                        <div>
                                            <button type="submit" className="btn btn-primary w-md mt-3">
                                                Add Route
                                            </button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                    {/* end row  */}



                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default AddRoute;
