import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Container, Row, Spinner } from "reactstrap"
import "../../pages/styles.css"
import { getTopDP, getTopSR } from "../../store/OrgDashboard/actions"
import { getUsers } from "../../store/users/actions"
import SurveyTable from "./SurveyTable"

import {
getLaparge
} from "../../helpers/backend_helper.js"
import routeListDown from "../../assets/images/routeListDown.svg"
import moment from "moment"
import XLSX from "xlsx"
const SurveyInfo = () => {
  const dispatch = useDispatch()
  const [orderExcel, setOrderExcel] = useState(false)
  const { users, authtoken, id, topSRs, topDPs, topDpLoading, topSrLoading } =
    useSelector(state => ({
      users: state.UserReducer,
      topSRs: state.DashboardReducer.topSRs.data,
      topDPs: state.DashboardReducer.topDPs,
      id: state.Login.id,
      authtoken: state.Login.token,
      topDpLoading: state.DashboardReducer.topDpLoading,
      topSrLoading: state.DashboardReducer.topSrLoading,
    }))
//   useEffect(() => {
//     dispatch(getUsers(authtoken, id))
//   }, [])

// const handleExcel = async () => {
//   setOrderExcel(true)
//   try {
//     var response = await getLaparge(authtoken)
//     console.log("order sss excel", response)
//   } catch (error) {}
//   let newArray = []
//   response?.data?.laparge?.forEach((surveyList, index) => {
//     let data = {}
//     data.name = surveyList?.name
//     surveyList.feedback.forEach((dt, idx) => {
//       data[dt.question] = dt?.answer
    
//     })
//     console.log("excel sheet", data)
//     newArray.push(data)
//   })
//   downloadxls(newArray)
// }
// const downloadxls = data => {
//   console.log(XLSX.version)
//   const ws = XLSX.utils.json_to_sheet(data)
//   const wb = XLSX.utils.book_new()
//   XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
//   XLSX.writeFile(wb, "Shop Order.xlsx")
//   setOrderExcel(false)
// }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Row>
                {/* storeData Render */}

                <Col md="3">
                  <Card className="mini-stats-wid card-info-list">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">You have total</p>
                          <h4 className="mb-0">-- Survey</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
        
                <Col md="3">
                  <Card className="card-info-list">
                    <CardBody>
                      <CardTitle className="mb-0 h4">Create New Survey</CardTitle>
                      <Row>
                        <Col>
                          <div className="mt-2 ">
                            <Link
                              to="/surveyAdd"
                              className="btn btn-primary btn-md"
                            >
                        Create Survey
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
   <SurveyTable/>
   {/* <Card>
        <div className="route-excel-attendance">
          {orderExcel ? (
            <div className="text-center route-excel">
              <Spinner />
            </div>
          ) : (
            <div className="route-excel">
              <div>
                <p className="mb-0">Download Excelsheet</p>
              </div>
              <div>
                <button className="route-excel-download" onClick={handleExcel}>
                  {" "}
                  <img src={routeListDown}></img>Download
                </button>
              </div>
            </div>
          )}
    
        </div>
      </Card> */}
      </div>
    </React.Fragment>
  )
}

export default SurveyInfo
