import { AvField } from "availity-reactstrap-validation"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import useDebounce from "Hooks/useDebounce"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Badge, Button, Card, Container, Row, Spinner } from "reactstrap"
import { getUsersInfo, storeUserData } from "../../store/users/actions"
import moment from "moment"


const tableHead = [
  "No.",
  "User name",
  "User Role",
  "Action",

]
const UserListPerform = props => {
  const [currentPage, setCurrentPage] = useState(1)

  const [role, setRole] = useState("SS")
  const [value, setValue] = useState("")
  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  )
 
  const dispatch = useDispatch()
  const history = useHistory()
  console.log("role", role)
  const { users, userInfo, authtoken, id, userInfoLoading, pageNumber } =
    useSelector(state => ({
      users: state.UserReducer,
      userInfo: state?.UserReducer?.userInfo,
      id: state.Login.id,
      pageNumber: state?.UserReducer?.userInfo?.pagenumber,
      authtoken: state.Login.token,
      userInfoLoading: state?.UserReducer?.userInfoLoading,
    }))

  const handleChangeRole = e => {
    setRole(e.target.value)
  }
  // useEffect(() => {

  //     dispatch(getUsers(authtoken, id))

  // }, [user])



  useEffect(() => {
    dispatch(getUsersInfo(authtoken, id, currentPage, value, role))
  }, [value, currentPage, role])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value !== "") {
        console.log("value", value)
        dispatch(getUsersInfo(authtoken, id, currentPage, value, role))
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [value])
  console.log("userInfo", userInfo)
  const handleEditUser = data => {
    console.log("handleEditUser", data)
    // dispatch(storeUserData("singleUser", data))
    history.push("/single-performance/" + data._id)
  }
  const handleChangeDate = e => {
    const value = convertDigitIn(e.target.value)

    setCurrentDate(value)
  }
  function convertDigitIn(str) {
    console.log("str", str.split("-").reverse().join("-"))
    return str.split("-").reverse().join("-")
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>DDC</title>
      </MetaTags>
    <div className="page-content">
    <Container fluid>
        <CardComponent>
          <div className="user-info-details">
            <div className="select-role">
              <select
                className="form-select m-auto"
                value={role}
                onChange={handleChangeRole}
              >
                <option value="SS">SS</option>
                <option value="DP">DSR</option>
                <option value="SR">SO</option>
                <option value="TO">TO</option>
                <option value="RBH">RBH</option>
              </select>
            </div>
            <div className="attendance-date">
              <AvForm>
                <AvField
                  name="startTime"
                  id="example-time-input"
                  className="form-control mb-3"
                  type="search"
                  placeholder="Search User ID"
                  onChange={e => setValue(e.target.value)}
                ></AvField>
              </AvForm>
            </div>
            <div className="date-order-excel">
              <AvForm>
                <AvField
                  name="startTime"
                  id="example-time-input"
                  className="form-control mb-3"
                  type="date"
                  value={moment(new Date()).format("YYYY-MM-DD")}
                  onChange={handleChangeDate}
                  errorMessage="Enter Start Time"
                  validate={{ required: { value: true } }}
                ></AvField>
              </AvForm>
            </div>
          </div>
          <Row>
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={userInfo?.users}
              tableHead={tableHead}
              setCurrentPage={setCurrentPage}
              page={pageNumber}
              currentPage={currentPage}
              isPagination
              productLoading={userInfoLoading}
            >
              {userInfoLoading ? (
                <tr style={{ width: "100%" }}>
                  <div
                    className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <Spinner />
                    </div>
                  </div>
                </tr>
              ) : userInfo?.users?.length ? (
                userInfo?.users?.map((user, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <th scope="row">{idx + 1}</th>
                      <td>{user.name}</td>
                      {user.role === "SR" ? (
                        <td>SO</td>
                      ) : user.role === "DP" ? (
                        <td>DSR</td>
                      ) : (
                        <td>SS</td>
                      )}

                   
                      <td>
                        <Button
                          color="primary"
                          className="btn-sm btn-rounded"
                          onClick={() => handleEditUser(user)}
                        >
                        Performance
                        </Button>
                      </td>
                   
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <NoTableData
                  colSpan="9"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>Currently you have no Data. </span>
                </NoTableData>
              )}
            </CustomTable>

            {/* table end */}
          </Row>
        </CardComponent>
      </Container>
    </div>
   
    </React.Fragment>
  )
}

export default UserListPerform
