import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
    Card, CardBody,
    CardTitle, Col,
    Container, FormGroup,
    Label, Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { addNewRegion } from "../../store/GeoInformation/actions";
const AddRegion = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { authtoken,id } = useSelector(state => ({
        authtoken: state.Login.token,
        id: state.Login.id,
    }))

    function handleSubmit(event, errors, values) {
        event.preventDefault();
        let obj = {}
        obj.name = values.name;
        obj.org = id
        console.log(values);
        console.log(authtoken);

        dispatch(addNewRegion(obj, history, authtoken))
    }
    return (
        <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>DDC | Add Region</title>
                    </MetaTags>
                    <Container fluid={true} >
                        <Breadcrumbs title="Add Region" breadcrumbItem="Add Region" />
                        <Row>
                            <Col md={6} className='m-auto'>
                                <Card >
                                    <CardBody >
                                        <CardTitle className="h4 mb-4">Add Region</CardTitle>

                                        <AvForm onSubmit={handleSubmit}>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="formrow-yourRegion-Input">Region Name</Label>
                                                    <AvField
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-yourRegion-Input"
                                                            placeholder='Type Region Name'
                                                            name="name"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <div>
                                                <button type="submit" className="btn btn-primary w-md">
                                                    Add Region
                                                </button>
                                            </div>
                                    </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
             </Container>
                </div>
            </React.Fragment >
    );
};
export default AddRegion;
