import {
  ADD_NEW_STORE,
  ADD_STORE_FAIL,
  ADD_STORE_SUCCESS,
  DELETE_STORE,
  DELETE_STORE_FAIL,
  ADD_EXCLUSIVE_SHOP_SUCCESS,
  ADD_EXCLUSIVE_SHOP_FAIL,
  DELETE_STORE_SUCCESS,
  GET_STORE,
  GET_STORES_FAIL,
  GET_STORES_SUCCESS,
  GET_STORE_FAIL,
  GET_STORE_SUCCESS,
  UPDATE_STORE,
  UPDATE_STORE_FAIL,
  GET_STORE_TOP_PRODUCT_SUCCESS,
  GET_STORE_TOP_PRODUCT_FAIL,
  UPDATE_STORE_SUCCESS,
  GET_STORE_ANALYTIC_SUCCESS,
  GET_STORE_ANALYTIC_FAIL,
  GET_ALL_STORE_SUCCESS,
  GET_ALL_STORE_FAIL,
  GET_SEARCH_STORE_SUCCESS,
  GET_SEARCH_STORE_FAIL,
  GET_STORE_ADD_EXCEL_SUCCESS,
  GET_STORE_ADD_EXCEL_FAIL,
  GET_STORE_ORDER_SUCCESS,
  GET_STORE_ORDER_FAIL

} from "./actionTypes"

const INIT_STATE = {
  
  store: [],
  stores: [],
  topStoreProducts: [],
  exclusiveShop: [],
  storeAnalytic:[],
  searchStore: [],
  storeAddExcel: [],
  storeOrder: [],
  topStoreProduct: {},
  allStore:[],
  error: {},
  loading: true,
  topStoreProductLoading: true,
  singleShopLoading: true,
  exclusiveShopLoading: true,
  storeAnalyticLoading:true,
  allStoreLoading : true,
  searchStoreLoading: true,
  storeAddExcelLoading: true,
  storeOrderLoading: true

}

const StoreReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORES_SUCCESS:
      return {
        ...state,
        stores: action.payload,
        loading: false,
      }

    case GET_STORES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: true,
      }

    case GET_STORE_SUCCESS:
      return {
        ...state,
        store: action.payload,
        singleShopLoading: false,
      }
    case GET_STORE_FAIL:
      return {
        ...state,
        error: action.payload,
        singleShopLoading: true,
      }
      case GET_STORE_ORDER_SUCCESS:
        return {
          ...state,
          storeOrder: action.payload,
          storeOrderLoading: false,
        }
      case GET_STORE_ORDER_FAIL:
        return {
          ...state,
          error: action.payload,
          storeOrderLoading: true,
        }
      case GET_STORE_ANALYTIC_SUCCESS:
        return {
          ...state,
          storeAnalytic: action.payload,
          storeAnalyticLoading: false,
        }
      case GET_STORE_ANALYTIC_FAIL:
        return {
          ...state,
          error: action.payload,
          storeAnalyticLoading: true,
        }

    case ADD_NEW_STORE:
      return {
        ...state,
        loading: true,
      }
    case ADD_STORE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      }

    case ADD_STORE_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }
    case ADD_EXCLUSIVE_SHOP_SUCCESS:
      return {
        ...state,
        exclusiveShop: [...state.exclusiveShop, action.payload],
        exclusiveShopLoading: false,
      }
    case ADD_EXCLUSIVE_SHOP_FAIL:
      return {
        ...state,
        error: action.payload,
        exclusiveShopLoading: true,
      }
    case GET_STORE_TOP_PRODUCT_SUCCESS:
      return {
        ...state,
        topStoreProducts: action.payload,
        topStoreProductLoading: false,
      }
    case GET_STORE_TOP_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        topStoreProductLoading: true,
      }

    case UPDATE_STORE:
      return {
        ...state,
        loading: false,
      }

    case UPDATE_STORE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      }

    case UPDATE_STORE_FAIL:
      return {
        ...state,
        error: action.payload.message,
      }
    case DELETE_STORE:
      return {
        ...state,
        loading: false,
      }

    case DELETE_STORE_SUCCESS:
      return {
        ...state,
        stores: state.stores.filter(
          store => store.id.toString() !== action.payload.values.id.toString()
        ),
        loading: false,
      }

    case DELETE_STORE_FAIL:
      return {
        ...state,
        error: action.payload.values,
        loading: false,
      }

      case GET_ALL_STORE_SUCCESS:
        return {
          ...state,
          allStore: action.payload,
          allStoreLoading: false,
        }
  
      case GET_ALL_STORE_FAIL:
        return {
          ...state,
          error: action.payload.message,
          allStoreLoading: true,
        }
        case GET_SEARCH_STORE_SUCCESS:
          return {
            ...state,
            searchStore: action.payload,
            searchStoreLoading: false,
          }
    
        case GET_SEARCH_STORE_FAIL:
          return {
            ...state,
            error: action.payload.message,
            searchStoreLoading: true,
          }
          case GET_STORE_ADD_EXCEL_SUCCESS:
            return {
              ...state,
              storeAddExcel: action.payload,
              storeAddExcelLoading: false,
            }
      
          case GET_STORE_ADD_EXCEL_FAIL:
            return {
              ...state,
              error: action.payload.message,
              storeAddExcelLoading: true,
            }
  

    default:
      return state
  }
}

export default StoreReducer
