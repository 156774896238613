
import Breadcrumbs from "components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container, Row, Spinner, UncontrolledTooltip
} from "reactstrap";
import { getGeo } from '../../../store/GeoInformation/actions';





const TableGeoData = props => {

  const history = useHistory()
  const dispatch = useDispatch();

  
      const { geo } = useSelector(state => ({ geo: state.GeoInformationReducer.geo.geo }))
      
      const { authtoken, id, geoloading } = useSelector(state => ({
          authtoken: state.Login.token,
          id: state.Login.id,
          geoloading: state.GeoInformationReducer.geoloading
      }))
      useEffect(() => {
                dispatch(getGeo(authtoken));
            }, [dispatch]);
            console.log('hhhhh',geo);
  const tableData = geoloading && geo?.map((geo, index) => {
    return {
      sl: index + 1,
      heading1:geo?.area?.name,
      heading2: geo?.region?.name,
      heading3: geo?.teritori?.name,
      heading4: geo?.geocode,
      heading5: geo?.routes?.length,
      manage: (
        <>
          <Button
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => {
              history.push(`table-route-geo/${geo?._id}`)
            }}
          >
            See Routes
          </Button>

        </>
      ),
    }
  })




  const GeoData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },
      {
        label: "Area",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Region",
        field: "heading2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Territory",
        field: "heading3",
        sort: "asc",
        width: 150,
      },
      {
        label: "Geo Code",
        field: "heading4",
        sort: "asc",
        width: 150,
      },,
      {
        label: "Total Routes",
        field: "heading5",
        sort: "asc",
        width: 150,
      },
     
      {
        label: "See Routes",
        field: "manage",
      },
    ],
    rows: tableData,
  }






  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div >
        <MetaTags>
          <title>GEO | DDC</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="GEO" breadcrumbItem="GEO details" />
          {geoloading ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>

                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={GeoData}
                    />
                   
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="text-center mt-5 pt-5">
              <Spinner />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(TableGeoData);
