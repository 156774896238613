import { map } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner, Table } from "reactstrap";
//Import Breadcrumb

import { getInvoice } from '../../../store/Order/actions';



const DistPreOrderInvoice = props => {
  const dispatch = useDispatch();
  const {
    params: { invoiceID },
  } = useRouteMatch('/distInvoice/:invoiceID');
  console.log(invoiceID);

  const { invocies, authtoken, dateLoading, id,invoiceLoading } = useSelector(state => ({
    invocies: state.OrderReducer.invocies,
    id: state.Login.id,
    authtoken: state.Login.token,
    invoiceLoading: state.OrderReducer.invoiceLoading
}));

useEffect(() => {
  dispatch(getInvoice(authtoken, invoiceID));
}, []);
  const printInvoice = () => {
    window.print();
  };
  console.log(invocies);


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Invoice Detail | DDC
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
         
          {invoiceLoading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  
                    <div className="mb-4 ">
                   {invocies.data.singleOrder.status==='Pending'? 
                     (<div><p className='text-center fw-bold '>Pre Order Invoice <span style={{ color: "blue" }}>(Pending)</span></p></div>)
                   :  
                    (<div> <p className='text-center fw-bold '>Invoice <span style={{ color: "green" }}>(Delivered)</span> </p></div>)} 
                    {invocies.data.singleOrder.editedOrder===null? (<div><p className='text-center fw-bold '> </p></div>)
                    : 
                    (<div><p className='text-center fw-bold '>Edited Invoice
                     <Link  to={`/distEdited-invoice/${invocies.data.singleOrder.editedOrder}`}><small> (See Orginal)</small></Link></p></div>)}
                   
                   
                   </div>
                    



                
                  <hr />

                  <Row>
                    <Col xs="6" className="mt-3">
                      <address>
                        <strong>Billed To:</strong>
                        <br />
                       {invocies.data.singleOrder.shop.name}
                        <br />
                        {invocies.data.singleOrder.shop.address}

                      </address>
                    </Col>
                    <Col xs="6" className="mt-3 text-end">
                      <address>
                        <strong>Order Date:</strong>
                        <br />
                       {invocies.data.singleOrder.orderTakenDate}
                        <br />
                        <br />
                      </address>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                    </Col>

                    <Col xs="6" className=" text-end">
                      <address>
                        <strong>Dp Name:</strong>
                        <br />
                        {invocies.data.singleOrder.dp.name}
                        <br />
                        <br />
                      </address>
                    </Col>
                  </Row>
                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 fw-bold">Order summary</h3>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-nowrap">
                      <thead>
                        <tr>
                          <th style={{ width: "70px" }}>No.</th>
                          <th >Item</th>
                          {/* <th >Quantity</th> */}
                          <th className="text-end">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(
                            invocies.data.singleOrder.orderProducts,
                            (item, key) => (
                        <tr >
                          <td>{key+1}</td>
                          <td>{item.productName} <span  style={{ color: "green" }}>({item.qty})</span>  </td>
                          {/* <td>{item.qty}</td> */}
                          <td className="text-end">{item.total}</td>
                        </tr>
                            )
                          )} 
                        <tr>
                          <td colSpan="2" className="text-end">
                            Sub Total
                          </td>
                          <td className="text-end">
                          {invocies.data.singleOrder.subTotal}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="text-end">
                              Vat
                          </td>
                          <td className="text-end">
                          {invocies.data.singleOrder.vat}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="text-end">
                              Discount
                          </td>
                          <td className="text-end">
                          {invocies.data.singleOrder.totalDiscount}
                          </td>
                        </tr>
                        
                        {/* <tr>
                          <td colSpan="2" className="border-0 text-end">
                            <strong>Shipping</strong>
                          </td>
                          <td className="border-0 text-end">
                            323
                          </td>
                        </tr> */}
                        <tr>
                          <td colSpan="2" className="text-end">
                            <strong>Grand Total</strong>
                          </td>
                          <td className=" text-end">
                            <h5 className="m-0">
                            {invocies.data.singleOrder.grandTotal}

                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className=" text-end">
                            <strong>Collected Amount</strong>
                          </td>
                          <td className=" text-end">
                            <h5 className="m-0 ">
                            {invocies.data.singleOrder.collectAmount}

                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="text-end">
                            <strong>Total Due</strong>
                          </td>
                          <td className=" text-end">
                            <h5 className="m-0 text-danger fw-bold">
                            {invocies.data.singleOrder.grandTotal - invocies.data.singleOrder.collectAmount } 
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-print-none">
                    <div className="float-end">
                      <Link
                        to="#"
                        onClick={printInvoice}
                        className="btn btn-success  me-2"
                      >
                        <i className="fa fa-print" />
                      </Link>

                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
                    )}
        </Container>
      </div>
    </React.Fragment>
  );
};

DistPreOrderInvoice.propTypes = {
  match: PropTypes.any,
};

export default withRouter(DistPreOrderInvoice);
