import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { Container } from "reactstrap"
import Breadcrumb3 from "../../components/Common/Breadcrumb3"
import BreadcrumbMultiple from "../../components/Common/Breadcrumb4"
const InnerLayer = ({
  children,
  wrapperClass,
  title,
  isBreadCrumb,
  link,
  mainTitle,
  subTitle,
  isMultiple,
  breadcrumbItems,
  buttonText,
  onAddHandler,
  isButton,
}) => {
  const { loading, authtoken, areaData, role } = useSelector(state => ({
    authtoken: state.Login.token,
    role: state.Login.userrole,
  }))
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {isMultiple ? (
            <BreadcrumbMultiple
              title={title}
              breadcrumbItems={breadcrumbItems}
            />
          ) : (
            <Breadcrumb3
              breadcrumbItem={title}
              isBreadCrumb={isBreadCrumb}
              title={mainTitle}
              subTitle={subTitle}
              backLink={link}
              buttonText={buttonText}
              onAddHandler={onAddHandler}
              role={role}
              isButton={isButton}
            />
          )}

          <div className={wrapperClass}>{children}</div>
        </Container>
      </div>
    </React.Fragment>
  )
}

InnerLayer.propTypes = {
  title: PropTypes.string,
  wrapperClass: PropTypes.string,
  children: PropTypes.any,
  isBreadCrumb: PropTypes.bool,
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string,
  link: PropTypes.string,
  breadcrumbItems: PropTypes.array,
  isMultiple: PropTypes.bool,
  buttonText: PropTypes.string,
  onAddHandler: PropTypes.string,
  isButton: PropTypes.bool,
}

export default InnerLayer
