import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import Select from "react-select"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getOffices } from "../../store/office/actions"
import { getLineManager, getUsers, updateUser } from "../../store/users/actions"
import { getRegion, getArea, getTerritroy } from "../../store/GeoInformation/actions"

const EditUser = () => {
  const { id: userId } = useParams()
  const [selectedLineManager, setSelectedLineManager] = useState(null)
    const [areaData, setAreaData] = useState(null)
  const [regionData, setRegionData] = useState(null)
  const [territoryData, setTerritoryData] = useState(null)
  const [file, setfile] = useState("")
  const [testloading, setloading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [loadArea, setLoadArea] = useState(false)
  const [loadTerritory, setLoadTerritory] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const { authtoken, offices, users, lineManager, id, usersList,areaInfo,areaList, territoryList,
    selectedRegion, selectedArea, selectedTerritory,regionInfo,territoryInfo,editUserInfo,
    lineManagerLoading} = useSelector(
   state => ({
     authtoken: state.Login.token,
     offices: state.OfficeReducer?.offices?.office,
     users: state.UserReducer,
     id: state.Login.id,
     lineManager: state.UserReducer?.lineManager,
     lineManagerLoading: state.UserReducer.lineManagerLoading,
     usersList: state.UserReducer?.users,
     editUserInfo: state.UserReducer.editUserInfo,
     areaInfo: state.GeoInformationReducer.area.area,
     regionInfo: state.GeoInformationReducer.region.region,
     territoryInfo: state.GeoInformationReducer.territory.territory,
     areaList: state.DashboardReducer.areaList,
     territoryList: state.DashboardReducer.territoryList,
     selectedRegion: state.DashboardReducer.selectedRegion,
     selectedArea: state.DashboardReducer.selectedArea,
     selectedTerritory: state.DashboardReducer.selectedTerritory,
   })
 )


 console.log('edit user info',editUserInfo);
 const [role, setRole] = useState(editUserInfo?.data?.role)


 const handleChange = e => {
  setRole(e.target.value)


  if (e.target.value == "SR") {
    
    setIsDisabled(false)
  }
  if (e.target.value == "DP") {
    setIsDisabled(true)

   
  }
  if (e.target.value == "SS") {
    setIsDisabled(false)
  }
  if (e.target.value == "TO") {
    setIsDisabled(false)
  }
  if (e.target.value == "RBH") {
    setIsDisabled(true)
  }
  if (e.target.value == "DISTRIBUTOR") {
    setIsDisabled(true)
  }
}
  useEffect(() => {
    dispatch(getRegion(authtoken))
    dispatch(getArea(authtoken))
    dispatch(getTerritroy(authtoken))

  }, [])



  const editUser = usersList?.find(user => user._id === userId)
  console.log(`file: EditUser.js ~ line 47 ~ EditUser ~ editUser`, editUser)
  useEffect(() => {
    setRegionData(editUser?.region?._id || "")
    setAreaData(editUser?.area?._id || "")
    setTerritoryData(editUser?.teritori?._id || "")
    setSelectedLineManager(editUser?.linemanager?._id || "")
  }, [editUser])

  if (!!users === false) {
    history.push("/user-details")
  }

  useEffect(() => {
    dispatch(getOffices(authtoken))
  }, [])
  console.log('selectedLineManager',selectedLineManager);
  useEffect(() => {
    dispatch(getLineManager(authtoken, role))
  }, [role])
  useEffect(() => {
    dispatch(getUsers(authtoken, id))
  }, [])
  const handleLineManager = value => {
    setSelectedLineManager(value._id)
  }

 
  const handleRegion = value => {
    console.log('value',value);
    setRegionData(value._id)
    // dispatch(onRegionFilterChange(data))
    // setLoadArea(!loadArea)
  }

  const handleArea = value => {
    setAreaData(value._id)
    // dispatch(onAreaFilterChange(data))
    // setLoadTerritory(!loadTerritory)
  }
  const handleTerritory = value => {
    setTerritoryData(value._id)
    // dispatch(onTerritoryFilterChange(data))
  }

  console.log(lineManager)
  async function handleSubmit(event, errors, values) {
    event.preventDefault()
    setloading(true)
    const formData = new FormData()
    // console.log(values)

    const { name, office, email, phoneNumber, employeeId, role } = values
    
      let newData = {}
      newData.name = name
      newData.role = role
      newData.employeeId = employeeId
      newData.phoneNumber = phoneNumber
      newData.office = office
     
      newData.org = id  
      if(regionData){
        newData.region = regionData

      }
      if(areaData){
        newData.area = areaData

      }
      if(territoryData){
        newData.teritori = territoryData

      }
      if(role === 'SR'){
      
        newData.linemanager = selectedLineManager
      }
      if(role === 'SS')
      {
        newData.linemanager = selectedLineManager
      }
      if(values.role === 'TO')
      {
        newData.linemanager = selectedLineManager
      }







      const data = {
        name,
        role,
        employeeId,
        phoneNumber,
        office,
       
        org: id,
        region: regionData,
        area: areaData,
        teritori: territoryData

      }
      formData.append("name", values.name)
      formData.append("email", values.email)
      formData.append("office", values.office)
      formData.append("phoneNumber", values.phoneNumber)
      formData.append("employeeId", values.employeeId)
      if (values.role === "SR") {
        formData.append("linemanager", selectedLineManager)
      } else if (values.role === "SS") {
        formData.append("linemanager", values.office)
      }
      // formdata.append("region", regionData)
      // formdata.append("area", areaData)
      // formdata.append("teritori", territoryData)
      formData.append("role", values.role)
      formData.append("org", id)
      if (file) {
        formData.append("photo", file)
      }
      // mainFilter
      for (const key of formData.entries()) {
        console.log(key)
      }

      console.log(`file: EditUser.js ~ line 142 ~ handleSubmit ~ data`, newData)
      dispatch(updateUser(userId, newData, authtoken, history))
  
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Add User</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Edit User" breadcrumbItem="Edit User" />
          <Row>
            <Col md={6} className="m-auto">
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Edit User</CardTitle>

                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Full name
                          </Label>
                          <AvField
                            name="name"
                            id="formrow-firstname-Input"
                            type="text"
                            className="form-control"
                            placeholder="Type User Name"
                            value={editUserInfo?.data?.name || ""}
                            errorMessage="Enter Name"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="phoneNumber">Phone Number</Label>
                          <AvField
                            name="phoneNumber"
                            type="text"
                            className="form-control"
                            value={editUserInfo?.data?.phoneNumber}
                            id="phoneNumber"
                            placeholder="Type phone Number"
                            errorMessage="Enter Phone Number"
                            required
                            validate={{
                              minLength: {
                                value: 11,
                                errorMessage: "Your phone must be 11 digits",
                              },
                              maxLength: {
                                value: 11,
                                errorMessage: "Your phone must be 11 digits",
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <AvField
                            name="email"
                            type="email"
                            className="form-control"
                            id="formrow-email-Input"
                            value={editUserInfo?.data?.email}
                            placeholder="Type Email address"
                            errorMessage="Enter Email"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="formrow-InputState">
                            Select Role
                          </Label>
                          <AvField
                            name="role"
                            id="formrow-InputState"
                            className="form-control"
                            type="select"
                            value={editUserInfo?.data?.role}
                            errorMessage="Select Role"
                            onChange={e => handleChange(e)}
                            validate={{ required: { value: true } }}
                          >
                            <option value="">Select Role</option>

                            <option value="SR">SO</option>
                            <option value="DP">DSR</option>
                            <option value="SS">SS</option>
                            <option value="DISTRIBUTOR">Distributor</option>
                            <option value="TO">TO</option>
                            <option value="RBH">RBH</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="employeeId">Employee Id</Label>
                          <AvField
                            name="employeeId"
                            type="text"
                            className="form-control"
                            value={editUserInfo?.data?.employeeId}
                            id="employeeId"
                            placeholder="Type Employee ID"
                            errorMessage="Type Employee ID"
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <AvField
                            value={editUserInfo?.data?.office || ""}
                            type="select"
                            name="office"
                            label="Office"
                          >
                            <option>Not Selected</option>
                            {offices?.map(index => {
                              return (
                                <option key={index._id} value={index._id}>
                                  {index.name}
                                </option>
                              )
                            })}
                          </AvField>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="linemanager">Supervisor</Label>
                          {!lineManagerLoading ? (
                            <Select
                              name="linemanager"
                              cacheOptions
                              isDisabled={isDisabled}
                              getOptionLabel={e => e.employeeId}
                              getOptionValue={e => e._id}
                              value={lineManager?.users?.filter(
                                data => data?._id === selectedLineManager
                              )}
                              options={lineManager.users}
                              defaultOptions
                              onChange={handleLineManager}
                            />
                          ) : (
                            <span />
                          )}
                         
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="discount">Image</Label>
                          <AvField
                            type="file"
                            className="form-control"
                            id="formrow-brand-Input"
                            name="image"
                            onChange={e => {
                              setfile(e.target.files[0])
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <div style={{ minWidth: "150px" }} className="me-3 mb-3">
                        <Label htmlFor="example-endTime-input">Region</Label>
                        <Select
                          name="region"
                          classNamePrefix="select2-selection"
                          // disabled={loadingCondition}
                          style={{ minWidth: "100px" }}
                          //   cacheOptions
                         
                          getOptionLabel={e => e.name}
                          getOptionValue={e => e._id}
                          value={regionInfo?.filter(
                            data => data?._id === regionData
                          )}
                          placeholder={"Select Region"}
                          options={regionInfo}
                          onChange={handleRegion}
                        />
                      </div> 
                   
                        <div
                          style={{ minWidth: "150px" }}
                          className="me-3 mb-3"
                        >
                          <Label htmlFor="example-endTime-input">Area</Label>
                          <Select
                            name="area"
                            classNamePrefix="select2-selection"
                            // disabled={loadingCondition}
                            style={{ minWidth: "100px" }}
                            //   cacheOptions
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            // defaultInputValue={}
                            value={areaInfo?.filter(
                              data => data?._id === areaData
                            )}
                            placeholder={"Select Area"}
                            options={areaInfo}
                            onChange={handleArea}
                          />
                        </div>
                     
 
                 
                        <div
                          style={{ minWidth: "150px" }}
                          className="me-3 mb-3"
                        >
                            <Label htmlFor="example-endTime-input">Territory</Label>
                          <Select
                            name="teritori"
                            classNamePrefix="select2-selection"
                            // disabled={loadingCondition}
                            style={{ minWidth: "100px" }}
                            value={territoryInfo?.filter(
                              data => data?._id === territoryData
                            )}
                            getOptionLabel={e => e.name}
                            getOptionValue={e => e._id}
                            // defaultInputValue={}
                            placeholder={"Select Territory"}
                            options={territoryInfo}
                            onChange={handleTerritory}
                          />
                        </div>
                
                    </Row>
                    {/* {"f" ? (
                      <div className="p-4 d-flex justify-content-center align-items-center p-2 mb-3 mt-5 mr-auto">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    ) : ( */}
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary w-md"
                        onSubmit={handleSubmit}
                      >
                        Update User
                      </button>
                    </div>
                    {/* )} */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* end row  */}
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default EditUser
