import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import {
    Card, CardBody,
    CardTitle, Col,
    Container, FormGroup,
    Label, Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { addNewTarget } from '../../store/Target/actions';

// import { addNewUser } from "../../store/users/actions";
const moment = require("moment")
const setTarget = () => {
    const year = moment().format("YYYY")
    const { authtoken,id } = useSelector(state => ({
        authtoken: state.Login.token,
        id: state.Login.id,
        
    }))
    const history = useHistory()
    const dispatch = useDispatch()
    const {
        params: { userID,userName },
    } = useRouteMatch('/target-set/:userID/:userName');
  console.log(userName);
    async function  handleSubmit  (event, errors, values)  {
        event.preventDefault()
        console.log(values);
        let obj = {}
        if (values.actualTarget) {
            obj.actualTarget = values.actualTarget
        }
        if(values.month){
            obj.month = values.month

        }
        
        obj.org = id;
        obj.user =userID
     
        console.log(obj);
        dispatch(addNewTarget(obj, history, authtoken))
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DDC | Set Target</title>
                </MetaTags>
                <Container fluid={true} >
                    <Breadcrumbs title="Add New Target" breadcrumbItem="Add New Target" />
                    <Row>
                        <Col md={6} className='m-auto'>
                            <Card >
                                <CardBody >
                                    <CardTitle className="h4 mb-4">Target Set for {userName}</CardTitle>

                                    <AvForm  onSubmit={handleSubmit}>
                                        <Row>
                                        <Col md={12}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-month">Select Month</Label>
                                                    <AvField
                                                        name='month'
                                                        id="formrow-month"
                                                        className="form-control"
                                                        type="select"
                                                        errorMessage="Select month"
                                                        validate={{ required: { value: true } }}
                                                    >
                                                        <option>Choose</option>
                                                        <option value={`01-${year}`}>January</option>
                                                        <option value={`02-${year}`}>February</option>
                                                        <option value={`03-${year}`}>March</option>
                                                        <option value={`04-${year}`}>April</option>
                                                        <option value={`05-${year}`}>May</option>
                                                        <option value={`06-${year}`}>June</option>
                                                        <option value={`07-${year}`}>July</option>
                                                        <option value={`08-${year}`}>August</option>
                                                        <option value={`09-${year}`}>September</option>
                                                        <option value={`10-${year}`}>October</option>
                                                        <option value={`11-${year}`}>November</option>
                                                        <option value={`12-${year}`}>December</option>

                                                        
                                                    </AvField>
                                               
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="amount">Type Amount </Label>
                                                    <AvField
                                                        name="actualTarget"
                                                        type="number"
                                                        className="form-control"
                                                        id="amount"
                                                        placeholder='Type Amount'
                                                        errorMessage="Enter Amount "
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        




                                        <div>
                                            <button type="submit" className="btn btn-primary w-md">
                                                Set Target
                                            </button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                    {/* end row  */}



                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    )
}


export default setTarget
