
import React, { useEffect } from "react"
import { makeQuery } from "helpers/Custom/makeQuery"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Spinner, Table } from "reactstrap"
import { getTopRegion } from "../../store/OrgDashboard/actions"
import "./Dashboard.css"

const TopRegion = () => {
  const dispatch = useDispatch()

  const { topRoutes, authtoken, topRouteLoading,    mainFilter,
    isSubFilterChanged,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    currentSelection, } = useSelector(state => ({
    topRoutes: state.DashboardReducer.topRoutes.data,
    id: state.Login.id,
    authtoken: state.Login.token,
    topRouteLoading: state.DashboardReducer.topRouteLoading,
    isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
    mainFilter: state.DashboardReducer.mainFilter,
    currentSelection: state.DashboardReducer.currentSelection,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    selectedTerritory: state.DashboardReducer.selectedTerritory,
  }))
  useEffect(() => {
    const query = makeQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection
    )
    dispatch(getTopRegion(authtoken, query))
  }, [isSubFilterChanged])

  // console.log(topRoutes);
  const bgClr = ["success", "primary", "warning"]
  return (
    <React.Fragment>
      {topRouteLoading ? (
        <div className="text-center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <Card className="top-region">
          <CardBody>
            <CardTitle className="mb-3">Top Selling Routes</CardTitle>
            {
               topRoutes?.data?.length === 0 ? 
               <div className="text-center">
               <div className="mb-3">
                 <i className="bx bx-map-pin text-primary display-4" />
               </div>
               <h4 className="text-danger">
                 No Routes Available
                 
               </h4>
            
             </div> :
             <>
              <div className="text-center">
              <div className="mb-3">
                <i className="bx bx-map-pin text-primary display-4" />
              </div>
              <h4 className="text-success">
                {topRoutes?.data?.[0]?.totalamount?.toLocaleString('en-US', {minimumFractionDigits: 2})} Tk
              </h4>
              <p>{topRoutes?.data?.[0]?.routename}</p>
            </div>

            <div className="table-responsive mt-4">
              <Table className="table align-middle table-nowrap">
                {(topRoutes?.data || []).map((data, key) => {
                  return (
                    <>
                      <tbody>
                        <tr>
                          <td style={{ width: "30%" }}>
                            <p className="">{data?.routename} </p>
                          </td>
                          <td style={{ width: "25%" }}>
                            <p className=" fw-bold">
                              {data?.totalamount?.toLocaleString('en-US', {minimumFractionDigits: 2})} Tk
                            </p>
                          </td>
                        </tr>
                        {/* <tr>
                  <td>
                <div className="progress animated-progess progress-sm">
                        <div
                          className={"progress-bar bg-"+ bgClr[key]}
                          role="progressbar"
                          style={{ width: "60%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                        
                      </div>
                </td>
                </tr>
                */}
                      </tbody>
                    </>
                  )
                })}
              </Table>
            </div>
            </>
 
            }
           
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  )
}

export default TopRegion
