import {
  ADD_NEW_SURVEY,
  GET_SURVEY_FAIL,
  GET_SURVEY_SUCCESS,
  ADD_NEW_SURVEY_SUCCESS,
  ADD_NEW_SURVEY_FAIL,
  GET_SURVEY_SHOP_SUCCESS,
  GET_SURVEY_SHOP_FAIL,
  GET_SINGLE_SHOP_QUES_SUCCESS,
  GET_SINGLE_SHOP_QUES_FAIL

} from "./actionTypes"

const INIT_STATE = {
  surveyList: [],
  surveyListLoading: true,
  surveyShop: [],
  surveyShopLoading: true,
  singleShopQues: [],
  singleShopQuesLoading: true,
  adding: false,
}

const SurveyListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_SURVEY: {
      return {
        ...state,
        adding: true,
      }
    }
    case ADD_NEW_SURVEY_SUCCESS: {
      return {
        ...state,
        adding: false,
      }
    }
    case ADD_NEW_SURVEY_FAIL: {
      return {
        ...state,
        adding: false,
      }
    }
    case GET_SURVEY_SUCCESS:
      return {
        ...state,
        surveyList: action.payload,
        surveyListLoading: false,
      }
    case GET_SURVEY_FAIL:
      return {
        ...state,
        error: action.payload,
        surveyListLoading: false,
      }
      case GET_SURVEY_SHOP_SUCCESS:
        return {
          ...state,
          surveyShop: action.payload,
          surveyShopLoading: false,
        }
      case GET_SURVEY_SHOP_FAIL:
        return {
          ...state,
          error: action.payload,
          surveyShopLoading: false,
        }
        case GET_SINGLE_SHOP_QUES_SUCCESS:
          return {
            ...state,
            singleShopQues: action.payload,
            singleShopQuesLoading: false,
          }
        case GET_SINGLE_SHOP_QUES_FAIL:
          return {
            ...state,
            error: action.payload,
            singleShopQuesLoading: false,
          }

    default:
      return state
  }
}

export default SurveyListReducer
