import { AvField, AvForm } from "availity-reactstrap-validation";
import { object } from "prop-types";
import React from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
    Card, CardBody,
    CardTitle, Col,
    Container, FormGroup, Label, Row
} from "reactstrap";
//Import Breadcrumb 
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {addNewUnit} from '../../store/Unit/actions'


const Unit = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const { authtoken,id } = useSelector(state => ({
        authtoken: state.Login.token,
        id: state.Login.id,
      }))
      
    function handleSubmit (event, errors, values) {
        event.preventDefault();
        let obj = {}
        obj.name = values.name;
        obj.org = id;
        console.log(obj);
        console.log(authtoken);

        dispatch(addNewUnit(obj, history, authtoken))

       
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DDC | Add Unit</title>
                </MetaTags>
                <Container fluid={true} >
                    <Breadcrumbs title="Add Unit" breadcrumbItem="Add Unit" />
                    <Row>
                        <Col md={6} className='m-auto'>
                            <Card >
                                <CardBody >
                                    <CardTitle className="h4 mb-4">Add Unit</CardTitle>

                                    <AvForm onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="formrow-product-Input">Unit Name</Label>
                                                    <AvField
                                                        type="text"
                                                        className="form-control"
                                                        id="formrow-product-Input"
                                                        placeholder='Type Category Name'
                                                        name="name"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                <button type="submit" className="btn btn-primary w-md">
                                                Add Unit
                                </button>          
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                   



                </Container>
            
            </div>
        </React.Fragment >
    )
}
export default Unit;