import {
  ADD_AREA_FAIL,
  ADD_AREA_SUCCESS,
  ADD_GEO_FAIL,
  ADD_GEO_SUCCESS,
  ADD_NEW_AREA,
  ADD_NEW_GEO,
  ADD_NEW_REGION,
  ADD_NEW_ROUTE,
  ADD_NEW_TERRITORY,
  ADD_REGION_FAIL,
  ADD_REGION_SUCCESS,
  ADD_ROUTE_FAIL,
  ADD_ROUTE_SUCCESS,
  ADD_TERRITORY_FAIL,
  ADD_TERRITORY_SUCCESS,
  DELETE_GEO,
  DELETE_GEO_FAIL,
  DELETE_GEO_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  GET_AREAS,
  GET_AREAS_FAIL,
  GET_AREAS_SUCCESS,
  GET_DATA,
  GET_DETAILS_ROUTES,
  GET_DETAILS_ROUTES_FAIL,
  GET_DETAILS_ROUTES_SUCCESS,
  GET_GEOS,
  GET_GEOS_FAIL,
  GET_GEOS_SUCCESS,
  GET_GEO_ROUTES,
  GET_GEO_ROUTES_FAIL,
  GET_GEO_ROUTES_SUCCESS,
  GET_REGIONS,
  GET_REGIONS_FAIL,
  GET_REGIONS_SUCCESS,
  GET_ROUTES,
  GET_ROUTES_FAIL,
  GET_ROUTES_SUCCESS,
  GET_ROUTE_STORE,
  GET_ROUTE_STORE_FAIL,
  GET_ROUTE_STORE_SUCCESS,
  GET_TERRITORYS,
  GET_TERRITORYS_FAIL,
  GET_TERRITORYS_SUCCESS,
  GET_USER,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  GET_ROUTES_INFO,
  GET_ROUTES_INFO_SUCCESS,
  GET_ROUTES_INFO_FAIL
} from "./actionTypes"

export const addNewArea = (data, history, authtoken) => ({
  type: ADD_NEW_AREA,
  payload: { data, history, authtoken },
})

export const addAreaSuccess = data => ({
  type: ADD_AREA_SUCCESS,
  payload: data,
})

export const addAreaFail = error => ({
  type: ADD_AREA_FAIL,
  payload: error,
})

export const addNewRegion = (data, history, authtoken) => ({
  type: ADD_NEW_REGION,
  payload: { data, history, authtoken },
})
export const addRegionSuccess = data => ({
  type: ADD_REGION_SUCCESS,
  payload: data,
})

export const addRegionFail = error => ({
  type: ADD_REGION_FAIL,
  payload: error,
})
export const addNewTerritory = (data, history, authtoken) => ({
  type: ADD_NEW_TERRITORY,
  payload: { data, history, authtoken },
})

export const addTerritorySuccess = data => ({
  type: ADD_TERRITORY_SUCCESS,
  payload: data,
})

export const addTerritoryFail = error => ({
  type: ADD_TERRITORY_FAIL,
  payload: error,
})

export const addNewGeo = (data, history, authtoken) => ({
  type: ADD_NEW_GEO,
  payload: { data, history, authtoken },
})

export const addGeoSuccess = data => ({
  type: ADD_GEO_SUCCESS,
  payload: data,
})

export const addGeoFail = error => ({
  type: ADD_GEO_FAIL,
  payload: error,
})

export const addNewRoute = (data, history, authtoken) => ({
  type: ADD_NEW_ROUTE,
  payload: { data, history, authtoken },
})

export const addRouteSuccess = data => ({
  type: ADD_ROUTE_SUCCESS,
  payload: data,
})

export const addRouteFail = error => ({
  type: ADD_ROUTE_FAIL,
  payload: error,
})
export const getRoute = (authtoken, id) => ({
  type: GET_ROUTES,
  payload: { authtoken, id },
})

export const getRouteSuccess = (data, authtoken) => ({
  type: GET_ROUTES_SUCCESS,
  payload: { data, authtoken },
})

export const getRouteFail = error => ({
  type: GET_ROUTES_FAIL,
  payload: error,
})

export const getGeoRoute = (authtoken, id) => ({
  type: GET_GEO_ROUTES,
  payload: { authtoken, id },
})

export const getGeoRouteSuccess = (data, authtoken) => ({
  type: GET_GEO_ROUTES_SUCCESS,
  payload: { data, authtoken },
})

export const getGeoRouteFail = error => ({
  type: GET_GEO_ROUTES_FAIL,
  payload: error,
})

export const getDetailsRoute = (authtoken, routeId) => ({
  type: GET_DETAILS_ROUTES,
  payload: { authtoken, routeId },
})
export const getDetailsRouteSuccess = (data, authtoken) => ({
  type: GET_DETAILS_ROUTES_SUCCESS,
  payload: { data, authtoken },
})

export const getDetailsRouteFail = error => ({
  type: GET_DETAILS_ROUTES_FAIL,
  payload: error,
})
export const getRouteShop = (authtoken, routeId) => ({
  type: GET_ROUTE_STORE,
  payload: { authtoken, routeId },
})
export const getRouteShopSuccess = (data, authtoken) => ({
  type: GET_ROUTE_STORE_SUCCESS,
  payload: { data, authtoken },
})

export const getRouteShopFail = error => ({
  type: GET_ROUTE_STORE_FAIL,
  payload: error,
})

export const getArea = authtoken => ({
  type: GET_AREAS,
  payload: { authtoken },
})

export const getAreaSuccess = data => ({
  type: GET_AREAS_SUCCESS,
  payload: data,
})

export const getAreaFail = error => ({
  type: GET_AREAS_FAIL,
  payload: error,
})

export const getRegion = authtoken => ({
  type: GET_REGIONS,
  payload: { authtoken },
})

export const getRegionSuccess = data => ({
  type: GET_REGIONS_SUCCESS,
  payload: data,
})

export const getRegionFail = error => ({
  type: GET_REGIONS_FAIL,
  payload: error,
})

export const getTerritroy = authtoken => ({
  type: GET_TERRITORYS,
  payload: { authtoken },
})

export const getTerritroySuccess = data => ({
  type: GET_TERRITORYS_SUCCESS,
  payload: data,
})

export const getTerritroyFail = error => ({
  type: GET_TERRITORYS_FAIL,
  payload: error,
})
export const getGeo = authtoken => ({
  type: GET_GEOS,
  payload: { authtoken },
})

export const getGeoSuccess = data => ({
  type: GET_GEOS_SUCCESS,
  payload: data,
})

export const getGeoFail = error => ({
  type: GET_GEOS_FAIL,
  payload: error,
})

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = data => ({
  type: GET_USERS_SUCCESS,
  payload: data,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})
export const getUser = id => ({
  type: GET_USER,
  payload: id,
})

export const getUserSuccess = data => ({
  type: GET_USER_SUCCESS,
  payload: data,
})

export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
})

export const addNewUser = (data, history) => ({
  type: ADD_NEW_USER,
  payload: { data, history },
})

export const addUserSuccess = data => ({
  type: ADD_USER_SUCCESS,
  payload: data,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const updateUser = (id, data) => ({
  type: UPDATE_USER,
  payload: { id, data },
})

export const updateUserSuccess = (id, data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { id, data },
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = data => ({
  type: DELETE_USER,
  payload: data,
})

export const deleteUserSuccess = data => ({
  type: DELETE_USER_SUCCESS,
  payload: data,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})
export const deleteGeo = data => ({
  type: DELETE_GEO,
  payload: data,
})

export const deleteGEOSuccess = data => ({
  type: DELETE_GEO_SUCCESS,
  payload: data,
})

export const deleteGEOFail = error => ({
  type: DELETE_GEO_FAIL,
  payload: error,
})

export const getData = () => ({
  type: GET_DATA,
})
export const getRouteInfo = (authtoken, orgId, currentPage, routeName) => ({
  type: GET_ROUTES_INFO,
  payload: { authtoken, orgId, currentPage, routeName },
})

export const getRouteInfoSuccess = (data, authtoken) => ({
  type: GET_ROUTES_INFO_SUCCESS,
  payload: { data, authtoken },
})

export const getRouteInfoFail = error => ({
  type: GET_ROUTES_INFO_FAIL,
  payload: error,
})