import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {  useRouteMatch } from "react-router"
import Select from "react-select"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { addNewInventory } from "../../store/Inventory/actions"
import { getProducts } from "../../store/Product/actions"
const AddOfficeProductInventory = () => {
  
  const {
    params: { OfficeInfoId },
  } = useRouteMatch("/addOfcProductInventory/:OfficeInfoId")
  const dispatch = useDispatch()
  const history = useHistory()

  const [selectedProduct, setSelectedProduct] = useState(null)
  const { authtoken, id, products, productLoading,orgid} = useSelector(state => ({
    products: state.ProductReducer.products,
    authtoken: state.Login.token,
    productLoading: state.ProductReducer.productLoading,
    id: state.Login.id,

  }))
  console.log('orgid',id);
  console.log('OfficeInfoId',OfficeInfoId);
  console.log();
  useEffect(() => {
    dispatch(getProducts(authtoken, id))
  }, [])
console.log('products',products);
  const handleChangeProduct = value => {
    setSelectedProduct(value._id)
  }

  async function handleSubmit(event, errors, values) {
    event.preventDefault()
    let obj = {}

    if (values.productQuantity) {
      obj.productQuantity = values.productQuantity
    }
    obj.productid = selectedProduct

    console.log(obj)
  
     dispatch(addNewInventory(obj,history,authtoken,OfficeInfoId))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC | Inventory</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Add Inventory" breadcrumbItem="Add Inventory" />

          <Row>
            <Col md={6} className="m-auto">
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Add Inventory</CardTitle>

                  <AvForm onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="example-offerTitle-input">
                            product Name
                          </Label>
                          {productLoading ? (
                            <Select
                              name="products"
                              cacheOptions
                              getOptionLabel={e => e.name}
                              getOptionValue={e => e._id}
                              options={products.data.product}
                              defaultOptions
                              onChange={handleChangeProduct}
                            />
                          ) : (
                            <span />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="example-offerTitle-input">
                            Add Quantity
                          </Label>
                          <AvField
                            name="productQuantity"
                            id="example-offerTitle-input"
                            className="form-control"
                            type="number"
                            placeholder="Type Inventory"
                            errorMessage="Enter  Offer Title"
                            validate={{ required: { value: true } }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Add Inventory
                      </button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default AddOfficeProductInventory
