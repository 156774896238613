import Breadcrumbs from "components/Common/Breadcrumb"
import { MDBDataTable } from "mdbreact"
import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import XLSX from "xlsx"
//redux
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch } from "react-router"
import { useHistory, withRouter } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
// import { getOrders } from '../../store/Order/actions';
// import { getRoute } from "../../store/GeoInformation/actions";
import { getRouteOrders } from "../../store/Order/actions"
import "../../assets/css/style.css"
import routeListDown from "../../assets/images/routeListDown.svg"

const moment = require("moment")

const RouteOrderDataTable = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const year = moment().format("MM-YYYY")
  console.log(year)
  const {
    params: { routeID,routeName },
  } = useRouteMatch("/Route-order/:routeID/:routeName")
  console.log(routeID,routeName)

  const { routeOrders, authtoken, routeloading, id } = useSelector(state => ({
    routeOrders: state.OrderReducer.routeOrders,
    id: state.Login.id,
    authtoken: state.Login.token,
    routeloading: state.OrderReducer.routeloading,
  }))

  useEffect(() => {
    dispatch(getRouteOrders(authtoken, id, routeID, year))
  }, [])
  // console.log(routeOrders.data.allorder);
  let allDateOrder,
    singleSubTotal,
    singleCollectAmount,
    orderTotal = 0,
    singleOrderTotal,
    totalCollect = 0,
    due
  if (!routeloading) {
    allDateOrder = routeOrders.data.allorder

    {
      (allDateOrder || []).map((dateOrder, key) => {
        singleSubTotal = Math.ceil(dateOrder?.orderamount)
        singleCollectAmount = Math.ceil(dateOrder?.receiveamount)
        singleOrderTotal = Math.ceil(dateOrder?.orderamount)
        orderTotal = orderTotal + singleSubTotal
        totalCollect = totalCollect + singleCollectAmount

        console.log(singleSubTotal)
        console.log(singleCollectAmount)
        if (singleSubTotal <= singleCollectAmount) {
          routeOrders.data.allorder[key].dueInfo = 0
        }
        if (singleSubTotal > singleCollectAmount) {
          routeOrders.data.allorder[key].dueInfo =
            singleSubTotal - singleCollectAmount
        }
      })
    }
  }

  const handleExcel = () => {
      console.log();
    let dateOrder = []
    allDateOrder?.forEach((order, index) => {
        console.log("ORDER", order)
        let data = {}
        data.routeName = routeName
        data.Date = order?._id?.datetostring
        data.orderAmount = order?.orderamount
        data.receiveAmount = order?.receiveamount
        // data.Due = order?.dueInfo
        data.totalDSR = order?.totaldp
        data.totalSO = order?.totalsr
        dateOrder.push(data)
      })
    downloadxls(dateOrder)
  }
  const downloadxls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "Date Order.xlsx")
  }

  console.log(routeOrders)
  console.log(orderTotal)
  console.log(totalCollect)
  due = orderTotal - totalCollect
  console.log(due)
  localStorage.setItem("dateTotalCollect", totalCollect)
  localStorage.setItem("dateTotalDue", due)

  const tableData =
    !routeloading &&
    routeOrders.data.allorder.map((route, index) => {
      return {
        sl: index + 1,
        heading1: route?._id?.datetostring,
        // heading2: route.shopnumber,
        heading2: Math.ceil(route?.orderamount),
        heading3: Math.ceil(route?.receiveamount),
        // heading4: Math.ceil(route.dueInfo),
        heading4: 0,
        heading5: route?.totalsr,
        // heading6: route?.totaldp,

        manage: (
          <>
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                history.push(`/date-order/${routeID}/${route._id.datetostring}`)
              }}
            >
              Shop List
            </Button>
          </>
        ),
        status: (
          <>
            <Badge className={"bg-success"}>Active</Badge>
          </>
        ),
      }
    })

  const orderData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },

      {
        label: "Date",
        field: "heading1",
        sort: "asc",
        width: 150,
      },

      {
        label: "Order Amount(tk)",
        field: "heading2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Received Amount(tk)",
        field: "heading3",
        sort: "asc",
        width: 150,
      },
      {
        label: "Due Amount(tk)",
        field: "heading4",
        sort: "asc",
        width: 150,
      },
      {
        label: "Assigned SO",
        field: "heading5",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Assigned DSR",
      //   field: "heading6",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "View ",
        field: "manage",
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <MetaTags>
          <title>Order | DDC</title>
        </MetaTags>
        <Container fluid>
          <h5 className="mb-4">Date List</h5>
          {/* <Breadcrumbs title="Order" breadcrumbItem="please select date for see orders" /> */}
          {!routeloading ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={orderData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="text-center mt-5 pt-5">
              <Spinner />
            </div>
          )}

        <Card>
        <div className="route-excel">
            <div>
              <p className="mb-0">Download Excelsheet</p>
            </div>
            <div>
              <button className="route-excel-download" onClick={handleExcel}>
                {" "}
                <img src={routeListDown}></img>Download
              </button>
            </div>
          </div>
        </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(RouteOrderDataTable)
// import { map } from "lodash";
// import React, { useEffect } from "react";
// import MetaTags from "react-meta-tags";
// //redux
// import { useDispatch, useSelector } from "react-redux";
// import { useHistory, useRouteMatch } from "react-router";
// import { withRouter } from "react-router-dom";
// import {
//     Button, Col,
//     Container,
//     Row, Spinner, Table
// } from "reactstrap";
// import { getRouteOrders } from '../../store/Order/actions';

// const RouteOrderDataTable = props => {

//     const history = useHistory()
//     const dispatch = useDispatch();
//     const {
//         params: { routeID },
//     } = useRouteMatch('/Route-order/:routeID');
//     console.log(routeID);

//     const { routeOrders, authtoken, routeloading, id } = useSelector(state => ({
//         routeOrders: state.OrderReducer.routeOrders,
//         id: state.Login.id,
//         authtoken: state.Login.token,
//         routeloading: state.OrderReducer.routeloading
//     }));

//     useEffect(() => {
//         dispatch(getRouteOrders(authtoken, id, routeID, 11 - 2021));
//     }, []);
//     // console.log(routeOrders.data.allorder);

//     return (
//         <React.Fragment>
//             {routeloading?(
//                         <div className="text-center mt-5 pt-5">
//                             <Spinner />
//                         </div>
//                     ):(
//             <div >
//                 <MetaTags>
//                     <title>
//                         Order List | DDC
//                     </title>
//                 </MetaTags>
//                 <Container fluid>
//                     {/* Render Breadcrumbs */}
//                     {/* <Breadcrumbs title="Role" breadcrumbItem="Role Details" /> */}
//                     <h5>Please Select date for see orders</h5>

//                         <Row >
//                             <Col lg="12">
//                                 <div className="">
//                                     <div className="table-responsive">
//                                         <Table className="project-list-table table-nowrap align-middle table-borderless">
//                                             <thead>
//                                                 <tr>

//                                                     <th scope="col">Date</th>
//                                                     <th scope="col">Receive Orders From</th>
//                                                     <th scope="col">Order Amount(tk)</th>
//                                                     <th scope="col">Receive Amount(tk)</th>
//                                                     <th scope="col">Total Assigned SR</th>
//                                                     <th scope="col">Total Assigned DP</th>
//                                                     <th scope="col">View Details</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {map(routeOrders.data.allorder, (routeOrder, index) => (
//                                                     <tr key={index}>

//                                                         <td>
//                                                             <p className="text-muted mb-0">{routeOrder._id.datetostring}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{routeOrder.shopnumber} shops</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{routeOrder.orderamount}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{routeOrder.receiveamount}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{routeOrder.totalsr}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-muted mb-0">{routeOrder.totaldp}</p>
//                                                         </td>

//                                                         {/* <td>
//                                                             <Link to="/date-order">
//                                                                 <Button
//                                                                     type="button"
//                                                                     color="primary"
//                                                                     className="btn-sm btn-rounded"

//                                                                 >
//                                                                     See Orders
//                                                                 </Button>
//                                                             </Link>
//                                                         </td> */}
//                                                         <td>

//                                                             <Button
//                                                                 type="button"
//                                                                 color="primary"
//                                                                 className="btn-sm btn-rounded"
//                                                                 onClick={() => {
//                                                                     history.push(`/date-order/${routeOrder._id.datetostring}`)
//                                                                 }}

//                                                             >
//                                                                 Order List
//                                                             </Button>

//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </Table>

//                                     </div>
//                                 </div>
//                             </Col>

//                         </Row>

//                 </Container>
//             </div>
//                     )}
//         </React.Fragment >
//     );
// };

// export default withRouter(RouteOrderDataTable);
