import Breadcrumbs from "components/Common/Breadcrumb"
import { isEmpty, map } from "lodash"
import { AvField } from "availity-reactstrap-validation"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter, useHistory } from "react-router-dom"
import { useRouteMatch } from "react-router"

import { MDBDataTable } from "mdbreact"
import { getUsers, updateUser } from "../../store/users/actions"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import CardComponent from "components/Common/CardComponent"
import { getUsersInfo, storeUserData } from "../../store/users/actions"
import AddFileUpload from 'components/Common/AddFileUpload';
import useExcelReader from '../../Hooks/useExcelReader';
import { post } from '../../helpers/api_helper';
import {
  Badge,
  Button,
  Col,
  Card,
  CardBody,
  Container,
  Row,
  Spinner,
  Table,
  ModalBody,
  Modal
} from "reactstrap"
const tableHead = [
  "No.",
  "Name",
  "Role",
  "Email",
  "User ID",
  "Supervisor ID",
  "Status",
  "Edit",
  "Set Route",
]
// import { getOrders } from '../../store/Order/actions';
// import { getRoute } from "../../store/GeoInformation/actions";

const TargetDataTable = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedFiles, setSelectedFiles] = useState({});
  const [value, setValue] = useState("")
  const { users, userInfo, authtoken, id, userInfoLoading, pageNumber } =
  useSelector(state => ({
    users: state.UserReducer,
    userInfo: state?.UserReducer?.userInfo,
    id: state.Login.id,
    pageNumber: state?.UserReducer?.userInfo?.pagenumber,
    authtoken: state.Login.token,
    userInfoLoading: state?.UserReducer?.userInfoLoading,
  }))
  console.log('userInfo', userInfo);
  useEffect(() => {
    dispatch(getUsersInfo(authtoken, id, currentPage, value, "SR"))
  }, [value, currentPage])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value !== "") {
        console.log("value", value)
        dispatch(getUsersInfo(authtoken, id, currentPage, value, "SR"))
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [value])
  const { items, readExcel } = useExcelReader();
  console.log(`🦄 ~ file: Dashboard.js ~ line 21 ~ Dashboard ~ items`, items);
  const [results, setResults] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [open, setOpen] = useState(false);
  const [adding, setAdding] = useState(false);
  const toggle = () => setOpen(!open);

  console.log(` ~ results`, results, isDone);
  const handleSubmit = async () => {
    setAdding(true);
    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      const data = {
        employeeId: element.employeeId,
        month: element.month,
        lpc: element.lpc,
        bcp: element.bcp,
        actualTarget: element.actualTarget,
        strikerate: element.strikerate
      };
    await post('/target/createbulktarget', data, { headers: { Authorization: `Bearer ${authtoken}` }}).then(response=>{
        setResults((prev) => [...prev, response]);
      }).catch((error)=>{
        console.log('error',error);
        setResults((prev) => [...prev, { status: 'failed' }]);
      }
      );
      if (i === items.length - 1) {
        setIsDone(true);
        setAdding(false);
        toaster('success', 'All data upload successful');
      }
    }
  };

  const tableHead1 = items?.[0] || {};
  return (
    <React.Fragment>
      <MetaTags>
        <title>User || Target</title>
      </MetaTags>
      <Container fluid className="page-content">
        <CardComponent>
          <div className="user-info-details">
         
            <div className="attendance-date">
              <AvForm>
                <AvField
                  name="startTime"
                  id="example-time-input"
                  className="form-control mb-3"
                  type="search"
                  placeholder="Search User ID"
                  onChange={e => setValue(e.target.value)}
                ></AvField>
              </AvForm>
            </div>
          </div>
          <Row>
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={userInfo?.users}
              tableHead={tableHead}
              setCurrentPage={setCurrentPage}
              page={pageNumber}
              currentPage={currentPage}
              isPagination
              productLoading={userInfoLoading}
            >
              {userInfoLoading ? (
                <tr style={{ width: "100%" }}>
                  <div
                    className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <Spinner />
                    </div>
                  </div>
                </tr>
              ) : userInfo?.users?.length ? (
                userInfo?.users?.map((user, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <th scope="row">{idx + 1}</th>
                      <td>{user.name}</td>
                      {user.role === "SR" ? (
                        <td>SO</td>
                      ) : user.role === "DP" ? (
                        <td>DSR</td>
                      ) : (
                        <td>{user.role}</td>
                      )}

                      <td>{user.email}</td>
                      <td>{user.employeeId}</td>
                      {/* <td>{store.phoneNumber}</td> */}
                      <td>{user?.linemanager?.employeeId}</td>
                      <td>
                        <Badge className={"bg-success"}>Active</Badge>
                      </td>
                      <td>
                        <Link
                          to="#"
                          className="text-success"
                          onClick={() => handleEditUser(user)}
                        >
                          <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                          />
                        </Link>
                      </td>
                      <td>
                        <Button
                          color="primary"
                          className="btn-sm btn-rounded"
                          onClick={() => {
                            history.push(`/sr-target-info/${user?._id}/${user?.name}`)
                          }}
                        >
                          Set Target
                        </Button>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <NoTableData
                  colSpan="9"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>Currently you have no Data. </span>
                </NoTableData>
              )}
            </CustomTable>

            {/* table end */}
          </Row>
        </CardComponent>
        <AddFileUpload
                setSelectedFiles={setSelectedFiles}
                acceptFile={
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                handleFile={(file) => {
                  readExcel(file);
                }}
              />
              <Modal isOpen={open} toggle={toggle} size="lg">
                <ModalBody>
                  <Table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>No.</th>
                        {Object.keys(tableHead1)?.map((item, idx) => (
                          <th key={idx}>{item}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {items?.map((item, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{item?.employeeId}</td>
                          <td>{item?.month}</td>
                          <td>{item?.actualTarget}</td>
                          <td>{item?.bcp}</td>
                          <td>{item?.lpc}</td>
                          <td>{item?.strikerate}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
              {items?.length > 0 ? (
                <div>
                  <button className="btn btn-primary me-2" onClick={toggle}>
                    Preview
                  </button>
                  <button
                    disabled={adding}
                    className="btn btn-primary "
                    onClick={handleSubmit}
                  >
                    {adding ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              ) : null}
      </Container>
   
    </React.Fragment>
  )
}

export default withRouter(TargetDataTable)
