export const queryMaker = (mainFilter, selectedSubFilter, chartFilter) => {
  let query = null
  // main Query maker condition
  if (Object?.keys(mainFilter)?.length !== 0) {
    if (mainFilter?.value !== "national") {
      if (!!selectedSubFilter?._id) {
        query = `main=${mainFilter?.value}&subfilter=${
          selectedSubFilter?._id || ""
        }`
      } else {
        query = `main=national`
      }
    } else {
      query = `main=national`
    }
  } else {
    query = `main=national`
  }

  // chart query add condition
  if (chartFilter !== "" && !!chartFilter) {
    query = `${query}&chart=${chartFilter}`
  }

  return query
}

export const makeQuery = (
  mainFilter,
  selectedRegion,
  selectedArea,
  selectedTerritory,
  currentSelection,
  chartFilter,
  chartFilter2,
  AivFilter
) => {
  let query = null
  if (Object.keys(mainFilter)?.length) {
    if (currentSelection === "main") {
      query = `main=national`
    }
    if (currentSelection === "region") {
      query = `main=region&subfilter=${selectedRegion?._id}`
    }
    if (currentSelection === "area") {
      query = `main=area&subfilter=${selectedArea?._id}`
    }
    if (currentSelection === "territory") {
      query = `main=territory&subfilter=${selectedTerritory?._id}`
    }
  } else {
    query = `main=national`
  }

  // chart query add condition
  if (chartFilter !== "" && !!chartFilter) {
    query = `${query}&chart=${chartFilter}`
  }

  if (chartFilter2 !== "" && !!chartFilter2) {
    query = `${query}&api=${chartFilter2}`
  }
  if (AivFilter !== "" && !!AivFilter) {
    query = `${query}&aiv=${AivFilter}`
  }
  return query
}

export const tableQuery = (
  mainFilter,
  selectedRegion,
  selectedArea,
  selectedTerritory,
  currentSelection,
  pagination
) => {
  let query = null
  if (Object.keys(mainFilter)?.length) {
    if (currentSelection === "main") {
      query = `main=national`
    }
    if (currentSelection === "region") {
      query = `main=region&subfilter=${selectedRegion?._id}`
    }
    if (currentSelection === "area") {
      query = `main=area&subfilter=${selectedArea?._id}`
    }
    if (currentSelection === "territory") {
      query = `main=territory&subfilter=${selectedTerritory?._id}`
    }
  } else {
    query = `main=national`
  }

  // chart query add condition
  if (pagination !== "" && !!pagination) {
    query = `${query}&currentpage=${pagination}`
  }
  return query
}
