
import Breadcrumbs from "components/Common/Breadcrumb";
import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";

import { MDBDataTable } from "mdbreact";
import "./datatables.scss"
import '../../assets/css/style.css'

import {
    Badge, Button, Col,
    Card,
    CardBody,
    Container,
    Row, Spinner, Table, CardTitle
} from "reactstrap";
import { getProducts } from '../../store/Product/actions';
import { getUnits } from "../../store/Unit/actions"

const TableUnit = props => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { units, authtoken, UnitLoading, id } = useSelector(state => ({
        units: state.UnitReducer.units.unit,
        id: state.Login.id,
        authtoken: state.Login.token,
        UnitLoading: state.UnitReducer.UnitLoading
    }));
    // console.log('tabledata', products.data);

    useEffect(() => {
        dispatch(getUnits(authtoken))
    }, []);
    

    const tableData = UnitLoading && units.map((unit, index) => {
        return {
            sl: index + 1,
            heading1: unit?.name,
           
        }
    })




    const productData = {
        columns: [
            {
                label: "No.",
                field: "sl",
                sort: "asc",
                width: 150,
            },
            {
                label: "name",
                field: "heading1",
                sort: "asc",
                width: 150,
            },
           


        ],
        rows: tableData,
    }






    return (
        <React.Fragment>
           <div className="page-content">
            <div >
                <MetaTags>
                    <title>Product | DDC</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Home" breadcrumbItem="Unit details" />
                    <Row>
              <Col xl="12">
                <Row>
                  {/* storeData Render */}
                  {!UnitLoading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
                  <Col md="3">
                    <Card className="mini-stats-wid card-info-list">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Total Unit</p>
                            <h4 className="mb-0">{units.length}</h4>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i
                                className={
                                  "bx " + "bx-copy-alt" + " font-size-24"
                                }
                              />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
               
                    )}

                  <Col md="3">
                    <Card className="card-info-list">
                      <CardBody>
                        <CardTitle className="mb-0 h4">Add Unit</CardTitle>
                        <Row>
                          <Col>
                            <div className="mt-3 mb-2">
                              <Link
                                to="/unit-add"
                                className="btn btn-primary btn-sm"
                              >
                                Add Unit
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
                    {UnitLoading ? (
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>

                                        <MDBDataTable
                                            responsive
                                            noBottomColumns
                                            striped
                                            data={productData}
                                        />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    )}
                </Container>
            </div>
            </div>
        </React.Fragment>
    );
};


export default withRouter(TableUnit);






