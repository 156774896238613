import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {  useRouteMatch } from "react-router";
import { getRouteShop } from '../../../store/GeoInformation/actions';
const RouteAmountAnalyticChart = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const {
      params: { routeID },
  } = useRouteMatch('/route-details/:routeID');
  console.log(routeID);
  const { shopRoute, authtoken, id,RouteShopLoading,stores } = useSelector(state => ({
  
    shopRoute: state.GeoInformationReducer.shopRoute,
    authtoken: state.Login.token,
    id: state.Login.id,
    RouteShopLoading: state.GeoInformationReducer.RouteShopLoading,
    loading: state.StoreReducer.loading,
  }))
  
 

  // const [modal, setModal] = useState(false);




  useEffect(() => {
      dispatch(getRouteShop(authtoken, routeID));
  }, []);
  let amountData,monthData,unitData
  if(!RouteShopLoading){
    console.log('jjjjj');
    amountData = shopRoute?.data?.amountdata
    monthData =  shopRoute?.data?.month
    unitData = shopRoute?.data?.unit
    
 
  }
  console.log(amountData);
  console.log(monthData);

console.log("hihhihi",shopRoute);
  const series = [
    {
      name: "Amount",
      data: amountData,
    },
    {
      name: "Unit",
      data: unitData,
    },
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#556ee6", "#34c38f"],
    xaxis: {
      type: "year",
      categories: monthData
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
    />
  )
}

export default RouteAmountAnalyticChart
