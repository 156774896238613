import {
  GET_DATE_ORDER, GET_DATE_ORDER_FAIL, GET_DATE_ORDER_SUCCESS, GET_INVOICE, GET_INVOICE_FAIL, GET_INVOICE_SUCCESS, GET_ORDER, GET_ORDERS, GET_ORDERS_FAIL, GET_ORDERS_SUCCESS, GET_ORDER_FAIL, GET_ORDER_SUCCESS,
  GET_ROUTE_ORDER, GET_ROUTE_ORDER_FAIL, GET_ROUTE_ORDER_SUCCESS,
  GET_EDIT_ORDER,GET_EDIT_ORDER_SUCCESS,GET_EDIT_ORDER_FAIL,
  GET_DIST_DATE_ORDER,GET_DIST_DATE_ORDER_SUCCESS,GET_DIST_DATE_ORDER_FAIL,
  GET_DIST_SHOP_ORDER,GET_DIST_SHOP_ORDER_SUCCESS,GET_DIST_SHOP_ORDER_FAIL,
  GET_ALL_ORDER,GET_ALL_ORDER_SUCCESS,GET_ALL_ORDER_FAIL,GET_DELIVERY_ORDER_EXCEL,
  GET_DELIVERY_ORDER_EXCEL_SUCCESS,GET_DELIVERY_ORDER_EXCEL_FAIL,
  GET_ALL_ORDER_EXCEL,GET_ALL_ORDER_EXCEL_SUCCESS,GET_ALL_ORDER_EXCEL_FAIL
} from './actionTypes'
export const getOrders = (authtoken, id) => ({
    type: GET_ORDERS,
    payload: { authtoken, id },
  })
  
  
  export const getOrdersSuccess = (data, authtoken) => ({
    type: GET_ORDERS_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getOrdersFail = error => ({
    type: GET_ORDERS_FAIL,
    payload: error,
  })
  export const getAllOrderExcelData = (authtoken, fromDate, toDate, pageNo, limit) => ({
    type: GET_ALL_ORDER_EXCEL,
    payload: { authtoken, fromDate, toDate, pageNo, limit },
  })
  
  
  export const getAllOrderExcelSuccess = (data, authtoken) => ({
    type: GET_ALL_ORDER_EXCEL_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getAllOrderExcelFail = error => ({
    type: GET_ALL_ORDER_EXCEL_FAIL,
    payload: error,
  })
  export const getOrder = id => ({
    type: GET_ORDER,
    payload: id,
  })
  
 
  
  export const getOrderSuccess = (data, authtoken) => ({
    type: GET_ORDER_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getOrderFail = error => ({
    type: GET_ORDER_FAIL,
    payload: error,
  })
  export const getEditOrders = (authtoken,id) => ({
    type: GET_EDIT_ORDER,
    payload:  { authtoken, id },
  })
  
  
  
  export const getEditOrderSuccess = (data, authtoken) => ({
    type: GET_EDIT_ORDER_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getEditOrderFail = error => ({
    type: GET_EDIT_ORDER_FAIL,
    payload: error,
  })
  export const getRouteOrders = (authtoken, orgid,route,month) => ({
    type: GET_ROUTE_ORDER,
    payload: { authtoken, orgid,route,month },
  })
  
  
  export const getRouteOrderSuccess = (data, authtoken) => ({
    type: GET_ROUTE_ORDER_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getRouteOrderFail = error => ({
    type: GET_ROUTE_ORDER_FAIL,
    payload: error,
  })
  export const getDistDateOrder = (authtoken, orgid,officeId,month) => ({
    type: GET_DIST_DATE_ORDER,
    payload: { authtoken, orgid,officeId,month },
  })
  
  
  export const getDistDateOrderSuccess = (data, authtoken) => ({
    type: GET_DIST_DATE_ORDER_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDistDateOrderFail = error => ({
    type: GET_DIST_DATE_ORDER_FAIL,
    payload: error,
  })
  export const getDateOrders = (authtoken,orgid,date ,routeID) => ({
    type: GET_DATE_ORDER,
    payload: { authtoken,orgid,date, routeID },
  })
  
  
  export const getDateOrderSuccess = (data, authtoken) => ({
    type: GET_DATE_ORDER_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDateOrderFail = error => ({
    type: GET_DATE_ORDER_FAIL,
    payload: error,
  })
  export const getDistShopOrders = (authtoken, orgid,officeId,month) => ({
    type: GET_DIST_SHOP_ORDER,
    payload: { authtoken, orgid,officeId,month },
  })
  
  
  export const getDistShopOrdersSuccess = (data, authtoken) => ({
    type: GET_DIST_SHOP_ORDER_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDistShopOrdersFail = error => ({
    type: GET_DIST_SHOP_ORDER_FAIL,
    payload: error,
  })

  
  export const getInvoice = (authtoken,id) => ({
    type: GET_INVOICE,
    payload: { authtoken,id },
  })
  
  
  export const getInvoiceSuccess = (data, authtoken) => ({
    type: GET_INVOICE_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getInvoiceFail = error => ({
    type: GET_INVOICE_FAIL,
    payload: error,
  })

  export const getallOrder = (authtoken, currentDate) => ({
    type: GET_ALL_ORDER,
    payload:  { authtoken, currentDate },
  })
  
  
  
  export const getallOrderSuccess = (data, authtoken) => ({
    type: GET_ALL_ORDER_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getallOrderFail = error => ({
    type: GET_ALL_ORDER_FAIL,
    payload: error,
  })

  export const getDeliveryOrderExcel = (authtoken, currentDate) => ({
    type: GET_DELIVERY_ORDER_EXCEL,
    payload:  { authtoken, currentDate },
  })
  
  
  
  export const getDeliveryOrderExcelSuccess = (data, authtoken) => ({
    type: GET_DELIVERY_ORDER_EXCEL_SUCCESS,
    payload: {data, authtoken},
  })
  
  export const getDeliveryOrderExcelFail = error => ({
    type: GET_DELIVERY_ORDER_EXCEL_FAIL,
    payload: error,
  })