import { toast } from "react-toastify"
import { call, put, takeEvery } from "redux-saga/effects"
import { addBrandsdata, getBrandsdata,getBrandsdataStatus } from "../../helpers/backend_helper.js"
import { addBrandFail, addBrandSuccess, getBrandsFail, getBrandsSuccess ,getBrandsStatusSuccess,getBrandsStatusFail} from "./actions"
import {
  ADD_NEW_BRAND, GET_BRANDS,GET_BRANDS_STATUS
} from "./actionTypes"

function* onAddBrand({ payload: { data, history,authtoken } }) {
    try {
      console.log('Saga',data);
      const response = yield call(addBrandsdata, data, authtoken)

     
      console.log(response);

      if (response.status === 'success') {
        console.log('test on 18', );
        yield put(addBrandSuccess(response.brand))
        toast.success('success')
        history.push('/brand')
 
      }
     
     
     
    } catch (error) {
      if (!error.response) {
        history.push('/brand')
      console.log('line 20');
      } else {
        let message = error.response.data.message
       console.log('line 24');
        // console.log("error message,", error.response.data.message)
        yield put(addBrandFail(message))
        toast.error(message)
      }
    }
  }


function* fetchBrand({ payload: { authtoken, orgid } }) {
  try {
    console.log('fetched brand');
    console.log(authtoken);
    console.log(orgid);
    const response = yield call(getBrandsdata, authtoken,orgid)
    console.log(response.brand);
    yield put(getBrandsSuccess(response.brand))
  } catch (error) {
    yield put(getBrandsFail(error))
  }
}

function* fetchBrandStatus({ payload: { authtoken, status } }) {
  try {
    console.log('fetched brand');
    console.log(authtoken);
    console.log(status);
    const response = yield call(getBrandsdataStatus, authtoken,status)
    console.log(response);
    yield put(getBrandsStatusSuccess(response))
  } catch (error) {
    yield put(getBrandsStatusFail(error))
  }
}

  function* BrandSaga() {
    yield takeEvery(ADD_NEW_BRAND, onAddBrand)
    yield takeEvery(GET_BRANDS, fetchBrand)
    yield takeEvery(GET_BRANDS_STATUS, fetchBrandStatus)
   
  }
  
  export default BrandSaga