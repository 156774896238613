import { AvField } from "availity-reactstrap-validation"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import NoTableData from "components/Common/NoTableData"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Badge, Button, Container, Row, Spinner, Input } from "reactstrap"
import moment from "moment"
import { Modal } from "react-bootstrap"
import { getSurveyShop, getSingleShopQues } from "../../store/SurveyInfo/action"
const tableHead = [
  "No.",
  "Shop Name",
  "Date",
  "Time",
  "SR Name",
  "Shop Type",
  "Action",
]
const SurveyShop = props => {
  const { id } = useParams()
  const { history } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [pageRange, setPageRange] = useState(10)
  const [value,setValue] = useState("")
  const dispatch = useDispatch()
  const [detailShow, setDetailShow] = useState(false)
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const { authtoken, surveyShop, surveyShopLoading, singleShopQues, singleShopQuesLoading } =
    useSelector(state => ({
      authtoken: state.Login.token,
      surveyShop: state?.SurveyListReducer?.surveyShop,
      singleShopQues: state?.SurveyListReducer?.singleShopQues,
      surveyShopLoading: state?.SurveyListReducer?.surveyShopLoading,
      singleShopQuesLoading: state?.SurveyListReducer?.singleShopQuesLoading,
    }))
  console.log("surveyShop", surveyShop)
  let totalPageNumber = Math.ceil(surveyShop?.totalShop / pageRange)
  const handleRange = e => {
    setPageRange(e.target.value)
  }
  useEffect(() => {
    dispatch(getSurveyShop(authtoken, id, pageRange, currentPage, startDate, endDate, value ))
  }, [pageRange, currentPage, startDate, endDate, value])
  const handleShop = data => {
    console.log("jh", data)
    let singleSurvey = data?._id
    dispatch(getSingleShopQues(authtoken, singleSurvey))
  }
  const handleStartDate = (e) => {
    console.log("start", e.target.value);
    const value = e.target.value;
    setStartDate(moment(value).format("YYYY-MM-DD"));
  };
  const handleEndDate = (e) => {
    console.log("end", e.target.value);
    const value = e.target.value;
    setEndDate(moment(value).format("YYYY-MM-DD"));
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>DDC</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <CardComponent>
            <div className="user-info-details">
              <div className="attendance-date">
                <AvForm>
                  <AvField
                    name="startTime"
                    id="example-time-input"
                    className="form-control mb-3"
                    type="date"
                    value={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={handleStartDate}
                    errorMessage="Enter Start Time"
                    validate={{ required: { value: true } }}
                  ></AvField>
                </AvForm>
              </div>
              <h6 className="mt-2">To Date</h6>

              <div className="attendance-date">
                <AvForm>
                  <AvField
                    name="startTime"
                    id="example-time-input"
                    className="form-control mb-3"
                    type="date"
                    value={moment(new Date()).format("YYYY-MM-DD")}
                     onChange={handleEndDate}
                    errorMessage="Enter end Time"
                    validate={{ required: { value: true } }}
                  ></AvField>
                </AvForm>
              </div>
              <div className="attendance-date">
                <AvForm>
                  <AvField
                    name="startTime"
                    id="example-time-input"
                    className="form-control mb-3"
                    type="search"
                    placeholder="Search.."
                    onChange={e => setValue(e.target.value)}
                  ></AvField>
                </AvForm>
              </div>
              <div style={{width:"10%"}}>
                <Input type="select" onChange={e => handleRange(e)}>
                  {/* <option defaultValue>Select...</option> */}
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Input>
              </div>
            </div>
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                //   data={userInfo?.users}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                page={totalPageNumber}
                currentPage={currentPage}
                isPagination
                productLoading={surveyShopLoading}
              >
                {surveyShopLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner />
                      </div>
                    </div>
                  </tr>
                ) : surveyShop?.surveyShop?.length ? (
                  surveyShop?.surveyShop?.map((survey, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <th scope="row">{idx + 1}</th>
                        <td>{survey?.shopname}</td>
                        <td>{survey?.date}</td>
                        <td>{survey?.collectionStartTime}</td>
                        {/* <td>{store.phoneNumber}</td> */}
                        <td>{survey?.user?.name}</td>
                        <td>{survey?.shopType}</td>
                        <td>
                          <Button
                            color="primary"
                            className="btn-sm btn-rounded ms-2"
                            onClick={() => {
                              setDetailShow(true)
                              handleShop(survey)
                            }}
                          >
                            View question
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <NoTableData
                    colSpan="9"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>Currently you have no Data. </span>
                  </NoTableData>
                )}
              </CustomTable>

              {/* table end */}
            </Row>
          </CardComponent>
     
          <Modal
            show={detailShow}
            onHide={() => setDetailShow(false)}
            centered
            className="question-view"
            size="lg"
          >
            <Modal.Header
              closeButton
              style={{ borderBottom: "none" }}
            ></Modal.Header>
            <Modal.Body className="">
              
              <div className="question-header">
                <h5>Question List</h5>
                <span>{singleShopQues?.Survey?.shopId?.name}</span>
              </div>
              <div className="question-content">
                {(singleShopQues?.Survey?.questions || []).map((data, key) => {
                  return (
                    <>
                      {data?.questionType === "Single" ? (
                        <ul>
                          <h6 className="mb-3">
                            {key + 1}. {data?.question}
                          </h6>
                          {(data?.options || []).map((option, key) => {
                            return (
                              <>
                           
                                <li className="mb-2">
                                <input
                                  className="form-check-input ms-2"
                                  type="radio"
                                  checked = { data?.givenanswer?.includes(option?.name)}
                                />
                                <label className="form-check-label ms-2">
                                  {option?.name}
                                </label>
                              </li>
                          
                              </>
                            )
                          })}
                        </ul>
                      ) : data?.questionType === "multiple" ? (
                        <ul>
                          <h6 className="mb-3">
                            {key + 1}. {data?.question}
                          </h6>
                          {(data?.options || []).map((option, key) => {
                            return (
                              <>
                           
                                <li className="mb-2">
                                  <input
                                    className="form-check-input ms-2"
                                    type="checkbox"
                                    checked = {data?.givenanswer?.includes(option?.name)}
                                  />
                                  <label className="form-check-label ms-2">
                                  {option?.name}
                                  </label>
                                </li>
                           
                              </>
                            )
                          })}
                        </ul>
                      ) : (
                        <ul>
                          <h6 className="mb-3">
                            {key + 1}. {data?.question}
                          </h6>
                          <li className="content">{data?.givenanswer}</li>
                        </ul>
                      )}
                    </>
                  )
                })}
              </div>
            </Modal.Body>
          </Modal>
        
        </Container>
      </div>
    </React.Fragment>
  )
}

SurveyShop.propTypes = {
  history: PropTypes.object,
}
export default withRouter(SurveyShop)
