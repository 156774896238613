import React, { Component, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import classNames from "classnames"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardTitle,
    CardBody,
} from "reactstrap"


import { Link } from "react-router-dom"
import RouteAmountAnalyticChart from "./AnalyticChartAmount";

const RouteAmountAnalytic = () => {
    const [periodType, setPeriodType] = useState("yearly")
    const [AreaType, setAreatype] = useState("Area")
    const [regionType, setRegiontype] = useState("Region")
    const [territoryType, setTerritoryType] = useState("Territory")


    return (
        <React.Fragment>
            <Container fluid>
            <Card>
                <CardBody>

                    <div className="d-sm-flex flex-wrap">
                        <h4 className="card-title">Total Amount and Unit (Month Wise)</h4>
                        <div className="ms-auto">
                            {/* <ul className="nav nav-pills">
                                <li className="nav-item me-2 ">
                                    <Link
                                        to="#"
                                        className={classNames(
                                            { active: AreaType === "Area" },
                                            "nav-link", "bg-dark"
                                        )}
                                        onClick={() => {
                                            onChangeChartPeriod("Area")
                                        }}
                                        id="one_month"
                                    >
                                        Area
                                    </Link>{" "}
                                </li>
                                <li className="nav-item me-2 ">
                                    <Link
                                        to="#"
                                        className={classNames(
                                            { active: regionType === "Region" },
                                            "nav-link", "bg-dark"
                                        )}
                                        onClick={() => {
                                            onChangeChartPeriod("Region")
                                        }}
                                        id="one_month"
                                    >
                                        Region
                                    </Link>{" "}
                                </li>
                                <li className="nav-item me-2 ">
                                    <Link
                                        to="#"
                                        className={classNames(
                                            { active: territoryType === "Territory" },
                                            "nav-link", "bg-dark"
                                        )}
                                        onClick={() => {
                                            onChangeChartPeriod("Territory")
                                        }}
                                        id="one_month"
                                    >
                                        Territory
                                    </Link>{" "}
                                </li>

                                <li className="nav-item">
                                    <Link
                                        to="#"
                                        className={classNames(
                                            { active: periodType === "yearly" },
                                            "nav-link", "bg-dark"
                                        )}
                                        onClick={() => {
                                            onChangeChartPeriod("yearly")
                                        }}
                                        id="one_month"
                                    >
                                        Year
                                    </Link>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <RouteAmountAnalyticChart />
                </CardBody>
            </Card>

            </Container>
        </React.Fragment>
    )
}

export default RouteAmountAnalytic
