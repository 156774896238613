import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select"
import {
    Card, CardBody,
    CardTitle, Col,
    Container, FormGroup,
    Label, Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { addNewTerritory ,getArea} from "../../store/GeoInformation/actions";

const AddTerritory = () => {
    const [selectedArea, setSelectedArea] = useState(null)
    const history = useHistory()
    const dispatch = useDispatch()
    const { authtoken,id,area, arealoading} = useSelector(state => ({
        area: state.GeoInformationReducer.area.area,
        authtoken: state.Login.token,
        id: state.Login.id,
        arealoading: state.GeoInformationReducer.arealoading,
      }))

      useEffect(() => {
        dispatch(getArea(authtoken))
       
      }, [])
      const handleChangearea = value => {
        setSelectedArea(value._id)
      }

    function handleSubmit (event, errors, values) {
        event.preventDefault();
        let obj = {}
        obj.area = selectedArea
        obj.name = values.name;
        obj.org = id
        console.log(obj);
        dispatch(addNewTerritory(obj, history, authtoken))
    }

    return (
        <React.Fragment>
        <div className="page-content">
            <MetaTags>
                <title>DDC | Add Territory</title>
            </MetaTags>
            <Container fluid={true} >
                <Breadcrumbs title="Add Territory" breadcrumbItem="Add Territory" />
                <Row>
                    <Col md={6} className='m-auto'>
                        <Card >
                            <CardBody >
                                <CardTitle className="h4 mb-4">Add Territory</CardTitle>
                                    <AvForm onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="formrow-yourRegion-Input">Territory Name</Label>
                                                    <AvField
                                                    type="text"
                                                    className="form-control"
                                                    id="formrow-yourRegion-Input"
                                                    placeholder='Type Territory Name'
                                                    name="name"
                                                    />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                   
                                    <Row>
                      {arealoading ? (
                        <Col md={12}>
                          <div className="mb-3">
                            <label>Area</label>
                            <Select
                              cacheOptions
                              getOptionLabel={e => e.name}
                              getOptionValue={e => e._id}
                              options={area}
                              defaultOptions
                              onChange={handleChangearea}
                            />
                          </div>
                        </Col>
                      ) : (
                        <p>hello</p>
                      )}
                    </Row>
                                    <div>
                                        <button type="submit" className="btn btn-primary w-md">
                                            Add Territory
                                        </button>
                                    </div>
                                    </AvForm>
                            </CardBody>
                        </Card>
                    </Col>


                </Row>
                {/* end row  */}



            </Container>
            {/* container-fluid */}
        </div>
    </React.Fragment >
    );
};

export default AddTerritory;