import PropTypes from "prop-types"
import React from "react"
import { FormFeedback, FormGroup, Input, Label } from "reactstrap"

const CustomInput2 = ({
  field,
  form: { touched, errors },
  label,
  id,
  ...props
}) => {
  return (
    <React.Fragment>
      <FormGroup className={""}>
        {label ? <Label htmlFor={id}>{label}</Label> : null}
        <Input
          type="text"
          id={id}
          invalid={touched[field.name] && errors[field.name] ? true : false}
          {...field}
          {...props}
        />
        {touched[field.name] && errors[field.name] ? (
          <FormFeedback type="invalid">{errors[field.name]}</FormFeedback>
        ) : null}
      </FormGroup>
    </React.Fragment>
  )
}

CustomInput2.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  validationType: PropTypes.object,
  field: PropTypes.object,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  id: PropTypes.string,
  form: PropTypes.object,
}

export default CustomInput2
