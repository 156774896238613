import SurveyActionTypes from "./actionTypes"

export const loadSurveyStart = () => ({
  type: SurveyActionTypes.LOAD_SURVEY_START,
})

export const loadSurveySuccess = Surveys => ({
  type: SurveyActionTypes.LOAD_SURVEY_SUCCESS,
  payload: Surveys,
})

export const loadSurveyFail = errorMsg => ({
  type: SurveyActionTypes.LOAD_SURVEY_FAIL,
  payload: errorMsg,
})

export const deleteSurveyStart = SurveyDetail => ({
  type: SurveyActionTypes.DELETE_SURVEY_START,
  payload: SurveyDetail,
})

export const deleteSurveySuccess = Survey => ({
  type: SurveyActionTypes.DELETE_SURVEY_SUCCESS,
  payload: Survey,
})

export const deleteSurveyFail = errorMsg => ({
  type: SurveyActionTypes.DELETE_SURVEY_FAIL,
  payload: errorMsg,
})

export const getSurveyData = (id, token) => ({
  type: SurveyActionTypes.GET_SURVEY_DATA,
  payload: { id, token },
})

export const getSurveyDataSuccess = data => ({
  type: SurveyActionTypes.GET_SURVEY_DATA_SUCCESS,
  payload: data,
})

export const getSurveyDataFail = errorMsg => ({
  type: SurveyActionTypes.GET_SURVEY_DATA_FAIL,
  payload: errorMsg,
})

export const getSurveyQuestion = (id, token) => ({
  type: SurveyActionTypes.GET_SURVEY_QUESTION,
  payload: { id, token },
})

export const getSurveyQuestionSuccess = data => ({
  type: SurveyActionTypes.GET_SURVEY_QUESTION_SUCCESS,
  payload: data,
})

export const getSurveyQuestionFail = errorMsg => ({
  type: SurveyActionTypes.GET_SURVEY_QUESTION_FAIL,
  payload: errorMsg,
})

export const createSurveyQuestion = (data, options) => ({
  type: SurveyActionTypes.CREATE_SURVEY_QUESTION,
  payload: { data, options },
})

export const createSurveyQuestionSuccess = data => ({
  type: SurveyActionTypes.CREATE_SURVEY_QUESTION_SUCCESS,
  payload: { data },
})

export const createSurveyQuestionFail = message => ({
  type: SurveyActionTypes.CREATE_SURVEY_QUESTION_FAIL,
  payload: { message },
})
