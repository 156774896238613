import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"
import "../../pages/styles.css"
import { getTopDP, getTopSR } from "../../store/OrgDashboard/actions"
import { getUsers } from "../../store/users/actions"
import TableUserInfo from "./TableUserInfo"
import UserTableData from "./UserTableData"

const UserDetails = () => {
  const dispatch = useDispatch()
  const { users, authtoken, id, topSRs, topDPs, topDpLoading, topSrLoading } =
    useSelector(state => ({
      users: state.UserReducer,
      topSRs: state.DashboardReducer.topSRs.data,
      topDPs: state.DashboardReducer.topDPs,
      id: state.Login.id,
      authtoken: state.Login.token,
      topDpLoading: state.DashboardReducer.topDpLoading,
      topSrLoading: state.DashboardReducer.topSrLoading,
    }))
  useEffect(() => {
    dispatch(getUsers(authtoken, id))
  }, [])
  // useEffect(() => {
  //   dispatch(getTopSR(authtoken))
  // }, [])
  // useEffect(() => {
  //   dispatch(getTopDP(authtoken))
  // }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>DDC</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Row>
                {/* storeData Render */}

                <Col md="3">
                  <Card className="mini-stats-wid card-info-list">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Users</p>
                          <h4 className="mb-0">{users?.users?.length}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col md="3">
                  <Card className="mini-stats-wid card-info-list">
                    <CardBody>
                   
                      <div className="d-flex">
                      {topSrLoading ? (
                        <div className="text-center  pt-5">
                            <Spinner />
                        </div>
                    ) : (
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Top SO
                          </p>
                          <h5 className="mb-1">{topSRs?.test[0]?.name}</h5>

                        </div>
                    )}
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />

                          </span>
                        </div>
                      </div>
                   
                    </CardBody>
                  </Card>
                </Col> */}
                {/* <Col md="3">
                  <Card className="mini-stats-wid card-info-list">
                    <CardBody>
                      <div className="d-flex">
                        {topDpLoading ? (
                          <div className="text-center pt-5">--</div>
                        ) : (
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Top DSR</p>
                            <h5 className="mb-1">
                              {topDPs?.data?.data[0]?.routename}
                            </h5>
                          </div>
                        )}
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className={
                                "bx " + "bx-copy-alt" + " font-size-24"
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}

                <Col md="3">
                  <Card className="card-info-list">
                    <CardBody>
                      <CardTitle className="mb-0 h4">Add User</CardTitle>
                      <Row>
                        <Col>
                          <div className="mt-2 ">
                            <Link
                              to="/adduser"
                              className="btn btn-primary btn-md"
                            >
                              Add User
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {/* <UserTableData/> */}
        <TableUserInfo/>
      </div>
    </React.Fragment>
  )
}

export default UserDetails
