import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner
} from "reactstrap"

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import TableOffer from "./TableOffer";
import { getOffers } from "../../store/Offer/actions.js";




const DetailsOffer = () => {
  const dispatch = useDispatch();
 

  const { offers, authtoken, loading } = useSelector(state => ({
      offers: state.OfferReducer.offers.data,
      authtoken: state.Login.token,
      loading: state.OfferReducer.loading
  }));
  useEffect(() => {
    dispatch(getOffers(authtoken));
}, []);


 
    return (
        <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>DDC</title>
          </MetaTags>
          <Container fluid>
            <h5 className='mb-3'>Offer List</h5>
            {loading ? (
                        <div className="text-center  pt-5">
                            <Spinner />
                        </div>
                    ) : (
               
            <Row>

              <Col xl="12">
                <Row>

                  {/* storeData Render */}
                 
                    <Col md="3">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Total Offers
                              </p>
                              <h4 className="mb-0">{offers.length}</h4>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Top Selling Offer
                              </p>
                              <h4 className="mb-0">--</h4>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    
                  
              <Col md="3">
              <Card >
                  <CardBody >
                    <CardTitle className="mb-0 h4">Create New Offer</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/create-offer"
                            className="btn btn-primary btn-md"
                          >
                            Create Offer
                          </Link>
                        </div>
                      </Col>
                     
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
                 
                </Row>
               

              </Col>
            </Row>
                    )}
         <TableOffer/>
          </Container>
        </div>


      </React.Fragment >
    );
};

export default DetailsOffer;