import PropTypes from "prop-types"
import React from "react"

function TextError(props) {
  return (
    <React.Fragment>
      <div
        className="mt-1"
        style={{ color: "#f46a6a", fontSize: "11px", fontWeight: "normal" }}
      >
        {props.children}
      </div>
    </React.Fragment>
  )
}

TextError.propTypes = {
  children: PropTypes.any,
}

export default TextError
