import { toast } from "react-toastify"
import { call, delay, put, takeEvery } from "redux-saga/effects"
import {
  getAreadata, getGeodata, getRegiondata, getRoutedata, getTerritorydata, postAreadata, postGeodata,
  postRegiondata, postRoutedata, postTerritorydata, getDetailsRoutedata,getRouteShop,getGeoRoute,getAllRouteInfo
} from "../../helpers/backend_helper.js"
import {
  addAreaFail, addAreaSuccess, addGeoFail, addGeoSuccess, addRegionFail, addRegionSuccess,
   addRouteFail, addRouteSuccess, addTerritoryFail, addTerritorySuccess, getAreaFail, 
   getAreaSuccess, getGeoFail, getGeoSuccess, getRegionFail, getRegionSuccess, getRouteFail,
    getRouteSuccess, getTerritroyFail, getTerritroySuccess,getDetailsRouteSuccess,getDetailsRouteFail,
    getRouteShopSuccess,getRouteShopFail,getGeoRouteSuccess,getGeoRouteFail,getRouteInfoSuccess,getRouteInfoFail
} from "./actions"
import {
  ADD_NEW_AREA, ADD_NEW_GEO,
  ADD_NEW_REGION, ADD_NEW_ROUTE, ADD_NEW_TERRITORY, GET_AREAS, GET_GEOS,
  GET_REGIONS, GET_ROUTES, GET_TERRITORYS,GET_DETAILS_ROUTES,GET_ROUTE_STORE,GET_GEO_ROUTES,
  GET_ROUTES_INFO
} from "./actionTypes"


  function* onAddArea({ payload: {data, history , authtoken} }) {
   
    try {
      const response = yield call(postAreadata, data, authtoken)
      console.log('hi');
      console.log(response);
      yield put(addAreaSuccess(response))
      toast('🦄 Area added successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/area-list")
     
       
    } catch (error) {
      
      if (!error.response) {
        toast('🦄 Area added successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push("/area-list")
      } else {
        let message = error.response.data.message
        yield put(addAreaFail(message))
        // console.log("hi",message);
        toast.error(message)
      }
    }
  }

function* onAddTeritory({ payload: {data, history , authtoken} }) {
   
  try {
    const response = yield call(postTerritorydata, data, authtoken)
    console.log('hi');
    console.log(response);
    yield put(addTerritorySuccess(response))
    toast('🦄 Territory added successfully!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.push("/territory-list")
   
     
  } catch (error) {
    
    if (!error.response) {
      toast('🦄 Territory added successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/territory-list")
    } else {
      let message = error.response.data.message
      yield put(addTerritoryFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}


function* onAddRegion({ payload: {data, history , authtoken} }) {
   
  try {
    const response = yield call(postRegiondata, data, authtoken)
    console.log('hi');
    console.log(response);
    yield put(addRegionSuccess(response))
    toast('🦄 Region added successfully!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.push("/region-list")
   
     
  } catch (error) {
    
    if (!error.response) {
      toast('🦄 Region added successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/region-list")
    } else {
      let message = error.response.data.message
      yield put(addRegionFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}


function* onAddGeo({ payload: { data, history, authtoken } }) {

  try {
    console.log('hello i am geo');
    const response = yield call(postGeodata, data, authtoken)
    console.log(response);
    yield put(addGeoSuccess(response.geo))
    toast.success('success')
    history.push("/details-geo")
  } catch (error) {
    console.log(error);
    if (!error.response) {
     
      toast.success('success')
      history.push("/details-geo")
    } else {
      let message = error.response.data.message

      yield put(addGeoFail(message))
      toast.error(message)
    }
  }
}

function* onAddRoute({ payload: { data, history, authtoken } }) {
  
  try {
    console.log('hello');
    const response = yield call(postRoutedata, data, authtoken)
    console.log(response);
    yield put(addRouteSuccess(response))
    toast('🦄 Route Added Successfully!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
     history.push("/route-list")
  } catch (error) {

    console.log(error);
    if (!error.response) {
      toast.success('success')
      history.push("/route-list")
    } else {
      let message = error.response.data.message

      yield put(addRouteFail(message))
      toast.error(message)
    }
  }
}

function* fetchRoute({ payload: { authtoken,id } }) {
  try {
    delay(1000)
    console.log('fetched area');
    const response = yield call(getRoutedata, authtoken,id)
    console.log(response);
    console.log('hello');
    yield put(getRouteSuccess(response))
  } catch (error) {
    console.log('hi');
    yield put(getRouteFail(error))
  }
}

function* fetchArea({ payload: {  authtoken } }){
  try {
    console.log('fetched area');
    const response = yield call(getAreadata, authtoken)
    console.log(response);
    yield put(getAreaSuccess(response))
  } catch (error) {
    yield put(getAreaFail(error))
  }
}

function* fetchRegion({ payload: { authtoken } }) {
  try {
    console.log('fetched area');
    const response = yield call(getRegiondata, authtoken)
    console.log(response);
    yield put(getRegionSuccess(response))
  } catch (error) {
    yield put(getRegionFail(error))
  }
}

function* fetchTerritory({ payload: { authtoken } }) {
  try {
    console.log('fetched area');
    const response = yield call(getTerritorydata, authtoken)
    console.log(response);
    yield put(getTerritroySuccess(response))
  } catch (error) {
    yield put(getTerritroyFail(error))
  }
}

function* fetchGeo({ payload: { authtoken } }) {
  try {
    const response = yield call(getGeodata, authtoken)
    console.log(response);
    yield put(getGeoSuccess(response))
  } catch (error) {
    yield put(getGeoFail(error))
  }
}
function* fetchRouteDetails({ payload: { authtoken, routeId } }) {
  try {
    console.log(routeId);
    console.log('197');
    const response = yield call(getDetailsRoutedata, authtoken,routeId)
    console.log(response);
    yield put(getDetailsRouteSuccess(response))
  } catch (error) {
    console.log('202');
    yield put(getRouteShopFail(error))
  }
}
function* fetchRouteShop({ payload: { authtoken, routeId } }) {
  try {
    console.log(routeId);
    console.log('197');
    const response = yield call(getRouteShop, authtoken,routeId)
    console.log(response);
    yield put(getRouteShopSuccess(response))
  } catch (error) {
    console.log('202');
    yield put(getDetailsRouteFail(error))
  }
}
function* fetchGeoRoute({ payload: { authtoken,id } }) {
  try {
    delay(1000)
    console.log('fetched area');
    const response = yield call(getGeoRoute, authtoken,id)
    console.log(response);
    console.log('hello');
    yield put(getGeoRouteSuccess(response))
  } catch (error) {
    console.log('hi');
    yield put(getGeoRouteFail(error))
  }
}
function* fetchAllRouteInfoData({ payload: { authtoken, orgId, currentPage, routeName } }) {

  try {
    delay(500)
    console.log('routeName',routeName);
    const response = yield call(getAllRouteInfo, authtoken, orgId, currentPage, routeName)
    console.log('HELLO',response);
    if (response.status === 'success'){
      console.log('success');
      yield put(getRouteInfoSuccess(response))
    }
    // yield put(getStoresSuccess(response.shop))
  } catch (error) {
    yield put(getRouteInfoFail(error))
  }
}

  function* GeoInformationSaga() {
    yield takeEvery(ADD_NEW_AREA, onAddArea)
    yield takeEvery(ADD_NEW_TERRITORY, onAddTeritory)
    yield takeEvery(ADD_NEW_REGION, onAddRegion)
    yield takeEvery(ADD_NEW_GEO, onAddGeo)
    yield takeEvery(GET_AREAS, fetchArea)
    yield takeEvery(GET_TERRITORYS, fetchTerritory)
    yield takeEvery(GET_REGIONS, fetchRegion)
    yield takeEvery(GET_GEOS, fetchGeo)
    yield takeEvery(GET_ROUTES, fetchRoute)
    yield takeEvery(ADD_NEW_ROUTE, onAddRoute)
    yield takeEvery(GET_DETAILS_ROUTES, fetchRouteDetails),
    yield takeEvery(GET_ROUTE_STORE, fetchRouteShop)
    yield takeEvery(GET_ROUTES_INFO, fetchAllRouteInfoData)
    yield takeEvery(GET_GEO_ROUTES, fetchGeoRoute)
  }
  
  export default GeoInformationSaga