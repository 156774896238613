import React, { useEffect } from "react"
import { makeQuery } from "helpers/Custom/makeQuery"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Row, Spinner } from "reactstrap"
import arrowUp from "../../assets/images/arrow-up.svg"
import arrowDown from "../../assets/images/Shape.svg"
import userImage from "../../assets/images/userImage2.svg"
import { getTopSR, getTotalSR } from "../../store/OrgDashboard/actions"
import "./Dashboard.css"

function TopSR(props) {
  const dispatch = useDispatch()

  const { authtoken, totalSR, totalSrLoading, id, topSRs, topSrLoading,
      mainFilter,
    isSubFilterChanged,
    selectedRegion,
    selectedArea,
    selectedTerritory,
    currentSelection,} =
    useSelector(state => ({
      totalSR: state.DashboardReducer.totalSR.data,
      topSRs: state.DashboardReducer.topSRs.data,
      id: state.Login.id,
      authtoken: state.Login.token,
      totalSrLoading: state.DashboardReducer.totalSrLoading,
      topSrLoading: state.DashboardReducer.topSrLoading,
      isSubFilterChanged: state.DashboardReducer.isSubFilterChanged,
      mainFilter: state.DashboardReducer.mainFilter,
      currentSelection: state.DashboardReducer.currentSelection,
      selectedRegion: state.DashboardReducer.selectedRegion,
      selectedArea: state.DashboardReducer.selectedArea,
      selectedTerritory: state.DashboardReducer.selectedTerritory,
    }))
    useEffect(() => {
      const query = makeQuery(
        mainFilter,
        selectedRegion,
        selectedArea,
        selectedTerritory,
        currentSelection
      )
      dispatch(getTotalSR(authtoken, query))
    }, [isSubFilterChanged])
  
  useEffect(() => {
    const query = makeQuery(
      mainFilter,
      selectedRegion,
      selectedArea,
      selectedTerritory,
      currentSelection
    )
    dispatch(getTopSR(authtoken, query))
  }, [isSubFilterChanged])



  // console.log(topSRs)
  const bgClr = ["success", "primary", "warning"]

  return (
    <React.Fragment>
      {totalSrLoading ? (
        <div className="text-center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <Card className="top-sr">
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="ms-2">
                <h5 className="card-title mb-3">
                  Top 3 SO’s Performance vs Target
                </h5>
              </div>
            </div>
            <Row className="text-center">
              <Col xs={3}>
                <div className="mt-3">
                  <p className="text-muted mb-1">
                    Today <br /> Visit
                  </p>
                  <p className="fw-bold mt-2">
                    {" "}
                    {/* {Math.ceil(totalSR?.thismonthval[0]?.total)} Tk */}
                    {totalSR?.totalvisitednumber}
                    
                  </p>
                </div>
              </Col>

              <Col xs={3}>
                <div className="mt-3">
                  <p className="text-muted mb-1">
                    Last <br /> week
                  </p>
                  {/* <p className="fw-bold mt-2">{Math.ceil(totalSR?.lastweek).toLocaleString()}</p> */}
                  {totalSR?.previousweek}
                  
                </div>
              </Col>
              <Col xs={3}>
                <div className="mt-3">
                  <p className="text-muted mb-1">
                    This <br /> Week
                  </p>
                  {/* <p className="fw-bold mt-2">{Math.ceil(totalSR?.sevenval).toLocaleString()}</p> */}
                  {totalSR?.lastsevendaysdata}
                </div>
              </Col>
              <Col xs={3}>
                <div className="mt-3">
                  <p className="text-muted mb-1">
                    {" "}
                    Growth <br />
                    (%)
                  </p>

                  {totalSR?.growthrateto > 0 ? (
                    <p className="fw-bold mt-2">
                      {" "}
                      <span className="pre-order-bg">
                        {Math.ceil(totalSR?.growthrateto)} %
                      </span>{" "}
                      <img src={arrowUp} alt="" />{" "}
                    </p>
                  ) : (
                    <p className="fw-bold mt-2">
                      {" "}
                      <span className="pre-order-bg-low">
                        {Math.ceil(totalSR?.growthrateto)} %
                      </span>{" "}
                      <img src={arrowDown} alt="" />{" "}
                    </p>
                  )}
                  
                </div>
              </Col>
            </Row>

            <hr />
            {topSrLoading ? (
              <div className="text-center pt-5">
                <Spinner />
              </div>
            ) : (
              <div>
                {(topSRs?.test || []).map((data, key) => {
                  const target = data?.percentage
                    .replace(/\d+% ?/g, "")
                    .split(".")
                    .join("")
                  let actualTarget
                  if (target >= 100) {
                    actualTarget = 100
                  }
                  if (target < 100) {
                    actualTarget = target
                  }

                  return (
                    <>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <div className="py-2">
                            <h5 className="font-size-14">
                              {/* <img
                                src={`${process.env.REACT_APP_S3}${data.image}`
                              }
                                className="rounded-circle avatar-xs align-self-center me-3"
                                alt=""
                              /> */}
                              <img
                                src={userImage}
                                className="rounded-circle avatar-xs align-self-center me-3"
                                alt=""
                              />
                              {data?.name}{" "}
                              <span className="float-end">
                                {actualTarget} %
                              </span>
                            </h5>
                            <div className="progress animated-progess progress-sm mt-2">
                              <div
                                className={"progress-bar bg-" + bgClr[key]}
                                role="progressbar"
                                style={{ width: data?.percentage }}
                                aria-valuenow="78"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </>
                  )
                })}
              </div>
            )}
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  )
}

export default TopSR
