import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getArea, getRegion, getTerritroy } from "../../store/GeoInformation/actions";
import {addNewGeo} from '../../store/GeoInformation/actions'


const AddRole = () => {
  const Rules = [
    "X",
    "Y",
    "Z",
    "A",
    "B",
    "C",
    "D"
]
const [selectedOptions, setSelectedOptions] = useState([])
const [selectedMulti, setselectedMulti] = useState([])
useEffect(() => {

  let roleList = []
  if (Rules) {
    Rules.forEach(element => {
          let obj = {}

          obj.label = element
          obj.value = element
          roleList.push(obj)
      })
      //stored from reducer
      setSelectedOptions(roleList)
  }

}, []);
const handleMultiChange = e => {
  setselectedMulti(Array.isArray(e) ? e.map(x => x.value) : [])
}
    

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>DDC | Create Role</title>
                </MetaTags>
                <Container fluid={true} >
                    <Breadcrumbs title="Create Role" breadcrumbItem="Create Role" />
                    <Row>
                        <Col md={6} className='m-auto'>
                            <Card >
                                <CardBody >
                                    <CardTitle className="h4 mb-4">Create Role</CardTitle>
                                        <AvForm>
                                        <Row>
                                            <Col sm="12" >
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="roleName">Role Name</Label>
                                                    <AvField
                                                        id="roleName"
                                                        name="name"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='Enter role name'
                                                    />
                                                </FormGroup>
                                              
                                               
                                                <Row>
                                            <Col lg={12}>
                                                <div className="mb-6">
                                                    <label className="control-label">Selcet Permission</label>
                                                    <Select
                                                        value={selectedOptions.filter(obj =>
                                                            selectedMulti.includes(obj.value)
                                                        )}
                                                        options={selectedOptions}
                                                        isMulti
                                                        isClearable
                                                        onChange={handleMultiChange}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>

                                        </Row>
                                            </Col>

                                        </Row>
                    
                                            
                                      
                                            <div>
                                                <button type="submit" className="btn btn-primary w-md mt-2">
                                                    Create Role
                                                </button>
                                            </div>
                                        </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* end row  */}



                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment >
    );
};

export default AddRole;

