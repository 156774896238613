import React from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody, Col, Row, Progress } from "reactstrap"
import "../../assets/css/style.css"
import bdtIamge from "../../assets/images/taka.svg"

const GraphPerformance = () => {
  var state = {
    series: [70],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          inverseOrder: false,
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          hollow: {
            margin: 5,
            size: "50%",
            background: "transparent",
            image: undefined,
            position: "front",
          },
          track: {
            show: true,
            background: "#FD94A7",
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: "16px",

              fontWeight: 600,

              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: "20px",
              fontFamily: undefined,
              fontWeight: 800,
              color: undefined,
              // offsetY: 16,
            },

            total: {
              show: true,
              label: "Achievement",
              color: "#373d3f",
              fontSize: "14px",

              fontWeight: 600,
            },
          },
        },
      },
      colors: ["#34C38F"],
    },
  }
  var state1 = {
    series: [70],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          inverseOrder: false,
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          hollow: {
            margin: 5,
            size: "50%",
            background: "transparent",
            image: undefined,
            imageWidth: 150,
            imageHeight: 150,
            imageOffsetX: 0,
            imageOffsetY: 0,
            imageClipped: true,
            position: "front",
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.5,
            },
          },
          track: {
            show: true,
            background: "#FD94A7",
            strokeWidth: "97%",
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: "16px",
              fontFamily: undefined,
              fontWeight: 600,
              color: undefined,
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: "20px",
              fontFamily: undefined,
              fontWeight: 800,
              color: undefined,
              // offsetY: 16,
            },

            total: {
              show: true,
              label: "Achievement",
              color: "#373d3f",
              fontSize: "14px",

              fontWeight: 600,
            },
          },
        },
      },

      colors: ["#34C38F"],
    },
  }

  return (
    <Card className="page-content">
     
        <div>
          <Row className="performance-graph-info">
            <Col md={5}>
              <div className="performance-info">
                <div id="chart">
                  <ReactApexChart
                    options={state1.options}
                    series={state1.series}
                    type="radialBar"
                    height={280}
                  />
                </div>
                <div className="graph-text">
                  <div>
                    <h6 className="achievement-text">
                      <span className="achievement"></span>Achievement
                    </h6>
                    <h6 className="achievement-value">
                      <img src={bdtIamge} className="bdt-value"></img> 1,00,0000
                    </h6>
                  </div>
                  <div className="mt-4">
                    <h6 className="graph-info">
                      <span className="graph-info-text"></span>Target
                    </h6>
                    <h6 className="graph-value">
                      <img src={bdtIamge} className="graph-bdt-value"></img>{" "}
                      1,00,0000
                    </h6>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={5}>
              <div className="performance-info">
                <div id="chart">
                  <ReactApexChart
                    options={state.options}
                    series={state.series}
                    type="radialBar"
                    height={280}
                  />
                </div>
                <div className="graph-text">
                  <div>
                    <h6 className="achievement-text">
                      <span className="achievement"></span>Total visited outlet
                    </h6>
                    <h6 className="achievement-value">
                      <img src={bdtIamge} className="bdt-value"></img> 1,00,0000
                    </h6>
                  </div>
                  <div className="mt-4">
                    <h6 className="graph-info">
                      <span className="graph-info-text"></span>Total no. of
                      outlet
                    </h6>
                    <h6 className="graph-value">
                      <img src={bdtIamge} className="graph-bdt-value"></img>{" "}
                      1,00,0000
                    </h6>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="performance-info">
                <div id="chart">
                  <ReactApexChart
                    options={state1.options}
                    series={state1.series}
                    type="radialBar"
                    height={280}
                  />
                </div>
                <div className="graph-text">
                  <div>
                    <h6 className="achievement-text">
                      <span className="achievement"></span>Total Memo
                    </h6>
                    <h6 className="achievement-value">
                      <img src={bdtIamge} className="bdt-value"></img> 1,00,0000
                    </h6>
                  </div>
                  <div className="mt-4">
                    <h6 className="graph-info">
                      <span className="graph-info-text"></span>Total visited
                      outlet
                    </h6>
                    <h6 className="graph-value">
                      <img src={bdtIamge} className="graph-bdt-value"></img>{" "}
                      1,00,0000
                    </h6>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={5}>
              <div className="performance-info">
                <div id="chart">
                  <ReactApexChart
                    options={state.options}
                    series={state.series}
                    type="radialBar"
                    height={280}
                  />
                </div>
                <div className="graph-text">
                  <div>
                    <h6 className="achievement-text">
                      <span className="achievement"></span>Total no of brand
                    </h6>
                    <h6 className="achievement-value">
                      <img src={bdtIamge} className="bdt-value"></img> 1,00,0000
                    </h6>
                  </div>
                  <div className="mt-4">
                    <h6 className="graph-info">
                      <span className="graph-info-text"></span>Total Memo
                    </h6>
                    <h6 className="graph-value">
                      <img src={bdtIamge} className="graph-bdt-value"></img>{" "}
                      1,00,0000
                    </h6>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
       {/* <div className="user-performance">
       <Row>
         <h3>Achievement</h3>
            <Progress value={75} color="success"/>
           
          </Row>
       </div> */}
        </div>
     
    </Card>
  )
}

export default GraphPerformance
