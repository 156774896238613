import React from "react"
import { useSelector } from "react-redux"
import { Route } from "react-router-dom"
// import "./assets/scss/theme.scss"
import HorizontalLayout from "../components/HorizontalLayout/"
// layouts Format
import PropTypes from "prop-types"
import VerticalLayout from "../components/VerticalLayout"
import LoadingToRedirect from "./LoadingToRedirect"

const OrgRoute = ({ children, ...rest }) => {
  const { token, email, userrole } = useSelector(state => ({ ...state.Login }))

  const { layoutType } = useSelector(state => ({ ...state.Layout }))

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout

        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return token && userrole === "ORG" ? (
    <Layout>
      <Route {...rest} render={() => children} />
    </Layout>
  ) : (
    <React.Fragment>
      <LoadingToRedirect />
    </React.Fragment>
  )
}

OrgRoute.propTypes = {
  children: PropTypes.any,
}

export default OrgRoute
