import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  CardText,
  Spinner
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom"
import TableDamaged from "./TableDamaged";





const DamageInfo = () => {

return (
  <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>DDC</title>
      </MetaTags>

      <Container fluid>
        {/* <h4 className='mb-3'>{product?.singleproduct?.name}</h4> */}
        <Row>

          <Col xl="12">
            <Row>

              {/* storeData Render */}

              <Col md="3">
                <Card className="mini-stats-wid card-info-list">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                        Total Return and Amount
                        </p>
                        
                        <h6 className="mb-1 fw-bold">320 tk. <span className="text-danger quantity-sell"> |  </span> 15 Pc. </h6>
                      </div>
                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i
                            className={
                              "bx " + "bx-copy-alt" + " font-size-24"
                            }
                          />

                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
         
              <Col md="3">
                <Card className="mini-stats-wid card-info-list">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                        Damage analytics
                        </p>
                      
                      </div>
                    
                    </div>
                    <div className="">
                          <Link
                            to="/damage-analytics"
                            className="btn btn-primary btn-sm "
                          >
                          See analytics
                          </Link>
                        </div>
                  </CardBody>
                </Card>
              </Col>

         

            </Row>


          </Col>
        </Row>


    
      <TableDamaged/>


      </Container>
                  
    </div>


  </React.Fragment >
);
};

export default DamageInfo;