import Breadcrumbs from "components/Common/Breadcrumb"
import { isEmpty, map } from "lodash"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter, useHistory } from "react-router-dom"
import XLSX from "xlsx"

import { MDBDataTable } from "mdbreact"

import {
  Badge,
  Button,
  Col,
  Card,
  CardBody,
  Container,
  Row,
  Spinner,
} from "reactstrap"

// import { getOrders } from '../../store/Order/actions';
import { getRoute } from "../../store/GeoInformation/actions"
import { getallOrder } from "../../store/Order/actions"
import routeListDown from "../../assets/images/routeListDown.svg"
const OrderDataTable = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getRoute(authtoken, id))
  }, [])

  const { routes, authtoken, loading, id, routeloading,allOrder,allOrderLoading } = useSelector(
    state => ({
      routes: state.GeoInformationReducer.routes,
      allOrder: state.OrderReducer.allOrder,
      authtoken: state.Login.token,
      id: state.Login.id,
      loading: state.GeoInformationReducer.loading,
      routeloading: state.GeoInformationReducer.routeloading,
      allOrderLoading:state.OrderReducer.allOrderLoading
    })
  )
  useEffect(() => {
    dispatch(getallOrder(authtoken))
  }, [])
  console.log("allOrder",allOrder);
  console.log('routes',routes);
  let allOrderExcel
  if(!allOrderLoading){
    allOrderExcel = allOrder.data.todayallorder

  }

  function convertDigitIn(str){
    console.log("str",str.split('-').reverse().join('-'));
    return str.split('-').reverse().join('-');
 }
  const handleExcel = () => {
    let newArray = []
    allOrderExcel.forEach((order, index) => {
      let data = {}
      data.Shop = order.shop.name
      data.SRName = order.sr.name
      // data.DPName = "Dhaka"
      data.orderTakenTime = order?.ordertakentime
      data.OrderTakenDate = order?.orderTakenDate
      data.OrderDeliveryDate =  convertDigitIn(order.deliveryDate)
      data.route = order?.route?.routeCode
      data.routeName = order?.route?.name
      data.area = order?.area?.name,
      data.region = order?.region?.name,
      data.grandtotal = parseFloat(order.grandTotal).toFixed(2)
      // console.log('order.grandTotal',typeof (parseFloat(order.grandTotal)));
      order.orderProducts.forEach((dt, idx) => {
        data[`ProductName-${idx}`] = dt.productName
        data[`ProductQty-${idx}`] = dt.qty
        data[`UnitPrice-${idx}`] = parseFloat(dt.unitPrice).toFixed(2)
        data[`Total-${idx}`] = parseFloat(dt.total).toFixed(2)
      })
      console.log(data);
      newArray.push(data)
    })
    downloadxls(newArray)
  }
  const downloadxls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "Shop Order.xlsx")
  }
  // // console.log(orders.Order);
  // useEffect(() => {
  //     dispatch(getOrders(authtoken, id));
  // }, []);

  // console.log(orders);
  // console.log(orderLoading);

  const tableData =
    routeloading &&
    routes.data.route.map((route, index) => {
      return {
        sl: index + 1,
        heading1: route?.name,
        heading2: route?.geo?.area?.name,
        heading3: route?.geo?.region?.name,
        heading4: route?.geo?.teritori?.name,

        manage: (
          <>
            <Button
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                history.push(`/Route-order/${route._id}/${route.name}`)
              }}
            >
              Order List
            </Button>
          </>
        ),
      }
    })

  const orderData = {
    columns: [
      {
        label: "No.",
        field: "sl",
        sort: "asc",
        width: 150,
      },

      {
        label: "Route Name",
        field: "heading1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Area",
        field: "heading2",
        sort: "asc",
        width: 150,
      },

      {
        label: "Region",
        field: "heading3",
        sort: "asc",
        width: 150,
      },
      {
        label: "Territory",
        field: "heading4",
        sort: "asc",
        width: 150,
      },

      {
        label: "View Details",
        field: "manage",
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <MetaTags>
          <title>Order | DDC</title>
        </MetaTags>
        <Container fluid>
          <h5 className="mb-3"> All Routes</h5>
          {/* <Breadcrumbs title="Dashboard" breadcrumbItem="See All Route" /> */}
          {routeloading ? (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      noBottomColumns
                      striped
                      data={orderData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className="text-center  pt-5">
              <Spinner />
            </div>
          )}
          <Card>
            <div className="route-excel">
              <div>
                <p className="mb-0">Download Excelsheet</p>
              </div>
              <div>
                <button className="route-excel-download" onClick={handleExcel}>
                  {" "}
                  <img src={routeListDown}></img>Download
                </button>
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(OrderDataTable)
