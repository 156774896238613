import React, { Component, useEffect, useState } from "react"

import classNames from "classnames"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Input,

} from "reactstrap"


import { Link } from "react-router-dom"
import StoreAmountChart from "./StoreAmountChart";

const StoreAmountUnit = () => {
  const [periodType, setPeriodType] = useState("yearly")
  

  return (
    <React.Fragment>
    <Card>
                <CardBody>
                 
                  <div className="d-sm-flex flex-wrap">
                  <h4 className="card-title mb-4">Store Wise Total Amount</h4>
                  <div className="ms-auto">
               
                      <ul className="nav nav-pills">
                      <li className="d-flex align-items-center">
                     
                          <Input
                            type="text"
                            className="form-control border-0 bg-light me-2"
                            placeholder="Bhai Bhai Store..."
                          />
                         
                        </li>
                        <li className="nav-item me-2 ">
                       
                        </li>
                        
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link","bg-dark"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly")
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <StoreAmountChart/>
                </CardBody>
              </Card>
  
    </React.Fragment>
  )
}

export default StoreAmountUnit
