"use strict"
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { postLogin } from "../../../helpers/api_helper"
import {
  postSocialLogin
} from "../../../helpers/backend_helper"
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { userError } from "../loginError/actions"
import { loginSuccess, loginUserError } from "./actions"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"






const loginWithEmployeeIdAsync = async (email, password) => {
  try {
    const response = await postLogin(email, password)
    console.log("hello response", response.data)
    return response.data
  } catch (error) {
    console.log("response from backend error", error.response.data)
     return error.response.data
  }
}

function* loginUser({ payload: { user, history } }) {
  const { email, password } = user
  console.log("hello data", email, password)
  try {
    console.log("from 22 line", email, password)

    const loginUserResponse = yield call(
      loginWithEmployeeIdAsync,
      email,
      password
    )

    console.log("response data from line 42", loginUserResponse)
    if (loginUserResponse.status === "success") {
      console.log('hello');
      // console.log("user data", loginUserResponse)
      // localStorage.setItem("authUser", loginUserResponse.token,)
      yield put(
        loginSuccess(
          loginUserResponse.user.name,
          loginUserResponse.token,
          loginUserResponse.user._id,
          loginUserResponse.user.email,
          loginUserResponse.user.role,
          loginUserResponse.user?.org?._id,
          loginUserResponse.user?.office?._id 
        )
      )
      console.log(loginUserResponse);
      if (loginUserResponse?.user?.role == "ADMIN") {
        history.push("/dashboard")
      } else if (loginUserResponse?.user?.role == "ORG") {
        
        console.log('hello');
        history.push("/orgdashboard")}
        else if (loginUserResponse?.user?.role == "DISTRIBUTOR") {
          console.log('hello');
          history.push("/activityProductList")}
      
      // history.push("/dashboard")
    }
     
    else {
      console.log("data error", loginUserResponse.message)
      yield put(userError(loginUserResponse.message))
      yield put(loginUserError())
    }
  } catch (err) {
    console.log("error from here", err)
  }
}

function* logoutUser({ history }) {
  try {
    history.push("/")
    // yield put(loginSuccess())
    // yield put(userError())

    // window.location.reload("/login")
    // history.push("/")
  } catch (error) {
    // yield put(apiError(error))
    console.log("error data", error)
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
