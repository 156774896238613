import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Spinner
} from "reactstrap"


import { Link } from "react-router-dom"
import OrderDataTable from "./TableRouteOrder";
import { useDispatch, useSelector } from "react-redux";
import {  useRouteMatch } from "react-router";

import { getRouteOrders } from '../../store/Order/actions';
import '../../pages/styles.css'

const moment = require("moment")

const RouteOrderList = () => {
  const year = moment().format("MM-YYYY")
  console.log('helllo',year);
  const dispatch = useDispatch();
        const {
            params: { routeID },
        } = useRouteMatch('/Route-order/:routeID');
        console.log(routeID);
    
    
        const { routeOrders, authtoken, routeloading, id } = useSelector(state => ({
            routeOrders: state.OrderReducer.routeOrders.data,
            id: state.Login.id,
            authtoken: state.Login.token,
            routeloading: state.OrderReducer.routeloading
        }));
        
        useEffect(() => {
            dispatch(getRouteOrders(authtoken, id, routeID, year));
        }, []);
      

 console.log(routeOrders);
    return (
        <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>DDC | Order</title>
          </MetaTags>
          {routeloading ? (
                        <div className="text-center mt-5 pt-5">
                            <Spinner />
                        </div>
                    ) : (
          <Container fluid>
            <Row>
            <h5 className='mb-3 mt-3'></h5>
              <Col xl="12">
                <Row>

                  {/* storeData Render */}
                 
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                               Received Orders (Total)
                              </p>
                              <h4 className="mb-0">{routeOrders?.totalreciveorder}</h4>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                               Received Orders (Today)
                              </p>
                              <h5 className="mb-1">{routeOrders?.todayreceiveorder}<small className='text-danger'>     </small></h5>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                               Received Amount
                              </p>
                              {/* <h5 className="mb-1">{localStorage.getItem('dateTotalCollect')<0?0:localStorage.getItem('dateTotalCollect')}</h5> */}
                              <h5>0</h5>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card className="mini-stats-wid card-info-list">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                               Due Amount
                              </p>
                              {/* <h5 className="mb-1">{localStorage.getItem('dateTotalDue')}</h5> */}
                              <h5>0</h5>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
{/*                   
              <Col md="3">
              <Card >
                  <CardBody >
                    <CardTitle className="mb-0 h4">Order Analytic</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/add-geo"
                            className="btn btn-primary btn-md"
                          >
                            See Analytic
                          </Link>
                        </div>
                      </Col>
                     
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col> */}
                 
                </Row>
               

              </Col>
            </Row>
      
         <OrderDataTable/>
          </Container>
                    )}
        </div>


      </React.Fragment >
    );
};

export default RouteOrderList;