import React from "react";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody, Col, Container,
  Row,CardTitle
} from "reactstrap";
import { Link } from "react-router-dom"
import UserTableData from "../../pages/Users/UserTableData";



const AdminUserDetails = () => {

 
    return (
        <React.Fragment>
         <div className="page-content">
          <MetaTags>
            <title>DDC</title>
          </MetaTags>
          <Container fluid>
            <Row>

              <Col xl="12">
                <Row>

                  {/* storeData Render */}
                 
                    <Col md="3">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Total Users
                              </p>
                              <h4 className="mb-0">100</h4>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Top User as SR
                              </p>
                              <h4 className="mb-0">--</h4>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Top User as DP
                              </p>
                              <h4 className="mb-0">--</h4>

                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " +"bx-copy-alt" + " font-size-24"
                                  }
                                />

                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
         
              <Col md="3">
              <Card >
                  <CardBody >
                    <CardTitle className="mb-0 h4">Add User</CardTitle>
                    <Row>
                      <Col >
                        <div className="mt-2 ">
                          <Link
                            to="/adminUser"
                            className="btn btn-primary btn-md"
                          >
                            Add User
                          </Link>
                        </div>
                      </Col>
                     
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
                 
                </Row>
               

              </Col>
            </Row>
          

        <UserTableData/>
          </Container>
        </div>


       
      </React.Fragment >
    );
};

export default AdminUserDetails;