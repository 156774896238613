import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
// import {
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
// } from "reactstrap"
import {
  clearFilters,
  getAreaByRegion,
  getTerritoryByArea,
  onAreaFilterChange,
  onMainFilterChange,
  onRegionFilterChange,
  onTerritoryFilterChange,
} from "store/actions"
import { getRegion } from "../../store/GeoInformation/actions"
const data = [
  {
    name: "National",
    value: "national",
    id: "",
  },
]

const DashboardFilter = ({ title }) => {
  const dispatch = useDispatch()
  const [loadArea, setLoadArea] = useState(false)
  const [loadTerritory, setLoadTerritory] = useState(false)
  useEffect(() => {
    dispatch(clearFilters())
  }, [])

  const {
    authToken,
    // selectedSubFilter,
    region,
    mainFilter,
    selectedRegion,
    selectedArea,
    // selectedTerritory,
    // currentSelection,
    areaList,
    territoryList,
  } = useSelector(state => ({
    areaList: state.DashboardReducer.areaList,
    territoryList: state.DashboardReducer.territoryList,
    mainFilter: state.DashboardReducer.mainFilter,
    // currentSelection: state.DashboardReducer.currentSelection,
    selectedRegion: state.DashboardReducer.selectedRegion,
    selectedArea: state.DashboardReducer.selectedArea,
    // selectedTerritory: state.DashboardReducer.selectedTerritory,
    authToken: state.Login.token,
    region: state.GeoInformationReducer.region.region,
  }))

  useEffect(() => {
    // dispatch(getData())
    if (mainFilter?.value === "national") {
      dispatch(getRegion(authToken))
    }
  }, [mainFilter])

  useEffect(() => {
    if (Object?.keys(selectedRegion)?.length) {
      dispatch(getAreaByRegion(authToken, selectedRegion?._id))
    }
  }, [loadArea])

  useEffect(() => {
    if (Object?.keys(selectedArea)?.length) {
      dispatch(getTerritoryByArea(authToken, selectedArea?._id))
    }
  }, [loadTerritory])

  const handleMainFilter = data => {
    dispatch(onMainFilterChange(data))
  }
  const handleRegion = data => {
    dispatch(onRegionFilterChange(data))
    setLoadArea(!loadArea)
  }

  // area
  const handleArea = data => {
    dispatch(onAreaFilterChange(data))
    setLoadTerritory(!loadTerritory)
  }

  // territory
  const handleTerritory = data => {
    dispatch(onTerritoryFilterChange(data))
  }
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-4">
          <h3 className="jdfhgbfjkhdfbdgfdu">{title}</h3>
        </div>{" "}
        <div className="col-sm-8">
          <div className="d-flex justify-content-end mobile-responsive-kudhdbvbn">
            {Object.keys(selectedArea)?.length && territoryList.length ? (
              <div
                //  style={{ minWidth: "150px" }}
                className=""
              >
                <Select
                  name="mainFilter"
                  classNamePrefix="select2-selection"
                  // disabled={loadingCondition}
                  // style={{ minWidth: "100px" }}
                  //   cacheOptions
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e._id}
                  // defaultInputValue={}
                  placeholder={"Select Territory"}
                  options={territoryList}
                  onChange={handleTerritory}
                />
              </div>
            ) : null}
            {Object.keys(selectedRegion)?.length && areaList?.length ? (
              <div
              //  style={{ minWidth: "150px" }}
              //   className="me-3"
              >
                <Select
                  name="mainFilter"
                  classNamePrefix="select2-selection"
                  // disabled={loadingCondition}
                  // style={{ minWidth: "100px" }}
                  //   cacheOptions
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e._id}
                  // defaultInputValue={}
                  placeholder={"Select Area"}
                  options={areaList}
                  onChange={handleArea}
                />
              </div>
            ) : null}
            {Object.keys(mainFilter)?.length &&
            mainFilter?.value === "national" ? (
              <div
              //  style={{ minWidth: "150px" }}
              //  className="me-3"
              >
                <Select
                  name="mainFilter"
                  classNamePrefix="select2-selection"
                  // disabled={loadingCondition}
                  style={{ minWidth: "100px" }}
                  //   cacheOptions
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e._id}
                  // defaultInputValue={}
                  placeholder={"Select Region"}
                  options={region}
                  onChange={handleRegion}
                />
              </div>
            ) : null}
            <div

            // style={{ minWidth: "150px" }}
            >
              <Select
                name="mainFilter"
                classNamePrefix="select2-selection"
                // style={{ minWidth: "100px" }}
                cacheOptions
                getOptionLabel={e => e.name}
                getOptionValue={e => e._id}
                defaultValue={data?.filter(data => data.value === "national")}
                options={data}
                onChange={handleMainFilter}
                // isClearable
                // isSearchable
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

DashboardFilter.propTypes = {
  title: PropTypes.string,
}

export default DashboardFilter
